import React from 'react'
import { ColoredText } from './UtilComponents'

const RosterDepth = ({
  format,
  handleSettings,
  loadingState,
  saveSettings,
  selectHandler,
  selectValue,
  userSettings,
}) => {
  const handleCheckbox = (e) => {
    const newState = { ...userSettings }
    newState.rosterDepth[format][selectValue].useInverse = e.target.checked
    handleSettings(newState)
  }

  const handleMultiplier = (value) => {
    const newState = { ...userSettings }
    newState.rosterDepth[format][selectValue].scale = value
    handleSettings(newState)
  }

  const multiplierValue = userSettings.rosterDepth[format][selectValue]?.scale
  const inverseBool =
    userSettings.rosterDepth[format][selectValue]?.useInverse || false

  return (
    <div>
      <div>
        {/* <div className="ap-header">8 and 10 Man Team Adjustment League</div> */}
        <div className="ap-header">
          Roster Size:{' '}
          <ColoredText
            text={`${selectValue.split('_').join(' ')} (${format})`}
          />
        </div>

        <div className="ap-roster-depth-header">
          <div className="ap-user-setting-segment">
            <div>Roster Depth</div>
            <select
              className="ap-user-settings-select"
              onChange={(e) => selectHandler(e.target.value)}
              value={selectValue}
            >
              <option value="SHALLOW">Shallow</option>
              <option value="NORMAL">Normal</option>
              <option value="DEEP">Deep</option>
              <option value="VERY_DEEP">Very Deep</option>
            </select>
          </div>
          <div className="ap-user-value ap-inverse-checkbox">
            <div>Use Inverse</div>
            <input
              checked={inverseBool}
              onChange={handleCheckbox}
              type="checkbox"
            />
          </div>
        </div>

        <div className="ap-segment">
          <div>
            <div>Depth Multiplier</div>
            <div>
              <input
                className="ap-input"
                onChange={(e) => handleMultiplier(e.target.value)}
                value={multiplierValue}
              />
            </div>
            <button className="ap-button" onClick={saveSettings}>
              Save
            </button>
            <span className="loading-span">{loadingState}</span>
          </div>
          <div>
            <div className="ap-intro-algo">
              <div className="ap-user-value">Base</div>
              <pre>
                return elo_rating * SCALE_BY_RANK(
                <ColoredText text={'MULTIPLIER'} /> / 100, PLAYER_RANK)
              </pre>
            </div>
            <div>
              <div className="ap-user-value">Edited</div>
              <pre>
                return elo_rating * SCALE_BY_RANK(
                <ColoredText text={multiplierValue} /> / 100, PLAYER_RANK)
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RosterDepth
