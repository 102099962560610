import React from 'react'

const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) {
    return null
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="max-w-[100vw] rounded-lg bg-white p-4 text-light-text shadow-lg dark:bg-dark dark:text-dark-text">
        <h4>Are you sure you want to delete this team?</h4>
        <div className="mt-4 flex justify-end gap-4">
          <button
            className="rounded bg-accent px-4 py-2 text-dark-text hover:bg-orange-600"
            onClick={onConfirm}
          >
            Delete
          </button>
          <button
            className="rounded bg-gray-300 px-4 py-2 hover:bg-gray-400 dark:bg-dark-bg2"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationModal
