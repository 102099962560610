import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import * as Tabs from '@radix-ui/react-tabs'
import HorozontalDivider from '../../_ui/HorozontalDivider'
import { HiSortAscending, HiSortDescending } from 'react-icons/hi'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

import {
  getUserReviews,
  getCreatorReviews,
  getUserLeagueProfiles,
} from '../../../store/api'
import Review from './Review'
import loadingGif from '../../../images/FlockLoadingIcon.gif'
import FeedbackModal from './FeedbackModal'
import BaseSearchBar from '../../_ui/BaseSearchBar'
import BaseSelect from '../../_ui/BaseSelect'
import TradeQuestion2 from './TradeQuestion2'
import StartSitQuestions from './StartSitQuestions'
import ProductTag from './ProductTag'

const MyReviews = () => {
  const { user_id, is_creator, is_contributor } = useSelector(
    (state) => state.user
  )

  const [tab, setTab] = useState('Pending')
  const [selectedReview, setSelectedReview] = useState(null)
  const [advice, setAdvice] = useState([])
  const [profiles, setProfiles] = useState([])
  const [isFromCreatorEndpoint, setIsFromCreatorEndpoint] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [productTypes, setProductTypes] = useState([
    'reviews',
    'trade_questions',
    'start_sit_questions',
  ])
  const [order, setOrder] = useState('desc')
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)

  //---------------------------------------------------------------- Use Effects ------------------------------------------------------------------------------------

  useEffect(() => {
    if (is_creator || is_contributor) {
      fetchCreatorReviews()
    } else {
      fetchUserReviews()
    }
    fetchUserProfiles()
  }, [tab, productTypes, order, limit, page, searchTerm])

  //---------------------------------------------------------------- Data Fetching ------------------------------------------------------------------------------------

  const fetchUserReviews = async () => {
    setLoading(true)
    try {
      const response = await getUserReviews(
        user_id,
        order,
        productTypes.join(','),
        tab.toLocaleLowerCase(),
        limit,
        page,
        searchTerm
      )
      setAdvice(response.data)
      return response // Return the response
    } catch (error) {
      console.error('Error fetching user reviews:', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchCreatorReviews = async () => {
    setLoading(true)
    try {
      const response = await getCreatorReviews(
        user_id,
        order,
        productTypes.join(','),
        tab.toLocaleLowerCase(),
        limit,
        page,
        searchTerm
      )
      setAdvice(response.data)
      setIsFromCreatorEndpoint(true)
      return response // Return the response
    } catch (error) {
      console.error('Error fetching creator reviews:', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchUserProfiles = async () => {
    try {
      const response = await getUserLeagueProfiles()
      setProfiles(response)
    } catch (error) {
      console.error('Error fetching user profiles:', error)
      throw error
    }
  }

  //---------------------------------------------------------------- Utility Functions ------------------------------------------------------------------------------------

  const handleStatusChange = (reviewId) => {
    if (is_creator || is_contributor) {
      handleCreatorStatusChange(reviewId)
    } else {
      handleUserStatusChange()
    }
    setTab('Pending')
  }

  const handleCreatorStatusChange = async () => {
    fetchCreatorReviews(user_id)
    fetchUserProfiles()
  }

  const handleUserStatusChange = () => {
    fetchUserReviews()
    fetchUserProfiles()

    setShowModal(true)
  }

  const handleReviewUpdate = async () => {
    try {
      let newReviews
      if (is_creator || is_contributor) {
        newReviews = await fetchCreatorReviews()
      } else {
        newReviews = await fetchUserReviews()
      }
      await fetchUserProfiles()

      // Update selectedReview with the new data
      if (selectedReview) {
        const updatedReview = newReviews.data.find(
          (review) => review.id === selectedReview.id
        )
        if (updatedReview) {
          setSelectedReview(updatedReview)
        }
      }
    } catch (error) {
      console.error('Error re-fetching reviews:', error)
    }
  }

  const selectProduct = (product) => {
    setPage(1)
    if (productTypes.includes(product)) {
      setProductTypes(productTypes.filter((type) => type !== product))
    } else {
      setProductTypes([...productTypes, product])
    }
  }

  const handleSelectReview = (review) => {
    let targetTab = 'Pending'
    if (review.status === 'complete') {
      targetTab = 'Complete'
    } else if (review.status === 'rejected') {
      targetTab = 'Rejected'
    }

    setTab(targetTab)
    setSelectedReview(review)
    setSearchTerm('')
  }

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const handleNextPage = () => {
    setPage(page + 1)
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const options = { hour: '2-digit', minute: '2-digit' }
    return `${date.toLocaleDateString()} at ${date.toLocaleTimeString([], options)}`
  }

  const tabs = [
    { value: 'Pending', label: 'Pending' },
    { value: 'in_progress', label: 'In Progress' },
    { value: 'Complete', label: 'Complete' },
    { value: 'Rejected', label: 'Rejected' },
  ]

  const renderSelectedReview = () => {
    if (!selectedReview) {
      return null
    }
    switch (selectedReview.table_name) {
      case 'trade_questions':
        return (
          <TradeQuestion2
            isCreator={isFromCreatorEndpoint}
            key={selectedReview.id} // Ensure a unique key is used
            onStatusChange={handleStatusChange}
            onUpdate={handleReviewUpdate}
            question={selectedReview}
            username={selectedReview.user_display_name}
          />
        )
      case 'reviews':
        return (
          <Review
            isFromCreatorEndpoint={isFromCreatorEndpoint}
            onStatusChange={handleStatusChange}
            onUpdate={handleReviewUpdate}
            profiles={profiles}
            review={selectedReview}
            teamName={selectedReview ? selectedReview.team_name : ''}
          />
        )
      case 'start_sit_questions':
        return (
          <StartSitQuestions
            isCreator={isFromCreatorEndpoint}
            key={selectedReview.id}
            onStatusChange={handleStatusChange}
            onUpdate={handleReviewUpdate}
            question={selectedReview}
            username={selectedReview.user_display_name}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className="flex flex-col gap-4 bg-light px-4 py-16 font-dmsans text-light-text dark:bg-dark dark:text-dark-text sm:px-[10%] md:px-[15%]">
      <Tabs.Root
        className="flex flex-col gap-6"
        defaultValue="Pending"
        onValueChange={(value) => setTab(value)}
        value={tab}
      >
        <div className="flex grid-cols-5 flex-wrap items-center gap-4 md:grid">
          <h3 className="col-span-1 text-xl font-bold md:text-3xl">
            My Reviews &nbsp;
            {(is_creator || is_contributor) && (
              <span className="text-sm italic text-accent md:text-lg">
                Creator
              </span>
            )}
          </h3>
          <div className="col-span-3 flex justify-center pl-2">
            <Tabs.List className="inline-flex flex-wrap justify-center gap-1 rounded-xl bg-light-bg p-1 dark:bg-dark-bg-select">
              {tabs.map((tabItem) => (
                <Tabs.Trigger
                  className={`rounded-lg px-8 py-2 transition-all duration-200 hover:bg-accent-bg hover:text-accent hover:dark:text-accent ${
                    tab === tabItem.value
                      ? 'bg-accent-bg font-bold text-accent'
                      : 'text-light-text2 dark:text-dark-text2'
                  }`}
                  key={tabItem.value}
                  value={tabItem.value}
                >
                  {tabItem.label}
                </Tabs.Trigger>
              ))}
            </Tabs.List>
          </div>
          <div className="col-span-1 flex justify-end gap-2">
            <BaseSelect
              highlight={productTypes.includes('reviews')}
              label={'Team Review'}
              onClick={() => selectProduct('reviews')}
            />
            <BaseSelect
              highlight={productTypes.includes('trade_questions')}
              label={'Trade Question'}
              onClick={() => selectProduct('trade_questions')}
            />
            <BaseSelect
              highlight={productTypes.includes('start_sit_questions')}
              label={'Start/Sit'}
              onClick={() => selectProduct('start_sit_questions')}
            />
          </div>
        </div>
        {loading ? (
          <div className="flex h-[75vh] justify-center">
            <img
              alt="Loading..."
              src={loadingGif}
              style={{ width: '250px', height: '250px' }}
            />
          </div>
        ) : (
          <div>
            {tabs.map((tabItem) => (
              <Tabs.Content key={tabItem.value} value={tabItem.value}>
                <div className="relative flex flex-col gap-4 rounded-xl lg:h-[70vh] lg:flex-row">
                  {advice.length > 0 ? (
                    <div className="flex flex-col gap-2">
                      <BaseSearchBar
                        onAddPlayer={handleSelectReview}
                        options={[]}
                        placeholder="Search for a Review"
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                      />
                      <div className="flex max-h-[75vh] flex-col gap-2 overflow-x-auto rounded-xl pb-16 md:max-h-[65vh] md:w-[310px]">
                        {advice.map((review, index) => (
                          <ProductTag
                            formatDate={formatDate}
                            index={index}
                            is_contributor={is_contributor}
                            is_creator={is_creator}
                            key={index}
                            onClick={() => setSelectedReview(review)}
                            product={review}
                            selectedReview={selectedReview}
                          />
                        ))}
                      </div>
                      <div className="absolute bottom-0 left-0 flex justify-between gap-6 rounded-b-xl px-4 py-4 backdrop-blur-lg md:w-[310px]">
                        <p className="flex items-center gap-4">
                          <IoIosArrowBack
                            className="transition-all duration-200 hover:text-accent dark:hover:text-accent"
                            onClick={handlePreviousPage}
                            size={28}
                          />
                          <span>{page}</span>
                          <IoIosArrowForward
                            className="transition-all duration-200 hover:text-accent dark:hover:text-accent"
                            onClick={() => setPage(page + 1)}
                            size={28}
                          />
                        </p>
                        <p>
                          {order === 'desc' ? (
                            <HiSortDescending
                              className="transition-all duration-200 hover:text-accent dark:hover:text-accent"
                              onClick={() => setOrder('asc')}
                              size={28}
                            />
                          ) : (
                            <HiSortAscending
                              className="transition-all duration-200 hover:text-accent dark:hover:text-accent"
                              onClick={() => setOrder('desc')}
                              size={28}
                            />
                          )}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="flex w-full flex-col items-center gap-10 py-10 text-center text-xl text-light-text dark:text-dark-text">
                      <img
                        alt="No reviews"
                        className="h-auto w-[150px]"
                        src=".https://static-site-images.s3.us-east-2.amazonaws.com/FF-REGULAR_V6.1.png"
                      />
                      You have no {tabItem.label} reviews
                    </div>
                  )}
                  <div className="md:hidden">
                    <HorozontalDivider />
                  </div>
                  {renderSelectedReview()}
                </div>
              </Tabs.Content>
            ))}
          </div>
        )}
      </Tabs.Root>
      {selectedReview && (
        <FeedbackModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          reviewId={selectedReview.id}
        />
      )}
    </div>
  )
}

export default MyReviews
