import React, { useState } from 'react'
import LineupPlayers from './LineupPlayers'
import BaseSearchBar from '../../../_ui/BaseSearchBar'
import { toast } from 'react-toastify'

const MyBench = ({
  players,
  setBench,
  bench,
  selectedPosition,
  setSelectedPosition,
  attachToPosition,
  disableX = false,
}) => {
  const [searchTerm, setSearchTerm] = useState('')

  const handleSelectPlayer = (player) => {
    if (!bench.find((p) => p.player_id === player.player_id)) {
      setBench((prevBench) => [...prevBench, player])
    }
  }

  const markContender = (index) => {
    setBench((prevBench) =>
      prevBench.map((player, i) =>
        i === index ? { ...player, isContender: !player.isContender } : player
      )
    )
  }

  const positionOrder = { QB: 1, RB: 2, WR: 3, TE: 4 }

  const sortAndGroupByPosition = (items) =>
    items
      .sort((a, b) => b.elo - a.elo)
      .sort((a, b) => positionOrder[a.position] - positionOrder[b.position])

  const sortedAndGroupedBench = sortAndGroupByPosition([...bench])

  return (
    <div className="grid gap-2">
      <span className="flex justify-between">
        <div>
          <h2 className="pb-2 text-xl font-semibold">My Bench</h2>
          {selectedPosition && (
            <span className="text-accent">
              Select {selectedPosition} |{' '}
              <button onClick={() => setSelectedPosition(null)}>Clear</button>
            </span>
          )}
        </div>
        {players && (
          <div className="max-w-[20rem]">
            <BaseSearchBar
              onAddPlayer={handleSelectPlayer}
              options={players}
              placeholder="Search for a Player"
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </div>
        )}
      </span>

      <div className="border-light-border flex flex-wrap justify-center gap-6 rounded-lg border-[1px] p-6 dark:border-dark-border">
        {sortedAndGroupedBench.map((player, index) => (
          <LineupPlayers
            isInactive={
              selectedPosition &&
              player.position !== selectedPosition &&
              selectedPosition !== 'FLEX'
            }
            disableX={disableX}
            isBench={true}
            isContender={player.isContender || player.is_contender}
            key={player.player_id}
            markContender={() => markContender(index)}
            onClick={() => attachToPosition(player)}
            player={player}
            position={player.position}
          />
        ))}
      </div>
    </div>
  )
}

export default MyBench
