import React, { useState, useEffect } from 'react'
import { FaArrowRight, FaXmark } from 'react-icons/fa6'
import { toast } from 'react-toastify'
import MyLineup from '../../Purchase/Products/StartSit/MyLineup'
import MyBench from '../../Purchase/Products/StartSit/MyBench'
import { saveStartSitQuestionOptions } from '../../../store/api'

import { saveTradeQuestionOptions } from '../../../store/api'
import { getPositions } from '../../../store/api'

const StartSitModal = ({ isOpen, onClose, trade, onUpdate }) => {
  if (!isOpen) {
    return null
  }

  useEffect(() => {
    fetchPositions()
  }, [])
  const [lineupPlayers, setLineupPlayers] = useState(
    trade.start_sit_configurations[0].roster_positions
  )

  const [bench, setBench] = useState(trade.start_sit_configurations[0].players)
  const [filteredBench, setFilteredBench] = useState([])
  const [selectedPosition, setSelectedPosition] = useState(null)
  const [positions, setPositions] = useState([])

  const attachToPosition = (player) => {
    const newLineupPlayers = [...lineupPlayers]
    let playerAssigned = false

    if (selectedPosition) {
      const updatedLineupPlayers = newLineupPlayers.map((lineupPlayer) => {
        const isEmptySlot = lineupPlayer.player === null

        if (
          !playerAssigned &&
          isEmptySlot &&
          ((selectedPosition === 'FLEX' &&
            lineupPlayer.short_name === 'FLEX') ||
            lineupPlayer.short_name === selectedPosition)
        ) {
          playerAssigned = true
          return { ...lineupPlayer, player }
        }

        return lineupPlayer
      })

      const newBenchPlayers = bench.filter(
        (benchPlayer) => benchPlayer.player_id !== player.player_id
      )
      setSelectedPosition(null)
      setLineupPlayers(updatedLineupPlayers)
      setBench(newBenchPlayers)
    }
  }

  const handleSetSelectedPosition = (position) => {
    setSelectedPosition(position)

    let filteredPlayers

    if (position !== 'FLEX' && position !== 'FX') {
      filteredPlayers = bench.filter(
        (player) =>
          player.position === position || player.short_name === position
      )
    } else {
      filteredPlayers = bench.filter((player) => player.position !== 'QB')
    }

    setFilteredBench(filteredPlayers)
  }

  const fetchPositions = async () => {
    const res = await getPositions()
    setPositions(res.data)
  }

  const submitTradeConfig = async () => {
    try {
      const mappedLineupPlayers = lineupPlayers.map((player) => {
        const position = positions.find(
          (pos) =>
            pos.short_name === player.position ||
            pos.short_name === player.short_name
        )

        return {
          rosterPositionId: position ? position.id : null,
          playerId: player.player.player_id,
        }
      })
      const mappedBenchPlayers = bench.map((player) => ({
        playerId: player.player_id,
        isContender: false,
      }))

      const payload = {
        rosterPositions: mappedLineupPlayers,
        players: mappedBenchPlayers,
      }
      await saveStartSitQuestionOptions(trade.id, payload)
      onUpdate()
      toast.success('Trade configuration submitted successfully.')
    } catch (error) {
      console.error('Error updating question status:', error)
      toast.error('Failed to update question status.')
    }
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative flex h-full max-h-[90dvh] w-full max-w-[90%] flex-col gap-4 overflow-auto rounded-lg bg-light bg-opacity-100 p-10 text-light-text shadow-lg backdrop-blur-md dark:bg-dark dark:bg-opacity-50 dark:text-dark-text">
        <div className="absolute right-2 top-2" onClick={onClose}>
          <FaXmark size={26} />
        </div>
        <h2 className="text-2xl font-semibold text-accent">
          {trade.user_display_name}
        </h2>
        <>
          <section>
            {' '}
            <MyLineup
              lineup={lineupPlayers}
              players={bench}
              setLineup={setLineupPlayers}
              setSelectedPosition={handleSetSelectedPosition}
            />
            <MyBench
              attachToPosition={attachToPosition}
              bench={selectedPosition ? filteredBench : bench}
              disableX={true}
              selectedPosition={selectedPosition}
              setBench={setBench}
              setSelectedPosition={setSelectedPosition}
            />{' '}
          </section>
          <section className="grid grid-cols-2 gap-4 px-[10%]">
            <div className="col-span-1">
              <h2 className="pb-2 text-2xl font-semibold md:w-[50%]">
                Settings
              </h2>
              <div className="grid md:w-[75%] md:grid-cols-2">
                <p className="col-span-1 flex flex-col gap-2">
                  <div className="flex gap-4">
                    <h3 className="font-bold">League Type</h3>
                    <div className="text-light-text2 dark:text-dark-text2">
                      {trade.league_settings.leagueType}
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <h3 className="font-bold">Lineup Count</h3>
                    <div className="text-light-text2 dark:text-dark-text2">
                      {trade.league_settings.lineupCount}
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <h3 className="font-bold">Team Count</h3>
                    <div className="text-light-text2 dark:text-dark-text2">
                      {trade.league_settings.teamCounts}
                    </div>
                  </div>
                </p>
                <p className="col-span-1 flex flex-col gap-2">
                  <div className="flex gap-4">
                    <h3 className="font-bold">PPR</h3>
                    <div className="text-light-text2 dark:text-dark-text2">
                      {trade.league_settings.ppr}
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <h3 className="font-bold">QBs</h3>
                    <div className="text-light-text2 dark:text-dark-text2">
                      {trade.league_settings.qbs}
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <h3 className="font-bold">TE Premium</h3>
                    <div className="text-light-text2 dark:text-dark-text2">
                      {trade.league_settings.tep ? 'True' : 'False'}
                    </div>
                  </div>
                </p>
              </div>
            </div>
            <div className="col-span-1 h-32 md:pl-14">
              <h2 className="pb-2 text-2xl font-semibold">Comments</h2>
              <div className="rounded-lg">{trade.comments}</div>
            </div>
            <div className="col-span-2 flex w-full justify-end">
              <p className="flex gap-4">
                <button
                  className="flex items-center rounded-lg bg-accent px-10 py-3 text-dark-text"
                  onClick={submitTradeConfig}
                >
                  Submit
                  <FaArrowRight className="ml-2" />
                </button>
              </p>
            </div>
          </section>
        </>
      </div>
    </div>
  )
}

export default StartSitModal
