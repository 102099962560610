import React from 'react'

const PoolSection = ({ config, players }) => {
  const bucketPlayers = players || []
  const getPlayersByPosition = (position) => {
    return bucketPlayers.filter((player) => player.position === position)
  }

  const renderPlayers = (players) => {
    return players.map((player) => (
      <div
        onDragStart={(e) =>
          e.dataTransfer.setData('player', JSON.stringify(player))
        }
        className={`${players.length < 2 ? 'xl:col-span-3' : 'xl:col-span-1'} flex items-center gap-2`}
        draggable
        key={player.player_id}
      >
        <div className="relative h-8 w-8 overflow-hidden rounded-full border-[1px] border-light2 bg-dark-border dark:border-dark-border">
          <img
            alt={player.player_name}
            className="player-picture h-full w-full object-cover"
            src={`https://flock-player-images-2.s3.us-east-2.amazonaws.com/${player.picture || player.image}`}
          />
        </div>
        <span>{player.player_name}</span>
      </div>
    ))
  }

  const qbPlayers = getPlayersByPosition('QB')
  const rbPlayers = getPlayersByPosition('RB')
  const wrPlayers = getPlayersByPosition('WR')
  const tePlayers = getPlayersByPosition('TE')
  const picks = getPlayersByPosition(null)

  return (
    <div className="grid max-h-[200px] min-h-[100px] w-full grid-cols-2 gap-1 overflow-auto md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      <section className="col-span-1 rounded-b-lg border-0 bg-accent-purple bg-opacity-60 p-2">
        <span className="text-lg font-semibold">QB</span>
        {renderPlayers(qbPlayers)}
      </section>
      <section className="col-span-1 rounded-b-lg border-0 bg-accent-green bg-opacity-60 p-2">
        <span className="text-lg font-semibold">RB</span>
        {renderPlayers(rbPlayers)}
      </section>
      <section className="col-span-1 rounded-b-lg border-0 bg-accent-red bg-opacity-60 p-2">
        <span className="text-lg font-semibold">WR</span>
        {renderPlayers(wrPlayers)}
      </section>
      <section className="col-span-1 rounded-b-lg border-0 bg-accent-cyan bg-opacity-60 p-2">
        <span className="text-lg font-semibold">TE</span>
        {renderPlayers(tePlayers)}
      </section>
      <section className="col-span-2 rounded-b-lg border-0 bg-accent bg-opacity-60 p-2 lg:col-span-3 xl:col-span-4">
        <span className="text-lg font-semibold">Picks</span>
        {renderPlayers(picks)}
      </section>
    </div>
  )
}

export default PoolSection
