import React from 'react'

const ToggleButton = ({ text, isSelected, onClick }) => {
  const selectedButtonClass = 'bg-accent text-dark-text font-medium'
  const notSelectedButtonClass = 'bg-[#E9793330] text-accent'

  return (
    <button
      className={`${isSelected ? selectedButtonClass : notSelectedButtonClass} rounded-full p-2 px-6 text-custom-font`}
      onClick={onClick}
    >
      {text}
    </button>
  )
}

export default ToggleButton
