import React from 'react'
import ManageContentList from './ManageContentList'
import '../../../styles/landing.css'

const ManageContent = () => {
  return (
    <div className="bg-light px-[10%] py-10 font-dmsans dark:bg-dark">
      <h3 className="pb-10 text-lg font-bold text-light-text dark:text-dark-text lg:text-3xl">
        Manage Content
      </h3>
      <ManageContentList />
    </div>
  )
}
export default ManageContent
