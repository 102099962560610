import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import DomPurify from 'dompurify'
import PostHeader from './PostHeader'
import { getPostById as getPostByIdAction } from '../../store/api'
import loadingGif from '../../images/FlockLoadingIcon.gif'

const Modal = ({ isOpen, close, title, children }) => {
  if (!isOpen) {
    return null
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div className="w-full max-w-lg overflow-hidden rounded-lg bg-white shadow-xl">
        <div className="border-b bg-gray-100 p-4">
          <h3 className="text-lg font-medium">{title}</h3>
        </div>
        <div className="p-4">{children}</div>
        <div className="flex justify-end border-t bg-gray-100 p-4">
          <button
            className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-700"
            onClick={close}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  )
}

const ViewPost = ({ community }) => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { postId } = useParams()

  const { is_creator, is_subscribed, loggedIn } = useSelector(
    (state) => state.user
  )

  const openDefaultState = !is_creator && !is_subscribed

  const [curPost, setCurPost] = useState(null)
  const [open, setOpen] = useState(openDefaultState)
  const [subscribed, setSubscribed] = useState(false)
  const [tierLevel, setTierLevel] = useState(null)

  const elRef = useRef()

  useEffect(() => {
    retrieveFullPost()
  }, [])

  useEffect(() => {
    updateStylesForDarkMode()
  }, [curPost])

  useLayoutEffect(() => {
    updateStylesForDarkMode()
    // Add event listener for class changes on the <html> element
    const observer = new MutationObserver(updateStylesForDarkMode)
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class'],
    })

    return () => {
      observer.disconnect()
    }
  }, [])

  const retrieveFullPost = async () => {
    try {
      const postOptions = {
        postId,
      }
      if (community) {
        postOptions.community = 1
      }
      if (state?.edit) {
        postOptions.edit = 1
      }
      const { post, subTier } = await getPostByIdAction(postOptions)
      const sanitizedBody = DomPurify.sanitize(post.body)
      const imageResult =
        'https://fantasy-flock-article-images.s3.us-east-2.amazonaws.com/public/' +
        post.image
      setCurPost({
        ...post,
        body: sanitizedBody,
        image: imageResult,
      })
      setSubscribed(subTier > 0)
      setTierLevel(subTier)
    } catch (err) {
      console.log('ERROR ViewPost.getPost : ', err)
      navigate('/')
    }
  }

  const updateStylesForDarkMode = () => {
    if (elRef.current) {
      const isDarkMode = document.documentElement.classList.contains('dark')
      const children = elRef.current.children

      for (let i = 0; i < children.length; i++) {
        const child = children[i]

        if (isDarkMode) {
          child.classList.add('text-white')
        } else {
          child.classList.remove('text-white')
        }

        Array.from(child.children).forEach((innerChild) => {
          if (isDarkMode) {
            innerChild.classList.add('text-white')
          } else {
            innerChild.classList.remove('text-white')
          }
        })
      }
    }
  }

  const convertEmbedded = () => {
    let link = curPost?.link

    if (link && (link.includes('youtube.com') || link.includes('youtu.be'))) {
      // Transform YouTube link to embed link
      link = link
        .replace(/watch\?v=/, 'embed/')
        .replace('youtu.be/', 'youtube.com/embed/')

      // Removing additional parameters
      const ampersandPosition = link.indexOf('&')
      if (ampersandPosition !== -1) {
        link = link.substring(0, ampersandPosition)
      }

      return (
        <iframe
          allowFullScreen
          className="my-4 aspect-video w-full"
          frameBorder="0"
          src={link}
          title="YouTube video player"
        ></iframe>
      )
    } else if (link && link.includes('docs.google.com')) {
      // Google Docs embedding logic
      return (
        <div className="flex w-full flex-col items-center bg-light">
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameBorder="0"
            height="1000"
            src={link}
            width="100%"
          />
        </div>
      )
    }

    return null
  }

  const handleSubscribe = () => {
    navigate('/subscribe')
  }

  const getModalContent = () => {
    let message = loggedIn
      ? 'In order to view full Posts from Flock Creators, please purchase a subscription.'
      : 'In order to view full Posts from Flock Creators, please create an account and purchase a subscription.'

    if (
      tierLevel > 0 &&
      tierLevel !== 2 &&
      curPost &&
      curPost.permission === 2
    ) {
      message =
        'Premium content is unavailable for users with the basic package, upgrade your subscription to view this content.'
    }

    return message
  }

  const downloadFile = (url) => {
    const link = document.createElement('a')
    link.href = url
    link.target = '_blank'
    link.setAttribute('download', 'document.pdf') // Replace 'document.pdf' with a meaningful filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const renderEmbeddedPDF = (pdfUrl) => {
    const viewerUrl = `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(pdfUrl)}`

    return (
      <div style={{ width: '100%', overflow: 'hidden' }}>
        <iframe
          style={{
            width: '100%',
            height: '800px',
            border: 'none',
          }}
          className="pdf-iframe"
          frameBorder="0"
          src={viewerUrl}
        ></iframe>
      </div>
    )
  }

  const renderPDFDocument = () => {
    if (curPost && curPost.link && curPost.type_id === 4) {
      return renderEmbeddedPDF(curPost.link)
    }
    return null
  }

  if (!curPost) {
    return null
  }

  return (
    <div className="bg-light px-2 dark:bg-dark md:px-[15%]">
      {curPost !== null ? (
        <div className="flex flex-col items-center rounded-lg pt-14">
          <div className="flex w-full flex-col py-6 text-4xl text-light-text dark:text-dark-text">
            <span className="border-b-[1px] border-dark-border py-4">
              {curPost.title}
            </span>
            <PostHeader
              author={curPost.author}
              community={community}
              createdDate={curPost.created_date}
            />
          </div>

          <div className="border-light-border flex flex-col items-center overflow-hidden rounded-lg border-[1px] dark:border-dark-border dark:bg-dark">
            <img
              alt={curPost.title + curPost.author}
              className="w-full object-contain"
              src={curPost.image}
            />
            {subscribed || is_creator || curPost.permission === 0 ? (
              <div className="font-dmsans">
                <div
                  className="w-full bg-light p-4 text-light-text dark:bg-dark dark:text-dark-text md:p-20"
                  dangerouslySetInnerHTML={{ __html: curPost.body }}
                  ref={elRef}
                />
                {curPost.type_id > 1 && curPost.link !== null ? (
                  <div className="body-container">
                    {curPost.type_id !== 4 && curPost.type_id > 1 ? (
                      <div className="">
                        <button className="mb-2 rounded-lg bg-accent p-2 text-dark-text transition-all duration-200">
                          <a href={curPost.link}>
                            {curPost.type_id === 2
                              ? 'Watch on YouTube'
                              : 'View Database'}
                          </a>
                        </button>
                        <div className="embedded-content">
                          {convertEmbedded()}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="">
                      {curPost.type_id === 4 ? (
                        <div className="pt-4">
                          <label className="font-medium text-light-text dark:text-dark-text">
                            Download draft guide here
                          </label>
                          <br />
                          <button
                            className="mb-2 rounded-lg bg-accent p-2 text-dark-text transition-all duration-200"
                            onClick={() => downloadFile(curPost.link)}
                          >
                            Download PDF
                          </button>
                          <div className="pdf-viewer max-w-[800px]">
                            {renderPDFDocument()}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <div className="flex w-full flex-col items-center bg-light">
                <div className="fade-effect relative w-full px-[10vw] py-8">
                  {curPost.body.slice(0, 200) + '...'}
                </div>
                <div className="items-center justify-evenly gap-4 rounded-t-lg border-t-0 bg-dark p-10 font-dmsans backdrop-blur-[1rem] md:flex lg:w-[75%] xl:h-[400px]">
                  <div>
                    <p className="flex flex-col gap-4">
                      <h3 className="text-4xl font-bold text-zinc-100">
                        Join the Flock
                      </h3>
                      <h4 className="font-bold text-dark-text2">
                        Get access to all of our tools and rankings
                      </h4>
                    </p>
                    <ul className="text-md ml-4 mt-2 flex list-disc flex-col gap-2 font-medium">
                      <li className="text-dark-text">
                        Unlimited access to Trade Calculator
                      </li>
                      <li className="text-dark-text">
                        Access to all premium posts and databases
                      </li>
                      <li className="text-dark-text">
                        Both expert rankings and community rankings
                      </li>
                      <li className="text-dark-text">
                        The exclusive flock discord channel
                      </li>
                      <li className="text-dark-text">Much more . . .</li>
                    </ul>
                    <button
                      className="mt-6 rounded-lg border-0 bg-accent px-4 py-2 text-lg"
                      onClick={handleSubscribe}
                    >
                      Upgrade Now
                    </button>
                  </div>
                  <img
                    className="max-h-[400px] max-w-[300px]"
                    height={200}
                    src="https://static-site-images.s3.us-east-2.amazonaws.com/FF-REGULAR_V6.1.png"
                    width={200}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="flex h-[90vh] w-full flex-col items-center justify-center">
          <div className="w-[200px]">
            <img alt="loading gif" src={loadingGif} />
          </div>
          <p className="text-xl text-light-text dark:text-dark-text">
            Loading...
          </p>
        </div>
      )}

      <Modal
        close={() => setOpen(false)}
        isOpen={open}
        title="Subscribe for Full Content!"
      >
        <p>A short preview of each Post is free to view for all users.</p>
        <p>{getModalContent()}</p>
      </Modal>
    </div>
  )
}

export default ViewPost
