import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const ReviewInfoModal = ({ teamName, comments, id }) => {
  const navigate = useNavigate()

  const [position, setPosition] = useState({ top: 5, left: 5 })
  const [dragging, setDragging] = useState(false)
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 })

  const handleMouseDown = (e) => {
    setDragging(true)
    setDragOffset({
      x: e.clientX - position.left,
      y: e.clientY - position.top,
    })
  }

  const handleMouseMove = (e) => {
    if (dragging) {
      setPosition({
        left: e.clientX - dragOffset.x,
        top: e.clientY - dragOffset.y,
      })
    }
  }

  const handleMouseUp = () => {
    setDragging(false)
  }

  const navigateToMyReviews = (id) => {
    navigate(`/my-reviews?id=${id}`)
  }

  useEffect(() => {
    if (dragging) {
      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
    } else {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [dragging, dragOffset])

  return (
    <div
      className="fixed z-50 flex w-64 flex-col gap-2 rounded-lg border-[1px] border-light2 bg-light bg-opacity-20 p-5 text-light-text shadow-lg backdrop-blur-md dark:border-dark-border dark:text-dark-text"
      onMouseDown={handleMouseDown}
      style={{ top: `${position.top}px`, left: `${position.left}px` }}
    >
      <h2 className="text-xl font-bold">Review Info</h2>
      <p>
        <strong>Team Name:</strong>{' '}
        <div className="text-light-text2 dark:text-dark-text2">{teamName}</div>
      </p>
      <p>
        <strong>Comments:</strong>{' '}
        <div className="text-light-text2 dark:text-dark-text2">{comments}</div>
      </p>
      <button onClick={() => navigateToMyReviews(id)}>Back to MyReviews</button>
    </div>
  )
}

export default ReviewInfoModal
