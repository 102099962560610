import React, { useState, useEffect } from 'react'
import { FaXmark } from 'react-icons/fa6'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Select from '../_ui/Select'

import { createUserLeagueProfile } from '../../store/api'

const YAHOO_CLIENT_ID = process.env.REACT_APP_YAHOO_CLIENT_ID

const SLEEPER = 'SLEEPER'
const ESPN = 'ESPN'
const YAHOO = 'YAHOO'

const SyncTeamsModal = ({ isOpen, onClose, onLeagueSubmitted }) => {
  const [leagueId, setLeagueId] = useState('')
  const [platform, setPlatform] = useState(SLEEPER)
  const [year, setYear] = useState(null)

  const leagueIdInputLabel =
    platform === SLEEPER
      ? 'Sleeper'
      : platform === ESPN
        ? 'ESPN'
        : platform === YAHOO
          ? 'Yahoo'
          : ''

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    // Cleanup when the component unmounts
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  if (!isOpen) {
    return null
  }

  const handleSyncTeam = async () => {
    if (platform === YAHOO) {
      syncToYahoo()
    } else {
      if (!validateInputs(leagueId, platform, year)) {
        return
      }

      const data = { leagueId: leagueId.trim(), platform, year }

      try {
        await createUserLeagueProfile(data)
        toast.success('Team synced successfully!')
        onLeagueSubmitted(true)
        onClose()
        setLeagueId('')
      } catch (error) {
        console.error('Failed to sync team:', error)
        toast.error('Failed to sync team')
      }
    }
  }

  const validateInputs = (leagueId, platform, year) => {
    const trimmedLeagueId = leagueId.trim()

    if (!trimmedLeagueId) {
      toast.error('League ID is required')
      return false
    }

    if (!year && platform === ESPN) {
      toast.error('Year is required')
      return false
    }

    return true
  }

  const handleYearSelect = (option) => {
    setYear(option.label)
  }

  const handlePlatformChange = (newPlatform) => {
    setPlatform(newPlatform)
    setYear(null)
    setLeagueId('')
  }

  const syncToYahoo = () => {
    const redirectUri = 'https://flockfantasy.com/access-profile?platform=YAHOO'
    const url = `https://api.login.yahoo.com/oauth2/request_auth?client_id=${YAHOO_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code&language=en-us`
    window.open(url, '_blank')
  }

  const sleeperHelperText = (
    <span className="pb-4 text-center text-light-text2 dark:text-dark-text2">
      *To find your Sleeper ID, follow this article from
      <a
        className="font-bold text-accent"
        href="https://support.sleeper.com/en/articles/4121798-how-do-i-find-my-league-id"
        target="blank"
      >
        {' '}
        Sleeper
      </a>
    </span>
  )

  const sleeperOrEspnLeagueInfo = (
    <>
      <div className="w-full">
        <div className="font-bold">{leagueIdInputLabel}</div>
        <input
          className="h-[50px] w-full rounded-custom border-[1px] border-light2 p-2 text-sm dark:border-dark-border dark:bg-dark"
          onChange={(e) => setLeagueId(e.target.value)}
          placeholder="Enter league id"
          type="text"
          value={leagueId}
        />
      </div>
      <div className="w-full">
        {platform === ESPN && (
          <>
            <div className="font-bold">League Year</div>
            <Select
              onSelect={handleYearSelect}
              options={['2023', '2024']}
              selectedValue={year}
            />
          </>
        )}
      </div>
    </>
  )

  const syncText = platform === YAHOO ? 'Sync all teams' : 'Sync Team'

  return (
    <div className="absolute inset-0 z-40 flex h-full items-center justify-center bg-black bg-opacity-50">
      <ToastContainer />
      <div className="rounded-lg border-[1px] border-light2 bg-light px-4 pb-10 pt-2 text-light-text transition-all duration-200 dark:border-dark-border dark:bg-dark dark:text-dark-text md:px-10 lg:w-[30vw]">
        <span
          className="flex w-full cursor-pointer justify-end"
          onClick={onClose}
        >
          <FaXmark
            className="fill-light-text hover:fill-accent dark:fill-dark-text hover:dark:fill-accent md:translate-x-8"
            size={22}
          />
        </span>
        <h2 className="text-xl font-medium">Sync New Team</h2>
        <div className="flex justify-center gap-6">
          <span
            className="my-6 cursor-pointer overflow-hidden rounded-full border-[2px] border-light2 transition-all duration-200 dark:border-dark-border hover:dark:border-accent"
            onClick={() => handlePlatformChange(SLEEPER)}
          >
            <img
              alt="team logo"
              height={75}
              src="https://static-site-images.s3.us-east-2.amazonaws.com/sleeper.jpeg"
              title="Sleeper"
              width={75}
            />
          </span>
          {/* <span
            className="my-6 grid cursor-pointer items-center overflow-hidden rounded-full border-[2px] border-light2 bg-slate-300 transition-all duration-200 dark:border-dark-border hover:dark:border-accent"
            onClick={() => handlePlatformChange(ESPN)}
          >
            <img
              alt="team logo"
              src="https://static-site-images.s3.us-east-2.amazonaws.com/espn.png"
              title={ESPN}
              width={75}
            />
          </span> */}
          <span
            className="my-6 grid cursor-pointer items-center overflow-hidden rounded-full border-[2px] border-light2 bg-slate-300 transition-all duration-200 dark:border-dark-border hover:dark:border-accent"
            onClick={() => handlePlatformChange(YAHOO)}
          >
            <img
              alt="team logo"
              src="https://static-site-images.s3.us-east-2.amazonaws.com/yahoo-fantasy-logo.png"
              title={YAHOO}
              width={75}
            />
          </span>
        </div>
        <div className="flex flex-col">
          {platform === SLEEPER && sleeperHelperText}
          <div className="flex w-full gap-4">
            {(platform === SLEEPER || platform === ESPN) &&
              sleeperOrEspnLeagueInfo}
          </div>
          <button
            className="mt-2 w-[130px] rounded-custom bg-accent p-2 text-sm text-white transition-colors hover:bg-orange-600"
            onClick={handleSyncTeam}
          >
            {syncText}
          </button>
        </div>
      </div>
    </div>
  )
}

export default SyncTeamsModal
