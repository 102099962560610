import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import HorozontalDivider from '../../_ui/HorozontalDivider'
import TeamReviewsBanner from '../../../images/TeamReviewsBanner.png'
import MyTeamsBanner from '../../../images/MyTeamsBanner.png'
import StoreItem from './StoreItem'
import TradeCalculatorBanner from '../../../images/TradeCalculatorBanner.png'
import TradeFinderBanner from '../../../images/TradeFinderBanner.png'
import StorefrontToolsBanner from './StorefrontToolsBanner'
import { getStoreItems } from '../../../store/api'
import { useSelector } from 'react-redux'

const Storefront = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { is_creator, is_subscribed, is_trialing } = useSelector(
    (state) => state.user
  )

  const [storeItems, setStoreItems] = useState([])
  const [loading, setLoading] = useState(true)

  //---------------------------------------------------------------- Use Effects ------------------------------------------------------------------------------------
  useEffect(() => {
    fetchStoreItems()
  }, [])

  //---------------------------------------------------------------- Data Fetching ------------------------------------------------------------------------------------
  const fetchStoreItems = async () => {
    try {
      const response = await getStoreItems()
      const sortedItems = response.data.sort((a, b) => {
        if (a.name === 'Team Review') {
          return -1
        }
        if (b.name === 'Team Review') {
          return 1
        }
        return 0
      })
      setStoreItems(sortedItems)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  //---------------------------------------------------------------- Utility Functions ------------------------------------------------------------------------------------
  const handleItemClick = (slug) => {
    const searchParams = new URLSearchParams(location.search)
    const promoCode = searchParams.get('code')
    if (promoCode) {
      navigate(`/purchase/${slug}?code=${promoCode}`)
    } else {
      navigate(`/purchase/${slug}`)
    }
  }

  const getImage = (name) => {
    switch (name) {
      case 'Team Review':
        return TeamReviewsBanner
      case 'Start/Sit Question':
        return MyTeamsBanner
      case 'Trade Question':
        return TradeCalculatorBanner
      default:
        return TradeFinderBanner
    }
  }

  return (
    <div className="px-4 py-16 font-dmsans text-light-text dark:text-dark-text lg:px-[20%]">
      <h2 className="mb-4 text-3xl font-bold">Personalized Advice</h2>
      <HorozontalDivider />
      {loading ? (
        <div className="mt-4 grid animate-pulse grid-cols-3 gap-8">
          <div className="col-span-1 flex h-[425px] flex-col gap-8 rounded-lg border-[1px] border-light2 bg-zinc-300 p-6 dark:border-dark-border dark:bg-zinc-500"></div>
          <div className="col-span-1 flex flex-col gap-8 rounded-lg border-[1px] border-light2 bg-zinc-300 p-6 dark:border-dark-border dark:bg-zinc-500"></div>
          <div className="col-span-1 flex flex-col gap-8 rounded-lg border-[1px] border-light2 bg-zinc-300 p-6 dark:border-dark-border dark:bg-zinc-500"></div>
        </div>
      ) : (
        <div>
          {/* For medium and larger screens */}
          <div className="mt-4 hidden grid-cols-3 gap-8 md:grid">
            {storeItems.map((item) => (
              <StoreItem
                description={item.description}
                disabled={!item.is_active}
                image={getImage(item.name)}
                key={item.id}
                name={item.name}
                onClick={() => handleItemClick(item.slug)}
              />
            ))}
            {!is_creator && !is_subscribed && !is_trialing && (
              <StorefrontToolsBanner />
            )}
          </div>

          {/* For smaller screens */}
          <div className="mt-4 flex flex-col items-center gap-8 md:hidden">
            {storeItems.length > 0 && (
              <>
                <StoreItem
                  description={storeItems[0].description}
                  disabled={!storeItems[0].is_active}
                  image={getImage(storeItems[0].name)}
                  key={storeItems[0].id}
                  name={storeItems[0].name}
                  onClick={() => handleItemClick(storeItems[0].slug)}
                />
                {!is_creator && !is_subscribed && !is_trialing && (
                  <StorefrontToolsBanner />
                )}
                {storeItems.slice(1).map((item) => (
                  <StoreItem
                    description={item.description}
                    disabled={!item.is_active}
                    image={getImage(item.name)}
                    key={item.id}
                    name={item.name}
                    onClick={() => handleItemClick(item.slug)}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Storefront
