import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Maintenance from './components/Misc/Maintenance.jsx'
import Layout from './components/Misc/Layout.jsx'
import Account from './components/Account/Account.jsx'
import AdminPanel from './components/Creator/AdminPanel/AdminPanel'
import CreatePost from './components/Creator/CreatePost/CreatePost.jsx'
import CreatePlayerCardImages from './components/Creator/PlayerImage/CreatePlayerCardImages.jsx'
import CreateRankings from './components/Creator/CreateRankings/CreateRankings'
import CreatorIncome from './components/Creator/Income/CreatorIncome.jsx'
import EditPost from './components/Creator/EditPost/EditPost.jsx'
import EloMananger from './components/Creator/AdminPanel/EloManager'
import PlatformAccessProfile from './components/MyTeams/PlatformAccessProfile.jsx'
import EspnSyncPrivacyPolicy from './components/MyTeams/EspnSyncPrivacyPolicy.jsx'
import FindTrades from './components/TradeFinder/FindTrades.jsx'
import Forgot from './components/Authentication/Forgot.jsx'
import Login from './components/Authentication/Login.jsx'
import ManageContent from './components/Creator/EditPost/ManageContent.jsx'
import Posts from './components/Posts/Posts.jsx'
import PaymentSuccess from './components/Payment/PaymentSuccess.js'
import PaymentCancel from './components/Payment/PaymentCancel.js'
import Products from './components/Purchase/Products/Products.jsx'
import Rankings from './components/Rankings/Rankings.jsx'
import Signup from './components/Authentication/Signup.jsx'
import Storefront from './components/Purchase/Storefront/Storefront.jsx'
import Subscribe from './components/Subscribe/Subscribe.jsx'
import Sync from './components/Sync/Sync'
import Team from './components/Team/Team.jsx'
import TradeCalc from './components/TradeCalc/TradeCalc.jsx'
import MyTeams from './components/MyTeams/MyTeams.jsx'
import MyReviews from './components/MyTeams/MyReviews/MyReviews.jsx'
import SubmitReview from './components/MyTeams/SubmitReview/SubmitReview.jsx'
import ViewPost from './components/Posts/ViewPost.jsx'
import UserDetails from './components/Creator/Income/UserDetails.jsx'

const Protected = ({ condition, children }) =>
  condition ? children : <Navigate replace to="/" />

const ProtectedRoute = ({ children }) => {
  const { loggedIn } = useSelector((state) => state.user)
  const location = useLocation()

  if (!loggedIn) {
    return <Navigate replace state={{ from: location }} to="/login" />
  }

  return children
}

const routes = (isAdmin) => [
  {
    path: '/',
    element: <Layout />,
    children: [
      // Homepage
      { index: true, element: <Posts /> },

      // About us
      { path: 'team', element: <Team /> },

      // Auth
      { path: ':promo', element: <Signup /> }, // Very important this is first
      { path: 'forgot', element: <Forgot /> },
      { path: 'login', element: <Login /> },
      { path: 'signup', element: <Signup /> },
      { path: 'signup/:promo', element: <Signup /> },

      // Account
      { path: 'account', element: <Account /> },

      // Admin (Mason only)
      {
        path: 'admin',
        element: (
          <Protected condition={isAdmin}>
            <AdminPanel />
          </Protected>
        ),
      },
      {
        path: 'eloManager',
        element: (
          <Protected condition={isAdmin}>
            <EloMananger />
          </Protected>
        ),
      },

      // Analyzer
      { path: 'sync', element: <Sync /> },
      {
        path: 'access-profile',
        element: (
          <ProtectedRoute>
            <PlatformAccessProfile />
          </ProtectedRoute>
        ),
      },
      {
        path: 'my-teams',
        element: (
          <ProtectedRoute>
            <MyTeams />
          </ProtectedRoute>
        ),
      },
      {
        path: 'access-profile/privacy-policy',
        element: <EspnSyncPrivacyPolicy />,
      },

      // Creator - Income
      {
        path: 'creator/income',
        element: (
          <ProtectedRoute>
            <CreatorIncome />
          </ProtectedRoute>
        ),
      },
      {
        path: 'creator/income/user/:userId',
        element: (
          <ProtectedRoute>
            <UserDetails />
          </ProtectedRoute>
        ),
      },

      // Creator - Rankings
      {
        path: 'creator/rankings',
        element: (
          <ProtectedRoute>
            <CreateRankings />
          </ProtectedRoute>
        ),
      },

      // Creator - Posts
      {
        path: 'post/create',
        element: (
          <ProtectedRoute>
            <CreatePost />
          </ProtectedRoute>
        ),
      },
      {
        path: 'post/edit/:postId',
        element: (
          <ProtectedRoute>
            <EditPost />
          </ProtectedRoute>
        ),
      },
      {
        path: 'post/manage',
        element: (
          <ProtectedRoute>
            <ManageContent />
          </ProtectedRoute>
        ),
      },

      // Creator - Player Cards
      {
        path: 'player-card/create',
        element: (
          <ProtectedRoute>
            <CreatePlayerCardImages />
          </ProtectedRoute>
        ),
      },

      // Payments - Purchases
      { path: 'purchase', element: <Storefront /> },
      {
        path: 'purchase/review',
        element: (
          <ProtectedRoute>
            <Products />
          </ProtectedRoute>
        ),
      },

      // Payments - Subscriptions
      { path: 'payment/success', element: <PaymentSuccess /> },
      { path: 'payment/cancel', element: <PaymentCancel /> },
      { path: 'subscribe', element: <Subscribe /> },

      // Posts (These need to be fix - no such thing as "community" anymore)
      { path: 'post/:postId', element: <ViewPost community={false} /> },
      {
        path: 'community_article/:postId',
        element: <ViewPost community={true} />,
      },

      // Products
      {
        path: '/purchase/:id',
        element: (
          <ProtectedRoute>
            <Products />
          </ProtectedRoute>
        ),
      },

      // Rankings
      { path: 'rankings', element: <Rankings /> },

      // Reviews
      {
        path: 'my-reviews',
        element: (
          <ProtectedRoute>
            <MyReviews />
          </ProtectedRoute>
        ),
      },

      // Trade Calculator
      {
        path: 'trade',
        element: (
          <ProtectedRoute>
            <TradeCalc />
          </ProtectedRoute>
        ),
      },

      // Trade Finder
      { path: 'trade-finder', element: <FindTrades /> },
    ],
  },
]

export default routes
