import React, { useState, useEffect } from 'react'
import useDarkMode from '../../hooks/useDarkMode'
import { ResponsiveBar } from '@nivo/bar'
import { Triangle } from 'react-loader-spinner'

const BarChart = ({ data }) => {
  if (!data || data.length === 0) {
    return (
      <Triangle ariaLabel="loading" color="#E97933" height={50} width={50} />
    )
  }

  const isDarkMode = useDarkMode()
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768)

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const transformedData = data
    .map((team) => ({
      ...team,
      QB: team.QB,
      RB: team.RB,
      TE: team.TE,
      WR: team.WR,
      PICKS: team.PICKS,
    }))
    .sort((a, b) => b.Overall - a.Overall)

  const theme = {
    axis: {
      ticks: {
        text: {
          fill: isDarkMode ? 'white' : 'black',
          fontFamily: 'Arial, sans-serif',
          fontSize: 12,
        },
      },
      legend: {
        text: {
          fontSize: 12,
          fill: '#E97933',
          outlineWidth: 0,
          outlineColor: 'transparent',
        },
      },
    },

    grid: {
      line: {
        stroke: isDarkMode ? '#444444' : '#CCCCCC',
      },
    },
    legends: {
      text: {
        fill: isDarkMode ? 'white' : 'black',
      },
    },
    labels: {
      text: {
        fill: isDarkMode ? 'white' : 'black',
      },
    },
    text: {
      fontSize: 11,
      fill: '#ffff00',
      outlineWidth: 0,
    },
    tooltip: {
      container: {
        background: isDarkMode ? '#333' : 'white',
        color: isDarkMode ? 'white' : 'black',
      },
    },
  }

  const CustomTooltip = ({ id, value, data }) => {
    const ranks = ['QB', 'RB', 'WR', 'TE', 'PICKS']
      .map((position) => `${position}_rank`)
      .reduce((acc, rankKey) => {
        acc[rankKey.replace('_rank', '')] = data[rankKey]
        return acc
      }, {})

    return (
      <div className="rounded-[4px] bg-black bg-opacity-60 p-[12px] text-white">
        {Object.entries(ranks).map(([position, rank]) => (
          <div key={position}>
            <strong style={{ color: positionColors[position] || 'white' }}>
              {position}
            </strong>
            : Rank {rank || 'N/A'}
          </div>
        ))}
      </div>
    )
  }

  const positionColors = {
    RB: '#9565E3',
    QB: '#7FC43A',
    WR: '#DC5454',
    TE: '#36C9C9',
    PICKS: '#F0C419',
  }

  // Maps team id to team name for the bar chart X axis
  const teamIdToNameMap = {}
  transformedData.forEach((team) => {
    teamIdToNameMap[team.teamId] = team.team
  })

  return (
    <div className="h-[65vh] w-full overflow-x-auto">
      <ResponsiveBar
        axisBottom={{
          legendPosition: 'middle',
          legendOffset: 32,
          tickRotation: isMobileView ? -90 : -20,
          format: (value) => teamIdToNameMap[value] || value,
        }}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#38bcb2',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[
          {
            match: { id: 'fries' },
            id: 'dots',
          },
          {
            match: { id: 'sandwich' },
            id: 'lines',
          },
        ]}
        legends={
          isMobileView
            ? []
            : [
                {
                  dataFrom: 'keys',
                  anchor: 'top-right',
                  direction: 'column',
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]
        }
        margin={{
          top: 0,
          right: isMobileView ? 0 : 130,
          bottom: isMobileView ? 100 : 80,
          left: isMobileView ? 0 : 80,
        }}
        tooltip={({ id, value, data }) => (
          <CustomTooltip data={data} id={id} value={value} />
        )}
        axisRight={null}
        axisTop={null}
        colors={({ id, data }) => positionColors[id] || '#000000'}
        data={transformedData}
        indexBy="teamId"
        indexScale={{ type: 'band', round: true }}
        keys={['QB', 'RB', 'TE', 'WR', 'PICKS']}
        label={() => ''}
        labelSkipHeight={12}
        labelSkipWidth={12}
        labelTextColor="black"
        padding={isMobileView ? 0.1 : 0.3}
        role="application"
        theme={theme}
        valueScale={{ type: 'linear' }}
      />
    </div>
  )
}

export default BarChart
