import React from 'react'

const PlayerScores = ({
  allPlayersByRank,
  multiplier,
  selectedPlayersByRank,
  prevMultiplier,
  teCheck,
}) => {
  return (
    <div>
      {selectedPlayersByRank.map((playerRank) => {
        const player = allPlayersByRank[playerRank]

        if (teCheck && player.position !== 'TE') {
          return null
        }

        return (
          <div key={`small-league-${player.rank}`}>
            ({playerRank}) {player.player_name} ELO Score:{' '}
            {prevMultiplier(player.elo_rating, player.rank, player.position)}{' '}
            -&gt; {multiplier(player.elo_rating, player.rank, player.position)}
          </div>
        )
      })}
    </div>
  )
}

export default PlayerScores
