import React from 'react'
import ReactDOM from 'react-dom/client'
import axios from 'axios'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import './styles/index.css'

import { persistor, store } from './store/reducer'
import { checkLoginInterceptor } from './services/authService'
import reportWebVitals from './reportWebVitals'

import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL

checkLoginInterceptor()

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
