export const AllPromo = {
  'Avery Huffman': '222696a0-038d-4689-a3e6-a97046cd2eec',
  'Mason Dodd': '38f6c2ae-6010-416a-8947-973a4ddc58e6',
  'Danny Mckinnon': '4e433a01-5935-4286-a73d-6df5015bcae2',
  'Zach Savage': '5ac59844-e390-46dd-9ecd-b50ecc3c0a08',
  'Nathan Schmidt': '83f307b7-2101-4dd0-8716-0a4fc7d50dbb',
  'Corey Buschlen': 'e401f630-5049-4c83-9b0a-453c1c547ebc',
  'Daniel Badake': 'e7c76f08-7131-416f-b08d-49f7b8dec85e',
  TestFlockDev: 'cbb77fb7-ff70-4e23-96a8-e75aa99530ba',
  DevAdmin: 'ff43e7ad-b1d7-43c2-8b3f-dd9a0f4bf1db',
  Joe: '3f708a1d-e00f-4e28-9f1e-f4c2aac35b5d',
  'Flock Dev': '7f8d1d7f-ad44-48a5-95c2-d5ccc17d6e0f',
}
