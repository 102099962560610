import React, { useState, useEffect } from 'react'
import SearchBar from '../../../_ui/SearchBar'
import { FaXmark } from 'react-icons/fa6'
import { toast } from 'react-toastify'

const TradeTeam = ({
  players,
  setPlayers,
  teams = [],
  league = '',
  isLinked = false,
  text,
  allPlayers = [],
  search = true,
  flex = false,
  isInteractive = true,
  isWinning = false,
  maxPlayers = 20,
  hover = false,
  rounded = true,
  pool = [],
  setPool,
  allowDuplicates = false,
  setSelectedTeamId,
}) => {
  const [selectedTeamName, setSelectedTeamName] = useState('')
  const [selectedTeamRoster, setSelectedTeamRoster] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    if (isLinked && players.length > 0) {
      const firstPlayer = players.find(
        (player) => !player.player_id.toString().startsWith('999')
      )

      if (firstPlayer) {
        findTeamByPlayerName(firstPlayer.player_name)
      }
    }
  }, [isLinked, players])

  const findTeamByPlayerName = (playerName) => {
    for (const team of teams) {
      const roster = league.rosters[team.id] || {}
      const rosterArray = Object.keys(roster).flatMap(
        (position) => roster[position]
      )
      if (rosterArray.some((player) => player.player_name === playerName)) {
        handleTeamChange({ label: team.label, id: team.id })
        break
      }
    }
  }

  const handleTeamChange = (selectedOption) => {
    setSelectedTeamName(selectedOption.label)
    setSelectedTeamId(selectedOption.id)
    const roster = league.rosters[selectedOption.id] || {}

    const rosterArray = Object.keys(roster).flatMap(
      (position) => roster[position]
    )
    const uniqueRosterArray = Array.from(
      new Map(
        rosterArray.map((player) => {
          return [player.player_id, player]
        })
      ).values()
    )
    setSelectedTeamRoster(uniqueRosterArray)

    if (setPool) {
      const filteredPool = uniqueRosterArray.filter(
        (player) =>
          !players.some(
            (teamPlayer) => teamPlayer.player_id === player.player_id
          )
      )
      setPool(filteredPool)
    }
  }

  const removePlayerFromTeam = (playerToRemove) => {
    if (!isInteractive) {
      return
    }

    setPlayers((prevPlayers) => {
      const updatedPlayers = prevPlayers.filter(
        (player) => player.player_id !== playerToRemove.player_id
      )

      if (setPool) {
        if (!allowDuplicates) {
          setPool((prevPool) => {
            const playerExists = prevPool.some(
              (p) => p.player_id === playerToRemove.player_id
            )
            if (playerExists) {
              return prevPool
            }
            return [...prevPool, playerToRemove]
          })
        }
        // Remove the team name if the list is empty or is a draft pick
        if (
          updatedPlayers.length === 0 ||
          (updatedPlayers.length === 1 &&
            updatedPlayers[0].player_id.toString().startsWith('999'))
        ) {
          setSelectedTeamName('')
        }
      }

      return updatedPlayers
    })
  }

  const addPlayerToTeam = (player) => {
    if (!isInteractive) {
      return
    }
    setPlayers((prevPlayers) => {
      const playerExists = prevPlayers.some(
        (p) => p.player_id === player.player_id
      )
      if (playerExists) {
        return prevPlayers
      }
      if (prevPlayers.length === maxPlayers - 1) {
        toast.warn('You have reached the maximum number of players.')
      }
      if (prevPlayers.length >= maxPlayers) {
        return prevPlayers
      }

      const playerWithPicture = {
        ...player,
        picture: player.picture ? player.picture : 'default-player.png',
      }

      // Remove player from pool
      if (setPool) {
        setPool((prevPool) =>
          prevPool.filter((p) => p.player_id !== player.player_id)
        )
      }

      return [...prevPlayers, playerWithPicture]
    })

    setSearchTerm('')
  }

  const handleDrop = (event) => {
    event.preventDefault()
    const player = JSON.parse(event.dataTransfer.getData('player'))
    addPlayerToTeam(player)
  }

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  const getRanks = () => {
    const firstValidPlayer = players.find(
      (player) => !player.player_id.toString().startsWith('999')
    )

    if (isLinked && selectedTeamRoster && firstValidPlayer) {
      return selectedTeamRoster
    }

    return allPlayers
  }

  return (
    <div
      className={`relative flex h-full ${flex ? 'w-full' : 'w-[300px]'} ${hover ? 'hover:border-accent dark:hover:border-accent' : ''} flex-col justify-between bg-light2 transition-all duration-200 dark:bg-dark ${rounded ? 'rounded-2xl' : 'rounded-t-2xl'} border-[1px] ${isWinning ? 'border-accent' : 'border-light2 p-2 dark:border-dark-border'}`}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <div className="top-0 z-10 w-[282px]">
        {search && (
          <SearchBar
            number={1}
            onAddPlayer={addPlayerToTeam}
            openAbove={false}
            ranks={getRanks()}
            searchTerm={searchTerm}
            selectedPlayers={players}
            setSearchTerm={setSearchTerm}
          />
        )}
      </div>
      <section className="relative flex h-full flex-wrap items-center justify-center gap-2 overflow-auto pt-2">
        {selectedTeamName && players.length > 0 && (
          <div className="absolute inset-0 pl-1 text-lg text-light-text2 dark:text-dark-text2">
            {selectedTeamName}
          </div>
        )}
        {players.length === 0 && (
          <div className="grid h-full items-center px-2 text-center text-light-text2 dark:text-dark-text2">
            {text || 'No players selected'}
          </div>
        )}
        <div className={'flex w-full flex-wrap justify-evenly gap-4'}>
          {players.length > 0 &&
            players.map((player, index) => (
              <div
                className={`${players.length < 2 ? 'col-span-3' : 'col-span-1'} flex flex-col items-center gap-2 text-center`}
                key={player.player_id}
                onClick={() => removePlayerFromTeam(player)}
              >
                <div
                  className={`relative h-24 w-24 overflow-hidden rounded-full border-[1px] border-light2 bg-dark-border dark:border-dark-border ${isInteractive && 'hover:border-accent dark:hover:border-accent'}`}
                >
                  <img
                    alt={player.player_name}
                    className="player-picture h-full w-full object-cover"
                    src={`https://flock-player-images-2.s3.us-east-2.amazonaws.com/${player.image || player.picture}`}
                  />
                  <div
                    className={`absolute inset-1 flex items-center opacity-0 ${isInteractive && 'transition-opacity duration-300 hover:opacity-100'}`}
                  >
                    <FaXmark className="text-red-500" size={20} />
                  </div>
                </div>
                <span className="max-w-[100px]">{player.player_name}</span>
              </div>
            ))}
        </div>
      </section>
    </div>
  )
}

export default TradeTeam
