import React from 'react'
import { useNavigate } from 'react-router-dom'

const SubscriptionModal = () => {
  const navigate = useNavigate()

  const handleSubscribe = () => {
    navigate('/subscribe')
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
      <div className="flex flex-col items-center rounded-[25px] bg-light p-6 py-10 text-center text-light-text shadow-lg dark:bg-dark dark:text-dark-text md:w-[40%] lg:w-[30rem]">
        <img
          alt="Goose"
          className="pb-4"
          height={75}
          src="https://static-site-images.s3.us-east-2.amazonaws.com/FF-REGULAR_V6.1.png"
          width={75}
        />
        <h2 className="mb-4 text-xl font-bold">
          Please subscribe to use this feature
        </h2>
        <p className="mb-4 px-8 text-sm text-light-text text-opacity-60 dark:text-dark-text">
          Access to Team Reviews is restricted to subscribers
        </p>
        <button
          className="rounded-[14px] bg-accent px-4 py-2 text-sm font-bold text-dark-text transition-all duration-300 hover:bg-orange-600 lg:w-[75%]"
          onClick={handleSubscribe}
        >
          Subscribe
        </button>
      </div>
    </div>
  )
}

export default SubscriptionModal
