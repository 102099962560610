import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import InputBox from '../_ui/InputBox'
import PassInputBox from '../_ui/PassInputBox'

const Forgot = () => {
  const [formEmail, setEmail] = useState('')
  const [formPass, setFormPass] = useState('')
  const [formCode, setFormCode] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [codeSent, setCodeSent] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [formEmail, formPass, formCode])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      codeSent ? handleChangeRequest() : handleForgot()
    }
  }

  const handleForgot = async () => {
    if (!formEmail) {
      setErrMsg('Email cannot be empty')
      return
    }

    try {
      await Auth.forgotPassword(formEmail)
      setCodeSent(true)
      setErrMsg('')
    } catch (error) {
      console.error('Error in password reset request:', error)
      setErrMsg(
        error.message || 'An error occurred during password reset request'
      )
    }
  }

  const handleChangeRequest = async () => {
    if (!formPass) {
      setErrMsg('Password cannot be empty')
      return
    } else if (!formCode) {
      setErrMsg('Verification Code cannot be empty')
      return
    }

    try {
      await Auth.forgotPasswordSubmit(formEmail, formCode, formPass)
      setErrMsg('')
      toast.success('Password changed successfully!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      setTimeout(() => {
        navigate('/login')
      }, 3000)
    } catch (error) {
      console.error('Error in password change:', error)
      setErrMsg(error.message || 'An error occurred during password change')
    }
  }

  const handleBackToLogin = () => {
    navigate('/login')
  }

  return (
    <div className="h-[91vh] bg-light p-10 font-dmsans dark:bg-dark">
      <div>
        <img
          height={50}
          src="https://static-site-images.s3.us-east-2.amazonaws.com/FF-REGULAR_V6.1.png"
          width={50}
        />
        <div className="flex h-full flex-col justify-center gap-[28px] pt-[5%] md:px-[40%]">
          {!codeSent ? (
            <>
              <h2 className="text-3xl font-bold leading-[33px] text-light-text dark:text-dark-text">
                Forgot password?
              </h2>
              <div className="text-sm leading-[23px] text-light-text2 text-opacity-60 dark:text-dark-text2">
                No worries, we&apos;ll send you reset instructions.
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-custom-font font-medium dark:text-dark-text3">
                  Email
                </div>
                <InputBox
                  key="emailInput"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email..."
                  value={formEmail}
                />
              </div>
              <p className="error-message">{errMsg}</p>
              <div
                className="inline-flex h-[51px] w-full cursor-pointer items-center justify-center gap-2.5 rounded-xl bg-accent px-[26px] py-[9px]"
                onClick={handleForgot}
              >
                <div className="font-['DM Sans'] text-sm font-bold text-white">
                  Reset Password
                </div>
              </div>
              <div className="flex w-full justify-center text-custom-font font-normal leading-snug text-light-text2 dark:text-dark-text3">
                Back to
                <button
                  className="cursor-pointer pl-1 text-accent"
                  onClick={handleBackToLogin}
                >
                  Login
                </button>
              </div>
            </>
          ) : (
            <>
              <h2 className="text-3xl font-bold leading-[33px] text-light-text dark:text-dark-text">
                Set a new password
              </h2>
              <div className="text-light-textd text-sm leading-[23px] text-opacity-60 dark:text-dark-text2">
                We sent a verification code to
                <span className="pl-1 font-medium text-accent">
                  {formEmail}
                </span>
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-custom-font font-medium dark:text-dark-text3">
                  Verification code
                </div>
                <InputBox
                  key="codeInput"
                  name="verificationCode"
                  onChange={(e) => setFormCode(e.target.value)}
                  placeholder="Enter your verification code..."
                  value={formCode}
                />
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-custom-font font-medium dark:text-dark-text3">
                  New Password
                </div>
                <PassInputBox
                  key="newPasswordInput"
                  name="newPassword"
                  onChange={(e) => setFormPass(e.target.value)}
                  placeholder="Enter a new password..."
                />
              </div>
              <p className="error-message text-red-500">{errMsg}</p>
              <div
                className="inline-flex h-[51px] w-full items-center justify-center gap-2.5 rounded-xl bg-accent px-[26px] py-[9px]"
                onClick={handleChangeRequest}
              >
                <div className="pointer-cursor text-sm font-bold text-white">
                  Continue
                </div>
              </div>
              <div className="flex w-full justify-center text-custom-font font-normal leading-snug text-light-text2 dark:text-dark-text3">
                Back to
                <button
                  className="cursor-pointer pl-1 text-accent"
                  onClick={handleBackToLogin}
                >
                  Login
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default Forgot
