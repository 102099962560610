import React, { useState } from 'react'

const PlayerSelect = ({ players, onChange, value, onRemove }) => {
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
    >
      <select
        className="ap-user-settings-select"
        onChange={onChange}
        value={value}
      >
        <option value="">Select a player</option>
        {Object.keys(players).map((playerId) => {
          const player = players[playerId]
          return (
            <option key={player.rank} value={player.rank}>
              #{player.rank} {player.player_name} ({player.elo_rating})
            </option>
          )
        })}
      </select>
      <button onClick={onRemove} style={{ marginLeft: '10px' }}>
        Remove
      </button>
    </div>
  )
}

const AddPlayers = ({
  allPlayersByRank,
  selectedPlayersByRank,
  setSelectedPlayersByRank,
}) => {
  const [selectNum, setSelectNum] = useState(0)

  const addPlayerSelect = () => {
    setSelectNum(selectNum + 1)
  }

  const removePlayerSelect = (index) => () => {
    // Remove the selectNum at the given index
    setSelectNum((current) => current - 1)

    // Remove the selected player at the given index
    const newSelectedPlayers = selectedPlayersByRank
      .filter((_, idx) => idx !== index)
      .sort((a, b) => Number(a) - Number(b))
    setSelectedPlayersByRank(newSelectedPlayers)
  }

  const handleSelectChange = (index) => (e) => {
    const newSelectedPlayers = [...selectedPlayersByRank]
    newSelectedPlayers[index] = e.target.value

    setSelectedPlayersByRank(
      newSelectedPlayers.sort((a, b) => Number(a) - Number(b))
    )
  }

  return (
    <div className="ap-add-player-container">
      <div>
        <button className="ap-button" onClick={addPlayerSelect}>
          Add Player
        </button>
      </div>

      {[...Array(selectNum).keys()].map((index) => (
        <PlayerSelect
          key={index}
          onChange={handleSelectChange(index)}
          onRemove={removePlayerSelect(index)}
          players={allPlayersByRank}
          value={selectedPlayersByRank[index] || ''}
        />
      ))}
    </div>
  )
}

export default AddPlayers
