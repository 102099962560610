// Layout.jsx
import React from 'react'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Navbar from '../_ui/Navbar'
import SubscribeButton from '../_ui/SubscribeButton'

const Layout = () => {
  const { loggedIn, is_creator, is_subscribed } = useSelector(
    (state) => state.user
  )

  return (
    <>
      <Navbar />
      <main>
        <Outlet />
      </main>
      {!loggedIn || (!is_subscribed && !is_creator && <SubscribeButton />)}
    </>
  )
}

export default Layout
