import { Auth } from 'aws-amplify'
import axios from 'axios'
import jwtDecode from 'jwt-decode'

import { store } from '../store/reducer'
import { logout as logoutAction } from '../store/userReducer'

const { dispatch } = store

const getNewToken = async () => {
  try {
    const session = await Auth.currentSession()
    const token = session.getAccessToken().getJwtToken()
    localStorage.setItem(process.env.REACT_APP_FLOCK_TOKEN, token)
    return token
  } catch (err) {
    return null
  }
}

export const handleToken = async () => {
  // Look for token in localStorage
  const token = localStorage.getItem(process.env.REACT_APP_FLOCK_TOKEN)
  let newToken = null

  if (token) {
    // If we have token, decode and see when it expires
    const decoded = jwtDecode(token)
    const currentTimeSeconds = Math.round(+new Date() / 1000)

    // If expired, get new token and set it
    if (decoded.exp < currentTimeSeconds) {
      newToken = await getNewToken()
    }
  } else {
    // No token set, get one
    newToken = await getNewToken()
  }

  if (newToken) {
    axios.defaults.headers.common['Authorization'] = newToken
    return { token: newToken, isNew: true }
  }

  return { token, isNew: false }
}

export const checkLoginInterceptor = () => {
  axios.interceptors.request.use(async (config) => {
    try {
      const { isNew, token } = await handleToken()
      if (isNew) {
        config.headers['Authorization'] = token
      }
    } catch (err) {
      dispatch(logoutAction())
      return config
    }
    return config
  })
}

export const getToken = async () => {
  try {
    const { token } = await handleToken()
    if (token) {
      axios.defaults.headers.common['Authorization'] = token
    }
    return token
  } catch (err) {
    dispatch(logoutAction())
    return null
  }
}
