import React from 'react'

import { ColoredText } from './UtilComponents'

const NON = 'NON'
const HALF = 'HALF'

const TePremium = ({ handleSettings, selectValue, userSettings }) => {
  const updateMultiplier = (position, value) => {
    const newScoring = { ...userSettings }
    newScoring.scoring[selectValue][position] = value
    handleSettings(newScoring)
  }

  const scoringText =
    selectValue === NON
      ? 'NON PPR'
      : selectValue === HALF
        ? '1/2 PPR'
        : 'FULL PPR'

  const tePremiumValue = userSettings.scoring[selectValue].te_premium

  return (
    <div>
      <div>
        <div className="ap-header">
          TE Premium: <ColoredText text={scoringText} />
        </div>
        <div className="ap-segment">
          <div>
            <div>Multiplier</div>
            <div>
              <input
                className="ap-input"
                onChange={(e) => updateMultiplier('te_premium', e.target.value)}
                value={tePremiumValue}
              />
            </div>
          </div>
          <div>
            <div className="ap-intro-algo">
              <div className="ap-user-value">Base</div>
              <pre>
                return elo_rating * <ColoredText text={'MULTIPLIER'} />
              </pre>
            </div>
            <div>
              <div className="ap-user-value">Edited</div>
              <pre>
                return elo_rating *{' '}
                <ColoredText text={userSettings.teMultiplier} />
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TePremium
