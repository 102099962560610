import React, { useState, useEffect } from 'react'
import { LiaTimesSolid } from 'react-icons/lia'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { saveTradeSettings, updateTradeSettings } from '../../store/api'

import RosterSelect from './RosterSelect'
import BaseSwitch from '../_ui/BaseSwitch'
import TeamSelect from './TeamSelect'
import ToggleButton from '../_ui/ToggleButton'

const CalcModal = ({ fetchSettings, onClose, modalVisible, settings }) => {
  const [league, setLeague] = useState(
    settings?.format.toLowerCase() || 'redraft'
  )
  const [ppr, setPpr] = useState(settings?.scoring || 'HALF')
  const [tePremium, setTepremium] = useState(
    settings?.enable_te_premium || false
  )
  const [superflex, setSuperflex] = useState(
    settings?.enable_superflex || false
  )
  const [team, setTeam] = useState(settings?.league_size?.toString() || '12')
  const [qb, setQb] = useState(settings?.qb?.toString() || '1')
  const [rb, setRb] = useState(settings?.rb?.toString() || '2')
  const [wr, setWr] = useState(settings?.wr?.toString() || '2')
  const [te, setTe] = useState(settings?.te?.toString() || '1')
  const [flex, setFlex] = useState(settings?.flex?.toString() || '1')
  const [bench, setBench] = useState(settings?.bench?.toString() || '5')
  const [isClosing, setIsClosing] = useState(false)

  useEffect(() => {
    if (settings) {
      setLeague(settings.format.toLowerCase())
      setPpr(settings.scoring)
      setTepremium(settings.enable_te_premium)
      setSuperflex(!!settings.enable_superflex)
      setTeam(settings.league_size.toString())
      setQb(settings.qb.toString())
      setRb(settings.rb.toString())
      setWr(settings.wr.toString())
      setTe(settings.te.toString())
      setFlex(settings.flex.toString())
      setBench(settings.bench.toString())
    }
  }, [settings])

  useEffect(() => {
    if (league === 'redraft') {
      setSuperflex(false)
    }
  }, [league])

  const handleModalClose = () => {
    setIsClosing(true)
    handleSubmit()
    onClose()
  }

  const handleSubmit = async () => {
    const tradeSettingsPayload = {
      format: league.toUpperCase(),
      scoring: ppr.toUpperCase(),
      enableTePremium: tePremium,
      leagueSize: parseInt(team, 10),
      qb: parseInt(qb, 10),
      rb: parseInt(rb, 10),
      wr: parseInt(wr, 10),
      te: parseInt(te, 10),
      flex: parseInt(flex, 10),
      bench: parseInt(bench, 10),
      enableSuperflex: superflex,
    }
    if (settings) {
      await updateTradeSettings(tradeSettingsPayload)
    } else {
      await saveTradeSettings(tradeSettingsPayload)
    }

    const changedFormat = league.format !== settings.format.toLowerCase()
    const changedSuperflexToggle = settings.enable_superflex !== superflex

    if (changedFormat || changedSuperflexToggle) {
      fetchSettings(false)
    }
  }

  const selectLeagueType = (type) => {
    setLeague(type.toLowerCase())
  }

  const selectPprType = (type) => {
    setPpr(type)
  }

  const handleSuperflexChange = (newSuperflex) => {
    setSuperflex(newSuperflex)
    setQb(newSuperflex ? '2' : '1')
  }

  const mobileStyle = {
    position: 'fixed',
    bottom: 0,
    height: '80%',
    width: '100%',
    overflow: 'auto',
    borderTopLeftRadius: '25px',
    borderTopRightRadius: '25px',
  }

  return (
    <div>
      <div
        className={`fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50 ${modalVisible ? 'flex' : 'hidden'}`}
      >
        <div
          className="max-h-[80%] overflow-auto rounded-[25px] bg-light px-6 pb-8 text-light-text dark:bg-dark dark:text-dark-text"
          style={window.innerWidth < 768 ? mobileStyle : null}
        >
          <div className="flex w-full justify-center">
            <span className="my-2 h-1 w-20 rounded-full bg-neutral-500" />
          </div>
          <div className="flex items-center justify-between pb-3">
            <h3 className="text-xl font-bold">League Settings</h3>
            <button>
              <LiaTimesSolid
                className="fill-light-text2 transition-colors duration-300 hover:fill-accent dark:fill-dark-text2 hover:dark:fill-accent"
                onClick={handleModalClose}
                size={22}
              />
            </button>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col gap-4 overflow-auto">
              <div className="flex flex-col gap-2">
                <div className="text-md font-medium">League Type</div>
                <div className="flex gap-2">
                  <ToggleButton
                    isSelected={league === 'redraft'}
                    onClick={() => selectLeagueType('Redraft')}
                    text="Redraft"
                  />
                  <ToggleButton
                    isSelected={league === 'dynasty'}
                    onClick={() => selectLeagueType('Dynasty')}
                    text="Dynasty"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-md font-medium">PPR</div>
                <div className="flex gap-2">
                  <ToggleButton
                    isSelected={ppr === 'NON'}
                    onClick={() => selectPprType('NON')}
                    text="None"
                  />
                  <ToggleButton
                    isSelected={ppr === 'HALF'}
                    onClick={() => selectPprType('HALF')}
                    text="1/2"
                  />
                  <ToggleButton
                    isSelected={ppr === 'PPR'}
                    onClick={() => selectPprType('PPR')}
                    text="Full"
                  />
                </div>
              </div>
              <div className="flex gap-2">
                <BaseSwitch
                  isChecked={tePremium}
                  label="TE Premium"
                  setIsChecked={setTepremium}
                />
                {league === 'dynasty' && (
                  <BaseSwitch
                    isChecked={superflex && league !== 'redraft'}
                    label="Superflex"
                    setIsChecked={handleSuperflexChange}
                  />
                )}
              </div>
              <TeamSelect onTeamSelect={setTeam} selectedTeamCount={team} />
              <div className="md:w-[20vw]">
                <RosterSelect
                  league={league}
                  maxBench={6}
                  maxPlayers={3}
                  onBenchChange={setBench}
                  onFlexChange={setFlex}
                  onRbChange={setRb}
                  onTeChange={setTe}
                  onWrChange={setWr}
                  settings={settings}
                  superflex={superflex}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default CalcModal
