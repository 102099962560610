import React from 'react'
import { MdOutlineSwapVert } from 'react-icons/md'
import { FaXmark, FaArrowDown, FaArrowUp } from 'react-icons/fa6'

import BaseCheckbox from '../../_ui/BaseCheckbox'

import PlayerAvatar from '../../../images/player_avatar.svg'
import loadingGif from '../../../images/FlockLoadingIcon.gif'

const positionColors = {
  RB: 'text-accent-purple bg-accent-bg-purple',
  QB: 'text-accent-green bg-accent-bg-green',
  WR: 'text-accent-red bg-accent-bg-red',
  TE: 'text-accent-cyan bg-accent-bg-cyan',
}

const SUPERFLEX = 'SUPERFLEX'

const RankingsTable = ({
  activeFilter,
  allPlayers,
  getFilteredRankings,
  handleCheckboxChange,
  handleDragStart,
  handleDrop,
  hideTiers,
  isLoading,
  moveActive,
  movePlayer,
  movePlayerDown,
  movePlayerUp,
  rankingsTiers,
  removePlayer,
  superflexTiers,
}) => {
  return (
    <div className="mt-8 h-[80vh] overflow-auto">
      {isLoading ? (
        <div className="flex h-[60vh] items-center justify-center">
          <img
            alt="Loading..."
            src={loadingGif}
            style={{ width: '250px', height: '250px' }}
          />
        </div>
      ) : getFilteredRankings().length === 0 ? (
        <div className="text-light-text dark:text-dark-text">
          You have no rankings
        </div>
      ) : (
        <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
          <thead className="text-xs font-medium text-light-text2 dark:text-dark-text2">
            <tr>
              <th className="px-6 py-3" scope="col"></th>
              <th className="px-6 py-3" scope="col">
                Player
              </th>
              <th className="px-6 py-3" scope="col">
                Team
              </th>
              <th className="px-6 py-3" scope="col">
                Pos
              </th>
              <th className="px-6 py-3" scope="col">
                Move
              </th>
              <th className="py-3 pl-6" scope="col">
                Down
              </th>
              <th className="py-3 pr-6" scope="col">
                Up
              </th>
              <th className="px-6 py-3" scope="col">
                Remove
              </th>
            </tr>
          </thead>
          <tbody>
            {getFilteredRankings().map((playerRanking, index) => {
              const { player_name, picture, position, team } =
                allPlayers[playerRanking.player_id]

              const tierBreaks =
                activeFilter === SUPERFLEX ? superflexTiers : rankingsTiers

              const actionText = moveActive ? (
                <MdOutlineSwapVert className="fill-accent" size={24} />
              ) : (
                <MdOutlineSwapVert
                  className="fill-light-text dark:fill-dark-text"
                  size={24}
                />
              )

              const playerPicture = picture
                ? `https://flock-player-images-2.s3.us-east-2.amazonaws.com/${picture}`
                : PlayerAvatar

              const positionClass = positionColors[position] || ''

              const isTierBreakpoint = (playerId) =>
                tierBreaks.includes(playerId)

              const isChecked = tierBreaks.includes(playerRanking.player_id)

              const getTierNumber = (playerId) => {
                const index = tierBreaks.indexOf(playerId)
                return index + 1
              }

              return (
                <tr
                  className="border-b-[1px] border-light2 font-medium text-light-text dark:border-dark-border dark:text-dark-text"
                  draggable
                  key={playerRanking.player_id}
                  onDragOver={(e) => e.preventDefault()}
                  onDragStart={(e) => handleDragStart(e, index)}
                  onDrop={(e) => handleDrop(e, index)}
                >
                  <td className="px-6 py-2">{index + 1}</td>
                  <td className="flex items-center gap-2 px-6 py-2">
                    <img
                      alt={player_name}
                      className="player-picture h-[50px]"
                      src={playerPicture}
                    />
                    {player_name}
                  </td>
                  <td className="px-6 py-2 text-accent">{team}</td>
                  <td className="px-6 py-2">
                    <span
                      className={`${positionClass} inline-block rounded-full px-3 py-1 text-center`}
                    >
                      {position}
                    </span>
                  </td>
                  <td className="px-6 py-2">
                    <button onClick={() => movePlayer(index)}>
                      {actionText}
                    </button>
                  </td>
                  <td className="py-2 pl-6">
                    <button onClick={() => movePlayerDown(index)}>
                      <FaArrowDown
                        className="duraction-200 fill-light-text transition-all hover:fill-accent dark:fill-dark-text dark:hover:fill-accent"
                        size={16}
                      />
                    </button>
                  </td>
                  <td className="py-2 pr-6">
                    <button onClick={() => movePlayerUp(index)}>
                      <FaArrowUp
                        className="duraction-200 fill-light-text transition-all hover:fill-accent dark:fill-dark-text dark:hover:fill-accent"
                        size={16}
                      />
                    </button>
                  </td>
                  <td className="px-6 py-2">
                    <button
                      className="flex items-center"
                      onClick={() => removePlayer(playerRanking.player_id)}
                    >
                      <FaXmark
                        className="duraction-200 fill-light-text transition-all hover:fill-accent dark:fill-dark-text dark:hover:fill-accent"
                        size={16}
                      />
                    </button>
                  </td>
                  {!hideTiers && (
                    <td className="relative">
                      <BaseCheckbox
                        onChange={(isChecked) =>
                          handleCheckboxChange(
                            isChecked,
                            playerRanking.player_id,
                            index
                          )
                        }
                        checked={isChecked}
                      />
                      {isTierBreakpoint(
                        playerRanking.player_id,
                        activeFilter
                      ) && (
                        <div className="absolute w-[48px] -translate-x-[8px] translate-y-[3px]">
                          <span className="whitespace-nowrap text-xs">
                            Tier{' '}
                            {getTierNumber(
                              playerRanking.player_id,
                              activeFilter
                            )}
                          </span>
                          <hr className="border-accent" />
                        </div>
                      )}
                    </td>
                  )}
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default RankingsTable
