/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-2',
  aws_cognito_identity_pool_id:
    'us-east-2:c45df1d4-0764-4bd2-b340-5b9a0756a423',
  aws_cognito_region: 'us-east-2',
  aws_user_pools_id: 'us-east-2_c9uWBDDTK',
  aws_user_pools_web_client_id: '3liua1jf4vn1f7bqk9not4ujqb',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['NICKNAME', 'EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
}

export default awsmobile
