import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
// We should remove this CSS file and use tailwind if possible?
import './Rankings.css'

import {
  getAllPlayers,
  getCommunityRankings as getCommunityRankingsAction,
  getRankings,
} from '../../store/api'
import MatchupModal from '../Matchups/MatchupModal'
import FilterModal from './FilterModal'
import PlayerAvatar from '../../images/player_avatar.svg'
import loadingGif from '../../images/FlockLoadingIcon.gif'
import PlayerInfoModal from './PlayerInfoModal'

import * as TabsPrimitive from '@radix-ui/react-tabs'
import { CiSearch } from 'react-icons/ci'
import {
  MdOutlineFileDownload,
  MdOutlineFilterAlt,
  MdArrowBackIosNew,
  MdArrowForwardIos,
  MdArrowUpward,
  MdArrowDownward,
  MdFirstPage,
  MdLastPage,
} from 'react-icons/md'
import { FaXmark } from 'react-icons/fa6'

const descendingComparator = (a, b, orderBy) => {
  let valueA = a[orderBy]
  let valueB = b[orderBy]

  if (orderBy === 'communityRank') {
    if (valueA === null && valueB === null) {
      return 0
    }
    if (valueA === null) {
      return -1
    }
    if (valueB === null) {
      return 1
    }
  }

  if (orderBy.startsWith('creatorRank_')) {
    const creator = orderBy.replace('creatorRank_', '')
    const rankA = Number(a.ranks[creator]) || Infinity
    const rankB = Number(b.ranks[creator]) || Infinity
    if (rankA > rankB) {
      return -1
    }
    if (rankA < rankB) {
      return 1
    }
    return 0
  }

  if (orderBy === 'position') {
    valueA = valueA && valueA.length > 0 ? valueA[0] : ''
    valueB = valueB && valueB.length > 0 ? valueB[0] : ''
  }

  if (!isNaN(valueA) && !isNaN(valueB)) {
    valueA = Number(valueA)
    valueB = Number(valueB)
  }

  if (valueB < valueA) {
    return -1
  }
  if (valueB > valueA) {
    return 1
  }

  return 0
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const initialHeaders = [
  { id: 'player_name', label: 'Player', widthClass: 'lg:w-3/12 w-5/12' },
  { id: 'team', label: 'Team', widthClass: 'w-2/12' },
  { id: 'position', label: 'Pos', widthClass: 'w-2/12' },
  { id: 'age', label: 'Age', widthClass: 'w-2/12' },
  { id: 'communityRank', label: 'Comm. Rank', widthClass: 'w-2/12' },
  { id: 'average_rank', label: 'Expert Rank', widthClass: 'w-2/12' },
]

const mapFormatToTab = (formatValue) => {
  switch (formatValue) {
    case 'year':
      return REDRAFT
    case 'superflex':
      return SUPERFLEX
    case 'qb':
      return ONEQB
    case 'best_ball':
      return BEST_BALL
    default:
      return formatValue
  }
}

const BEST_BALL = 'BEST_BALL'
const REDRAFT = 'REDRAFT'
const SUPERFLEX = 'SUPERFLEX'
const ONEQB = 'ONEQB'

const QB = 'QB'
const RB = 'RB'
const WR = 'WR'
const TE = 'TE'
const ROOKIE = 'ROOKIE'
const DEVY = 'DEVY'

const leaguesWithDraftPicks = [SUPERFLEX, ONEQB]

const Rankings = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const query = new URLSearchParams(location.search)
  const initialTab = mapFormatToTab(query.get('format'))

  const { is_creator, is_subscribed, is_trialing } = useSelector(
    (state) => state.user
  )

  const [rankings, setRankings] = useState('')
  const [ranks, setRanks] = useState('')
  const [lastUpdated, setLastUpdated] = useState({})
  const [rawRankings, setRawRankings] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [tab, setTab] = useState(initialTab)
  const [totalRows, setTotalRows] = useState(25)
  const [modalVisible, setModalVisible] = useState(true)
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [selectedFullPlayer, setSelectedFullPlayer] = useState(null)

  const [fullLastUpdated, setFullLastUpdated] = useState(null)
  // const [ prosectLastUpdated, setProsectLastUpdated ] = useState(null)

  const [limitedView] = useState(!is_creator && !is_subscribed)
  const [creators, setCreators] = useState([])
  const [selectedCreators, setSelectedCreators] = useState([])
  const [selectedTeam, setSelectedTeam] = useState('')

  const [ageFilter, setAgeFilter] = useState([18, 50])
  const [qbFilter, setQbFilter] = useState(false)
  const [rbFilter, setRbFilter] = useState(false)
  const [wrFilter, setWrFilter] = useState(false)
  const [teFilter, setTeFilter] = useState(false)
  const [rookieFilter, setRookieFilter] = useState(false)
  const [devyFilter, setDevyFilter] = useState(false)
  const [sortField, setSortField] = useState(
    is_subscribed || is_creator || is_trialing
      ? 'average_rank'
      : 'communityRank'
  )
  const [sortOrder, setSortOrder] = useState('asc')

  const [headers, setHeaders] = useState(initialHeaders)

  const order = 'ASC'

  //----------------------------------------------------------------Use Effects-------------------------------------------------------------------------------------
  useEffect(() => {
    if (tab) {
      setLoading(true)
      setRankings([])
      fetchRankings()
    }
  }, [tab])

  useEffect(() => {
    const formatFromUrl = query.get('format') || 'year'
    handleTabChange(formatFromUrl)
  }, [])

  useEffect(() => {
    if (modalVisible) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }

    return () => {
      document.body.style.overflow = ''
    }
  }, [modalVisible])

  useEffect(() => {
    const formatFromUrl = query.get('format')
    const activeTab = mapFormatToTab(formatFromUrl)
    setTab(activeTab)
  }, [location.search])

  useEffect(() => {
    if (rankings && rankings.length) {
      updateRows()
    }
  }, [
    rankings,
    page,
    rowsPerPage,
    sortField,
    sortOrder,
    selectedCreators,
    tab,
    ageFilter,
    selectedTeam,
    qbFilter,
    rbFilter,
    wrFilter,
    teFilter,
    rookieFilter,
    devyFilter,
  ])

  useEffect(() => {
    if (searchQuery) {
      const filteredRankings = rawRankings.filter((ranking) =>
        ranking.player_name.toLowerCase().includes(searchQuery.toLowerCase())
      )
      setRankings(filteredRankings)
    } else {
      setRankings(rawRankings)
    }
  }, [searchQuery, rawRankings])

  useEffect(() => {
    if (ranks.length > 0) {
      setCreators(extractCreators(ranks))
    }
  }, [ranks])

  useEffect(() => {
    setModalVisible(true)
  }, [])

  console.log('SORT : ', sortField, sortOrder)

  const getPositionalRankings = useMemo(() => {
    const positionalRanks = { [QB]: 1, [RB]: 1, [WR]: 1, [TE]: 1 }
    // Use this for DEVY
    // const rookiePositionalRanks = { [QB]: 1, [RB]: 1, [WR]: 1, [TE]: 1 }
    const rankingsByPlayerId = {}

    const handleRanking = (playerRanking) => {
      // USE THIS FOR DEVY
      // if (playerRanking.is_rookie) {
      //   const positionalRank = rookiePositionalRanks[playerRanking.position]
      //   rookiePositionalRanks[playerRanking.position] = positionalRank + 1
      //   return positionalRank
      // }

      const positionalRank = positionalRanks[playerRanking.position]
      positionalRanks[playerRanking.position] = positionalRank + 1
      return positionalRank
    }

    const sortedRankings = stableSort(
      rawRankings,
      getComparator(sortOrder, sortField)
    )

    sortedRankings.forEach((playerRanking, index) => {
      rankingsByPlayerId[playerRanking.player_id] = {
        positionalRank: handleRanking(playerRanking),
        overallRank: index + 1,
      }
    })

    return rankingsByPlayerId
  }, [rawRankings, sortField, sortOrder])

  //----------------------------------------------------------------Data fetching-------------------------------------------------------------------------------------
  const fetchCommRankings = async () => {
    const { formatValue } = getActiveSettings()

    const { players } = await getCommunityRankingsAction({ type: formatValue })
    return players
  }

  const fetchRankings = async () => {
    const { activeTab } = getActiveSettings()
    // Deactive prospects until needed again. Just comment back in this code
    // const prospectLeague = activeTab === SUPERFLEX ? SUPERFLEX : ONEQB

    setLoading(true)
    // const [ commRanks, creatorResp, getProspectsRes, playerDataByPlayerId, ] = await Promise.all([
    const [commRanks, creatorResp, playerDataByPlayerId] = await Promise.all([
      fetchCommRankings(),
      getRankings(activeTab),
      // getProspectRankings(prospectLeague),
      getAllPlayers(),
    ])
    // const prospects = getProspectsRes.data.map((playerData) => {
    //   return { ...playerDataByPlayerId[playerData.player_id], ...playerData }
    // })
    // const creatorRanks = [ ...creatorResp.data, ...prospects ]
    const creatorRanks = [...creatorResp.data]
    setLastUpdated(creatorResp.last_updated)

    const ranks = creatorRanks
      .map((creatorRank) => {
        const commRankInfo = commRanks[creatorRank.player_id]
        const playerData = playerDataByPlayerId[creatorRank.player_id]
        if (creatorRank.is_rookie) {
          return {
            ...creatorRank,
            communityRank: commRankInfo?.rank || null,
            dob: calculateAge(playerData),
            elo_rating: commRankInfo?.elo_rating || null,
            experience: 0,
          }
        }

        return {
          ...creatorRank,
          communityRank: commRankInfo?.rank || null,
          dob: calculateAge(playerData),
          elo_rating: commRankInfo?.elo_rating || null,
          college: playerData?.college || null,
          height: playerData?.height,
          weight: playerData?.weight,
          position: playerData?.position,
          jersey_number: playerData?.jersey_number,
        }
      })
      .filter((rank) => rank.average_rank !== null && rank.average_rank >= 0)
    setRawRankings(ranks)
    setRanks(ranks)
    setLoading(false)

    setFullLastUpdated(creatorResp.last_updated)
    // setProsectLastUpdated(getProspectsRes.last_updated)
  }

  //----------------------------------------------------------------Utility Functions------------------------------------------------------------------------------------
  const getActiveSettings = () => {
    const search = location.search
    const query = new URLSearchParams(search)
    const formatValue = query.get('format')

    let activeTab
    if (formatValue === 'year') {
      activeTab = REDRAFT
    } else if (formatValue === 'superflex') {
      activeTab = SUPERFLEX
    } else if (formatValue === 'qb') {
      activeTab = ONEQB
    } else {
      activeTab = formatValue // Default to the formatValue if it's not one of the specific cases
    }

    return { activeTab, formatValue }
  }

  const ageFilteFn = (rankingsArr) =>
    rankingsArr.filter((ranking) => {
      if (ranking.is_draft_pick) {
        return true
      }
      return ranking.dob >= ageFilter[0] && ranking.dob <= ageFilter[1]
    })

  const rookieFilterFn = (rankingsArr) => {
    return rankingsArr.filter((ranking) => {
      if (ranking.is_draft_pick) {
        if (rookieFilter) {
          return false
        }
        return true
      }

      return ranking.is_rookie
    })
  }

  const devyFilterFn = (rankingsArr) => {
    return rankingsArr.filter((ranking) => {
      if (!devyFilter) {
        if (ranking.is_draft_pick) {
          return true
        }

        return ranking.player_id < 99000
      }

      return ranking.is_rookie && ranking.player_id >= 99000
    })
  }

  const teamFilter = (rankingsArr) => {
    return rankingsArr.filter((ranking) => ranking.team === selectedTeam)
  }

  const positionalFilter = (rankingsArr) => {
    const postionalDictFilter = {
      [QB]: qbFilter,
      [RB]: rbFilter,
      [WR]: wrFilter,
      [TE]: teFilter,
    }
    const activeFilters = []

    Object.keys(postionalDictFilter).forEach((positionalKey) => {
      if (postionalDictFilter[positionalKey]) {
        activeFilters.push(positionalKey)
      }
    })

    if (activeFilters.length > 0) {
      return rankingsArr.filter((ranking) => {
        if (ranking.is_draft_pick) {
          return true
        }
        return activeFilters.includes(ranking.position)
      })
    }

    return rankingsArr
  }

  const updateRows = () => {
    let filteredRankings = rankings

    // This is to remove current year draft picks after draft is over
    // This is to help transition the website to next year of draft picks
    filteredRankings = filteredRankings.filter((ranking) => {
      return (
        !ranking.player_name.includes('2024') &&
        !ranking.player_name.includes('2027')
      )
    })

    if (leaguesWithDraftPicks.indexOf(tab) === -1) {
      filteredRankings = filteredRankings.filter((ranking) => {
        return !ranking.is_draft_pick
      })
    }

    if (selectedTeam && !devyFilter) {
      filteredRankings = teamFilter(filteredRankings)
    }

    filteredRankings = ageFilteFn(filteredRankings)

    if (rookieFilter) {
      filteredRankings = rookieFilterFn(filteredRankings)
    }

    filteredRankings = devyFilterFn(filteredRankings)

    filteredRankings = positionalFilter(filteredRankings)

    const sortFieldValue =
      devyFilter && sortField === 'communityRank' ? 'average_rank' : sortField

    const sortedRankings = stableSort(
      filteredRankings,
      getComparator(sortOrder, sortFieldValue)
    )

    // Calculate the start and end indices for pagination
    const start = page * rowsPerPage
    const end = Math.min(start + rowsPerPage, filteredRankings.length)

    // Handling headers and rows
    let newHeaders = [...initialHeaders]
    selectedCreators.forEach((creator) => {
      if (!newHeaders.some((header) => header.label === creator)) {
        newHeaders.push({
          id: `creatorRank_${creator}`,
          label: creator,
          widthClass: '',
        })
      }
    })

    adjustColumnWidths(newHeaders)

    const tempTierBreaks = {}

    // Slice the sorted rankings for the current page and map to rows
    const newRows = sortedRankings
      .slice(start, end)
      .map((ranking, index, arr) => {
        const row = {
          ...ranking,
          average_rank: ranking.average_rank
            ? parseFloat(ranking.average_rank).toFixed(2)
            : 0,
          communityRank: ranking.communityRank,
          team: ranking.team === 'None' ? '-' : ranking.team,
          player_id: ranking.player_id,
          picture: ranking.picture,
        }

        selectedCreators.forEach((creator) => {
          const rank = ranking.ranks[creator] || '-'
          const tier = ranking.tiers ? ranking.tiers[creator] : undefined

          row[`creatorRank_${creator}`] = rank

          if (tier !== undefined) {
            if (!tempTierBreaks[creator]) {
              tempTierBreaks[creator] = {}
            }
            tempTierBreaks[creator][ranking.player_id] = tier

            let nextRanking = arr[index + 1]
            if (nextRanking) {
              let nextTier = nextRanking.tiers
                ? nextRanking.tiers[creator]
                : undefined
              if (tier !== nextTier) {
                row[`creatorRank_${creator}_tierBreak`] = true
              }
            }
          }
        })

        return row
      })

    setCreators(extractCreators(filteredRankings))

    // Update state with new headers and rows
    setTotalRows(filteredRankings)
    setHeaders(newHeaders)
    setRows(newRows)
  }

  const removeFilters = () => {
    setQbFilter(false)
    setRbFilter(false)
    setWrFilter(false)
    setTeFilter(false)
    setRookieFilter(false)
    setDevyFilter(false)
    setAgeFilter([18, 50])
    setSortField(
      is_subscribed || is_creator || is_trialing
        ? 'average_rank'
        : 'communityRank'
    )
    setPage(0)
  }

  const handleFilterModal = (filterType) => {
    switch (filterType) {
      case QB:
        setQbFilter((prev) => !prev)
        break
      case RB:
        setRbFilter((prev) => !prev)
        break
      case WR:
        setWrFilter((prev) => !prev)
        break
      case TE:
        setTeFilter((prev) => !prev)
        break
      case ROOKIE:
        setRookieFilter((prev) => !prev)
        break
      case DEVY:
        setRookieFilter((prev) => !prev)
        break
      default:
        removeFilters()
        break
    }
  }

  const adjustColumnWidths = (headers) => {
    const totalColumns = headers.length
    const playerNameExtraWidth = 17
    const baseWidth = (100 - playerNameExtraWidth) / (totalColumns - 1)

    headers.forEach((header) => {
      if (header.id === 'player_name') {
        header.widthClass = `w-[${playerNameExtraWidth + baseWidth}%]`
        header.style = { width: `${playerNameExtraWidth + baseWidth}%` }
      } else {
        header.widthClass = `w-[${baseWidth}%]`
        header.style = { width: `${baseWidth}%` }
      }
    })

    if (totalColumns === initialHeaders.length) {
      headers.forEach((header) => {
        header.widthClass =
          header.id === 'player_name' ? 'lg:w-3/12 w-8/12' : 'w-2/12'
        delete header.style
      })
    }
  }

  const rankingsToCSV = () => {
    let lineArray = []
    const headerRow = ['Name', 'Team', 'Position'] // Fixed columns

    const orderedColumns = [] // To store the order and type of columns
    const otherCreators = selectedCreators ? [...selectedCreators] : []

    // Determine the primary column based on sortField
    if (sortField.startsWith('creatorRank_')) {
      const creator = sortField.replace('creatorRank_', '')
      orderedColumns.push({ type: 'creator', name: creator, includeTier: true })
      // Remove this creator from otherCreators
      const index = otherCreators.indexOf(creator)
      if (index > -1) {
        otherCreators.splice(index, 1)
      }
    } else if (sortField === 'average_rank') {
      orderedColumns.push({ type: 'average' })
    } else if (sortField === 'communityRank') {
      orderedColumns.push({ type: 'community' })
    }

    // Ensure Community and Average are included in order
    if (!orderedColumns.find((col) => col.type === 'community')) {
      orderedColumns.push({ type: 'community' })
    }
    if (!orderedColumns.find((col) => col.type === 'average')) {
      orderedColumns.push({ type: 'average' })
    }

    // Add remaining creators without tiers
    otherCreators.forEach((creator) => {
      orderedColumns.push({
        type: 'creator',
        name: creator,
        includeTier: false,
      })
    })

    // Build the CSV header row
    orderedColumns.forEach((col) => {
      if (col.type === 'community') {
        headerRow.push('Community Rank')
      } else if (col.type === 'average') {
        headerRow.push('Average Rank')
      } else if (col.type === 'creator') {
        headerRow.push(`${col.name} Rank`)
        if (col.includeTier) {
          headerRow.push(`${col.name} Tier`)
        }
      }
    })

    lineArray.push(headerRow.join(','))

    // Sort the rankings based on sortField
    const comparator = getComparator(order, sortField)
    const sortedRankings = stableSort(rankings, comparator)

    // Build the CSV data rows
    sortedRankings.forEach((data) => {
      const row = [data.player_name, data.team || '', data.position || '']

      orderedColumns.forEach((col) => {
        if (col.type === 'community') {
          row.push(data.communityRank !== undefined ? data.communityRank : '-')
        } else if (col.type === 'average') {
          row.push(
            data.average_rank !== undefined
              ? parseFloat(data.average_rank).toFixed(2)
              : '-'
          )
        } else if (col.type === 'creator') {
          row.push(
            data.ranks && data.ranks[col.name] !== undefined
              ? data.ranks[col.name]
              : '-'
          )
          if (col.includeTier) {
            row.push(
              data.tiers && data.tiers[col.name] !== undefined
                ? data.tiers[col.name]
                : '-'
            )
          }
        }
      })

      lineArray.push(row.join(','))
    })

    // Convert array to CSV string
    const csvContent = lineArray.join('\n')

    // Create blob and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', `${tab}-rankings.csv`)
    link.style.display = 'none'

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }

  const extractCreators = (ranks) => {
    const creatorSet = new Set()
    ranks.forEach((rank) => {
      if (rank.ranks) {
        Object.keys(rank.ranks).forEach((creator) => creatorSet.add(creator))
      }
    })
    return Array.from(creatorSet)
  }

  const calculateAge = ({ dob, age }) => {
    if (!dob) {
      if (age) {
        return age
      }
      return ''
    }
    const birthDate = new Date(dob)
    const now = new Date()
    let playerAge = now.getFullYear() - birthDate.getFullYear()
    const m = now.getMonth() - birthDate.getMonth()

    if (m < 0 || (m === 0 && now.getDate() < birthDate.getDate())) {
      playerAge--
    }

    const monthFraction = m >= 0 ? m / 12 : (12 + m) / 12

    return (playerAge + monthFraction).toFixed(1)
  }

  //----------------------------------------------------------------Handlers-------------------------------------------------------------------------------------
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
  }

  const handleSelectPlayer = (playerId) => {
    const player = ranks.find((p) => p.player_id === playerId)
    setSelectedFullPlayer(player)
  }

  const handleCreatorSelected = (creator) => {
    setSelectedCreators((prevCreators) => {
      if (!prevCreators.includes(creator.label)) {
        const newCreators = [...prevCreators, creator.label]
        setHeaders((prevHeaders) => [
          ...prevHeaders,
          {
            id: `creatorRank_${creator.label}`,
            label: creator.label,
            widthClass: '',
            sortable: true,
          },
        ])

        setSortField(`creatorRank_${creator.label}`)
        setSortOrder('asc')
        return newCreators
      }
      return prevCreators
    })
  }

  const handleRemoveCreator = (creatorToRemove) => {
    setSelectedCreators((prevCreators) => {
      const newCreators = prevCreators.filter(
        (creator) => creator !== creatorToRemove
      )
      if (newCreators.length === 0) {
        setSortField(
          is_subscribed || is_creator || is_trialing
            ? 'average_rank'
            : 'communityRank'
        )
        setSortOrder('asc')
      }
      return newCreators
    })
  }

  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }

  const handleSort = (field) => {
    // Determine the new sort order based on the current field and order
    let newSortOrder = 'asc'

    if (field === 'Age') {
      // Toggle between 'asc' and 'desc' for Age
      if (sortField === field) {
        newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc'
      }
    } else {
      // For other fields, set to 'asc' if a different field is clicked or
      // if the same field is already in descending order
      if (sortField === field && sortOrder === 'desc') {
        newSortOrder = 'asc'
      }
    }

    // Update the sortField and sortOrder states
    setSortField(field)
    setSortOrder(newSortOrder)

    // Trigger re-sorting of the rankings
    const sortedRankings = stableSort(
      [...rankings],
      getComparator(newSortOrder, field)
    )
    setRankings(sortedRankings)
    setPage(0)
  }

  const handleTabChange = (newFormat) => {
    let queryParam = newFormat

    if (newFormat === REDRAFT) {
      queryParam = 'year'
    } else if (newFormat === SUPERFLEX) {
      queryParam = 'superflex'
    } else if (newFormat === ONEQB) {
      queryParam = 'qb'
    } else if (newFormat === BEST_BALL) {
      queryParam = BEST_BALL
    }

    setQbFilter(false)
    setRbFilter(false)
    setWrFilter(false)
    setTeFilter(false)
    setRookieFilter(false)
    setAgeFilter([18, 50])
    navigate(`?format=${queryParam}`)
  }

  const renderTableCell = (rank, key) => {
    let content
    let tierBreakElement = null

    // Common styling for all cells
    const baseClasses =
      'text-sm font-medium text-light-text dark:text-dark-text'

    switch (key) {
      case 'player_name': {
        const playerPicture = rank.picture
          ? `https://flock-player-images-2.s3.us-east-2.amazonaws.com/${rank.picture}`
          : PlayerAvatar
        content = (
          <div className="flex h-[50px] cursor-pointer items-center gap-4">
            <img
              alt={rank.player_name}
              className="player-picture h-[50px]"
              src={playerPicture}
            />
            {rank.player_name}
          </div>
        )
        break
      }
      case 'team': {
        content = <span className="text-accent">{rank.team}</span>
        break
      }
      case 'position': {
        const positionColors = {
          RB: 'text-accent-purple bg-accent-bg-purple',
          QB: 'text-accent-green bg-accent-bg-green',
          WR: 'text-accent-red bg-accent-bg-red',
          TE: 'text-accent-cyan bg-accent-bg-cyan',
        }
        if (rank.position) {
          const pos = rank.position
          const { positionalRank } = getPositionalRankings[rank.player_id]
          const positionalText = `${rank.position} ${positionalRank}`
          return (
            <span
              className={`${positionColors[pos] || ''} block w-14 rounded-full py-1 text-center`}
              key={`${rank.player_id}-${rank.position}`}
            >
              {positionalText}
            </span>
          )
        } else {
          content = <span></span>
        }
        break
      }
      case 'communityRank':
        content = (
          <div className="flex h-full w-full items-center justify-center pl-4 lg:pl-8">
            {rank.communityRank}
          </div>
        )
        break

      case 'average_rank':
        content = (
          <span className={`${limitedView ? 'blur-sm' : ''}`}>
            {parseFloat(rank.average_rank).toFixed(2)}
          </span>
        )
        break
      case 'age':
        content = rank.dob
        break
      default:
        content = rank[key]

        if (key.startsWith('creatorRank_') && key === sortField) {
          const creator = key.replace('creatorRank_', '')
          if (rank[`${key}_tierBreak`]) {
            const tierValue = rank.tiers ? rank.tiers[creator] : ''

            tierBreakElement = (
              <div className="flex flex-col items-end">
                <hr className="translate-y-[25px] border-t-[1px] border-accent px-4 md:px-9" />
                <span className="absolute -bottom-6 right-0 text-xs">{`Tier ${tierValue}`}</span>
              </div>
            )
          }
        }
    }

    return (
      <td className={baseClasses}>
        <div className="relative">
          {content}
          {tierBreakElement}
        </div>
      </td>
    )
  }

  const renderHeaderCell = (header) => {
    const isCreatorHeader = header.id.startsWith('creatorRank_')
    const isSortable =
      isCreatorHeader ||
      ['age', 'communityRank', 'average_rank'].includes(header.id)

    return (
      <th
        className={`${header.widthClass} sticky top-0 z-[2] bg-light py-3 text-left text-xs font-medium text-light-text2 dark:bg-dark dark:text-dark-text2`}
        key={header.id}
        onClick={isSortable ? () => handleSort(header.id) : null}
        style={header.style}
      >
        <div className="flex items-center">
          <div className="flex flex-col">
            {header.label}
            {isCreatorHeader && lastUpdated[header.label] && (
              <span className="text-xs text-light-text2 dark:text-dark-text2">
                {`${new Date(lastUpdated[header.label]).toLocaleDateString()}`}
              </span>
            )}
          </div>
          {sortField === header.id &&
            (sortOrder === 'asc' ? (
              <MdArrowUpward className="fill-light-text2 dark:fill-dark-text2" />
            ) : (
              <MdArrowDownward className="fill-light-text2 dark:fill-dark-text2" />
            ))}
          {isCreatorHeader && (
            <FaXmark
              onClick={(e) => {
                e.stopPropagation()
                handleRemoveCreator(header.label)
              }}
              className="ml-2 cursor-pointer transition-all duration-200 hover:fill-accent"
              size={16}
            />
          )}
        </div>
      </th>
    )
  }

  const highlightOverall =
    !qbFilter &&
    !rbFilter &&
    !wrFilter &&
    !teFilter &&
    !rookieFilter &&
    !devyFilter

  return (
    <div className="overflow-hidden bg-light px-[5%] pt-8 font-dmsans dark:bg-dark md:px-[15%]">
      {modalVisible && (
        <MatchupModal
          format="year"
          modalVisible={modalVisible}
          onClose={toggleModal}
        />
      )}
      <FilterModal
        ageRange={ageFilter}
        applyFilters={handleFilterModal}
        creators={creators}
        fullUpdated={fullLastUpdated}
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        onCreatorSelect={handleCreatorSelected}
        // prospectUpdated={prosectLastUpdated}
        qbFilter={qbFilter}
        rbFilter={rbFilter}
        rookieFilter={rookieFilter}
        selectedTeam={selectedTeam}
        setAgeRange={setAgeFilter}
        setSelectedTeam={setSelectedTeam}
        teFilter={teFilter}
        wrFilter={wrFilter}
      />
      <span className="text-[26px] font-bold text-light-text dark:text-dark-text">
        Rankings
      </span>
      <TabsPrimitive.Root
        className="flex flex-col justify-between"
        defaultValue={initialTab}
        onValueChange={handleTabChange}
      >
        <div className="flex flex-col items-center justify-center py-6 md:flex-row md:flex-wrap lg:justify-between">
          <div className="flex justify-center gap-3 md:flex-wrap md:justify-normal">
            <div className="mt-2 flex h-[50px] items-center gap-4 rounded-custom border-[1px] border-light2 p-3 dark:border-dark-border md:w-[300px]">
              <CiSearch className="fill-light-bg2" size={22} />
              <input
                className="bg-transparent text-sm text-light-text2 outline-none dark:text-dark-text2"
                onChange={handleSearchChange}
                placeholder="Search for a player..."
                type="text"
                value={searchQuery}
              />
            </div>
            <button
              className="mt-2 flex h-[50px] items-center gap-3 rounded-[11px] border-0 bg-accent px-4 py-2 text-sm font-bold text-dark-text md:mx-6 lg:hidden"
              onClick={() => setIsFilterModalOpen(true)}
            >
              <MdOutlineFilterAlt size={18} />
            </button>
          </div>
          <TabsPrimitive.List className="mt-2 flex justify-center gap-1 rounded-custom border-0 bg-light-bg p-[4px] text-custom-font dark:bg-dark-bg-select md:mt-0 md:h-[50px]">
            <TabsPrimitive.Trigger
              className={`rounded-lg px-8 py-2 transition-all duration-200 hover:bg-accent-bg hover:text-accent hover:dark:text-accent ${
                tab === REDRAFT
                  ? 'bg-accent-bg font-bold text-accent'
                  : 'text-light-text2 dark:text-dark-text2'
              }`}
              value={REDRAFT}
            >
              Redraft · 2024
            </TabsPrimitive.Trigger>
            <TabsPrimitive.Trigger
              className={`rounded-lg px-4 py-1 transition-all duration-200 hover:bg-accent-bg hover:text-accent hover:dark:text-accent ${
                tab === SUPERFLEX
                  ? 'bg-accent-bg font-bold text-accent'
                  : 'text-light-text2 dark:text-dark-text2'
              }`}
              value={SUPERFLEX}
            >
              Dynasty · Superflex
            </TabsPrimitive.Trigger>
            <TabsPrimitive.Trigger
              className={`rounded-lg px-8 py-2 transition-all duration-200 hover:bg-accent-bg hover:text-accent hover:dark:text-accent ${
                tab === ONEQB
                  ? 'bg-accent-bg font-bold text-accent'
                  : 'text-light-text2 dark:text-dark-text2'
              }`}
              value={ONEQB}
            >
              Dynasty · 1QB
            </TabsPrimitive.Trigger>
            {/* <TabsPrimitive.Trigger
              className={`rounded-lg px-8 py-2 transition-all duration-200 hover:bg-accent-bg hover:text-accent hover:dark:text-accent ${
                tab === BEST_BALL
                  ? 'bg-accent-bg font-bold text-accent'
                  : 'text-light-text2 dark:text-dark-text2'
              }`}
              value={BEST_BALL}
            >
              Best Ball
            </TabsPrimitive.Trigger> */}
          </TabsPrimitive.List>

          <div className="hidden items-center gap-4 md:pt-2 lg:flex lg:justify-end xl:w-[27%]">
            <button
              className="flex h-[50px] items-center gap-2 rounded-custom border-[1px] border-light2 p-3 px-6 text-custom-font font-bold text-light-text transition-all duration-200 hover:bg-light2 dark:border-dark-border dark:text-dark-text hover:dark:bg-dark-bg2"
              onClick={rankingsToCSV}
            >
              <MdOutlineFileDownload
                className="fill-light-text dark:fill-dark-text"
                size={22}
              />
              Download CSV
            </button>
            <button
              className="flex h-[50px] items-center gap-3 rounded-custom border-0 bg-accent px-4 py-2 text-sm font-bold text-dark-text"
              onClick={() => setIsFilterModalOpen(true)}
            >
              <MdOutlineFilterAlt size={18} />
              Filter
            </button>
          </div>
        </div>
        <div className="hidden gap-2 pb-2 md:flex">
          <button
            className={`rounded-full border-[1px] border-light2 px-[14px] py-2 text-[13px] transition-all duration-200 hover:bg-accent hover:text-light dark:border-dark-border ${
              highlightOverall
                ? 'bg-accent font-bold text-dark-text'
                : 'text-light-text dark:text-dark-text'
            }`}
            onClick={() => removeFilters()}
          >
            Overall
          </button>
          <button
            className={`rounded-full border-[1px] border-light2 px-[14px] py-2 text-[13px] transition-all duration-200 hover:bg-accent hover:text-light dark:border-dark-border ${
              qbFilter
                ? 'bg-accent font-bold text-dark-text'
                : 'text-light-text dark:text-dark-text'
            }`}
            onClick={() => setQbFilter((prev) => !prev)}
          >
            QB
          </button>
          <button
            className={`rounded-full border-[1px] border-light2 px-[14px] py-2 text-[13px] transition-all duration-200 hover:bg-accent hover:text-light dark:border-dark-border ${
              rbFilter
                ? 'bg-accent font-bold text-dark-text'
                : 'text-light-text dark:text-dark-text'
            }`}
            onClick={() => setRbFilter((prev) => !prev)}
          >
            RB
          </button>
          <button
            className={`rounded-full border-[1px] border-light2 px-[14px] py-2 text-[13px] transition-all duration-200 hover:bg-accent hover:text-light dark:border-dark-border ${
              wrFilter
                ? 'bg-accent font-bold text-dark-text'
                : 'text-light-text dark:text-dark-text'
            }`}
            onClick={() => setWrFilter((prev) => !prev)}
          >
            WR
          </button>
          <button
            className={`rounded-full border-[1px] border-light2 px-[14px] py-2 text-[13px] transition-all duration-200 hover:bg-accent hover:text-light dark:border-dark-border ${
              teFilter
                ? 'bg-accent font-bold text-dark-text'
                : 'text-light-text dark:text-dark-text'
            }`}
            onClick={() => setTeFilter((prev) => !prev)}
          >
            TE
          </button>
          <button
            className={`rounded-full border-[1px] border-light2 px-[14px] py-2 text-[13px] transition-all duration-200 hover:bg-accent hover:text-light dark:border-dark-border ${
              rookieFilter
                ? 'bg-accent font-bold text-dark-text'
                : 'text-light-text dark:text-dark-text'
            }`}
            onClick={() => setRookieFilter((prev) => !prev)}
          >
            Rookies
          </button>
          {/* <button
            className={`text-[13px] border-[1px] dark:border-dark-border border-light2 rounded-full px-[14px] py-2 hover:bg-accent hover:text-light transition-all duration-200 ${
              devyFilter
                ? 'bg-accent font-bold text-dark-text'
                : 'text-light-text dark:text-dark-text'
            }`}
            onClick={() => setDevyFilter((prev) => !prev)}
          >
            Prospects
          </button> */}
        </div>
        <TabsPrimitive.Content value={REDRAFT}>
          <div className="w-full overflow-x-auto">
            {loading ? (
              <div className="flex h-[60vh] items-center justify-center">
                <img
                  alt="Loading..."
                  src={loadingGif}
                  style={{ width: '250px', height: '250px' }}
                />
              </div>
            ) : (
              <div className="fixTableHead sticky top-0">
                <table className="w-[150%] min-w-full overflow-scroll md:w-auto">
                  <thead>
                    <tr id="header-row">
                      <th className="pl-3 text-left text-xs font-medium text-dark-text2"></th>
                      {headers.map((header) => renderHeaderCell(header))}
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, index) => (
                      <tr
                        key={index}
                        onClick={() => handleSelectPlayer(row.player_id)}
                      >
                        <td className="border-y-[1px] border-dark-border px-4 py-2 text-sm font-medium text-light-text2 dark:text-light">
                          {page * rowsPerPage + index + 1}
                        </td>
                        {headers.map((header) => (
                          <td
                            className="border-y-[1px] border-light2 py-2 text-sm font-medium text-light-text dark:border-dark-border dark:text-dark-text"
                            key={header.id}
                          >
                            {renderTableCell(row, header.id)}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </TabsPrimitive.Content>

        <TabsPrimitive.Content value={BEST_BALL}>
          <div className="w-full overflow-x-auto">
            {loading ? (
              <div className="flex h-[60vh] items-center justify-center">
                <img
                  alt="Loading..."
                  src={loadingGif}
                  style={{ width: '250px', height: '250px' }}
                />
              </div>
            ) : (
              <div className="fixTableHead h-[92vh]">
                <table className="w-[150%] min-w-full overflow-scroll md:w-auto">
                  <thead>
                    <tr id="header-row">
                      <th className="bg-light pl-3 text-left text-xs font-medium text-dark-text2 dark:bg-dark"></th>
                      {headers.map((header) => renderHeaderCell(header))}
                    </tr>
                  </thead>
                  <tbody className="">
                    {rows.map((row, index) => (
                      <tr
                        key={index}
                        onClick={() => handleSelectPlayer(row.player_id)}
                      >
                        <td className="border-y-[1px] border-dark-border px-4 py-2 text-sm font-medium text-light-text2 dark:text-light">
                          {page * rowsPerPage + index + 1}
                        </td>
                        {headers.map((header) => (
                          <td
                            className="border-y-[1px] border-light2 py-2 text-sm font-medium text-light-text dark:border-dark-border dark:text-dark-text"
                            key={header.id}
                          >
                            {renderTableCell(row, header.id)}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </TabsPrimitive.Content>
        <TabsPrimitive.Content value={SUPERFLEX}>
          <div className="w-full overflow-x-auto">
            {loading ? (
              <div className="flex h-[60vh] items-center justify-center">
                <img
                  alt="Loading..."
                  src={loadingGif}
                  style={{ width: '250px', height: '250px' }}
                />
              </div>
            ) : (
              <div className="fixTableHead h-[92vh]">
                <table className="w-[150%] min-w-full overflow-scroll md:w-auto">
                  <thead>
                    <tr id="header-row">
                      <th className="bg-light pl-3 text-left text-xs font-medium text-dark-text2 dark:bg-dark"></th>
                      {headers.map((header) => renderHeaderCell(header))}
                    </tr>
                  </thead>
                  <tbody className="">
                    {rows.map((row, index) => (
                      <tr
                        key={index}
                        onClick={() => handleSelectPlayer(row.player_id)}
                      >
                        <td className="border-y-[1px] border-dark-border px-4 py-2 text-sm font-medium text-light-text2 dark:text-light">
                          {page * rowsPerPage + index + 1}
                        </td>
                        {headers.map((header) => (
                          <td
                            className="border-y-[1px] border-light2 py-2 text-sm font-medium text-light-text dark:border-dark-border dark:text-dark-text"
                            key={header.id}
                          >
                            {renderTableCell(row, header.id)}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </TabsPrimitive.Content>
        <TabsPrimitive.Content value={ONEQB}>
          <div className="w-full overflow-x-auto">
            {loading ? (
              <div className="flex h-[60vh] items-center justify-center">
                <img
                  alt="Loading..."
                  src={loadingGif}
                  style={{ width: '250px', height: '250px' }}
                />
              </div>
            ) : (
              <div className="fixTableHead h-[92vh]">
                <table className="w-[150%] min-w-full overflow-scroll md:w-auto">
                  <thead>
                    <tr id="header-row">
                      <th className="bg-light pl-3 text-left text-xs font-medium text-dark-text2 dark:bg-dark"></th>
                      {headers.map((header) => renderHeaderCell(header))}
                    </tr>
                  </thead>
                  <tbody className="">
                    {rows.map((row, index) => (
                      <tr
                        key={index}
                        onClick={() => handleSelectPlayer(row.player_id)}
                      >
                        <td className="border-y-[1px] border-dark-border px-4 py-2 text-sm font-medium text-light-text2 dark:text-light">
                          {page * rowsPerPage + index + 1}
                        </td>
                        {headers.map((header) => (
                          <td
                            className="border-y-[1px] border-light2 py-2 text-sm font-medium text-light-text dark:border-dark-border dark:text-dark-text"
                            key={header.id}
                          >
                            {renderTableCell(row, header.id)}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </TabsPrimitive.Content>
        <div className="flex items-center gap-6 py-4">
          <button
            className="hover:text-black"
            disabled={page === 0}
            onClick={() => setPage(0)}
          >
            <MdFirstPage
              className="cursor-pointer fill-light-text transition-colors duration-200 hover:fill-accent dark:fill-dark-text"
              size={22}
            />
          </button>
          <button
            className="hover:text-black"
            disabled={page === 0}
            onClick={() => setPage((prev) => Math.max(prev - 1, 0))}
          >
            <MdArrowBackIosNew
              className="cursor-pointer fill-light-text transition-colors duration-200 hover:fill-accent dark:fill-dark-text"
              size={22}
            />
          </button>
          <button
            onClick={() =>
              setPage((prev) =>
                Math.min(
                  prev + 1,
                  Math.ceil(totalRows.length / rowsPerPage) - 1
                )
              )
            }
            disabled={page >= Math.ceil(totalRows.length / rowsPerPage) - 1}
          >
            <MdArrowForwardIos
              className="cursor-pointer fill-light-text transition-colors duration-200 hover:fill-accent dark:fill-dark-text"
              size={22}
            />
          </button>
          <button
            onClick={() =>
              setPage(Math.ceil(totalRows.length / rowsPerPage) - 1)
            }
            disabled={page >= Math.ceil(totalRows.length / rowsPerPage) - 1}
          >
            <MdLastPage
              className="cursor-pointer fill-light-text transition-colors duration-200 hover:fill-accent dark:fill-dark-text"
              size={22}
            />
          </button>
          <select
            className="rounded-custom border-dark-border bg-dark bg-transparent text-light-text dark:text-dark-text"
            onChange={(e) => setRowsPerPage(parseInt(e.target.value, 10))}
            value={rowsPerPage}
          >
            {[10, 25, 50].map((pageSize) => (
              <option className="bg-dark" key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
      </TabsPrimitive.Root>
      {selectedFullPlayer && (
        <PlayerInfoModal
          onClose={() => setSelectedFullPlayer(null)}
          player={selectedFullPlayer}
          ranks={ranks}
          tab={tab}
        />
      )}
    </div>
  )
}
export default Rankings
