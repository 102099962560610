import React from 'react'
import { motion } from 'framer-motion'

function ProgressBar({ value, max, barColor, isTradeFavorable }) {
  const filledPercentage = (value / max) * 100

  const lineStyle = {
    backgroundImage:
      'linear-gradient(45deg, rgba(255, 255, 255, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.5) 75%, transparent 75%, transparent)',
    backgroundSize: '8px 8px',
  }

  const showBox = value > 0 && max > 0 // Condition to check if trade result is present

  return (
    <div className="relative w-full">
      <div
        className={`h-1 w-full overflow-hidden rounded-full ${barColor.backgroundBg}`}
      >
        <motion.div
          animate={{ width: `${filledPercentage}%` }}
          className={`h-full rounded-full ${barColor.fillBg}`}
          initial={{ width: 0 }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
        />
      </div>
      {showBox && (
        <div
          className={`absolute left-1/2 top-[-8px] z-10 h-6 w-14 -translate-x-1/2 -translate-y-[2px] transform rounded ${isTradeFavorable ? 'bg-green-500 bg-opacity-50' : 'bg-red-500 bg-opacity-50'} flex items-center justify-center`}
          style={lineStyle}
        />
      )}
    </div>
  )
}

export default ProgressBar
