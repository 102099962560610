import React, { useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { FaLock } from 'react-icons/fa6'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const CreatorSelect = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleSelect = (option) => {
    if (option.disabled) {
      toast.error('Please upgrade your subscription for more creator options')
      return
    } else {
      onChange(option.user_id)
      setIsOpen(false)
    }
  }

  return (
    <div className="relative flex text-left text-[15px]">
      <ToastContainer />
      <div
        className="flex min-w-[8rem] cursor-pointer items-center justify-between bg-transparent p-2 text-accent"
        onClick={() => setIsOpen(!isOpen)}
      >
        {value
          ? options.find((option) => option.user_id === value)?.username
          : 'Select an option'}
        <MdKeyboardArrowDown className="fill-accent" size={18} />
      </div>

      {isOpen && (
        <div className="absolute z-10 mt-10 w-full min-w-[200px] origin-top-right rounded-lg border-[1px] border-accent bg-light text-light-text shadow-lg dark:bg-dark dark:text-dark-text">
          {options.map((option) => {
            let lastUpdated = ''

            if (option.last_updated) {
              const jsDate = new Date(option.last_updated)
              const month = jsDate.getMonth() + 1
              const day = jsDate.getDate()

              lastUpdated = `(${month}/${day})`
            }

            const handleBold = value === option.user_id ? 'font-bold' : ''
            const optionStyle = `cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-zinc-500 hover:bg-opacity-40 ${option.disabled ? '' : ''}`

            return (
              <div
                className={`relative flex cursor-pointer select-none items-center justify-between py-2 pl-3 pr-9 hover:bg-zinc-500 hover:bg-opacity-40 ${handleBold} ${optionStyle}`}
                key={option.user_id}
                onClick={() => handleSelect(option)}
              >
                {option.username} {lastUpdated}
                {option.disabled && (
                  <FaLock className="ml-2 inline" size={12} />
                )}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default CreatorSelect
