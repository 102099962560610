import React, { useState, useMemo, useRef, useEffect } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import ImageUploader from 'quill-image-uploader'
import { Storage } from 'aws-amplify'
import cryptoJs from 'crypto-js'
import 'quill/themes/snow'
import 'quill/dist/quill.snow.css'
import '../../styles/editor.css'
import { useSelector } from 'react-redux'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

Quill.register('modules/imageUploader', ImageUploader)

function Editor({ value, onChange, onPreviewChange, setBodySize }) {
  const { userSub } = useSelector((state) => state.user)
  const [description, setDescription] = useState(value || '')
  const [preview, setPreview] = useState('')
  const [bodyPercentage, setBodyPercentage] = useState(0)
  const maxLength = 64000

  const quillRef = useRef()

  useEffect(() => {
    if (value) {
      console.log(value)
      setDescription(value)
    }
  }, [value])

  useEffect(() => {
    const spaceUsed = Math.round((description.length / maxLength) * 100)
    setBodyPercentage(spaceUsed)
    setBodySize(spaceUsed)

    let previewText = quillRef.current.getEditor().root.innerText
    const previewTextLength = previewText.length
    previewText =
      previewTextLength > 245 ? previewText.slice(0, 245) : previewText

    if (preview !== previewText) {
      setPreview(previewText)
      onPreviewChange(previewText)
    }

    onChange(description)
  }, [description])

  async function imageHandler() {
    const input = document.createElement('input')

    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = async () => {
      var file = input.files[0]

      const fileName = userSub + '/' + cryptoJs.SHA1(userSub + file.name)

      s3Upload(fileName, file)
    }
  }

  async function s3Upload(fileName, file) {
    const editor = quillRef.current.getEditor()
    try {
      const result = await Storage.put(fileName, file, {
        contentType: 'image/png',
      })
      console.log('Storage put result: ', result)
      const selection = editor.getSelection()
      editor.insertEmbed(
        selection.index,
        'image',
        'https://fantasy-flock-article-images.s3.us-east-2.amazonaws.com/public/' +
          fileName
      )
    } catch (error) {
      console.error('Upload failed')
      throw new Error('Upload failed')
    }
  }

  const handleOnBlur = () => {
    console.log('BLUR', description)
    onChange(description)
  }

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['code-block', 'link', 'image'],
  ]

  const modules = useMemo(
    () => ({
      toolbar: {
        container: toolbarOptions,
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  )

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'imageBlot',
  ]

  return (
    <>
      <ReactQuill
        formats={formats}
        modules={modules}
        onBluf={handleOnBlur}
        onChange={(val) => setDescription(val)}
        placeholder="Add a body for your post"
        preserveWhitespace
        ref={quillRef}
        theme="snow"
        value={description}
      />
      <div className="border-t-[1px] border-light bg-light p-[8px] dark:border-dark-border">
        <div className="flex items-center">
          <div className="mt-[-5px] h-[50px] w-[55px]">
            <CircularProgressbar
              styles={buildStyles({
                strokeLinecap: 'round',
                textSize: '30px',
                pathTransitionDuration: 1,
                pathColor: 'rgba(251, 113,30)',
                textColor: '#000',
                trailColor: '#282c34',
              })}
              text={`${bodyPercentage}%`}
              value={bodyPercentage}
            />
          </div>
          <p className="ml-[10px] inline-block text-light-text">Space Used</p>
        </div>
      </div>
    </>
  )
}

export default Editor
