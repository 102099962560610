import React, { useState } from 'react'
import axios from 'axios'
import { PiTriangleFill } from 'react-icons/pi'
import { FaCheck } from 'react-icons/fa6'
import { FaTimes } from 'react-icons/fa'
import InputBox from '../_ui/InputBox'

const SyncPopover = ({ children }) => {
  const [currentTab, setCurrentTab] = useState('sleeper')
  const [username, setUsername] = useState('')
  const [espnLeagueId, setEspnLeagueId] = useState('')
  const [mflLeagueId, setMflLeagueId] = useState('')
  const [queryStatus, setQueryStatus] = useState('idle')
  const [sleeperId, setSleeperId] = useState('')

  const selectTab = (tabName) => {
    setCurrentTab(tabName)
    setQueryStatus('idle')
    setUsername('')
  }

  const querySleeperUsername = async () => {
    try {
      const userRes = await axios(`https://api.sleeper.app/v1/user/${username}`)
      const userId = userRes.data.user_id
      setSleeperId(userId)
      const leaguesRes = await axios(
        `https://api.sleeper.app/v1/user/${userId}/leagues/nfl/2023`
      )
      if (leaguesRes.data && leaguesRes.data.length > 0) {
        leaguesRes.data.forEach((league) => {
          postLeagueProfile(league.league_id, 'SLEEPER', userId)
        })
      } else {
        setQueryStatus('error')
      }
    } catch (err) {
      console.log('ERROR FETCHING:', err)
      setQueryStatus('error')
    }
  }

  const queryEspnLeagueId = async () => {
    try {
      // Logic to handle the ESPN league ID
      // Example: await axios.post('/yourApiEndpoint', { leagueId: espnLeagueId })
      postLeagueProfile(espnLeagueId, 'ESPN')
    } catch (err) {
      console.log('ERROR:', err)
      setQueryStatus('error')
    }
  }

  // Function to handle MFL league ID submission
  const queryMflLeagueId = async () => {
    try {
      // Logic to handle the MFL league ID
      // Example: await axios.post('/yourApiEndpoint', { leagueId: mflLeagueId })
      postLeagueProfile(mflLeagueId, 'MFL')
    } catch (err) {
      console.log('ERROR:', err)
      setQueryStatus('error')
    }
  }

  const postLeagueProfile = async (leagueId, platform, userId) => {
    try {
      await axios.post('/leagueProfile', { leagueId, platform, sleeperId })
      setQueryStatus('success')
    } catch (err) {
      console.error('Error posting league profile:', err)
    }
  }

  return (
    <div className="absolute left-1/2 top-full z-10 mt-4 flex w-[15rem] -translate-x-1/2 transform flex-col gap-4 rounded-lg border-[1px] border-light2 bg-light px-4 pb-4 shadow-lg dark:border-dark-bg2 dark:bg-dark">
      <div className="flex w-full justify-center">
        <PiTriangleFill className="-translate-y-4 fill-light dark:fill-dark-bg2" />
      </div>
      <div className="flex h-10 items-center justify-evenly">
        <button className="h-10 w-10" onClick={() => selectTab('sleeper')}>
          <img
            alt="Sleeper"
            className={`overflow-hidden rounded-full ${currentTab === 'sleeper' ? '' : 'opacity-50'}`}
            src="/sleeper.jpeg"
          />
        </button>
        <button className="h-14 w-14" onClick={() => selectTab('espn')}>
          <img
            alt="ESPN"
            className={`overflow-hidden rounded-full ${currentTab === 'espn' ? '' : 'opacity-50'}`}
            src="/ESPN-logo.png"
          />
        </button>
        <button className="h-12 w-12" onClick={() => selectTab('mfl')}>
          <img
            alt="MFL"
            className={`overflow-hidden rounded-full ${currentTab === 'mfl' ? '' : 'opacity-50'}`}
            src="/mfl_logo.png"
          />
        </button>
      </div>
      <hr className="border-light2 dark:border-dark-text2"></hr>

      {currentTab === 'sleeper' && (
        // Content for Sleeper tab
        <div className="flex flex-col justify-center gap-3">
          <div className="text-sm text-light-text2">
            Enter your Sleeper username
          </div>
          <InputBox
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter Username"
            value={username}
          />
          <div className="flex items-center gap-6">
            <button
              className="text-md w-36 rounded-xl bg-accent py-2 font-medium text-dark-text"
              onClick={querySleeperUsername}
            >
              Link Sleeper
            </button>
            {queryStatus === 'success' && (
              <FaCheck className="text-green-500" />
            )}
            {queryStatus === 'error' && <FaTimes className="text-red-500" />}
          </div>
        </div>
      )}

      {currentTab === 'espn' && (
        <div className="flex flex-col justify-center gap-3">
          <div className="text-sm text-light-text2">
            Enter your ESPN League ID
          </div>
          <InputBox
            onChange={(e) => setEspnLeagueId(e.target.value)}
            placeholder="Enter ESPN League ID"
            value={espnLeagueId}
          />
          <div className="flex items-center gap-6">
            <button
              className="text-md w-36 rounded-xl bg-accent py-2 font-medium text-dark-text"
              onClick={queryEspnLeagueId}
            >
              Link ESPN
            </button>
            {queryStatus === 'success' && (
              <FaCheck className="text-green-500" />
            )}
            {queryStatus === 'error' && <FaTimes className="text-red-500" />}
          </div>
        </div>
      )}

      {currentTab === 'mfl' && (
        <div className="flex flex-col justify-center gap-3">
          <div className="text-sm text-light-text2">
            Enter your MFL League ID
          </div>
          <InputBox
            onChange={(e) => setMflLeagueId(e.target.value)}
            placeholder="Enter MFL League ID"
            value={mflLeagueId}
          />
          <div className="flex items-center gap-6">
            <button
              className="text-md w-36 rounded-xl bg-accent py-2 font-medium text-dark-text"
              onClick={queryMflLeagueId}
            >
              Link MFL
            </button>
            {queryStatus === 'success' && (
              <FaCheck className="text-green-500" />
            )}
            {queryStatus === 'error' && <FaTimes className="text-red-500" />}
          </div>
        </div>
      )}

      {children}
    </div>
  )
}

export default SyncPopover
