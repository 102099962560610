import PostList from './PostList'
import { useEffect, useState, React } from 'react'

function Posts() {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 900

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  return (
    <div className="bg-light font-dmsans dark:bg-dark xl:px-[15%]">
      <div className={!isMobile ? '' : ''}>
        <PostList isMobile={isMobile} />
      </div>
    </div>
  )
}
export default Posts
