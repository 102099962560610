import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import * as Tabs from '@radix-ui/react-tabs'
import TradeModal from './TradeModal'
import { ProgressBar } from 'react-loader-spinner'
import { AllPromo } from '../../../static/AllPromo'
import moment from 'moment-timezone'
import TradeTeam from '../../Purchase/Products/TradeQuestions/TradeTeam'
import { TbArrowsLeftRight } from 'react-icons/tb'
import { FaPaperclip } from 'react-icons/fa6'
import { FaPlay } from 'react-icons/fa6'
import { FaCircleXmark } from 'react-icons/fa6'
import Checkbox from '../Checkbox'
import StartSitModal from './StartSitModal'
import LineupPlayers from '../../Purchase/Products/StartSit/LineupPlayers'

import {
  getPresignedUrlForReview,
  saveFilesToS3,
  updateReviewStatus,
  saveMessages,
  saveTradeQuestionOptions,
} from '../../../store/api'
import HorozontalDivider from '../../_ui/HorozontalDivider'
import { FaCrown } from 'react-icons/fa6'

const COMPLETE_REVIEW = 'complete'

const StartSitQuestions = ({ onStatusChange, onUpdate, question }) => {
  if (!question || !question.message_room) {
    return <div>Loading question details or not available...</div>
  }
  const { is_creator, user_id, is_contributor } = useSelector(
    (state) => state.user
  )

  const [tab, setTab] = useState('chat')
  const [message, setMessage] = useState('')
  const [file, setFile] = useState(null)
  const [isChecked, setIsChecked] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [checkboxKey, setCheckboxKey] = useState(0)
  const [wordCount, setWordCount] = useState(0)
  const [uploadLoading, setUploadLoading] = useState(false)
  const [messages, setMessages] = useState(question.message_room.messages)

  const fileInputRef = useRef(null)

  useEffect(() => {
    if (isChecked) {
      if (is_creator || is_contributor) {
        updateToComplete()
      } else {
        updateToUserComplete()
      }
    }
  }, [isChecked])

  const sendMessage = async () => {
    setUploadLoading(true)
    try {
      let newMessage
      if (file) {
        const { presigned_url, file_id } = await getPresignedUrlForReview()
        const response = await saveFilesToS3(presigned_url, file)
        if (!response.success) {
          console.log('Failed to upload file to S3:', response)
          throw new Error(
            `Failed to upload file to S3: HTTP status ${response.status}`
          )
        }

        const fileDetails = [
          {
            fileId: file_id,
            fileName: file.name,
            fileType: file.type,
          },
        ]

        await saveMessages({
          messageRoomId: question.message_room.id,
          message: message,
          files: fileDetails,
        })

        newMessage = {
          user_id,
          created_on: new Date().toISOString(),
          message,
          files: [{ file_id, file_name: file.name, file_type: file.type }],
        }
      } else {
        await saveMessages({
          messageRoomId: question.message_room.id,
          message: message,
        })

        newMessage = {
          user_id,
          created_on: new Date().toISOString(),
          message,
          files: [],
        }
      }

      setMessages((prevMessages) => [...prevMessages, newMessage])
      toast.success('Message sent!')
      setMessage('')
      setFile(null)
    } catch (error) {
      console.error('Error sending message:', error)
      toast.error('Failed to send message')
    } finally {
      setUploadLoading(false)
    }
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      sendMessage()
    }
  }

  const updateToComplete = async () => {
    try {
      const payload = { status: 'complete' }
      await saveTradeQuestionOptions(question.id, payload)
      toast.success('Review status updated!')
      setCheckboxKey((prevKey) => prevKey + 1)
      setIsChecked(true)
    } catch (error) {
      console.error('Error updating question status:', error)
      toast.error('Failed to update question status.')
      setIsChecked(false)
    }
  }

  const updateToUserComplete = async () => {
    try {
      const payload = { has_requester_accepted: true }
      await saveTradeQuestionOptions(question.id, payload)
      toast.success('Review status updated!')
    } catch (error) {
      console.error('Error updating question status:', error)
      toast.error('Failed to update question status.')
      setIsChecked(false)
    }
  }

  const formatDate = (dateString) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const date = moment.tz(dateString, 'UTC').tz(userTimeZone)
    const currentDate = moment().tz(userTimeZone)
    if (currentDate.diff(date, 'hours') < 24) {
      return date.format('LT z')
    } else {
      return date.format('LL')
    }
  }

  const formatDateWithoutTime = (dateString) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const date = moment.tz(dateString, 'UTC').tz(userTimeZone)
    return date.format('LL')
  }

  const addOneMonth = (dateString) => {
    const date = new Date(dateString)
    date.setMonth(date.getMonth() + 1)
    return date
  }

  const deletionDate = question.completed_on
    ? formatDateWithoutTime(addOneMonth(question.completed_on))
    : 'Not set'

  const handleTextChange = (event) => {
    const newText = event.target.value
    const words = newText.match(/\b\w+\b/g) || []
    setWordCount(words.length)
    if (words.length <= 250) {
      setMessage(newText)
    } else {
      toast.error('Message exceeds 250 word limit.')
    }
  }

  const handleTabChange = (newValue) => {
    setTab(newValue)
  }

  const updateStatus = async (status) => {
    try {
      const payload = { status: status }
      await saveTradeQuestionOptions(question.id, payload)
      onUpdate()
      toast.success(`Updated to ${status}!`)
    } catch (error) {
      console.error('Error updating question status:', error)
      toast.error('Failed to update question status.')
    }
  }

  const handleUpdateToComplete = async () => {
    onStatusChange(question.id)
    const success = await updateStatus('complete')
    if (success) {
      setIsChecked(true)
    }
  }

  const handleUpdateToRejected = async () => {
    onStatusChange(question.id)
    const success = await updateStatus('rejected')
  }

  const handleUpdateToInProgress = async () => {
    onStatusChange(question.id)
    const success = await updateStatus('in_progress')
  }

  const handleUpdateToUserComplete = async () => {
    onStatusChange(question.id)
    const success = await updateToUserComplete()
    if (success) {
      setIsChecked(true)
      setShowModal(true)
    }
  }

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]
    if (selectedFile) {
      if (
        ['audio/mpeg', 'image/jpeg', 'image/png'].includes(selectedFile.type)
      ) {
        const maxFileSize = 100 * 1024 * 1024
        if (selectedFile.size <= maxFileSize) {
          setFile(selectedFile)
          toast.info(`File ${selectedFile.name} ready for upload`)
        } else {
          toast.error('File size exceeds the 30 MB limit.')
        }
      } else {
        toast.error('Please select a valid file type (MP3, JPEG, or PNG).')
      }
    }
  }

  const triggerFileInput = () => {
    fileInputRef.current.click()
  }

  return (
    <div className="relative flex max-w-[1000px] flex-grow flex-col overflow-auto rounded-xl border-[1px] border-light2 dark:border-dark-border">
      <Tabs.Root
        className="flex h-full flex-col"
        defaultValue="chat"
        onValueChange={handleTabChange}
      >
        <div className="absolute top-0 z-20 pb-2 md:px-12">
          <Tabs.List className="inline-flex overflow-hidden rounded-b-xl border-x-[1px] border-b-[1px] border-light2 bg-light-bg text-light-text2 dark:border-dark-border dark:bg-dark dark:text-dark-text2">
            <Tabs.Trigger
              className={`border-r-[1px] border-light2 p-4 transition-all duration-200 hover:bg-accent-bg hover:text-accent dark:border-dark-border hover:dark:text-accent ${
                tab === 'chat'
                  ? 'bg-accent-bg font-bold text-accent dark:bg-accent-bg'
                  : ''
              }`}
              value="chat"
            >
              Chat
            </Tabs.Trigger>
            <Tabs.Trigger
              className={`border-r-[1px] border-light2 p-4 transition-all duration-200 hover:bg-accent-bg hover:text-accent dark:border-dark-border hover:dark:text-accent ${
                tab === 'details'
                  ? 'bg-accent-bg font-bold text-accent dark:bg-accent-bg'
                  : ''
              }`}
              value="details"
            >
              Details
            </Tabs.Trigger>
          </Tabs.List>
        </div>
        <div className="z-[15] flex gap-4 rounded-lg backdrop-blur-md md:absolute md:right-6 md:top-6">
          {(is_creator || is_contributor) && (
            <div className="flex flex-col gap-4 md:flex-row">
              {question.status === 'pending' && (
                <div
                  className="flex cursor-pointer items-center rounded-lg border-[1px] border-light2 px-3 py-2 dark:border-dark-border"
                  onClick={handleUpdateToInProgress}
                >
                  <FaPlay className="pr-2 text-green-600" size={22} />
                  <span className="text-sm font-bold text-light-text dark:text-dark-text">
                    Start
                  </span>
                </div>
              )}
              <div
                className="flex cursor-pointer items-center rounded-lg border-[1px] border-light2 px-3 py-2 dark:border-dark-border"
                onClick={handleUpdateToRejected}
              >
                <FaCircleXmark className="mr-2 text-red-600" size={22} />
                <span className="text-sm font-bold text-light-text dark:text-dark-text">
                  Reject
                </span>
              </div>
            </div>
          )}
          {is_creator || is_contributor ? (
            <Checkbox
              text={
                question.status === COMPLETE_REVIEW
                  ? 'Marked as Complete'
                  : 'Mark as Complete'
              }
              defaultChecked={question.status === COMPLETE_REVIEW}
              key={question.id}
              onChange={handleUpdateToComplete}
            />
          ) : question.has_requester_accepted ? (
            <span className="text-sm font-bold text-accent">Complete</span>
          ) : question.status === COMPLETE_REVIEW ? (
            <Checkbox
              defaultChecked={false}
              key={question.id}
              onChange={handleUpdateToUserComplete}
              text="Mark as Complete"
            />
          ) : (
            <span className="text-sm font-bold text-accent">
              Waiting for Creator
            </span>
          )}
        </div>
        <Tabs.Content className="h-full" value="chat">
          <div className="flex h-full flex-grow flex-col">
            <div className="flex h-[75vh] w-full flex-grow flex-col gap-2 overflow-auto px-1 pb-[175px] pt-24 md:h-64 md:px-12 md:pb-[105px]">
              {messages.map((message, index) => {
                const formattedTimestamp = formatDate(message.created_on)
                const isRightAligned =
                  message.user_id === user_id ||
                  (is_creator &&
                    Object.values(AllPromo).includes(message.user_id))
                return (
                  <div
                    className={`text-left ${isRightAligned ? 'flex justify-end' : 'flex justify-start'}`}
                    key={index}
                  >
                    <div
                      className={`max-w-full rounded-lg px-3 py-2 md:min-w-[20rem] md:max-w-[50%] ${isRightAligned ? 'bg-zinc-300 dark:bg-zinc-600' : 'bg-accent-bg'}`}
                    >
                      <div className="flex justify-between gap-2">
                        <span
                          className={`font-bold ${isRightAligned ? 'text-light-text dark:text-dark-text' : 'text-accent'}`}
                        >
                          {is_creator || is_contributor
                            ? isRightAligned
                              ? 'You'
                              : question.user_display_name
                            : isRightAligned
                              ? 'You'
                              : question.promoter_display_name}
                        </span>
                        <div className=""></div>
                        <span className="text-light-text2 dark:text-dark-text2">
                          {formattedTimestamp}
                        </span>
                      </div>
                      <p className="text-light-text dark:text-dark-text">
                        {message.message}
                      </p>
                      {message.files && message.files.length > 0 && (
                        <div>
                          {message.files &&
                            message.files.map((file, index) => (
                              <div
                                className="my-2 flex max-w-full items-center gap-2"
                                key={index}
                              >
                                {file.file_type === 'audio/mpeg' ? (
                                  <audio className="w-full" controls>
                                    <source
                                      src={`https://fantasy-flock-message-files.s3.us-east-2.amazonaws.com/${file.file_id}`}
                                      type="audio/mpeg"
                                    />
                                    Your browser does not support the audio
                                    element.
                                  </audio>
                                ) : file.file_type === 'image/jpeg' ||
                                  file.file_type === 'image/png' ? (
                                  <img
                                    alt={file.file_name}
                                    src={`https://fantasy-flock-message-files.s3.us-east-2.amazonaws.com/${file.file_id}`}
                                    style={{ maxWidth: '100%', height: 'auto' }}
                                  />
                                ) : (
                                  <a
                                    className="w-full overflow-hidden overflow-ellipsis whitespace-nowrap text-accent underline"
                                    href={`https://fantasy-flock-message-files.s3.us-east-2.amazonaws.com/${file.file_id}`}
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    {file.file_name}
                                  </a>
                                )}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
              {question.status === COMPLETE_REVIEW && (
                <p className="text-light-text2 dark:text-dark-text2">
                  You have until
                  <span className="px-1 text-accent">{deletionDate}</span>
                  to download any files.
                </p>
              )}
            </div>
            <div className="absolute bottom-0 w-full border-t-[1px] border-light2 bg-light2 px-2 py-4 dark:border-dark-border dark:bg-dark md:px-12">
              <div className="flex flex-col gap-2 md:flex-row md:items-center md:gap-0">
                <input
                  disabled={
                    question.status === COMPLETE_REVIEW &&
                    !(is_creator || is_contributor)
                  }
                  className={`mr-2 flex-grow rounded-lg border-[1px] border-light2 bg-light px-3 py-2 dark:border-dark-border dark:bg-dark ${question.status === COMPLETE_REVIEW && !(is_creator || is_contributor) ? 'cursor-not-allowed opacity-50' : ''}`}
                  onChange={handleTextChange}
                  onKeyPress={handleKeyPress}
                  placeholder="Type your message..."
                  type="text"
                  value={message}
                />
                {uploadLoading ? (
                  <ProgressBar
                    barColor="#E97933"
                    borderColor="#BB8360"
                    height={50}
                    type="Oval"
                    width={70}
                  />
                ) : (
                  <button
                    disabled={
                      question.status === COMPLETE_REVIEW &&
                      !(is_creator || is_contributor)
                    }
                    className={`rounded-lg bg-accent px-4 py-2 text-dark-text transition-colors duration-200 hover:bg-orange-600 ${(question.status === COMPLETE_REVIEW) === true && !(is_creator || is_contributor) ? 'cursor-not-allowed opacity-50' : ''}`}
                    onClick={sendMessage}
                  >
                    Send
                  </button>
                )}
                <div className="flex items-center">
                  <button
                    disabled={
                      (question.status === COMPLETE_REVIEW) === true &&
                      !(is_creator || is_contributor)
                    }
                    className={`p-3 ${question.status === COMPLETE_REVIEW && !(is_creator || is_contributor) ? 'cursor-not-allowed opacity-50' : ''}`}
                    onClick={triggerFileInput}
                  >
                    <FaPaperclip />
                  </button>
                  <input
                    disabled={
                      (question.status === COMPLETE_REVIEW) === true &&
                      !(is_creator || is_contributor)
                    }
                    accept="audio/mpeg,image/jpeg,image/png"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    type="file"
                  />
                  <div className="flex w-full justify-between md:hidden">
                    <span className="text-sm text-light-text2 dark:text-dark-text2">
                      {wordCount}/250 words
                    </span>
                    {file && (
                      <div className="w-64 overflow-hidden text-ellipsis whitespace-nowrap text-sm text-light-text2 dark:text-dark-text2">
                        {file.name}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="hidden w-full justify-between pt-2 md:flex">
                <span className="text-sm text-light-text2 dark:text-dark-text2">
                  {wordCount}/250 words
                </span>
                {file && (
                  <div className="w-64 overflow-hidden text-ellipsis whitespace-nowrap text-sm text-light-text2 dark:text-dark-text2">
                    {file.name}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Tabs.Content>
        <Tabs.Content className="h-full" value="details">
          <div className="flex h-full flex-grow flex-col gap-4 px-10 pb-10 pt-16">
            <div className="flex w-full justify-between">
              <div className="pt-4 text-2xl font-bold text-accent">
                {question.user_display_name}
              </div>
            </div>

            <div className="grid auto-rows-auto grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
              <div className="col-span-1 grid gap-2">
                <div className="text-xl font-bold">League Settings</div>
                <div className="grid grid-cols-2 gap-2">
                  <span className="font-semibold text-accent">
                    League Type:
                  </span>
                  <span>{question.league_settings?.leagueType || 'N/A'}</span>

                  <span className="font-semibold text-accent">PPR:</span>
                  <span>{question.league_settings?.ppr || 'N/A'}</span>

                  <span className="font-semibold text-accent">QBs:</span>
                  <span>{question.league_settings?.qbs || 'N/A'}</span>

                  <span className="font-semibold text-accent">Teams:</span>
                  <span>{question.league_settings?.teamCounts || 'N/A'}</span>

                  <span className="font-semibold text-accent">TEP:</span>
                  <span>
                    {question.league_settings?.tep !== undefined
                      ? String(question.league_settings.tep)
                      : 'N/A'}
                  </span>
                  <span className="font-semibold text-accent">Lineup:</span>
                  <span>
                    {question.league_settings?.lineupCount !== undefined
                      ? String(question.league_settings.lineupCount)
                      : 'N/A'}
                  </span>
                </div>
              </div>
              <div className="col-span-1">
                <div className="text-xl font-bold">Comments</div>
                <div>{question.comments}</div>
              </div>
              <div className="col-span-1">
                <p className="flex items-end gap-2 pb-4">
                  <h2 className="text-xl font-semibold">Status : </h2>
                  <div className="text-accent">
                    {question.status.charAt(0).toUpperCase() +
                      question.status.slice(1)}
                  </div>
                </p>
                {(is_creator || is_contributor) && (
                  <button
                    className="border-light-border rounded-lg border-[1px] px-8 py-2 transition-all duration-200 hover:bg-accent dark:border-dark-border dark:hover:bg-accent"
                    onClick={() => setShowModal(!showModal)}
                  >
                    Open Trade
                  </button>
                )}
              </div>
            </div>
            <HorozontalDivider />
            <div className="flex w-full flex-col items-center gap-4 pb-4">
              {question.start_sit_configurations.map((configuration, index) => {
                const positionOrder = { QB: 1, RB: 2, WR: 3, TE: 4 }

                const sortAndGroupByPosition = (items) =>
                  items
                    .sort((a, b) => b.elo - a.elo)
                    .sort(
                      (a, b) =>
                        positionOrder[a.position || a.short_name] -
                        positionOrder[b.position || b.short_name]
                    )

                const groupedAndSortedBenchPlayers = sortAndGroupByPosition([
                  ...configuration.players,
                ])
                const groupedAndSortedRosterPositions = sortAndGroupByPosition([
                  ...configuration.roster_positions,
                ])

                return (
                  <div className="w-full" key={index}>
                    <div className="text-xl font-bold">
                      {index === 0
                        ? 'Original'
                        : 'Here are some other options for you'}
                    </div>
                    <h2 className="text-lg font-semibold text-accent">
                      Lineup
                    </h2>
                    <div className="flex flex-wrap gap-2">
                      {groupedAndSortedRosterPositions.map((position) => (
                        <LineupPlayers
                          position={
                            position.position
                              ? position.position
                              : position.short_name
                          }
                          isInactive={true}
                          key={position.id}
                          player={position.player}
                        />
                      ))}
                    </div>
                    <h2 className="pt-6 text-lg font-semibold text-accent">
                      Bench
                    </h2>
                    <div className="flex flex-wrap gap-2">
                      {groupedAndSortedBenchPlayers.map((player) => (
                        <LineupPlayers
                          isBench={true}
                          isContender={player.is_contender}
                          isInactive={true}
                          key={player.player_id}
                          player={player}
                          position={player.position}
                        />
                      ))}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </Tabs.Content>
      </Tabs.Root>
      {showModal && (
        <StartSitModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onUpdate={onUpdate}
          trade={question}
        />
      )}
    </div>
  )
}

export default StartSitQuestions
