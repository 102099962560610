export const calcLeagueSize = ({
  baseValue,
  denominator,
  logValue,
  numerator,
  playerRank,
}) => {
  const scalingAdjustment = (12 - Number(numerator)) / Number(denominator)
  const base = Number(baseValue) + scalingAdjustment
  return base - Math.log(playerRank) / Math.log(logValue)
}

export const calcScaleDepth = (multiplier, elo, rank) => {
  const maxMultiplier = Number(multiplier)
  const minMultiplier = Number(multiplier) * -1

  const multiplierPercentage =
    maxMultiplier + ((minMultiplier - maxMultiplier) * (rank - 1)) / (200 - 1)

  return elo + Math.ceil(elo * (multiplierPercentage / 100))
}

export const calcScaleDepthInverse = (multiplier, elo, rank) => {
  const maxMultiplier = Number(multiplier)
  const minMultiplier = Number(multiplier) * -1

  const multiplierPercentage =
    minMultiplier + ((maxMultiplier - minMultiplier) * (rank - 1)) / (200 - 1)

  return elo + Math.ceil(elo * (multiplierPercentage / 100))
}

//Below is not being used:

// export const largeLeagueCalc = (playerRank, totalPlayers, leagueSize, varianceValue) => {
//   const spreadAdjustment = (Number(leagueSize) - 12) / 4
//   const midPoint = totalPlayers / 2
//   const variance = (totalPlayers / varianceValue) + spreadAdjustment
//   const scaling = Math.exp(-Math.pow(playerRank - midPoint, 2) / (2 * Math.pow(variance, 2)))

//   // Cap the scaling factor to avoid excessive boosts
//   const maxScalingFactor = 1.2
//   return Math.min(1 + scaling, maxScalingFactor)

//   // Weird attempt at fixing this (11/16) :
//   // const spreadAdjustment = (Number(14) - 12) / 10
//   // const scaling = spreadAdjustment - 5000 * 1/10000

//   // // Cap the scaling factor to avoid excessive boosts
//   // const maxScalingFactor = 1.2
//   // return Math.min(1 + scaling, maxScalingFactor)
// }

// const isMidTierPlayer = (playerElo, eloRange) => {
//   return playerElo >= eloRange.low && playerElo <= eloRange.high
// }

// const calcPositionScarcity = (roster, playerPosition, talentDepth) => {
//   const { flex } = roster
//   const position = roster[playerPosition]

//   const positionSlots = position + (flex / 2)
//   return positionSlots * talentDepth[playerPosition]
// }

// export const calcForRosterSize = (playerElo, playerPosition, leagueSlots, talentDepth, eloRange, benchAndFlexPercentages) => {
//   const { flex, bench } = leagueSlots

//   const flexAdjustmentFactor = 1.0 + (flex * Number(benchAndFlexPercentages.flex))
//   const benchAdjustmentFactor = 1.0 + (bench * Number(benchAndFlexPercentages.bench))

//   const adjustPosition = [ 'rb', 'wr' ]
//   if (adjustPosition.indexOf(playerPosition) > -1) {
//     playerElo = playerElo * flexAdjustmentFactor
//   }

//   const midTierPlayer = isMidTierPlayer(playerElo, eloRange)
//   if (midTierPlayer) {
//     playerElo *= benchAdjustmentFactor
//   }

//   const positionScarcity = calcPositionScarcity(leagueSlots, playerPosition, talentDepth)
//   return playerElo * (1.0 + (0.1 * (1 / positionScarcity)))
// }

// Potential Starters

// WR + RB + FLEX

// SHALLOW = 5

// NORMAL = 6

// DEEP 7-8

// VERY DEEP 8+

// Teams in league * total rostered players = starting line up  + bench

// 10 team league * roster size

// waive wire option
