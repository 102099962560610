export const PromoGroupMap = {
  //Domain
  '222696a0-038d-4689-a3e6-a97046cd2eec':
    '222696a0-038d-4689-a3e6-a97046cd2eec',
  '83f307b7-2101-4dd0-8716-0a4fc7d50dbb':
    '222696a0-038d-4689-a3e6-a97046cd2eec',
  //Flock
  '38f6c2ae-6010-416a-8947-973a4ddc58e6':
    '38f6c2ae-6010-416a-8947-973a4ddc58e6',
  //Land
  'e7c76f08-7131-416f-b08d-49f7b8dec85e':
    '5ac59844-e390-46dd-9ecd-b50ecc3c0a08',
  '5ac59844-e390-46dd-9ecd-b50ecc3c0a08':
    '5ac59844-e390-46dd-9ecd-b50ecc3c0a08',
  //FSE
  'e401f630-5049-4c83-9b0a-453c1c547ebc':
    'e401f630-5049-4c83-9b0a-453c1c547ebc',
  '4e433a01-5935-4286-a73d-6df5015bcae2':
    'e401f630-5049-4c83-9b0a-453c1c547ebc',
  //Misc
  'cbb77fb7-ff70-4e23-96a8-e75aa99530ba':
    '222696a0-038d-4689-a3e6-a97046cd2eec',
  '7f8d1d7f-ad44-48a5-95c2-d5ccc17d6e0f':
    '38f6c2ae-6010-416a-8947-973a4ddc58e6',
}
