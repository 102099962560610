import React, { useState, useEffect } from 'react'
import LineupPlayers from './LineupPlayers'
import { FaChevronDown } from 'react-icons/fa6'
import AddPositionModal from './AddPositionModal'

const MyLineup = ({
  setLineup,
  setSelectedPosition,
  lineup,
  onRemoveClick,
  removeFromLineup,
}) => {
  const [showModal, setShowModal] = useState(false)

  const handleSelectPlayer = (player) => {
    setLineup((prevPlayers) => [...prevPlayers, player])
  }

  const positionOrder = { QB: 1, RB: 2, WR: 3, TE: 4, FX: 5 }

  const sortAndGroupByPosition = (items) => {
    return items
      .sort((a, b) => {
        const eloA = a.player ? a.player.elo : -Infinity
        const eloB = b.player ? b.player.elo : -Infinity
        return eloB - eloA
      })
      .sort((a, b) => positionOrder[a.short_name] - positionOrder[b.short_name])
  }

  const sortedAndGroupedLineup = sortAndGroupByPosition([...lineup])

  return (
    <div>
      <span className="flex justify-between">
        <h2 className="pb-2 text-xl font-semibold">My Lineup</h2>
        <div className="">
          <button className="mr-4" onClick={() => setShowModal(!showModal)}>
            <FaChevronDown />
          </button>
          <div className="relative w-full">
            {showModal && (
              <AddPositionModal selectPlayer={handleSelectPlayer} />
            )}
          </div>
        </div>
      </span>

      <div className="flex flex-wrap justify-center gap-6 rounded-lg border-[1px] border-light2 bg-zinc-200 p-6 dark:border-dark-border dark:bg-dark">
        {sortedAndGroupedLineup.map((player, index) => (
          <LineupPlayers
            onClick={() =>
              setSelectedPosition(
                player.position ? player.position : player.short_name
              )
            }
            isUndecided={player.isUndecided}
            key={index}
            onRemoveClick={() => onRemoveClick(player)}
            player={player.player}
            position={player.position ? player.position : player.short_name}
            removeFromLineup={() => removeFromLineup(player)}
            setSelectedPosition={() => setSelectedPosition}
          />
        ))}
      </div>
    </div>
  )
}

export default MyLineup
