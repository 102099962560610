import React from 'react'
import * as Switch from '@radix-ui/react-switch'

const BaseSwitch = ({
  label,
  isChecked,
  setIsChecked,
  justifyStart = false,
}) => (
  <form>
    <div
      className={`flex flex-col ${justifyStart ? 'items-start' : 'items-center'} justify-center`}
    >
      <label
        className="text-md mt-[4px] pb-2 font-medium leading-none text-light-text dark:text-dark-text"
        htmlFor={label.toLowerCase().replace(/\s+/g, '-')}
      >
        {label}
      </label>
      <Switch.Root
        checked={isChecked}
        className="relative h-[25px] w-[42px] cursor-pointer rounded-full bg-[#47474E] outline-none focus:shadow-custom-orange data-[state=checked]:bg-accent"
        id={`${label.toLowerCase().replace(/\s+/g, '-')}-switch`}
        onCheckedChange={setIsChecked}
        style={{ WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)' }}
      >
        <Switch.Thumb className="block h-[21px] w-[21px] translate-x-0.5 rounded-full bg-[#8A8A8A] transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-[19px] data-[state=checked]:bg-light" />
      </Switch.Root>
    </div>
  </form>
)

export default BaseSwitch
