import React from 'react'
import { IoCheckmarkOutline } from 'react-icons/io5'
import { HiXMark } from 'react-icons/hi2'

const Perk = ({ isOffered, text }) => {
  const enforceWidth = text.length > 45 ? 'max-w-[80%]' : ''
  const bubbleColor = isOffered
    ? 'bg-accent-bg'
    : 'relative left-[-1px] bg-red-800'
  return (
    <div className="flex items-center justify-between">
      <span className={`text-sm font-medium ${enforceWidth}`}>{text}</span>
      <div
        className={`flex h-6 w-6 items-center justify-center rounded-full ${bubbleColor}`}
      >
        {isOffered ? (
          <IoCheckmarkOutline className="fill-accent text-accent" size={14} />
        ) : (
          <HiXMark className="fill-light-text2 dark:fill-dark-text" size={14} />
        )}
      </div>
    </div>
  )
}

export default Perk
