import React from 'react'

const LimitModal = () => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
      <div className="flex flex-col items-center rounded bg-light p-6 text-center text-light-text shadow-lg dark:bg-dark dark:text-dark-text">
        <h2 className="mb-4 text-2xl">
          You are limited to 3 free trades a day
        </h2>
        <img
          alt="Goose"
          className="pb-4"
          height={150}
          src="https://static-site-images.s3.us-east-2.amazonaws.com/FF-REGULAR_V6.1.png"
          width={150}
        />
        <p className="mb-4 text-zinc-100">Subscribe for unlimited use</p>
        <button
          onClick={() => {
            window.location.href = '/subscribe'
          }}
          className="rounded bg-custom-orange px-4 py-2 font-bold text-white transition-all duration-300 hover:bg-orange-700"
        >
          Subscribe
        </button>
      </div>
    </div>
  )
}

export default LimitModal
