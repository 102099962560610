import React, { useState, useEffect } from 'react'
import { FaRegTrashCan } from 'react-icons/fa6'
import { IoPersonSharp } from 'react-icons/io5'
import { motion, AnimatePresence } from 'framer-motion'

import PlayerAvatar from '../../images/player_avatar.svg'
import SearchBar from '../_ui/SearchBar'

function TeamTradeCard({
  teamNumber,
  ranks,
  onPlayersChange,
  resetTradeResult,
  resetTrigger,
  isWinning,
  otherTeamPlayerCount,
  onSelectPlayer,
  selectedExpertName,
}) {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedPlayers, setSelectedPlayers] = useState([])

  useEffect(() => {
    resetTradeResult()
  }, [selectedPlayers])

  useEffect(() => {
    onPlayersChange(selectedPlayers)
  }, [selectedPlayers, onPlayersChange])

  useEffect(() => {
    setSelectedPlayers([])
    setSearchTerm('')
  }, [resetTrigger])

  const addPlayerToTeam = (player) => {
    setSelectedPlayers((prevPlayers) => {
      const playerExists = prevPlayers.some(
        (p) => p.player_id === player.player_id && !player.is_draft_pick
      )
      return playerExists ? prevPlayers : [...prevPlayers, player]
    })
    setSearchTerm('')
  }

  const removePlayerFromTeam = (removeIndex) => {
    setSelectedPlayers((prevPlayers) =>
      prevPlayers.filter((player, i) => i !== removeIndex)
    )
  }

  const fadeinEffect = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  }

  const positionColors = {
    RB: 'text-accent-purple bg-accent-bg-purple',
    QB: 'text-accent-green bg-accent-bg-green',
    WR: 'text-accent-red bg-accent-bg-red',
    TE: 'text-accent-cyan bg-accent-bg-cyan',
  }

  const cardBackgroundClass = isWinning
    ? 'bg-accent-bg border-[1px] border-accent'
    : 'bg-neutral-100 dark:bg-opacity-10'

  const renderBlankPlayers = () => {
    const difference = otherTeamPlayerCount - selectedPlayers.length

    // Only add placeholders if the difference is positive, meaning the other team has more players,
    // and both teams have at least one player
    if (
      difference > 0 &&
      (otherTeamPlayerCount > 1 || selectedPlayers.length > 1)
    ) {
      const placeholders = []

      for (let i = 0; i < difference; i++) {
        placeholders.push(
          <motion.div
            animate="visible"
            className="my-2 flex w-full items-center justify-between gap-4 rounded-[14px] bg-light px-2 py-2 dark:bg-zinc-100 dark:bg-opacity-10"
            exit="exit"
            initial="hidden"
            key={`placeholder-${teamNumber}-${i}`}
            transition={{ duration: 0.5 }}
            variants={fadeinEffect}
          >
            <div className="flex items-center gap-2">
              <div className="flex items-center justify-center rounded-[10px] bg-zinc-400 p-1">
                <IoPersonSharp size={53} />
              </div>
              <div className={'text-md flex min-w-0 flex-col px-2'}>
                <div className="flex min-w-0">
                  <div className="max-w-[175px] overflow-hidden text-ellipsis whitespace-nowrap px-2 text-lg text-light-text2 dark:text-dark-text2">
                    Waiver Wire Add
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )
      }
      return placeholders
    }
    return null
  }

  const comparePlayersByPositionRank = (playerA, playerB) => {
    const getPositionRank = (player) => {
      const rank = player[`${player.position}_Rank`]
      return isNaN(rank) ? Number.MAX_SAFE_INTEGER : rank
    }

    const rankA = getPositionRank(playerA)
    const rankB = getPositionRank(playerB)

    return rankA - rankB
  }

  const getRankKey = (expertName, position) => {
    if (expertName === 'Community') {
      return `community_${position}_Rank`
    } else if (expertName === 'Expert') {
      return `expert_${position}_Rank`
    } else {
      const normalizedExpertName = expertName.replace(/\s+/g, '')
      return `${normalizedExpertName}_${position}_Rank`
    }
  }

  return (
    <div className="flex h-full flex-col items-center">
      <div
        className={`flex h-full min-h-[350px] flex-col gap-2 rounded-xl px-6 py-8 text-light-text dark:text-dark-text md:w-[486px] ${cardBackgroundClass}`}
      >
        <span className="w-full">
          <div className="pb-4 text-lg font-medium">
            {teamNumber} receive...
          </div>
          <SearchBar
            number={teamNumber}
            onAddPlayer={addPlayerToTeam}
            ranks={ranks}
            resetTrigger={resetTrigger}
            searchTerm={searchTerm}
            selectedPlayers={selectedPlayers}
            setSearchTerm={setSearchTerm}
          />
        </span>

        <div className="overflow-auto">
          <AnimatePresence>
            {selectedPlayers
              .sort(comparePlayersByPositionRank)
              .map((player, rowIndex) => {
                const image = player.picture
                  ? `https://flock-player-images-2.s3.us-east-2.amazonaws.com/${player.picture}`
                  : PlayerAvatar
                const playerPosition = player.position
                const positionStyle = positionColors[playerPosition] || ''
                const playerPositionalRank =
                  player[getRankKey(selectedExpertName, player.position)] ||
                  'N/A'
                const displayedRank =
                  selectedExpertName === 'Community'
                    ? player.commRank
                    : selectedExpertName === 'Expert'
                      ? // player.expert_rank
                        player.expert_rank
                      : player.ranks[selectedExpertName]

                return (
                  <motion.div
                    animate="visible"
                    className="my-2 flex w-full items-center justify-between gap-4 rounded-[14px] bg-light px-2 py-2 dark:bg-zinc-100 dark:bg-opacity-10"
                    exit="exit"
                    initial="hidden"
                    key={`${player.player_id}-${rowIndex}`}
                    transition={{ duration: 0.5 }}
                    variants={fadeinEffect}
                  >
                    <div className="flex items-center gap-2">
                      <img
                        alt={player.player_name}
                        className="w-[64px] rounded-xl"
                        src={image}
                      />
                      <div className={'text-md flex min-w-0 flex-col px-2'}>
                        <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                          {player.player_name}
                        </div>
                        <div className="flex gap-2">
                          <span
                            className={`flex w-10 rounded-full px-1 text-sm ${positionStyle} justify-center gap-1`}
                          >
                            {player.position} <div> {playerPositionalRank}</div>
                          </span>
                          <span className="flex rounded-full bg-neutral-100 px-2 text-sm text-light-text dark:bg-opacity-10 dark:text-dark-text">
                            {' '}
                            Overall {displayedRank}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center gap-4">
                      <button onClick={() => onSelectPlayer(player)}>
                        View Info
                      </button>
                      <button
                        className="border-light-border ml-auto rounded-md border-[1px] p-1 dark:border-dark-border"
                        onClick={() => removePlayerFromTeam(rowIndex)}
                      >
                        <FaRegTrashCan
                          className="fill-light-text2 transition-colors duration-300 hover:fill-custom-orange dark:fill-dark-text2"
                          size={18}
                        />
                      </button>
                    </div>
                  </motion.div>
                )
              })}
            {renderBlankPlayers()}
          </AnimatePresence>
        </div>
      </div>
    </div>
  )
}

export default TeamTradeCard
