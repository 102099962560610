import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getTrialEligibility as getTrialEligibilityAction } from '../../store/api'

const SubscribeButton = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const isMobile = width <= 750
  const navigate = useNavigate()
  const location = useLocation()
  const [eligible, setEligibility] = useState(undefined)

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const trialEligibilityRes = await getTrialEligibilityAction()
        setEligibility(trialEligibilityRes)
      } catch (err) {
        console.error('ERROR getTrialEligibility:', err)
      }
    }

    fetchProductData()
  }, [])

  return location.pathname !== '/subscribe' ? (
    <div
      className={`fixed ${isMobile ? 'bottom-3 right-4' : 'bottom-10 right-[12rem]'} z-10`}
    >
      <button
        className="rounded-lg bg-accent px-4 py-2 text-dark-text shadow-custom-glow transition-all duration-300 hover:bg-orange-600"
        onClick={() => navigate('/subscribe')}
      >
        {eligible ? 'Try for free' : 'Subscribe'}
      </button>
    </div>
  ) : null
}

export default SubscribeButton
