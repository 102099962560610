import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { useParams, useNavigate } from 'react-router-dom'

import { exchangePromoCode, saveUser as saveUserAction } from '../../store/api'
import { Triangle } from 'react-loader-spinner'

import InputBox from '../_ui/InputBox'
import PassInputBox from '../_ui/PassInputBox'
import BaseCheckbox from '../_ui/BaseCheckbox'
import VerificationCodeComponent from '../Misc/verificationCodeComponent'
import PdfModal from '../_ui/PdfModal'

const Signup = () => {
  const { promo } = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [step, setStep] = useState(1)
  const [formName, setName] = useState('')
  const [formEmail, setEmail] = useState('')
  const [formPass, setPassword] = useState('')
  const [formPhone, setPhone] = useState('')
  const [formCode, setCode] = useState(promo || '')
  const [agreeToTerms, setAgreeToTerms] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [needsValidation, setNeedsValidation] = useState(false)
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false)
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false)

  const openTermsModal = () => setIsTermsModalOpen(true)
  const closeTermsModal = () => setIsTermsModalOpen(false)
  const openPrivacyModal = () => setIsPrivacyModalOpen(true)
  const closePrivacyModal = () => setIsPrivacyModalOpen(false)

  async function codeIsValid() {
    let alphanumeric = /[A-Za-z0-9]+/
    if (formCode === '') {
      return ''
    }

    //Confirm that code meets correct shape
    const isValid = alphanumeric.test(formCode)
    if (!isValid) {
      return undefined
    }

    //Fetch the owner of the entered promo code
    //If no owner, return undefined
    //If owner, return owner's id and attach to this user's promoter_id field
    try {
      const { promoter_id } = await exchangePromoCode(formCode)
      return promoter_id
    } catch (err) {
      console.log('ERROR Signup.codeisValid : ', err)
      return undefined
    }
  }

  const handleSignup = async () => {
    if (step === 1) {
      if (!agreeToTerms) {
        setErrMsg('You must agree to the terms and conditions')
        return
      }
      setStep(2)
      setErrMsg('')
    } else {
      setIsLoading(true)
      if (!formEmail.trim()) {
        setIsLoading(false)
        setErrMsg('Email cannot be empty')
        return
      } else if (!formPass) {
        setIsLoading(false)
        setErrMsg('Password cannot be empty')
        return
      } else if (!formName.trim()) {
        setIsLoading(false)
        setErrMsg('Username cannot be empty')
        return
      }

      const promoter = await codeIsValid()

      if (promoter === undefined) {
        setIsLoading(false)
        setErrMsg('Promo code used is not valid')
        return
      }

      try {
        const signupRes = await Auth.signUp({
          username: formEmail,
          password: formPass,
          attributes: {
            email: formEmail,
            nickname: formName,
            phone_number: formPhone,
            'custom:promoter_id': promoter,
          },
        })

        const saveUserPayload = {
          userId: signupRes.userSub,
          username: formName,
        }

        if (promoter) {
          saveUserPayload.promoCode = formCode.toUpperCase().trim()
          saveUserPayload.promoterId = promoter
        }

        await saveUserAction(saveUserPayload)

        setNeedsValidation(true)
      } catch (error) {
        console.error('error signing up:', error)
        setErrMsg(error.message || 'An error occurred during signup')
      } finally {
        setIsLoading(false)
      }
    }
  }

  if (needsValidation) {
    return (
      <VerificationCodeComponent
        email={formEmail}
        open={needsValidation}
        pass={formPass}
        route="/subscribe"
        setOpen={setNeedsValidation}
      ></VerificationCodeComponent>
    )
  }

  const navigateToLogin = () => {
    navigate('/login')
  }

  return (
    <div className="flex h-[91vh] flex-col justify-between bg-light p-10 font-dmsans dark:bg-dark">
      <img
        height={50}
        src="https://static-site-images.s3.us-east-2.amazonaws.com/FF-REGULAR_V6.1.png"
        width={50}
      />

      <div className="flex justify-center">
        <div className="flex h-full min-w-[20%] flex-col justify-center gap-[28px] pt-[5%]">
          <h2 className="text-3xl font-bold leading-[33px] text-light-text dark:text-dark-text">
            Join the Flock
          </h2>
          <div className="flex flex-col gap-2">
            {step === 1 ? (
              <div className="flex flex-col gap-6">
                <div className="text-sm leading-[23px] text-light-text2 text-opacity-60 dark:text-dark-text2">
                  Welcome to the Flock!
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-custom-font font-medium dark:text-dark-text3">
                    Email
                  </div>
                  <InputBox
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email..."
                    value={formEmail}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-custom-font font-medium dark:text-dark-text3">
                    Password
                  </div>
                  <PassInputBox
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password..."
                  />
                </div>
                <BaseCheckbox
                  checked={agreeToTerms}
                  onChange={(checked) => setAgreeToTerms(checked)}
                  text="I agree to the Terms of Service"
                />
                {errMsg && (
                  <p className="mt-2 text-center text-red-500">{errMsg}</p>
                )}
                <button
                  className="inline-flex h-[51px] w-full cursor-pointer items-center justify-center rounded-xl bg-accent text-sm font-bold text-white"
                  onClick={handleSignup}
                >
                  Next
                </button>
                <div
                  className="cursor-pointer pl-2 text-custom-font font-medium leading-snug text-orange-400"
                  onClick={navigateToLogin}
                >
                  Back To Login
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-2">
                  <div className="text-custom-font font-medium dark:text-dark-text3">
                    Username
                  </div>
                  <InputBox
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter your username..."
                    value={formName}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-custom-font font-medium dark:text-dark-text3">
                    Phone · Optional
                  </div>
                  <input
                    autoComplete="off"
                    style={{ display: 'none' }}
                    type="text"
                  />
                  <InputBox
                    name={`phone-${Math.random()}`}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Enter your phone number..."
                    value={formPhone}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-custom-font font-medium dark:text-dark-text3">
                    Promo Code · Optional
                  </div>
                  <InputBox
                    onChange={(e) => setCode(e.target.value)}
                    placeholder="Enter your promo code..."
                    value={formCode}
                  />
                  <div className="text-custom-font font-medium text-light-text2 dark:text-dark-text3">
                    Promo code gives you 30% off all subscription purchases
                  </div>
                </div>
                <div
                  className="inline-flex h-[51px] w-full cursor-pointer items-center justify-center rounded-xl bg-accent text-sm font-bold text-white md:w-[350px]"
                  onClick={handleSignup}
                >
                  {isLoading ? (
                    <Triangle
                      ariaLabel="loading"
                      color="#FFF"
                      height={30}
                      width={30}
                    />
                  ) : (
                    'Join the Flock'
                  )}
                </div>
                {errMsg && (
                  <p className="mt-2 text-center text-red-500">{errMsg}</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="py-auto flex flex-grow items-end gap-8 bg-light pb-[2%] dark:bg-dark md:px-10">
        <span className="text-custom-font font-normal text-light-text2 dark:text-dark-text2">
          @ Flock 2023
        </span>
        <span
          className="cursor-pointer text-custom-font font-medium text-accent"
          onClick={openPrivacyModal}
        >
          Privacy Policy
        </span>
        <span
          className="cursor-pointer text-custom-font font-medium text-accent"
          onClick={openTermsModal}
        >
          Terms of Service
        </span>
        <PdfModal
          file="/Website_Terms_Of_Use.pdf"
          isOpen={isTermsModalOpen}
          onClose={closeTermsModal}
        />
        <PdfModal
          file="/Online_Privacy_Policy.pdf"
          isOpen={isPrivacyModalOpen}
          onClose={closePrivacyModal}
        />
      </div>
    </div>
  )
}

export default Signup
