import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import TeamSelect from '../../../MyTeams/TeamSelect'
import {
  getUserLeagueProfiles,
  getPresignedUrlForReviewImage,
  saveFilesToS3,
  calculateLeague,
  handleCheckout,
} from '../../../../store/api'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import BaseSwitch from '../../../_ui/BaseSwitch'
import ImageLinker from '../../../MyTeams/SubmitReview/ImageLinker'
import ReviewSettingsModal from '../../../MyTeams/SubmitReview/ReviewSettingsModal'
import BaseCheckbox from '../../../_ui/BaseCheckbox'
import BaseSearchBar from '../../../_ui/BaseSearchBar'
import HorzontalDivider from '../../../_ui/HorozontalDivider'
import { FaDiscord, FaChevronDown } from 'react-icons/fa'
import { ProgressBar } from 'react-loader-spinner'
import { PromoToId } from '../../../../static/PromoToId'
import { FaXmark } from 'react-icons/fa6'
import { MdOutlineSettings } from 'react-icons/md'
import { AvailablePicks } from '../../../../static/AvailablePicks'
import { CreatorOption } from '../CreatorOption'

const promoOrder = {
  FLOCK: '38f6c2ae-6010-416a-8947-973a4ddc58e6',
  DOMAIN: '222696a0-038d-4689-a3e6-a97046cd2eec',
  LAND: '5ac59844-e390-46dd-9ecd-b50ecc3c0a08',
  FSE: 'e401f630-5049-4c83-9b0a-453c1c547ebc',
  TEST_DEV: 'b23841d4-f381-4569-8857-39b80406e7d5',
}

const TeamReviews = (product) => {
  const location = useLocation()
  const navigate = useNavigate()
  const promoName = useSelector((state) => state.user.applied_promo_code)
  const promoterId = promoName ? PromoToId[promoName.toUpperCase()] : null

  const [teamNames, setTeamNames] = useState([])
  const [teamNameToId, setTeamNameToId] = useState({})
  const [editDiscordName, setEditDiscordName] = useState('')
  const [teamName, setTeamName] = useState('')
  const [placeholderDisplayName, setPlaceholderDisplayName] =
    useState('Your Discord Name')
  const [comments, setComments] = useState('')
  const [selectedLeagueId, setSelectedLeagueId] = useState('')
  const [selectedTeamId, setSelectedTeamId] = useState('')
  const [placeholderLeagueName, setPlaceholderLeagueName] =
    useState('Flock League')
  const [placeholderTeamName, setPlaceholderTeamName] =
    useState('Enter a team name')
  const [profiles, setProfiles] = useState([])
  const [selectedTeamName, setSelectedTeamName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isNotSleeper, setIsNotSleeper] = useState(false)
  const [images, setImages] = useState([])
  const [teamOptions, setTeamOptions] = useState([])
  const [confirmPicks, setConfirmPicks] = useState(false)
  const [teamPicks, setTeamPicks] = useState([])
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [tep, setTep] = useState(false)

  const [step, setStep] = useState(1)

  const [isOpen, setIsOpen] = useState(false)
  const [currentLeagueType, setCurrentLeagueType] = useState('')
  const [selectedPprTypes, setSelectedPprTypes] = useState('')
  const [selectedQbs, setSelectedQbs] = useState('')
  const [selectedTeamCounts, setSelectedTeamCounts] = useState('')
  const [lineupCount, setLineupCount] = useState('')
  const [selectedProducts, setSelectedProducts] = useState([])

  const initialTeamPicksRef = useRef([])

  const [groupedProducts, setGroupedProducts] = useState({})

  useEffect(() => {
    fetchLeagueAndProfiles()
    const searchParams = new URLSearchParams(location.search)
    const leagueId = searchParams.get('leagueId')
    if (leagueId) {
      setSelectedLeagueId(leagueId)
    }
    setGroupedProducts(groupProductsByPromoterId(product.product, promoOrder))
  }, [location.search, product.product])

  useEffect(() => {
    if (selectedLeagueId && profiles.length > 0) {
      const league = profiles.find(
        (profile) => profile.league_id === selectedLeagueId
      )
      if (league) {
        setPlaceholderLeagueName(league.league_name)
      }
      getCalculatedLeague(selectedLeagueId)
    }
  }, [selectedLeagueId, profiles])

  useEffect(() => {
    if (selectedTeamId) {
      getCalculatedLeague(selectedLeagueId, selectedTeamId)
    }
  }, [selectedTeamId])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])

  const fetchLeagueAndProfiles = async () => {
    try {
      const profilesData = await getUserLeagueProfiles()
      setProfiles(profilesData)
      const names = profilesData.map((profile) => profile.league_name)
      const nameToId = profilesData.reduce((acc, profile) => {
        acc[profile.league_name] = profile.league_id
        return acc
      }, {})

      setTeamNames(names)
      setTeamNameToId(nameToId)
    } catch (error) {
      console.error('Failed to fetch creators and profiles:', error)
    }
  }

  const getCalculatedLeague = async (leagueId, selectedTeamId) => {
    try {
      const league = await calculateLeague({ creatorId: 'EXPERT', leagueId })
      const userMap = league.settings.users.reduce((map, user) => {
        const teamName = user.team_name || user.display_name
        map[user.user_id] = {
          teamName,
          teamImage: user.team_image || '',
          displayName: user.display_name,
          userId: user.user_id,
        }
        return map
      }, {})

      const mergedData = Object.keys(league.values)
        .map((teamId) => {
          const user = userMap[teamId]
          return {
            teamId,
            teamName: user.teamName,
            teamImage: user.teamImage,
          }
        })
        .sort((a, b) => b.overall - a.overall)

      const teamOptions = createTeamOptions(mergedData)
      setTeamOptions(teamOptions)

      const team = league.rosters[selectedTeamId]

      if (team) {
        setTeamPicks(team.PICKS)
        initialTeamPicksRef.current = team.PICKS
        return team.PICKS
      } else {
        console.error(
          'No team found with the given selectedTeamId:',
          selectedTeamId
        )
        return null
      }
    } catch (error) {
      console.error('Failed to fetch league:', error)
      return null
    }
  }

  const fetchPresignedUrl = async () => {
    try {
      const response = await getPresignedUrlForReviewImage()
      return response
    } catch (error) {
      console.error('Failed to fetch presigned url:', error)
    }
  }

  //---------------------------------------------------------------- Utility Functions ------------------------------------------------------------------------------------

  const groupProductsByPromoterId = (products, promoOrder) => {
    // Group products by promoter ID
    const groupedProducts = products.reduce((acc, item) => {
      const promoterId = item.configuration.creator.promoter_id
      if (!acc[promoterId]) {
        acc[promoterId] = []
      }
      acc[promoterId].push(item)
      return acc
    }, {})
    // Sort each group's products by discount price or price
    for (const promoterId in groupedProducts) {
      groupedProducts[promoterId].sort((a, b) => {
        const priceA =
          a.discount_price !== undefined ? a.discount_price : a.price
        const priceB =
          b.discount_price !== undefined ? b.discount_price : b.price
        return priceA - priceB
      })
    }

    // Reorder the grouped products based on the promoOrder map
    const sortedGroupedProducts = Object.keys(promoOrder).reduce((acc, key) => {
      const promoterId = promoOrder[key]
      if (groupedProducts[promoterId]) {
        acc[promoterId] = groupedProducts[promoterId]
      }
      return acc
    }, {})
    return sortedGroupedProducts
  }

  const handleSortChange = (selectedOption) => {
    const value = teamNameToId[selectedOption.label]
    setSelectedLeagueId(value)
  }

  const handleTeamChange = (selectedOption) => {
    setPlaceholderTeamName(selectedOption.label)
    setSelectedTeamId(selectedOption.id)
    setSelectedTeamName(selectedOption.label)
  }

  const handleCommentsChange = (event) => {
    const inputComments = event.target.value
    if (inputComments.length <= 500) {
      setComments(inputComments)
    }
  }

  const handleCheckboxChange = (checked) => {
    setConfirmPicks(checked)
  }

  const handleAddPlayer = (player) => {
    setTeamPicks([...teamPicks, player])
    setSearchTerm('')
  }

  const handleRemovePick = (index) => {
    const newPicks = teamPicks.filter((_, i) => i !== index)
    setTeamPicks(newPicks)
  }

  const handleReviewCheckout = async () => {
    setIsLoading(true)

    if (!validateInputs()) {
      setIsLoading(false)
      return
    }

    const rookiePicksChanged =
      JSON.stringify(teamPicks) !== JSON.stringify(initialTeamPicksRef.current)
    const rookiePicks = rookiePicksChanged
      ? teamPicks.map((pick) => pick.description).join(', ')
      : ''

    let productDetails
    if (isNotSleeper) {
      const files = await uploadImages()
      productDetails = {
        comments: String(comments),
        discordUsername: String(editDiscordName),
        files: files,
        teamName: String(teamName),
        rookiePicks: rookiePicks,
        leagueSettings: {
          leagueType: currentLeagueType,
          ppr: selectedPprTypes,
          qbs: selectedQbs,
          teamCounts: selectedTeamCounts,
          tep: tep,
          lineupCount: lineupCount,
        },
        type: 'review',
      }
    } else {
      productDetails = {
        comments: String(comments),
        discordUsername: String(editDiscordName),
        leagueId: String(selectedLeagueId),
        teamId: String(selectedTeamId),
        teamName: String(selectedTeamName),
        rookiePicks: rookiePicks,
        type: 'review',
      }
    }

    const payload = {
      products: selectedProducts.map((product) => {
        const group = groupedProducts[product.configuration.creator.promoter_id]
        const productsWithinBudget = group.filter(
          (p) => p.discount_price <= product.price || p.price <= product.price
        )
        const bestProduct = productsWithinBudget.reduce(
          (best, current) =>
            current.discount_price > best.discount_price ||
            current.price > best.price
              ? current
              : best,
          productsWithinBudget[0] || product
        )

        return {
          id: bestProduct.id,
          price: product.price,
          discountId: bestProduct?.discount?.id || null,
          name: bestProduct.name,
          promoterId: bestProduct.configuration.creator.promoter_id,
        }
      }),
      productDetails: productDetails,
    }

    try {
      const res = await handleCheckout(payload)
      window.location.href = res.url
    } catch (error) {
      console.error('Error submitting review:', error)
      if (error.response && error.response.data) {
        console.error('Server response:', error.response.data)
      }
      toast.error('Error submitting review.')
    }

    setIsLoading(false)
  }

  const validateInputs = () => {
    if (comments.length > 500) {
      toast.error('Comments cannot exceed 500 characters.')
      return false
    }
    if (!confirmPicks) {
      toast.error('Please confirm your picks.')
      return false
    }
    if (isNotSleeper) {
      if (images.length < 1 || images.length > 4) {
        toast.error('You must upload between 1 and 4 images.')
        return false
      }
      if (!teamName) {
        toast.error('Please enter a team name.')
        return false
      }
      if (
        !currentLeagueType ||
        !selectedPprTypes ||
        !selectedQbs ||
        !selectedTeamCounts
      ) {
        toast.error('Please select all league settings.')
        return false
      }
    } else {
      if (!selectedTeamName) {
        toast.error('Please enter a team name.')
        return false
      }
    }
    return true
  }

  const handleContinueClick = () => {
    if (validateInputs()) {
      setStep(2)
    }
  }

  const uploadImages = async () => {
    const uploadedFiles = []
    if (images.length > 0) {
      for (const image of images) {
        const { file_id, presigned_url } = await fetchPresignedUrl()
        await saveFilesToS3(presigned_url, image.file)
        uploadedFiles.push({
          fileId: file_id,
          fileName: image.name,
          fileType: image.type,
        })
      }
    }
    return uploadedFiles
  }

  const createTeamOptions = (transformedData) => {
    if (!transformedData) {
      return []
    }
    return transformedData.map((team) => ({
      label: team.teamName || team.displayName,
      id: team.teamId,
      teamImage: team.teamImage,
    }))
  }

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const handleLeagueTypeChange = (type) => {
    setCurrentLeagueType(type)
  }

  const handleTeamSelectionChange = (teams) => {
    setSelectedTeamCounts(teams)
  }

  const toggleTep = () => {
    setTep((prev) => !prev)
  }

  async function linkDiscord() {
    window.location =
      'https://discord.com/api/oauth2/authorize?client_id=1087443675136663605&redirect_uri=https%3A%2F%2Fflockfantasy.com%2Faccount&response_type=code&scope=identify%20guilds.join%20guilds%20guilds.members.read'
  }

  const filterPicks = (availablePicks, selectedTeamCounts) => {
    if (!selectedTeamCounts) {
      return availablePicks
    }
    return availablePicks.filter((pick) => {
      const [round, pickNumber] = pick.pick_number.split('.').map(Number)
      return pickNumber <= selectedTeamCounts
    })
  }

  const filteredOptions = filterPicks(AvailablePicks, selectedTeamCounts)

  return (
    <div className="flex flex-col items-center bg-light px-4 py-16 font-dmsans text-light-text dark:bg-dark dark:text-dark-text">
      {step === 1 && (
        <body className="flex flex-col gap-10 md:px-[30vw]">
          <div className="grid gap-2">
            <h3 className="text-xl font-bold md:text-3xl">
              Tell us about your team!
            </h3>
            <span className="max-w-64 text-light-text2 dark:text-dark-text2">
              {product.product[0].description}
            </span>
            <HorzontalDivider />
          </div>
          <p className="flex flex-col gap-2">
            <div className="flex w-full flex-col gap-4 md:flex-row md:gap-10">
              {!isNotSleeper ? (
                <div className="flex flex-col gap-3">
                  <h3 className="text-xl font-bold">My League</h3>
                  <TeamSelect
                    defaultValue={selectedLeagueId}
                    footer={false}
                    onSelect={handleSortChange}
                    options={teamNames}
                    placeholder={placeholderLeagueName}
                  />
                  {teamNames.length === 0 && (
                    <span className="text-light-text2 dark:text-dark-text2">
                      Please add a league to your profile
                      <a
                        className="cursor-pointer font-bold text-accent"
                        onClick={() => navigate('/my-teams')}
                      >
                        {' '}
                        Here
                      </a>
                    </span>
                  )}
                </div>
              ) : (
                <div className="flex flex-col gap-3">
                  <h3 className="text-xl font-bold">League Screenshots</h3>
                  <ImageLinker images={images} setImages={setImages} />
                </div>
              )}
              {isNotSleeper ? (
                <div className="flex flex-col gap-3">
                  <h3 className="text-xl font-bold">Team Name</h3>
                  <input
                    className="text-md h-[50px] w-full rounded-lg border-[1px] border-light2 p-2 px-4 dark:border-dark-border dark:bg-dark"
                    onChange={(e) => setTeamName(e.target.value)}
                    placeholder={'Enter your team name'}
                    type="text"
                    value={teamName}
                  />
                </div>
              ) : (
                <div className="flex flex-col gap-3 md:w-72">
                  <h3 className="text-xl font-bold">My Team</h3>
                  <TeamSelect
                    defaultValue={selectedTeamName}
                    footer={false}
                    onSelect={handleTeamChange}
                    options={teamOptions}
                    placeholder={placeholderTeamName}
                  />
                </div>
              )}
            </div>
            <BaseSwitch
              isChecked={isNotSleeper}
              justifyStart={true}
              label="My team is not on Sleeper?"
              setIsChecked={setIsNotSleeper}
            />
          </p>
          {isNotSleeper && (
            <p className="flex w-full flex-col items-start gap-2">
              <h3 className="text-xl font-bold">League Settings</h3>
              <div className="flex w-full flex-col gap-4 md:flex-row md:gap-10">
                <div className="border-light-border flex h-[50px] w-full items-center gap-2 rounded-lg border-[1px] bg-zinc-300 transition-all duration-200 dark:border-dark-border dark:bg-dark hover:dark:bg-accent-bg">
                  <button
                    className="flex w-full cursor-pointer items-center justify-center gap-2 rounded-custom p-2 text-white"
                    onClick={openModal}
                  >
                    <MdOutlineSettings
                      className="text-light-text dark:text-dark-text"
                      size={24}
                    />
                    <span className="font-bold text-light-text dark:text-dark-text">
                      Choose Settings
                    </span>
                  </button>
                </div>
                <div className="flex w-full flex-col gap-3"></div>
              </div>
            </p>
          )}
          <p className="flex flex-col gap-2">
            <div className="flex justify-between md:w-[410px]">
              <BaseCheckbox
                text={
                  isNotSleeper ? 'Add rookie picks' : 'Correct rookie picks?'
                }
                checked={confirmPicks}
                onChange={handleCheckboxChange}
              />
              <FaChevronDown
                className={`accordion-icon cursor-pointer ${isAccordionOpen ? 'open' : ''}`}
                onClick={() => setIsAccordionOpen(!isAccordionOpen)}
              />
            </div>
            <div className="max-h-80">
              {isAccordionOpen && (
                <div className="max-h-80 min-h-[20rem] overflow-auto rounded-b-2xl border-x-[1px] border-b-[1px] border-light2 p-2 dark:border-dark-border md:w-[420px]">
                  <BaseSearchBar
                    onAddPlayer={handleAddPlayer}
                    options={filteredOptions}
                    placeholder="Search for a pick"
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                  />
                  <ul className="pt-2">
                    {teamPicks.map((pick, index) => (
                      <li
                        className="flex w-full items-center justify-between px-2 pb-1"
                        key={index}
                      >
                        <span className="text-lg font-semibold text-light-text dark:text-dark-text">
                          {pick.description}
                        </span>
                        <FaXmark
                          className="cursor-pointer transition-all duration-200 hover:text-accent hover:dark:text-accent"
                          onClick={() => handleRemovePick(index)}
                          size={22}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </p>
          <p className="flex flex-col gap-2 md:max-w-[80%]">
            <div className="flex flex-col gap-3">
              <h3 className="text-xl font-bold">Discord Username</h3>
              <div className="flex gap-2">
                <input
                  className="text-md w-full rounded-lg border-[1px] border-light2 p-2 dark:border-dark-border dark:bg-dark"
                  onChange={(e) => setEditDiscordName(e.target.value)}
                  placeholder={placeholderDisplayName}
                  type="text"
                  value={editDiscordName}
                />
                <div
                  className="flex cursor-pointer items-center justify-center rounded-full bg-[#EAEAEA] p-2 px-3"
                  onClick={linkDiscord}
                >
                  <FaDiscord className="fill-[#222222]" size={18} />
                </div>
              </div>
              <span className="text-light-text2 dark:text-dark-text2">
                Help with Discord Username
                <a
                  className="pl-1 font-bold text-accent"
                  href="https://support.discord.com/hc/en-us/articles/12620128861463-New-Usernames-Display-Names"
                  target="blank"
                >
                  Here
                </a>
              </span>
            </div>
          </p>
          <p className="md:max-w-[50%]">
            <div className="flex flex-col gap-3">
              <h3 className="text-xl font-bold">Promo Code</h3>
              <div className="flex h-12 w-36 items-center justify-center rounded-lg border-[1px] border-light2 bg-light-bg-select text-light-text2 dark:border-dark-border dark:bg-dark-select-bg dark:text-dark-text2">
                {promoName ? promoName : 'No Promo Code Applied'}
              </div>
            </div>
          </p>
          <p>
            <h3 className="pb-4 text-xl font-bold">Comments or Questions</h3>
            <textarea
              className="text-md h-[20vh] w-full rounded-lg border-[1px] border-light2 bg-white p-4 dark:border-dark-border dark:bg-dark"
              onChange={handleCommentsChange}
              placeholder="Enter your comments or questions here"
              value={comments}
            ></textarea>
            <div className="pt-1">{comments.length} out of 500 characters</div>
            <div className="flex items-center justify-end pt-10">
              {isLoading ? (
                <ProgressBar
                  barColor="#E97933"
                  borderColor="#BB8360"
                  height={75}
                  type="Oval"
                  width={100}
                />
              ) : (
                <button
                  className="flex items-center gap-2 rounded-lg bg-accent px-24 py-3 text-dark-text"
                  onClick={handleContinueClick}
                >
                  Continue
                </button>
              )}
            </div>
          </p>
        </body>
      )}
      {step === 2 && (
        <CreatorOption
          comments={comments}
          goBack={() => setStep(1)}
          groupedProducts={groupedProducts}
          handleCommentsChange={handleCommentsChange}
          handleReviewCheckout={handleReviewCheckout}
          isLoading={isLoading}
          product={product.product}
          setSelectedProducts={setSelectedProducts}
        />
      )}
      <ReviewSettingsModal
        currentLeagueType={currentLeagueType}
        isOpen={isOpen}
        lineupCount={lineupCount}
        onClose={closeModal}
        onTeamSelectionChange={handleTeamSelectionChange}
        selectLeagueType={handleLeagueTypeChange}
        selectedPprTypes={selectedPprTypes}
        selectedQbs={selectedQbs}
        selectedTeamCounts={selectedTeamCounts}
        setLineupCount={setLineupCount}
        setSelectedPprTypes={setSelectedPprTypes}
        setSelectedQbs={setSelectedQbs}
        tep={tep}
        toggleTep={toggleTep}
      />
    </div>
  )
}

export default TeamReviews
