import React, { useEffect, useMemo, useState } from 'react'
import { useBlocker } from 'react-router-dom'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { MdOutlineFileDownload } from 'react-icons/md'
import { FaFileImport } from 'react-icons/fa6'
import { IoCloudUploadSharp } from 'react-icons/io5'
import { toast, ToastContainer } from 'react-toastify'
import { Triangle } from 'react-loader-spinner'

import {
  getAllPlayers as getAllPlayersAction,
  submitRanking,
  getCreatorRankings as getCreatorRankingsAction,
} from '../../../store/api'

import SearchBar from '../../_ui/SearchBar'
import RankingsTable from './RankingsTable'

const BEST_BALL = 'BEST_BALL'
const REDRAFT = 'REDRAFT'
const DYNASTY = 'DYNASTY'
const PROSPECTS = 'PROSPECTS'
const WEEKLY = 'WEEKLY'

const ONEQB = '1QB'
const SUPERFLEX = 'SUPERFLEX'
const OVERALL = 'OVERALL'
const QB = 'QB'
const RB = 'RB'
const WR = 'WR'
const TE = 'TE'

const UP = 'UP'
const DOWN = 'DOWN'

const superflexFormats = [DYNASTY, PROSPECTS]
const positionalFilters = [QB, RB, WR, TE]

const tabConfig = [
  { label: 'Redraft', value: REDRAFT },
  { label: 'Dynasty', value: DYNASTY },
  // { label: 'Best Ball', value: BEST_BALL },
  { label: 'Weekly', value: WEEKLY },
  // { label: 'Prospects', value: PROSPECTS }
]

const CreateRankings = () => {
  const [activeFilter, setActiveFilter] = useState(ONEQB)
  const [activeTab, setActiveTab] = useState(DYNASTY)

  const [allPlayers, setAllPlayers] = useState({})

  const [rankings, setRankings] = useState([])
  const [rankingsTiers, setRankingsTiers] = useState([])

  const [superflexRankings, setSuperflexRankings] = useState([])
  const [superflexTiers, setSuperflexTiers] = useState([])

  const [playerMovingId, setPlayerIdMoving] = useState(null)
  const [moveActive, setMoveActive] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [searchTerm, setSearchTerm] = useState('')

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)

  const [showChangeFormatModal, setShowChangeFormatModal] = useState(false)
  const [nextTab, setNextTab] = useState(null)

  const superflexFormatActive = superflexFormats.indexOf(activeTab) > -1

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      hasUnsavedChanges !== false &&
      currentLocation.pathname !== nextLocation.pathname
  )

  useEffect(() => {
    getCreatorRankings()
  }, [activeTab])

  useEffect(() => {
    const csvString = createCSVString()
    const localStorageKey = `creator-ranking-${activeTab.toLowerCase()}`
    localStorage.setItem(localStorageKey, JSON.stringify(csvString))
  }, [rankings, superflexRankings, rankingsTiers, superflexTiers])

  const playerArray = useMemo(() => {
    if (activeTab === PROSPECTS) {
      return Object.keys(allPlayers)
        .filter((playerId) => allPlayers[playerId].is_rookie)
        .map((id) => allPlayers[id])
    }

    return Object.keys(allPlayers)
      .filter((playerId) => {
        // Don't return fake players (devy/picks) if redraft / BB
        if (!superflexFormatActive) {
          return playerId < 99900
        } else {
          // Otherwise, return draft picks too (no devy)
          return playerId < 99900 || allPlayers[playerId].is_draft_pick
        }
      })
      .map((id) => allPlayers[id])
  }, [activeTab, allPlayers])

  const addPlayer = ({ player_id }) => {
    const rankingsPlayer = {
      player_id,
      rank: rankings.length + 1,
      tier: rankings[rankings.length - 1]?.tier || 1,
    }

    setRankings((prev) => [...prev, rankingsPlayer])

    // Only do this is the format includes a superflex option
    if (superflexFormatActive) {
      const superflexPlayer = {
        player_id,
        rank: superflexRankings.length,
        tier: superflexRankings[superflexRankings.length - 1]?.tier || 1,
      }
      setSuperflexRankings((prev) => [...prev, superflexPlayer])
    }
    setHasUnsavedChanges(true)
    setSearchTerm('')
  }

  const createCSVString = () => {
    let csvContent = 'Player_Id,Name,Rank\n'

    const lookup = {}

    // For now, let's only export order by "rankings" ASC
    // If creators need order by "sf_rankings" ASC, use this later
    if (superflexFormatActive) {
      csvContent = 'Player_Id,Name,Rank,Superflex_Rank\n'

      superflexRankings.forEach((ranking) => {
        lookup[ranking.player_id] = ranking.rank
      })
    }

    rankings.forEach((player) => {
      let row = [
        player.player_id,
        allPlayers[player.player_id].player_name,
        player.rank,
      ].join(',')

      if (superflexFormatActive) {
        row += `,${lookup[player.player_id]}`
      }

      csvContent += `${row}\n`
    })

    return csvContent
  }

  // Need to finish
  // const getRankingsFromLocalStorage = () => {
  //   const data = JSON.parse(localStorage.getItem(`creator-ranking-${activeTab.toLowerCase()}`))

  //   const lines = data.trim().split('\n')

  //   // Initialize arrays for the two sets of objects
  //   const rankingsForData = []
  //   const superflexRankingsFromData = []

  //   // Process each line
  //   lines.forEach((line) => {
  //     const [ player_id, player_name, rank, superflex_rank ] = line.split(',')
  //     rankingsForData.push({ player_id, player_name, rank })
  //     if (superflex_rank) {
  //       superflexRankingsFromData.push({ player_id, player_name, rank: superflex_rank })
  //     }

  //     setRankings(rankingsForData)

  //     if (superflexRankingsFromData.length > 0) {
  //       setSuperflexRankings(superflexRankingsFromData)
  //     }
  //   })
  // }

  const getCreatorRankings = async () => {
    setIsLoading(true)
    try {
      const [allPlayersRes, creatorRankingsRes] = await Promise.all([
        getAllPlayersAction(),
        getCreatorRankingsAction(activeTab),
      ])

      getTierBreaks(creatorRankingsRes)
      setAllPlayers(allPlayersRes)
      setRankings(creatorRankingsRes)

      if (superflexFormats.includes(activeTab)) {
        const sfRanks = creatorRankingsRes.map(
          ({ player_id, sf_rank, sf_tier }) => {
            return {
              player_id,
              rank: sf_rank,
              tier: sf_tier,
            }
          }
        )
        sfRanks.sort((a, b) => a.rank - b.rank)
        getTierBreaks(sfRanks, true)
        setSuperflexRankings(sfRanks)
      }

      setIsLoading(false)
    } catch (error) {
      console.error('Failed to fetch data:', error)
    }
  }

  const getFilteredRankings = () => {
    switch (activeFilter) {
      case REDRAFT:
        return rankings
      case WEEKLY:
        return rankings
      case BEST_BALL:
        return rankings
      case ONEQB:
        return rankings
      case SUPERFLEX:
        return superflexRankings
      case QB:
      case RB:
      case WR:
      case TE:
        return rankings.filter(({ player_id }) => {
          const { position } = allPlayers[player_id]
          return position === activeFilter
        })
      default:
        return rankings
    }
  }

  //----------------------------------------------------------------Handlers-------------------------------------------------------------------------------------

  const transferRankings = () => {
    if (activeTab === REDRAFT) {
      transferRedraftRankings(REDRAFT)
    } else if (activeTab === BEST_BALL) {
      transferBestBallRankings()
    } else if (activeTab === WEEKLY) {
      transferRedraftRankings(WEEKLY)
    }
  }

  const transferBestBallRankings = async () => {
    const [allPlayersRes, creatorRankingsRes] = await Promise.all([
      getAllPlayersAction(),
      getCreatorRankingsAction(DYNASTY),
    ])

    const mockData = creatorRankingsRes.map((ranking) => ({
      ...ranking,
      format: BEST_BALL,
      sf_rank: null,
      sf_tier: null,
    }))

    getTierBreaks(creatorRankingsRes)
    setAllPlayers(allPlayersRes)
    setRankings(mockData)
  }

  const transferRedraftRankings = async (type) => {
    let lookup
    if (type === REDRAFT) {
      lookup = BEST_BALL
    } else if (type === WEEKLY) {
      lookup = REDRAFT
    }

    const [allPlayersRes, creatorRankingsRes] = await Promise.all([
      getAllPlayersAction(),
      getCreatorRankingsAction(lookup),
    ])

    const redraftData = []

    creatorRankingsRes.forEach((ranking) => {
      if (ranking.player_id >= 99900) {
        return
      }
      const rankData = {
        ...ranking,
        format: type,
        sf_rank: null,
        sf_tier: null,
      }

      redraftData.push(rankData)
    })

    getTierBreaks(redraftData)
    setAllPlayers(allPlayersRes)
    setRankings(redraftData)
  }

  const handleTabChange = (value) => {
    if (hasUnsavedChanges) {
      setShowChangeFormatModal(true)
      setNextTab(value)
    } else {
      if (superflexFormats.includes(value)) {
        setActiveFilter(ONEQB)
      } else {
        setActiveFilter(OVERALL)
      }
      setActiveTab(value)
    }
  }

  const handleImportCSV = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = function (e) {
        const content = e.target.result
        parseCSV(content)
      }
      reader.readAsText(file)
    }
  }

  const parseCSV = (content) => {
    const lines = content.split('\n').filter((line) => line)
    const playerRankings = lines.slice(1).map((line) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [playerId, name, rank, superflexRank] = line.split(',')
      return {
        format: activeTab,
        player_id: playerId,
        rank: parseInt(rank, 10), // Convert rank to integer
        sf_rank: parseInt(superflexRank, 10), // Convert superflex rank to integer
        tier: 1,
        sf_tier: 1,
      }
    })

    const rankingsData = playerRankings.map((ranking) => ({
      player_id: ranking.player_id,
      rank: ranking.rank,
      tier: ranking.tier,
    }))

    if (superflexFormatActive) {
      const superflexData = playerRankings
        .map((ranking) => ({
          player_id: ranking.player_id,
          rank: ranking.sf_rank,
          tier: ranking.sf_tier,
        }))
        .sort((a, b) => a.rank - b.rank)

      setSuperflexRankings(superflexData)
    }

    setRankings(rankingsData)
  }

  const processOnlyRankings = () => {
    const playerDict = {}

    let rankTier = 1

    rankings.forEach((playerRecord, index) => {
      const newRank = {
        ...playerRecord,
        rank: index + 1,
        tier: rankTier,
      }

      if (rankingsTiers.includes(playerRecord.player_id)) {
        rankTier = rankTier + 1
      }

      playerDict[playerRecord.player_id] = newRank
    })

    return Object.keys(playerDict).map((playerId) => playerDict[playerId])
  }

  const processRankingsAndSuperflex = () => {
    const playerDict = {}

    let rankTier = 1
    let sfTier = 1

    rankings.forEach((playerRecord, index) => {
      const newRank = {
        ...playerRecord,
        rank: index + 1,
        tier: rankTier,
      }

      if (rankingsTiers.includes(playerRecord.player_id)) {
        rankTier = rankTier + 1
      }

      playerDict[playerRecord.player_id] = newRank
    })

    superflexRankings.forEach((playerRecord, index) => {
      playerDict[playerRecord.player_id].sf_rank = index + 1
      playerDict[playerRecord.player_id].sf_tier = sfTier

      if (superflexTiers.includes(playerRecord.player_id)) {
        sfTier = sfTier + 1
      }
    })

    return Object.keys(playerDict).map((playerId) => playerDict[playerId])
  }

  const handleSubmitRankings = async () => {
    setIsSubmitting(true)
    let proccessedRankings

    if (superflexFormats.includes(activeTab)) {
      proccessedRankings = processRankingsAndSuperflex()
    } else {
      proccessedRankings = processOnlyRankings()
    }

    const payload = {
      format: activeTab,
      rankings: proccessedRankings,
    }

    console.log('PAY LOAD: ', payload)
    try {
      await submitRanking(payload)
      toast.success('Rankings submitted successfully!')
    } catch (error) {
      console.error('Failed to submit rankings:', error)
      toast.error('Failed to submit rankings. Please try again.')
    } finally {
      setIsSubmitting(false)
      setHasUnsavedChanges(false)
    }
  }

  const getTierBreaks = (rankings, isSuperFlex = false) => {
    const breakPoints = []

    rankings.forEach(({ player_id, tier }, index) => {
      if (tier !== rankings[index + 1]?.tier) {
        breakPoints.push(player_id)
      }
    })

    if (isSuperFlex) {
      setSuperflexTiers(breakPoints)
    } else {
      setRankingsTiers(breakPoints)
    }
  }

  const recalculatePlayerTiers = (newRankings, tiers, setter) => {
    const rankingIdsInTiers = newRankings.filter(({ player_id }) =>
      tiers.includes(player_id)
    )
    const onlyPlayerIds = rankingIdsInTiers.map(({ player_id }) => player_id)
    setter(onlyPlayerIds)
  }

  const handleCheckboxChange = (isChecked, playerId, indexPosition) => {
    if (isChecked) {
      addTierBreakpoint(playerId, indexPosition)
    } else {
      removeTierBreakpoint(playerId)
    }
    setHasUnsavedChanges(true)
  }

  const addTierBreakpoint = (addPlayerId, indexPosition) => {
    const isSuperflex = activeFilter === SUPERFLEX

    const currentTiers = isSuperflex ? [...superflexTiers] : [...rankingsTiers]
    let ranks = isSuperflex ? superflexRankings : rankings

    ranks = ranks.slice(0, indexPosition)
    const playersAhead = ranks.filter(({ player_id }) =>
      currentTiers.includes(player_id)
    )

    currentTiers.splice(playersAhead.length, 0, addPlayerId)

    if (isSuperflex) {
      setSuperflexTiers(currentTiers)
    } else {
      setRankingsTiers(currentTiers)
    }
    setHasUnsavedChanges(true)
  }

  const removeTierBreakpoint = (removePlayerId) => {
    if (activeFilter === SUPERFLEX) {
      const newTiers = superflexTiers.filter(
        (playerId) => removePlayerId !== playerId
      )
      setSuperflexTiers(newTiers)
    } else {
      const newTiers = rankingsTiers.filter(
        (playerId) => removePlayerId !== playerId
      )
      setRankingsTiers(newTiers)
    }
    setHasUnsavedChanges(true)
  }

  //----------------------------------------------------------------Utility functions-------------------------------------------------------------------------------------

  const movePlayerOne = (currentIndex, direction) => {
    const newIndex = direction === UP ? currentIndex - 1 : currentIndex + 1

    const moveRank = (prevState) => {
      const newState = [...prevState]
      if (newIndex >= 0 && newIndex < newState.length) {
        ;[newState[currentIndex], newState[newIndex]] = [
          newState[newIndex],
          newState[currentIndex],
        ]
      }

      return newState
    }

    const movePostionalRank = () => {
      const rankingsLists = [{ stateArray: rankings, stateSetter: setRankings }]

      // Only do this if format includes a Superflex
      const superflexFormat = superflexFormats.includes(activeTab)
      if (superflexFormat) {
        rankingsLists.push({
          stateArray: superflexRankings,
          stateSetter: setSuperflexRankings,
        })
      }

      const filteredArray = getFilteredRankings()
      const mainPlayerMoving = filteredArray[currentIndex]

      const targetIndex =
        direction === 'UP' ? currentIndex - 1 : currentIndex + 1
      const targetPlayer = filteredArray[targetIndex]

      rankingsLists.forEach(({ stateArray, stateSetter }) => {
        const newRankingsState = [...stateArray]

        const mainPlayerMovingOverallIndex = stateArray.findIndex(
          (player) => player.player_id === mainPlayerMoving.player_id
        )
        const targetPlayerMovingOverallIndex = stateArray.findIndex(
          (player) => player.player_id === targetPlayer.player_id
        )

        newRankingsState.splice(mainPlayerMovingOverallIndex, 1)
        newRankingsState.splice(
          targetPlayerMovingOverallIndex,
          0,
          mainPlayerMoving
        )
        recalculatePlayerTiers(
          newRankingsState,
          rankingsTiers,
          setRankingsTiers
        )
        stateSetter(newRankingsState)
      })
    }

    // Only if superflex is selected
    if (activeFilter === SUPERFLEX) {
      const newSuperflexRanks = moveRank(superflexRankings)
      setSuperflexRankings(newSuperflexRanks)
      recalculatePlayerTiers(
        newSuperflexRanks,
        superflexTiers,
        setSuperflexTiers
      )
    }
    // If any positional filter is selected
    else if (positionalFilters.includes(activeFilter)) {
      movePostionalRank()
    } else {
      // Else, we're just working with regular ranks
      const newRanks = moveRank(rankings)
      setRankings(newRanks)
      recalculatePlayerTiers(newRanks, rankingsTiers, setRankingsTiers)
    }
    setHasUnsavedChanges(true)
  }

  const movePlayerUp = (index) => movePlayerOne(index, UP)
  const movePlayerDown = (index) => movePlayerOne(index, DOWN)

  const movePlayer = (playerIndex) => {
    if (moveActive && playerMovingId !== null) {
      const playersArray = getFilteredRankings()

      const mainPlayerFilteredIndex = playersArray.findIndex(
        (p) => p.player_id === playerMovingId
      )

      const targetPlayerId = playersArray[playerIndex].player_id

      const mainPlayerMoving = playersArray[mainPlayerFilteredIndex]
      const targetPlayerIndex = playersArray.findIndex(
        (p) => targetPlayerId === p.player_id
      )

      const updatedPlayersArray = [...playersArray]
      updatedPlayersArray.splice(mainPlayerFilteredIndex, 1)
      updatedPlayersArray.splice(targetPlayerIndex, 0, mainPlayerMoving)

      if (positionalFilters.includes(activeFilter)) {
        // Apply changes to both 1QB players and Superflex players
        const rankingsLists = [
          {
            stateArray: rankings,
            stateSetter: setRankings,
            tiers: rankingsTiers,
            setTiers: setRankingsTiers,
          },
        ]

        // Only do this if format includes a Superflex
        if (superflexFormats.includes(activeTab)) {
          rankingsLists.push({
            stateArray: superflexRankings,
            stateSetter: setSuperflexRankings,
            tiers: superflexTiers,
            setTiers: setSuperflexTiers,
          })
        }

        rankingsLists.forEach(
          ({ stateArray, stateSetter, tiers, setTiers }) => {
            const newRankingsState = [...stateArray]

            let positionCount = 0
            let insertIndex = 0
            let found = false

            newRankingsState.forEach((playerRanking, index) => {
              const playerPosition =
                allPlayers[playerRanking.player_id].position
              if (!found && playerPosition === activeFilter) {
                if (positionCount === playerIndex) {
                  found = true
                  insertIndex = index
                } else {
                  positionCount = positionCount + 1
                }
              }
            })

            const mainPlayersOverallIndex = newRankingsState.findIndex(
              (playerRecord) => playerRecord.player_id === playerMovingId
            )

            newRankingsState.splice(mainPlayersOverallIndex, 1)
            newRankingsState.splice(insertIndex, 0, mainPlayerMoving)

            stateSetter(newRankingsState)
            recalculatePlayerTiers(newRankingsState, tiers, setTiers)
          }
        )
      } else if (activeFilter === SUPERFLEX) {
        setSuperflexRankings(updatedPlayersArray)
        recalculatePlayerTiers(
          updatedPlayersArray,
          superflexTiers,
          setSuperflexTiers
        )
      } else {
        setRankings(updatedPlayersArray)
        recalculatePlayerTiers(
          updatedPlayersArray,
          rankingsTiers,
          setRankingsTiers
        )
      }

      setMoveActive(false)
      setPlayerIdMoving(null)
    } else {
      const movingPlayerId = getFilteredRankings()[playerIndex].player_id
      setPlayerIdMoving(movingPlayerId)
      setMoveActive(true)
    }
    setHasUnsavedChanges(true)
  }

  const removePlayer = (removeId) => {
    const removeFromRanking = rankings.filter(
      ({ player_id }) => player_id !== removeId
    )
    setRankings(removeFromRanking)

    if (rankingsTiers.includes(removeId)) {
      const removeFromTiers = rankingsTiers.filter(
        (playerId) => playerId !== removeId
      )
      setRankingsTiers(removeFromTiers)
    }

    // Only do this is the format includes a Superflex option
    if (superflexFormats.includes(activeTab)) {
      const removeFromSuperflex = superflexRankings.filter(
        ({ player_id }) => player_id !== removeId
      )
      setSuperflexRankings(removeFromSuperflex)

      if (superflexTiers.includes(removeId)) {
        const removeFromSuperflexTiers = superflexTiers.filter(
          (playerId) => playerId !== removeId
        )
        setSuperflexTiers(removeFromSuperflexTiers)
      }
    }
    setHasUnsavedChanges(true)
  }

  const rankingsToCSV = () => {
    const csvContent = createCSVString()

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.setAttribute('href', url)
    let name = `${activeTab}-rankings.csv`
    link.setAttribute('download', name)
    link.style.display = 'none'

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }

  const handleDragStart = (e, index) => {
    movePlayer(index)
  }

  const handleDrop = (e, endIndex) => {
    movePlayer(endIndex)
  }

  //---------------------------------------------------------------- Constants -------------------------------------------------------------------------------------

  const hideTiers = positionalFilters.includes(activeFilter) ? true : false

  const importText =
    activeTab === REDRAFT
      ? 'Import from Best Ball'
      : activeTab === WEEKLY
        ? 'Import from Redraft'
        : 'Import from 1QB'

  return (
    <div className="h-[92vh] overflow-auto bg-light px-[5%] py-8 font-dmsans dark:bg-dark md:px-[15%]">
      <ToastContainer />
      <span className="text-[26px] font-bold text-light-text dark:text-dark-text">
        Create Rankings
      </span>
      <TabsPrimitive.Root
        className="flex flex-col justify-between"
        onValueChange={handleTabChange}
        value={activeTab}
      >
        <div className="py-6 md:flex md:flex-wrap xl:justify-between">
          <div className="mt-2 flex max-w-[455px] justify-center gap-3 pr-2 md:flex-wrap md:justify-normal">
            <SearchBar
              allowDuplicateDraftPicks={false}
              onAddPlayer={addPlayer}
              ranks={playerArray}
              searchTerm={searchTerm}
              selectedPlayers={rankings}
              setSearchTerm={setSearchTerm}
            />
          </div>
          <div className="flex items-center gap-2">
            <TabsPrimitive.List className="mb-2 mt-2 flex h-[50px] justify-center gap-1 rounded-custom border-0 bg-light-bg p-[4px] text-custom-font dark:bg-dark-bg-select md:mb-0">
              {tabConfig.map((tab) => (
                <TabsPrimitive.Trigger
                  className={`rounded-lg px-8 py-2 transition-all duration-200 hover:bg-accent-bg hover:text-accent hover:dark:text-accent ${
                    activeTab === tab.value
                      ? 'bg-accent-bg font-bold text-accent'
                      : 'text-light-text2 dark:text-dark-text2'
                  }`}
                  key={tab.value}
                  value={tab.value}
                >
                  {tab.label}
                </TabsPrimitive.Trigger>
              ))}
            </TabsPrimitive.List>
          </div>
          <div className="flex flex-wrap items-center gap-4 lg:justify-end">
            <button
              className="flex h-[50px] items-center gap-2 rounded-custom border-[1px] border-light2 p-3 px-6 text-custom-font font-bold text-light-text transition-all duration-200 hover:bg-light2 dark:border-dark-border dark:text-dark-text hover:dark:bg-dark-bg2"
              onClick={rankingsToCSV}
            >
              <MdOutlineFileDownload
                className="fill-light-text dark:fill-dark-text"
                size={26}
              />
              Download CSV
            </button>
            <button
              onClick={() => {
                document.getElementById('csvInput').click()
              }}
              className="flex h-[50px] items-center gap-2 rounded-custom border-[1px] border-light2 p-3 px-6 text-custom-font font-bold text-light-text transition-all duration-200 hover:bg-light2 dark:border-dark-border dark:text-dark-text hover:dark:bg-dark-bg2"
            >
              <FaFileImport
                className="fill-light-text dark:fill-dark-text"
                size={18}
              />
              Import
            </button>
            {/* Hidden file input for handling file selection */}
            <input
              accept=".csv"
              id="csvInput"
              onChange={handleImportCSV}
              style={{ display: 'none' }}
              type="file"
            />
            <button
              className="flex h-[50px] w-[125px] items-center justify-center gap-3 rounded-custom border-0 bg-accent px-4 py-2 text-sm font-bold text-dark-text"
              disabled={isSubmitting}
              onClick={handleSubmitRankings}
            >
              {isSubmitting ? (
                <div className="flex h-[18px] items-center">
                  <Triangle color="#FFFFFF" height={30} width={30} />
                </div>
              ) : (
                <>
                  <IoCloudUploadSharp size={18} />
                  Publish
                </>
              )}
            </button>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex flex-wrap gap-2 pb-2">
            {superflexFormats.includes(activeTab) && (
              <>
                <button
                  className={`rounded-full border-[1px] border-light2 px-[14px] py-2 text-[13px] transition-all duration-200 hover:bg-accent hover:text-light dark:border-dark-border ${
                    activeFilter === ONEQB
                      ? 'bg-accent font-bold text-dark-text'
                      : 'text-light-text dark:text-dark-text'
                  }`}
                  onClick={() => setActiveFilter(ONEQB)}
                >
                  1QB
                </button>
                <button
                  className={`rounded-full border-[1px] border-light2 px-[14px] py-2 text-[13px] transition-all duration-200 hover:bg-accent hover:text-light dark:border-dark-border ${
                    activeFilter === SUPERFLEX
                      ? 'bg-accent font-bold text-dark-text'
                      : 'text-light-text dark:text-dark-text'
                  }`}
                  onClick={() => setActiveFilter(SUPERFLEX)}
                >
                  SuperFlex
                </button>
              </>
            )}
            {!superflexFormats.includes(activeTab) && (
              <button
                className={`rounded-full border-[1px] border-light2 px-[14px] py-2 text-[13px] transition-all duration-200 hover:bg-accent hover:text-light dark:border-dark-border ${
                  activeFilter === OVERALL
                    ? 'bg-accent font-bold text-dark-text'
                    : 'text-light-text dark:text-dark-text'
                }`}
                onClick={() => setActiveFilter(OVERALL)}
              >
                Overall
              </button>
            )}
            <div className="border-r-[1px] border-light2 dark:border-dark-border"></div>
            <button
              className={`rounded-full border-[1px] border-light2 px-[14px] py-2 text-[13px] transition-all duration-200 hover:bg-accent hover:text-light dark:border-dark-border ${
                activeFilter === QB
                  ? 'bg-accent font-bold text-dark-text'
                  : 'text-light-text dark:text-dark-text'
              }`}
              onClick={() => setActiveFilter(QB)}
            >
              QB
            </button>
            <button
              className={`rounded-full border-[1px] border-light2 px-[14px] py-2 text-[13px] transition-all duration-200 hover:bg-accent hover:text-light dark:border-dark-border ${
                activeFilter === RB
                  ? 'bg-accent font-bold text-dark-text'
                  : 'text-light-text dark:text-dark-text'
              }`}
              onClick={() => setActiveFilter(RB)}
            >
              RB
            </button>
            <button
              className={`rounded-full border-[1px] border-light2 px-[14px] py-2 text-[13px] transition-all duration-200 hover:bg-accent hover:text-light dark:border-dark-border ${
                activeFilter === WR
                  ? 'bg-accent font-bold text-dark-text'
                  : 'text-light-text dark:text-dark-text'
              }`}
              onClick={() => setActiveFilter(WR)}
            >
              WR
            </button>
            <button
              className={`rounded-full border-[1px] border-light2 px-[14px] py-2 text-[13px] transition-all duration-200 hover:bg-accent hover:text-light dark:border-dark-border ${
                activeFilter === TE
                  ? 'bg-accent font-bold text-dark-text'
                  : 'text-light-text dark:text-dark-text'
              }`}
              onClick={() => setActiveFilter(TE)}
            >
              TE
            </button>
          </div>
          {(activeTab === BEST_BALL ||
            activeTab === REDRAFT ||
            activeTab === WEEKLY) &&
            rankings.length === 0 && (
              <button
                className="flex h-[50px] items-center gap-2 rounded-custom border-[1px] border-light2 p-3 px-6 text-custom-font font-bold text-light-text transition-all duration-200 hover:bg-light2 dark:border-dark-border dark:text-dark-text hover:dark:bg-dark-bg2"
                onClick={transferRankings}
              >
                {importText}
              </button>
            )}
        </div>
        {[REDRAFT, BEST_BALL, DYNASTY, WEEKLY, PROSPECTS].map((format) => {
          return (
            <TabsPrimitive.Content key={format} value={format}>
              <RankingsTable
                activeFilter={activeFilter}
                allPlayers={allPlayers}
                getFilteredRankings={getFilteredRankings}
                handleCheckboxChange={handleCheckboxChange}
                handleDragStart={handleDragStart}
                handleDrop={handleDrop}
                hideTiers={hideTiers}
                isLoading={isLoading}
                moveActive={moveActive}
                movePlayer={movePlayer}
                movePlayerDown={movePlayerDown}
                movePlayerUp={movePlayerUp}
                rankingsTiers={rankingsTiers}
                removePlayer={removePlayer}
                superflexTiers={superflexTiers}
              />
            </TabsPrimitive.Content>
          )
        })}
      </TabsPrimitive.Root>

      {blocker.state === 'blocked' ? (
        <div className="fixed inset-0 z-[100] flex items-center justify-center bg-black bg-opacity-50 pt-10 text-light-text backdrop-blur-sm dark:text-dark-text">
          <div className="border-light-border flex flex-col gap-3 rounded-lg border-[1px] bg-dark-bg2 px-8 py-10 dark:border-dark-border">
            <span className="font-medium">Are you sure you want to leave?</span>
            <span className="font-medium">All progress will be lost</span>
            <div className="flex gap-4">
              <button
                className="rounded-md bg-zinc-400 px-4 py-3"
                onClick={() => blocker.reset()}
              >
                Cancel
              </button>
              <button
                className="rounded-md bg-accent px-4 py-3"
                onClick={() => blocker.proceed()}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {showChangeFormatModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="flex flex-col items-center space-y-4 rounded-lg bg-light p-4 text-light-text shadow-lg dark:bg-dark dark:text-dark-text">
            <div>
              Are you sure you want to change formats? You will lose your
              progress.
            </div>
            <div className="flex space-x-4">
              <button
                className="rounded bg-zinc-500 px-4 py-2 text-white"
                onClick={() => setShowChangeFormatModal(false)}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setActiveTab(nextTab)
                  setShowChangeFormatModal(false)
                  setHasUnsavedChanges(false)
                }}
                className="rounded bg-accent px-4 py-2 text-white"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CreateRankings
