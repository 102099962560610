import React, { useEffect, useState } from 'react'
import ToggleButton from '../_ui/ToggleButton'

import { LiaTimesSolid } from 'react-icons/lia'

const SettingsModal = ({
  isOpen,
  onClose,
  currentLeagueType,
  selectLeagueType,
  onTeamSelectionChange,
  selectedTeamCounts,
  setSelectedQbs,
  setSelectedPprTypes,
  selectedQbs,
  selectedPprTypes,
}) => {
  const [selectedTeams, setSelectedTeams] = useState([])

  const handleTeamSelect = (teamCount) => {
    setSelectedTeams((prev) => {
      const newSelectedTeams = prev.includes(teamCount)
        ? prev.filter((count) => count !== teamCount)
        : [...prev, teamCount]
      onTeamSelectionChange(newSelectedTeams)
      return newSelectedTeams
    })
  }

  const toggleQbSelection = (qbCount) => {
    setSelectedQbs((prevSelected) => {
      if (prevSelected.includes(qbCount)) {
        return prevSelected.filter((count) => count !== qbCount)
      } else {
        return [...prevSelected, qbCount]
      }
    })
  }

  const togglePprSelection = (pprType) => {
    setSelectedPprTypes((prevSelected) => {
      if (prevSelected.includes(pprType)) {
        return prevSelected.filter((type) => type !== pprType)
      } else {
        return [...prevSelected, pprType]
      }
    })
  }

  const isSelectedTeam = (count) => selectedTeamCounts.includes(count)

  if (!isOpen) {
    return null
  }

  return (
    <div
      className={`fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50 ${isOpen ? 'flex' : 'hidden'}`}
    >
      <div className="max-h-[80%] overflow-scroll rounded-[25px] bg-light px-10 py-8 text-light-text dark:bg-dark dark:text-dark-text">
        <div className="flex items-center justify-between pb-3">
          <h3 className="text-xl font-bold">Trade Settings</h3>
          <button>
            <LiaTimesSolid
              className="fill-light-text2 transition-colors duration-300 hover:fill-accent dark:fill-dark-text2 hover:dark:fill-accent"
              onClick={onClose}
              size={22}
            />
          </button>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col gap-4 overflow-scroll">
            <div className="flex flex-col gap-2">
              <div className="text-md font-medium">League Type</div>
              <div className="flex gap-2">
                <ToggleButton
                  isSelected={currentLeagueType === 'Redraft'}
                  onClick={() => selectLeagueType('Dynasty')}
                  text="Redraft"
                />
                <ToggleButton
                  isSelected={currentLeagueType === 'Dynasty'}
                  onClick={() => selectLeagueType('Redraft')}
                  text="Dynasty"
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              {/* PPR Selection */}
              <div className="text-md font-medium">PPR</div>
              <div className="flex gap-2">
                {[
                  { label: 'None', value: 0 },
                  { label: 'Half', value: 0.5 },
                  { label: 'Full', value: 1 },
                ].map((pprOption) => (
                  <ToggleButton
                    isSelected={selectedPprTypes.includes(pprOption.value)}
                    key={pprOption.label}
                    onClick={() => togglePprSelection(pprOption.value)}
                    text={pprOption.label}
                  />
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              {/* QB Selection */}
              <div className="text-md font-medium">QBs</div>
              <div className="flex gap-2">
                {[1, 2].map((qbCount) => (
                  <ToggleButton
                    isSelected={selectedQbs.includes(qbCount)}
                    key={qbCount}
                    onClick={() => toggleQbSelection(qbCount)}
                    text={`${qbCount}`}
                  />
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="text-md font-medium">Teams</div>
              <div className="flex gap-2">
                {[8, 10, 12, 14].map((count) => (
                  <ToggleButton
                    isSelected={isSelectedTeam(count)}
                    key={count}
                    onClick={() => handleTeamSelect(count)}
                    text={`${count}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingsModal
