import React from 'react'
import { FaArrowsRotate } from 'react-icons/fa6'

const daysAgo = (dateString) => {
  const now = new Date()
  const past = new Date(dateString)
  const diff = now - past
  const days = Math.floor(diff / (1000 * 60 * 60 * 24))

  if (days === 0) {
    return 'Today'
  }
  return `${days} day${days > 1 ? 's' : ''} ago`
}

const getScoring = (ppr) => {
  let scoring
  if (ppr === 1) {
    scoring = 'FULL PPR'
  } else if (ppr === 0.5) {
    scoring = 'HALF PPR'
  } else {
    scoring = 'NON PPR'
  }
  return scoring
}

const TradeHistoryDetails = ({
  trade,
  highlightedPlayersGetting,
  highlightedPlayersGiving,
}) => {
  const scoring = getScoring(trade.ppr)
  const isHighlighted = (playerId, highlightedPlayerId) => {
    return playerId === highlightedPlayerId[0]
  }

  return (
    <div className="flex h-full flex-col justify-between border-t-[1px] border-light2 py-3 text-light-text dark:border-dark-border dark:text-dark-text">
      <div className="flex flex-grow flex-col justify-center">
        {/* Side 1 Player Names */}
        <div className="flex items-center justify-between gap-2">
          <div className="flex flex-col gap-1 text-sm font-medium md:w-36">
            {trade.side1.map((player, i) => (
              <div
                className={
                  isHighlighted(player.id, highlightedPlayersGetting)
                    ? 'text-accent'
                    : ''
                }
                key={`side1-${i}-${trade.id}-${player.id}`}
              >
                {player.name}
              </div>
            ))}
          </div>
          <div className="my-auto mr-4 flex flex-col items-center md:w-[100px]">
            <FaArrowsRotate className="fill-dark dark:fill-light" size={22} />
            <div>{daysAgo(trade.date)}</div>
          </div>
          {/* Side 2 Player Names */}
          <div className="flex flex-col gap-1 text-sm font-medium md:w-36">
            <div className="flex flex-col gap-1 text-sm font-medium md:w-36">
              {trade.side2.map((player, i) => (
                <div
                  className={
                    isHighlighted(player.id, highlightedPlayersGiving)
                      ? 'text-accent'
                      : ''
                  }
                  key={`side2-${i}-${trade.id}-${player.id}`}
                >
                  {player.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between pt-4 text-sm font-bold">
        <div className="rounded-full border-[1px] border-accent-purple bg-accent-bg-purple p-2 px-3">
          {trade.numQbs} QBs
        </div>
        <div className="rounded-full border-[1px] border-accent-cyan bg-accent-bg-cyan p-2 px-3">
          {trade.numTeams} Teams
        </div>
        <div className="rounded-full border-[1px] border-accent-green bg-accent-bg-green p-2 px-3">
          Start {trade.numStarters}
        </div>
        <div className="rounded-full border-[1px] border-accent-red bg-accent-bg-red p-2 px-3">
          {scoring}
        </div>
      </div>
    </div>
  )
}

export default TradeHistoryDetails
