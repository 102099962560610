import React from 'react'
import { FaXmark } from 'react-icons/fa6'

const SafariImageViewer = ({ isOpen, images, currentImage, onClose }) => {
  if (!isOpen) {
    return null
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
        onClick={onClose}
      ></div>
      <div className="relative z-10 w-full max-w-4xl rounded-lg p-4">
        <button
          className="absolute -right-2 -top-2 text-2xl text-dark-text"
          onClick={onClose}
        >
          <FaXmark className="text-dark-text" size={22} />
        </button>
        <img
          alt="Current"
          className="h-auto w-full"
          src={images[currentImage]}
        />
      </div>
    </div>
  )
}

export default SafariImageViewer
