import axios from 'axios'

export async function getCreatorDetails() {
  try {
    const { data } = await axios('/getCreatorDetails')
    return data
  } catch (err) {
    console.log('ERROR getCreatorDetails : ', err)
  }
}

export async function evaluatePost(post_id, action) {
  const request_body = {
    post_id,
  }
  try {
    const { data } = await axios.post(
      '/reviewCommunityContent/?type=post&action=' + action,
      JSON.stringify(request_body)
    )
    return data.data
  } catch (err) {
    console.log('ERROR evaluatePost : ', err)
    return false
  }
}

export async function evaluateRankings(user_id, action) {
  const request_body = {
    user_id: user_id,
  }

  try {
    const { data } = await axios.post(
      '/reviewCommunityContent/?type=ranking&action=' + action,
      JSON.stringify(request_body)
    )
    return data.data
  } catch (err) {
    console.log('ERROR evaluateRankings : ', err)
  }
}

export async function applyUnderDogCredit(username) {
  const underdogData = {
    underdogUsername: username,
  }

  try {
    const { data } = await axios.post(
      '/applyUnderDogCode',
      JSON.stringify(underdogData)
    )
    return data.data
  } catch (err) {
    console.log('ERROR applyUnderDogCredit : ', err)
  }
}

export async function linkDiscordAndUpdateRoles(code) {
  const codeData = {
    code: code,
  }
  try {
    const { data } = await axios.post(
      '/manageDiscord?action=link',
      JSON.stringify(codeData)
    )
    return data.data
  } catch (err) {
    console.log('ERROR linkDiscordAndUpdateRoles : ', err)
  }
}

export async function updateDiscordRoles(id) {
  const discordData = {
    discord_id: id,
  }
  try {
    const { data } = await axios.post(
      '/manageDiscord/?action=update',
      JSON.stringify(discordData)
    )
    return data.data
  } catch (err) {
    console.log('ERROR updateDiscordRoles : ', err)
  }
}
