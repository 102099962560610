import React, { useRef } from 'react'
import { MdFileUpload } from 'react-icons/md'
import { FaXmark } from 'react-icons/fa6'
import { toast } from 'react-toastify'

const ImageLinker = ({ images, setImages }) => {
  const fileInputRef = useRef(null)

  const handleAddImage = (event) => {
    const files = Array.from(event.target.files)
    const allowedTypes = ['image/png', 'image/jpeg']

    const newImages = files
      .filter((file) => allowedTypes.includes(file.type))
      .map((file) => {
        let fileName = file.name
        if (fileName.length > 75) {
          fileName = `${fileName.substring(0, 72)}...`
        }
        return {
          name: fileName,
          url: URL.createObjectURL(file),
          type: file.type,
          file,
        }
      })

    if (newImages.length !== files.length) {
      toast('Only PNG and JPEG files are allowed.')
    }

    setImages([...images, ...newImages])
  }

  const handleRemoveImage = (index) => {
    const newImages = images.filter((_, i) => i !== index)
    setImages(newImages)
  }

  const triggerFileInput = () => {
    fileInputRef.current.click()
  }

  return (
    <div className="flex flex-col">
      <div className="border-light-border flex h-[50px] items-center gap-2 rounded-lg border-[1px] bg-zinc-300 transition-all duration-200 dark:border-dark-border dark:bg-dark hover:dark:bg-accent-bg">
        <button
          className="flex w-full cursor-pointer items-center justify-center gap-2 rounded-custom p-2 text-white"
          onClick={triggerFileInput}
        >
          <MdFileUpload
            className="text-light-text dark:text-dark-text"
            size={24}
          />
          <span className="font-bold text-light-text dark:text-dark-text">
            Link Files
          </span>
        </button>
        <input
          accept="image/png, image/jpeg"
          className="hidden"
          id="file-upload"
          multiple
          onChange={handleAddImage}
          ref={fileInputRef}
          type="file"
        />
      </div>
      <div className="mt-2 flex flex-col gap-2">
        {images.map((image, index) => (
          <div className="flex items-center justify-between p-2" key={index}>
            <div className="text-md w-64 overflow-hidden text-ellipsis whitespace-nowrap text-light-text2 dark:text-dark-text2">
              {image.name}
            </div>
            <FaXmark
              className="cursor-pointer text-light-text dark:text-dark-text"
              onClick={() => handleRemoveImage(index)}
              size={22}
            />
          </div>
        ))}
      </div>
      {images.length === 0 && (
        <span className="text-light-text2 dark:text-dark-text2">
          Please add screenshots of your team
        </span>
      )}
    </div>
  )
}

export default ImageLinker
