import React from 'react'

const PostSkeleton = (index) => {
  return (
    <div
      className="w-full border-t-[1px] border-light2 dark:border-dark-border"
      key={index}
    >
      <div className="flex w-full animate-pulse flex-col justify-center gap-10 rounded-md px-4 py-2 md:h-52 md:flex-row md:justify-start">
        <div className="flex items-center md:w-60 lg:w-80">
          <div className="w-[320px] rounded-lg bg-zinc-300 md:h-[175px]" />
        </div>
        <div className="w-full">
          <div className="flex items-center justify-between">
            <div className="mt-2 h-6 w-48 rounded-full bg-zinc-300"></div>
            <div className="h-8 w-8 rounded-full bg-zinc-300" />
          </div>
          <div className="mt-2 h-4 w-36 rounded-full bg-zinc-300"></div>
          <div className="mt-2 h-4 w-24 rounded-full bg-zinc-300"></div>
          <div className="mt-2 h-4 w-full rounded-full bg-zinc-300"></div>
          <div className="mt-2 h-4 w-full rounded-full bg-zinc-300"></div>
          <div className="mt-2 h-4 w-full rounded-full bg-zinc-300"></div>
        </div>
      </div>
    </div>
  )
}

export default PostSkeleton
