import React, { useEffect, useState } from 'react'
import { getCommunityRankings, updatePlayerElo } from '../../../store/api'
import './EloManager.css'

const BEST_BALL = 'BEST_BALL'

const formatOptions = [
  { text: 'Redraft', value: 'year' },
  { text: 'Superflex', value: 'superflex' },
  { text: '1 QB', value: 'qb' },
  { text: 'Best Ball', value: 'BEST_BALL' },
]

const EloManager = () => {
  const [pristinePlayers, setPristinePlayers] = useState([])
  const [players, setPlayers] = useState([])
  const [selectedFormat, setSelectedFormat] = useState(formatOptions[0])

  useEffect(() => {
    getElosByFormat()
  }, [selectedFormat])

  const getElosByFormat = async () => {
    const { players } = await getCommunityRankings({
      asArray: true,
      type: selectedFormat.value,
    })

    setPlayers(players)
    setPristinePlayers(players)
  }

  const findNewRank = (index, elo) => {
    const pristineElo = Number(pristinePlayers[index].elo_rating)
    const newElo = Number(elo)

    if (newElo > pristineElo) {
      // Changing the rank possibly higher. Will return update if just an update
      for (let i = 0; i < pristinePlayers.length; i++) {
        if (newElo > Number(pristinePlayers[i].elo_rating)) {
          return i + 1
        }
      }
    } else {
      // Lowering rank is weird. This is needed to update but not change the rank
      if (
        newElo < pristinePlayers[index].elo_rating &&
        newElo > pristinePlayers[index + 1].elo_rating
      ) {
        return index + 1
      }

      // Otherwise we are changing the rank lower
      for (let i = index + 1; i < pristinePlayers.length; i++) {
        if (newElo > Number(pristinePlayers[i].elo_rating)) {
          return i
        }
      }
    }

    return pristinePlayers.length
  }

  const handleChange = (event) => {
    const newSelectedFormat = formatOptions.find(
      (option) => option.value === event.target.value
    )
    setSelectedFormat(newSelectedFormat)
  }

  const handleElo = (index, rating) => {
    setPlayers((state) => {
      const newState = state.map((item, idx) => {
        if (idx === index) {
          return { ...item, elo_rating: rating }
        }
        return item
      })

      return newState
    })
  }

  const updateElo = async (playerRecord) => {
    const formatValue = selectedFormat.value
    const format =
      formatValue === 'year'
        ? 1
        : formatValue === 'superflex'
          ? 2
          : formatValue === 'qb'
            ? 3
            : formatValue === BEST_BALL
              ? 4
              : null

    if (format) {
      const newPlayerRecord = {
        ...playerRecord,
        elo_rating: Number(playerRecord.elo_rating),
      }
      const payload = { format, player: newPlayerRecord }
      await updatePlayerElo(payload)

      const filteredPlayers = pristinePlayers.filter(
        (player) => player.player_id !== playerRecord.player_id
      )
      filteredPlayers.push(newPlayerRecord)
      filteredPlayers.sort((a, b) => b.elo_rating - a.elo_rating)

      setPlayers(filteredPlayers)
      setPristinePlayers(filteredPlayers)
    }
  }

  return (
    <div className="page-wrapper">
      <div className="format-dropdown">
        <select onChange={handleChange} value={selectedFormat.value}>
          {formatOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
      </div>
      <div>
        {/* Render players or other components */}
        {players.map((player, index) => {
          const touched =
            Number(pristinePlayers[index].elo_rating) !==
            Number(player.elo_rating)
          const newRank = touched && findNewRank(index, player.elo_rating)

          const updateText =
            Number(newRank) === index + 1 ? 'Update' : `Move to ${newRank}`

          return (
            <div className="player-wrapper" key={player.player_id}>
              <div>
                <div className="player-info">
                  <div>
                    {index + 1} - {player.player_name}
                  </div>
                </div>
                <div>
                  <input
                    onChange={(e) => handleElo(index, e.target.value)}
                    value={player.elo_rating}
                  />
                </div>
              </div>
              <div className="button-wrapper">
                {touched && (
                  <div style={{ display: 'flex' }}>
                    <div>
                      <button
                        className="elo-manage-update-btn"
                        onClick={() => updateElo(player)}
                        style={{ marginRight: '12px' }}
                      >
                        {updateText}
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={() =>
                          handleElo(index, pristinePlayers[index].elo_rating)
                        }
                        className="elo-manage-update-btn"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default EloManager
