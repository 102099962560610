import React, { useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import '../../styles/webkit.css'

const PassInputBox = ({ placeholder, onChange }) => {
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className="flex justify-center gap-3 md:justify-normal">
      <div className="flex w-full items-center justify-between gap-4 rounded-custom border-[1px] border-light2 p-3 dark:border-dark-border">
        <input
          className="flex-grow bg-transparent text-sm outline-none dark:text-dark-text2"
          onChange={onChange}
          placeholder={placeholder}
          type={showPassword ? 'text' : 'password'}
        />
        <button
          className="focus:outline-none"
          onClick={togglePasswordVisibility}
          type="button"
        >
          {showPassword ? (
            <FaEye className="cursor-pointer fill-dark dark:fill-dark-text2" />
          ) : (
            <FaEyeSlash className="cursor-pointer fill-dark dark:fill-dark-text2" />
          )}
        </button>
      </div>
    </div>
  )
}

export default PassInputBox
