import React, { useEffect, useState } from 'react'

import { getTradeHistory } from '../../store/api'

import TradeHistoryDetails from './TradeHistoryDetails'
import loadingGif from '../../images/FlockLoadingIcon.gif'

const TradeHistory = ({
  playersGetting,
  playersGiving,
  payload,
  team1SelectedPlayer,
  team2SelectedPlayer,
}) => {
  const [tradesFound, setTradesFound] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  useEffect(() => {
    const isValidArray = (arr) => arr && Array.isArray(arr) && arr.length > 0

    if (isValidArray(playersGetting) || isValidArray(playersGiving)) {
      getHistory()
    }
  }, [playersGetting, playersGiving, payload])

  const getHistory = async () => {
    const finalPayload = {
      ...payload,
      side1: team1SelectedPlayer ? team1SelectedPlayer.fantasy_calc_id : null,
      side2: team2SelectedPlayer ? team2SelectedPlayer.fantasy_calc_id : null,
      _source: 'flock',
    }
    setIsFetching(true)
    try {
      const tradeHistory = await getTradeHistory(finalPayload)
      setTradesFound(tradeHistory)
    } catch (err) {
      console.log('ERROR getTradeHistory : ', err)
    }
    setIsFetching(false)
  }

  return (
    <div className="flex max-h-[35rem] flex-wrap justify-center gap-4 overflow-auto md:mx-[1%]">
      {isFetching ? (
        <img alt="Loading..." height={300} src={loadingGif} width={300} />
      ) : (
        tradesFound.map((trade) => (
          <div
            className="mx-2 my-2 w-full md:w-[calc(50%-2rem)]"
            key={trade.id}
          >
            <TradeHistoryDetails
              highlightedPlayersGetting={playersGetting}
              highlightedPlayersGiving={playersGiving}
              trade={trade}
            />
          </div>
        ))
      )}
    </div>
  )
}

export default TradeHistory
