import React from 'react'
import { ColoredText } from './UtilComponents'

const NON = 'NON'
const HALF = 'HALF'
const FULL = 'PPR'

const LeagueScoring = ({
  handleSettings,
  loadingState,
  saveSettings,
  selectHandler,
  selectValue,
  userSettings,
}) => {
  const updateMultiplier = (position, value) => {
    const newScoring = { ...userSettings }
    newScoring.scoring[selectValue][position] = value
    handleSettings(newScoring)
  }

  const scoringText =
    selectValue === NON
      ? 'NON PPR'
      : selectValue === HALF
        ? '1/2 PPR'
        : 'FULL PPR'

  const qbValue = userSettings.scoring[selectValue].qb
  const rbValue = userSettings.scoring[selectValue].rb
  const wrValue = userSettings.scoring[selectValue].wr
  const teValue = userSettings.scoring[selectValue].te

  return (
    <div>
      <div>
        <div className="ap-header">
          Scoring Format: <ColoredText text={scoringText} />
        </div>
        <div className="ap-user-setting-segment">
          <div>League Scoring</div>
          <select
            className="ap-user-settings-select"
            onChange={(e) => selectHandler(e.target.value)}
            value={selectValue}
          >
            <option value={NON}>Non PPR</option>
            <option value={HALF}>Half PPR</option>
            <option value={FULL}>Full PPR</option>
          </select>
        </div>
        <div className="ap-segment">
          <div>
            <div>Position Multiplier</div>
            <div>
              <div>QB</div>
              <input
                className="ap-input"
                onChange={(e) => updateMultiplier('qb', e.target.value)}
                value={qbValue}
              />
            </div>
            <div>
              <div>RB</div>
              <input
                className="ap-input"
                onChange={(e) => updateMultiplier('rb', e.target.value)}
                value={rbValue}
              />
            </div>
            <div>
              <div>WR</div>
              <input
                className="ap-input"
                onChange={(e) => updateMultiplier('wr', e.target.value)}
                value={wrValue}
              />
            </div>
            <div>
              <div>TE</div>
              <input
                className="ap-input"
                onChange={(e) => updateMultiplier('te', e.target.value)}
                value={teValue}
              />
            </div>
          </div>
          <div>
            <div className="ap-intro-algo">
              <div>
                <span className="ap-user-value">Base</span> (QB data used in
                example)
              </div>
              <pre>
                return elo_rating * <ColoredText text={'POSITION_MULTIPLIER'} />
              </pre>
            </div>
            <div>
              <div className="ap-user-value">Edited</div>
              <pre>
                return elo_rating *{' '}
                <ColoredText text={userSettings.scoring[selectValue].qb} />
              </pre>
            </div>
          </div>
        </div>
      </div>

      <button className="ap-button" onClick={saveSettings}>
        Save
      </button>
      <span className="loading-span">{loadingState}</span>
    </div>
  )
}

export default LeagueScoring
