import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FaXmark } from 'react-icons/fa6'
import Select from '../_ui/Select'
import * as Slider from '@radix-ui/react-slider'
import BaseCheckbox from '../_ui/BaseCheckbox'

const QB = 'QB'
const RB = 'RB'
const WR = 'WR'
const TE = 'TE'
const ROOKIE = 'ROOKIE'
// const DEVY = 'DEVY'

const FilterModal = ({
  ageRange,
  applyFilters,
  creators,
  fullUpdated,
  isOpen,
  onClose,
  onCreatorSelect,
  prospectUpdated,
  qbFilter,
  rbFilter,
  rookieFilter,
  selectedTeam,
  setAgeRange,
  setSelectedTeam,
  teFilter,
  wrFilter,
}) => {
  const { subscription_tier, is_creator } = useSelector((state) => state.user)

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  if (!isOpen || !ageRange) {
    return null
  }

  const handleFilter = (type) => {
    applyFilters(type)
  }

  const nflTeamAbbreviations = [
    'All',
    'ARI',
    'ATL',
    'BAL',
    'BUF',
    'CAR',
    'CHI',
    'CIN',
    'CLE',
    'DAL',
    'DEN',
    'DET',
    'GB',
    'HOU',
    'IND',
    'JAX',
    'KC',
    'LV',
    'LAC',
    'LAR',
    'MIA',
    'MIN',
    'NE',
    'NO',
    'NYG',
    'NYJ',
    'PHI',
    'PIT',
    'SF',
    'SEA',
    'TB',
    'TEN',
    'WAS',
  ]
  const calculatePosition = (value) => {
    const MIN_AGE = 18
    const MAX_AGE = 50
    const sliderWidth = 345
    return ((value - MIN_AGE) / (MAX_AGE - MIN_AGE)) * sliderWidth
  }

  const handleTeamSelection = (selectedTeamAbbreviation) => {
    const team =
      selectedTeamAbbreviation.label === 'All'
        ? ''
        : selectedTeamAbbreviation.label
    setSelectedTeam(team)
  }

  const highlightAll =
    !qbFilter && !rbFilter && !wrFilter && !teFilter && !rookieFilter

  return (
    <div className="absolute inset-0 z-10 flex items-end justify-center bg-light-bg-blur dark:bg-bg-blur md:items-center">
      <div className="h-8/10 flex w-full flex-col rounded-t-2xl bg-white p-4 dark:bg-dark md:h-auto md:w-auto md:rounded-[25px]">
        <FaXmark
          className="ml-auto cursor-pointer fill-light-text2 transition-all duration-200 hover:fill-accent hover:dark:fill-accent"
          onClick={onClose}
          size={18}
        />
        <div className="flex flex-col gap-8 px-4 pb-8">
          <span className="text-xl font-bold dark:text-dark-text">
            Filter Rankings
          </span>
          <div className="flex w-full flex-wrap justify-start gap-4 dark:text-dark-text md:hidden">
            <BaseCheckbox
              checked={highlightAll}
              onChange={() => handleFilter(null)}
              text="ALL"
            />
            <BaseCheckbox
              checked={rookieFilter}
              onChange={() => handleFilter(ROOKIE)}
              text="Rookies"
            />
            {/* <BaseCheckbox checked={rookieFilter} onChange={() => handleFilter(DEVY)} text="Prospects" /> */}
          </div>
          <div className="flex w-full flex-wrap justify-start gap-4 dark:text-dark-text md:hidden">
            <BaseCheckbox
              checked={qbFilter}
              onChange={() => handleFilter(QB)}
              text="QB"
            />
            <BaseCheckbox
              checked={rbFilter}
              onChange={() => handleFilter(RB)}
              text="RB"
            />
            <BaseCheckbox
              checked={wrFilter}
              onChange={() => handleFilter(WR)}
              text="WR"
            />
            <BaseCheckbox
              checked={teFilter}
              onChange={() => handleFilter(TE)}
              text="TE"
            />
          </div>
          <div></div>
          <div className="relative dark:text-dark-text">
            Age
            <div className="w-full md:w-[345px]">
              <Slider.Root
                className="relative flex w-full touch-none select-none items-center pt-[12px]"
                max={50}
                min={18}
                onValueChange={setAgeRange}
                step={1}
                value={ageRange}
              >
                <Slider.Track className="relative h-[3px] grow rounded-full bg-[#EEE] dark:bg-dark-slider-bg">
                  <Slider.Range className="absolute h-full rounded-full bg-accent" />
                </Slider.Track>
                <Slider.Thumb className="shadow-blackA4 block h-4 w-4 rounded-[10px] bg-white shadow-[0_2px_10px]" />
                <Slider.Thumb className="shadow-blackA4 block h-4 w-4 rounded-[10px] bg-white shadow-[0_2px_10px]" />
                <span
                  className="absolute -bottom-8 left-[0px] translate-x-[0%]"
                  style={{ left: `${calculatePosition(ageRange[0])}px` }}
                >
                  {ageRange[0]}
                </span>
                <span
                  className="absolute -bottom-8 left-[0px] translate-x-[-100%] pr-4 md:pr-0"
                  style={{ left: `${calculatePosition(ageRange[1])}px` }}
                >
                  {ageRange[1]}
                </span>
              </Slider.Root>
            </div>
          </div>
          <div className="flex flex-col gap-2 pt-8 dark:text-dark-text">
            Team
            <Select
              onSelect={handleTeamSelection}
              options={nflTeamAbbreviations}
              selectedValue={selectedTeam}
            />
          </div>
          <div className="flex flex-col gap-2 dark:text-dark-text">
            Add Expert
            {console.log('creators', creators)}
            <Select
              disabled={subscription_tier === 0 && !is_creator}
              lastUpdated={rookieFilter ? prospectUpdated : fullUpdated}
              onSelect={onCreatorSelect}
              options={creators}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FilterModal
