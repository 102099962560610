import React, { useState } from 'react'
import axios from 'axios'
import InputBox from '../_ui/InputBox'
import { TbPlus } from 'react-icons/tb'

const Sync = () => {
  const [userLeagues, setUserLeagues] = useState([])
  const [username, setUsername] = useState('')

  const querySleeperUsername = async () => {
    try {
      const userRes = await axios(`https://api.sleeper.app/v1/user/${username}`)
      const { user_id } = userRes.data
      const { data } = await axios(
        `https://api.sleeper.app/v1/user/${user_id}/leagues/nfl/2023`
      )
      setUserLeagues(data)
    } catch (err) {
      console.log('ERROR FETCHING : ', err)
    }
  }

  return (
    <div className="flex h-[90vh] flex-col gap-4 bg-light p-10 pb-[10%] font-dmsans text-light-text dark:bg-dark dark:text-dark-text">
      <img
        height={50}
        src="https://static-site-images.s3.us-east-2.amazonaws.com/FF-REGULAR_V6.1.png"
        width={50}
      />
      <div className="flex h-full flex-col justify-center gap-[28px] md:px-[38%]">
        <div className="text-3xl font-bold">Sync your Team</div>
        <div className="flex items-center gap-4">
          <div className="flex flex-col justify-center gap-3">
            <div className="text-md text-light-text2 dark:text-dark-text2">
              Enter your league username to select your leagues
            </div>
            <InputBox
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter Username"
              value={username}
            />
            <button
              className="text-md w-full rounded-xl bg-accent py-3 font-medium text-dark-text"
              onClick={querySleeperUsername}
            >
              Search
            </button>
          </div>
        </div>
        <div className="h-[12rem] overflow-scroll">
          {userLeagues.map((league, i) => {
            return (
              <div key={i}>
                <div className="absolute flex w-[20%] justify-between gap-6 border-t-[1px] border-dark-border py-4">
                  <div>{i + 1}</div>
                  <div className="">{league.name}</div>
                  <TbPlus size={22} />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Sync
