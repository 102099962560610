import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { useDispatch } from 'react-redux'

import { updateUsername } from '../../store/api'
import { setUsername } from '../../store/userReducer'

const ChangeDisplayName = ({
  placeholderDisplayName,
  currentDisplayName,
  saveDisplayName,
  setEditDisplayName,
}) => {
  const dispatch = useDispatch()

  const [usernameErrMsg, setUsernameErrMsg] = useState('')
  const creatorUsernames = new Set([
    'masondodd',
    'fantasyland',
    'FSE',
    'DOMAIN',
  ])

  const handleChangeDisplayName = async () => {
    setUsernameErrMsg('')

    const trimmedDisplayName = currentDisplayName.trim()
    if (trimmedDisplayName.length < 3) {
      setUsernameErrMsg('Username must be at least 3 characters long')
      return
    }

    if (creatorUsernames.has(trimmedDisplayName.toLowerCase())) {
      setUsernameErrMsg('Cant use a creators display name')
      return
    }

    try {
      const user = await Auth.currentAuthenticatedUser()

      await Auth.updateUserAttributes(user, { nickname: trimmedDisplayName })
      await updateUsername({ username: trimmedDisplayName })

      dispatch(setUsername(trimmedDisplayName))
      saveDisplayName(trimmedDisplayName)
    } catch (err) {
      console.error('An error occurred when changing display name:', err)
      setUsernameErrMsg('An error occurred when changing display name')
    }
  }

  return (
    <div className="flex gap-6 text-sm">
      <input
        className="flex-grow rounded-custom border-[1px] border-light2 p-2 text-sm dark:border-dark-border dark:bg-dark"
        onChange={(e) => setEditDisplayName(e.target.value)}
        placeholder={placeholderDisplayName}
        type="text"
        value={currentDisplayName}
      />
      <button
        className="rounded-custom bg-custom-orange p-2 px-8 text-light transition-colors hover:bg-orange-600"
        onClick={handleChangeDisplayName}
      >
        Edit Name
      </button>
      {usernameErrMsg && <p className="mt-2 text-red-500">{usernameErrMsg}</p>}
    </div>
  )
}

export default ChangeDisplayName
