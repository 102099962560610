import React from 'react'
import { ColoredText } from './UtilComponents'

const LeagueSize = ({
  format,
  handleSettings,
  loadingState,
  saveSettings,
  selectHandler,
  selectValue,
  userSettings,
}) => {
  const handleMultiplier = (key, value) => {
    const newState = { ...userSettings }
    newState.size[format][selectValue][key] = value
    handleSettings(newState)
  }

  const numeratorValue = userSettings.size[format][selectValue]?.numerator
  const denominatorValue = userSettings.size[format][selectValue]?.denominator
  const baseValue = userSettings.size[format][selectValue]?.base
  const logValue = userSettings.size[format][selectValue]?.log

  return (
    <div>
      <div>
        <div className="ap-header">
          Player Size: <ColoredText text={`${selectValue} Man League`} />
        </div>

        <div className="ap-user-setting-segment">
          <div>League Size</div>
          <select
            className="ap-user-settings-select"
            onChange={(e) => selectHandler(e.target.value)}
            value={selectValue}
          >
            <option value="8">8</option>
            <option value="10">10</option>
            <option value="12">12</option>
            <option value="14">14</option>
            <option value="16">16</option>
          </select>
        </div>

        <div className="ap-segment">
          <div>
            <div>Scaling Numerator</div>
            <div>
              <input
                className="ap-input"
                onChange={(e) => handleMultiplier('numerator', e.target.value)}
                value={numeratorValue}
              />
            </div>
            <div>Scaling Denomintor</div>
            <div>
              <input
                onChange={(e) =>
                  handleMultiplier('denominator', e.target.value)
                }
                className="ap-input"
                value={denominatorValue}
              />
            </div>
            <div>Base Value</div>
            <div>
              <input
                className="ap-input"
                onChange={(e) => handleMultiplier('base', e.target.value)}
                value={baseValue}
              />
            </div>
            <div>Log Value</div>
            <div>
              <input
                className="ap-input"
                onChange={(e) => handleMultiplier('log', e.target.value)}
                value={logValue}
              />
            </div>
          </div>
          <div>
            <div className="ap-intro-algo">
              <div className="ap-user-value">Base</div>
              <pre>
                scaling_adjustment = (12 -{' '}
                <ColoredText text={'SCALING NUMERATOR'} />) /{' '}
                <ColoredText text={'SCALING DENOMINATOR'} />
              </pre>
              <pre>
                base = <ColoredText text={'BASE_VALUE'} /> + scaling_adjustment
              </pre>
              <pre>
                return base - math.log(player_rank,{' '}
                <ColoredText text={'LOG_VALUE'} />)
              </pre>
            </div>
            <div>
              <div className="ap-user-value">Edited</div>
              <pre>
                scaling_adjustment = (12 - <ColoredText text={numeratorValue} />
                ) / <ColoredText text={denominatorValue} />
              </pre>
              <pre>
                base = <ColoredText text={baseValue} /> + scaling_adjustment
              </pre>
              <pre>
                return base - math.log(player_rank) / math.log(
                <ColoredText text={logValue} />)
              </pre>
            </div>
          </div>
        </div>
      </div>

      <button className="ap-button" onClick={saveSettings}>
        Save
      </button>
      <span className="loading-span">{loadingState}</span>
    </div>
  )
}

export default LeagueSize
