import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { IoPersonSharp } from 'react-icons/io5'
import { MdArrowUpward, MdArrowDownward } from 'react-icons/md'
import { FaSearch } from 'react-icons/fa'
import { Triangle } from 'react-loader-spinner'

import {
  calculateLeague,
  getCreatorLastRankedByFormat as getAllCreatorsLastRankedByFormatAction,
  getUserLeagueProfiles,
  getVouchers,
  getStoreItems,
} from '../../store/api'

import TeamSelect from './TeamSelect'
import CreatorSelect from '../TradeCalc/CreatorSelect'
import SyncTeamsModal from './SyncTeamsModal'
import SignInModal from '../_ui/SignInModal'
import BarChart from '../_ui/BarChart'
import ReviewInfoModal from './ReviewInfoModal'
import SubscriptionModal from '../_ui/SubscriptionModal'

const EXPERT = 'EXPERT'
const COMMUNITY = 'COMMUNITY'
const BEST_BALL = 'BEST_BALL'
const DYNASTY = 'DYNASTY'
const REDRAFT = 'REDRAFT'

// We should do something with these..
const LoadingComponent = () => (
  <Triangle ariaLabel="loading" color="#E97933" height={50} width={50} />
)

const SyncLeagueButton = ({ setIsModalOpen }) => (
  <button
    className="whitespace-nowrap rounded-lg bg-accent px-6 py-3 text-dark-text transition-all duration-200 hover:bg-orange-600"
    onClick={() => setIsModalOpen(true)}
  >
    Sync Your First League
  </button>
)

const EspnSyncRequired = () => (
  <div className="font-bold text-light-text dark:text-dark-text">
    Please make sure to sync your ESPN account with our platform by using our
    browser extension
  </div>
)

const BadLeagueAlert = ({ leagueNotStarted, message }) => {
  if (leagueNotStarted) {
    return (
      <div className="font-bold text-light-text dark:text-dark-text">
        Your league has not started yet
      </div>
    )
  }

  return <EspnSyncRequired />
}

const MyTeams = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const teamName = decodeURIComponent(searchParams.get('team_name') || '')
  const comments = decodeURIComponent(searchParams.get('comments') || '')
  const rookie_picks = decodeURIComponent(searchParams.get('rookie_picks'))
  const leagueIdFromUrl = searchParams.get('league_id')
  const idFromUrl = searchParams.get('id')
  const teamIdFromUrl = searchParams.get('team_id')

  const { is_creator, loggedIn, is_subscribed, is_trialing, user_id } =
    useSelector((state) => state.user)

  const [badLeagueMessage, setBadLeagueMessage] = useState('')
  const [badLeaguePlatform, setBadLeaguePlatform] = useState(null)
  const [creatorList, setCreatorList] = useState([])
  const [hasFetchedLeagues, setHasFetchedLeagues] = useState(false)
  const [teamSelectPlaceholder, setTeamSelectPlaceholder] =
    useState('Select a League')
  const [showInfoModal, setShowInfoModal] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedLeague, setSelectedLeague] = useState(null)
  const [teamNames, setTeamNames] = useState([])
  const [teams, setTeams] = useState([])
  const [transformedData, setTransformedData] = useState([])
  const [leagueData, setLeagueData] = useState({})
  const [accordionOpen, setAccordionOpen] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [leagueSubmitted, setLeagueSubmitted] = useState(false)

  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [selectedPlayer, setSelectedPlayer] = useState(null)
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  })
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(null)
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
  const [hasActiveVouchers, setHasActiveVouchers] = useState(false)
  const [storeItems, setStoreItems] = useState([])

  const defaultUserId =
    is_creator || is_trialing || is_subscribed ? EXPERT : COMMUNITY

  const [selectedExpert, setSelectedExpert] = useState(defaultUserId)

  //---------------------------------------------------------------- Use Effects ------------------------------------------------------------------------------------

  useEffect(() => {
    fetchVouchers()
    fetchStoreItems()
  }, [])

  useEffect(() => {
    //If coming from myReviwes use this fetching function
    if (leagueIdFromUrl) {
      fetchReviewLeagueInfo(leagueIdFromUrl)
    } else {
      //Otherwise use this profile and league fetching function
      //but if user has fetched their selected league already then fetch only league data
      if (selectedLeague) {
        getCalculateLeague(selectedLeague, teams)
      } else {
        fetchLeagueAndProfiles()
      }
    }
  }, [selectedExpert, selectedLeague])

  useEffect(() => {
    if (leagueSubmitted) {
      fetchLeagueAndProfiles()
    }
  }, [leagueSubmitted])

  useEffect(() => {
    if (activeAccordionIndex !== null) {
      const accordionElement = document.getElementById(
        `accordion-${activeAccordionIndex}`
      )
      if (accordionElement) {
        accordionElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }, [activeAccordionIndex])

  useEffect(() => {
    try {
      if (searchQuery?.length > 1) {
        const results = []
        Object.values(leagueData.rosters || {}).forEach((roster) => {
          ;['QB', 'RB', 'WR', 'TE', 'PICKS'].forEach((position) => {
            roster[position].forEach((player) => {
              if (
                player?.player_name
                  ?.toLowerCase()
                  .includes(searchQuery.toLowerCase())
              ) {
                results.push(player)
              }
            })
          })
        })
        setSearchResults(results)
      } else {
        setSearchResults([])
      }
    } catch (error) {
      console.error('Error processing search:', error)
      setSearchResults([])
    }
  }, [searchQuery, leagueData.rosters])

  useEffect(() => {
    if (leagueData?.settings) {
      const { is_best_ball, is_redraft } = leagueData.settings
      let format = DYNASTY
      if (is_best_ball) {
        format = BEST_BALL
      } else if (is_redraft) {
        format = REDRAFT
      }

      getCreatorLastRankByFormat(format)
    }
  }, [leagueData])

  //---------------------------------------------------------------- Data Fetching ------------------------------------------------------------------------------------

  const getCreatorLastRankByFormat = async (format) => {
    try {
      const { data: allCreators } =
        await getAllCreatorsLastRankedByFormatAction(format)

      const communityCreator = {
        user_id: COMMUNITY,
        username: 'Community',
        disabled: false,
      }
      const expertCreator = {
        user_id: EXPERT,
        username: 'Expert',
        disabled: true,
      }

      let updatedCreators = [expertCreator, ...allCreators].map((creator) => ({
        ...creator,
        disabled: false,
      }))

      if (!is_creator && !is_subscribed && !is_trialing) {
        updatedCreators = updatedCreators.map((creator) => ({
          ...creator,
          disabled: true,
        }))
      }

      updatedCreators.unshift(communityCreator)
      setCreatorList(updatedCreators)
    } catch (error) {
      console.error('Failed to fetch creators:', error)
      toast.error('Failed to fetch creators.')
    }
  }

  const fetchLeagueAndProfiles = async () => {
    setIsLoading(true)
    try {
      const profiles = await getUserLeagueProfiles()
      setIsLoading(false)

      if (profiles?.length > 0) {
        const initialLeagueId = profiles[0].league_id
        setSelectedLeague(initialLeagueId)

        const names = profiles.map((profile) => ({
          name: profile.league_name,
          id: profile.league_id,
          platform: profile.platform || null,
        }))

        setTeams(profiles)
        setTeamNames(names)

        const matchingProfile = profiles.find(
          (profile) => profile.league_id === initialLeagueId
        )
        if (matchingProfile) {
          setTeamSelectPlaceholder(matchingProfile.league_name)
        } else {
          setTeamSelectPlaceholder('Select a League')
        }

        setHasFetchedLeagues(true)
      } else {
        setHasFetchedLeagues(false)
        toast('Please link a profile to view your teams.')
      }
    } catch (error) {
      console.error('Failed to fetch profiles:', error)
      toast.error('Failed to fetch profiles.')
      setIsLoading(false)
    }
  }

  const fetchReviewLeagueInfo = async (leagueId) => {
    setIsLoading(true)
    setSelectedLeague(leagueId)

    try {
      const profiles = await getUserLeagueProfiles()
      const calcData = await calculateLeague({
        creatorId: selectedExpert,
        leagueId,
      })

      if (!Array.isArray(profiles)) {
        throw new Error('profiles is not an array')
      }

      const mapData =
        typeof rookie_picks === 'string' && rookie_picks.length > 5
          ? replaceRookiePicks(calcData)
          : calcData
      setLeagueData(mapData)

      setTeams(profiles)

      const teamNames = profiles.map((profile) => ({
        name: profile.league_name,
        id: profile.league_id,
        platform: profile.platform || null,
      }))
      setTeamNames(teamNames)

      const userMap = createUserMap(mapData.settings.users)
      if (typeof userMap !== 'object' || userMap === null) {
        throw new Error('userMap is not an object')
      }

      const transformedData = transformDataForBarChart(
        addPositionalRanks(mapData),
        userMap
      )
      setTransformedData(transformedData)
    } catch (error) {
      console.error('Failed to fetch league info:', error)

      if (error.message.includes('profiles')) {
        toast.error(
          'Failed to fetch user league profiles. Please check your network connection.'
        )
      } else if (error.message.includes('calculateLeague')) {
        toast.error(
          'Failed to calculate league data. Please verify your league ID and creator ID.'
        )
      } else if (error.message.includes('transformDataForBarChart')) {
        toast.error(
          'Failed to transform data for bar chart. Please check the data format.'
        )
      } else {
        toast.error('An unexpected error occurred. Please try again later.')
      }

      setBadLeagueMessage(error.message || 'Failed to load league data')
    } finally {
      setIsLoading(false)
      setHasFetchedLeagues(true)
    }
  }

  const replaceRookiePicks = (leagueData) => {
    const rookiePicksArray = rookie_picks.split(', ')

    const rookiePicksObjects = rookiePicksArray.map((pick) => ({
      player_name: pick,
    }))

    const rosters = leagueData.rosters
    if (rosters[teamIdFromUrl]) {
      rosters[teamIdFromUrl].PICKS = rookiePicksObjects
    } else {
      console.log(`Team with ID ${teamIdFromUrl} not found.`)
    }

    return leagueData
  }

  const fetchVouchers = async () => {
    try {
      const response = await getVouchers(user_id)
      const vouchersData = response.data

      const hasActiveVouchers = vouchersData.some(
        (voucher) => voucher.status === 'available'
      )

      setHasActiveVouchers(hasActiveVouchers)
    } catch (error) {
      console.error('Failed to fetch vouchers:', error)
    }
  }

  const fetchStoreItems = async () => {
    try {
      const response = await getStoreItems()
      setStoreItems(response)
    } catch (error) {
      console.error(error)
    }
  }

  const getCalculateLeague = async (leagueId, profiles, profile = null) => {
    setIsLoading(true)
    try {
      const selectedLeague = teams.find((team) => {
        return team.league_id === leagueId
      })
      const platform = selectedLeague?.platform || 'SLEEPER'

      const payload = { creatorId: selectedExpert, leagueId, platform }

      const data = await calculateLeague(payload)
      setLeagueData(data)

      if (data && data.values) {
        const selectedProfile =
          profile || profiles.find((p) => p.league_id === leagueId)

        if (selectedProfile && Array.isArray(selectedProfile.users)) {
          if (badLeagueMessage?.length > 0) {
            setBadLeagueMessage('')
          }
          const userMap = createUserMap(selectedProfile.users)
          const positionalData = addPositionalRanks(data)
          const transformed = transformDataForBarChart(positionalData, userMap)

          setTransformedData(transformed)
          setIsLoading(false)
        } else {
          console.warn('No matching user profile found or missing users array')
          setBadLeagueMessage('We were unable to process your league')
        }
      } else {
        setIsLoading(false)
        setBadLeagueMessage(
          'Your league hasnt started or doesnt have any data to display'
        )
      }
    } catch (error) {
      console.error('Failed to calculate league:', error)
      const serverMessage =
        error.response?.data?.message ||
        'Your league hasnt started or doesnt have any data to display'

      const platformError = error.response?.data?.platform || null
      console.log('ERROR PLATFORM : ', error.response?.data, platformError)

      setIsLoading(false)
      setBadLeaguePlatform(platformError)
      setBadLeagueMessage(serverMessage)
    }
  }

  //---------------------------------------------------------------- Utility Functions ------------------------------------------------------------------------------------

  const handleSortChange = (selectedOption) => {
    const leagueId =
      typeof selectedOption === 'object' ? selectedOption.id : selectedOption
    setSelectedLeague(leagueId)

    const selectedLeagueName =
      teamNames.find((team) => team.id === leagueId)?.name || 'Select a League'
    setTeamSelectPlaceholder(selectedLeagueName)
  }

  const toggleInfoModal = () => setShowInfoModal(!showInfoModal)
  const showModal = () => {
    return (teamName.trim() !== '' || comments.trim() !== '') && showInfoModal
  }

  const findTeamNameByTeamId = (input) => {
    const decodedInput = decodeURIComponent(input.trim())
    const user = leagueData.settings?.users?.find(
      (user) =>
        user.user_id === decodedInput ||
        user.team_name?.trim() === decodedInput ||
        user.display_name?.trim() === decodedInput
    )
    return user ? user.team_name || user.display_name : 'Team not found'
  }

  const addNewTeam = () => {
    setIsModalOpen(true)
  }

  const toggleAccordion = (index) => {
    setAccordionOpen((prev) => ({
      ...prev,
      [index]: !prev[index],
    }))
  }

  const handleSelectPlayer = (selectedPlayer) => {
    let foundTeamId = null

    Object.entries(leagueData.rosters || {}).forEach(([teamId, roster]) => {
      ;['QB', 'RB', 'WR', 'TE', 'PICKS'].forEach((position) => {
        roster[position].forEach((player) => {
          if (player.player_name === selectedPlayer.player_name) {
            foundTeamId = teamId
          }
        })
      })
    })

    if (foundTeamId !== null) {
      const foundTeamIndex = transformedData.findIndex(
        (team) => team.teamId === foundTeamId
      )
      setActiveAccordionIndex(foundTeamIndex)

      if (foundTeamIndex !== -1) {
        setAccordionOpen({
          ...Object.keys(accordionOpen).reduce(
            (acc, key) => ({ ...acc, [key]: false }),
            {}
          ), // Optional: close all other accordions
          [foundTeamIndex]: true,
        })
      }
    }
    setSelectedPlayer(selectedPlayer)
    setSearchQuery('')
  }

  const getColorForRank = (rank) => {
    if (rank <= 10) {
      return 'text-green-500'
    }
    if (rank <= 20) {
      return 'text-green-400'
    }
    if (rank <= 30) {
      return 'text-yellow-400'
    }
    if (rank <= 40) {
      return 'text-yellow-500'
    }
    if (rank <= 50) {
      return 'text-yellow-600'
    }
    if (rank <= 60) {
      return 'text-orange-400'
    }
    if (rank <= 70) {
      return 'text-orange-500'
    }
    if (rank <= 80) {
      return 'text-orange-600'
    }
    if (rank <= 90) {
      return 'text-orange-700'
    }
    return 'text-red-500'
  }

  const columnsConfig = [
    { title: 'QB Rank', sortKey: 'QB_rank', width: '10%', minWidth: '80px' },
    { title: 'RB Rank', sortKey: 'RB_rank', width: '10%', minWidth: '80px' },
    { title: 'WR Rank', sortKey: 'WR_rank', width: '10%', minWidth: '80px' },
    { title: 'TE Rank', sortKey: 'TE_rank', width: '10%', minWidth: '80px' },
    {
      title: 'PICKS Rank',
      sortKey: 'PICKS_rank',
      width: '10%',
      minWidth: '80px',
    },
    {
      title: 'Overall',
      sortKey: 'Overall_rank',
      width: '10%',
      minWidth: '80px',
    },
  ]

  const transformDataForBarChart = (leagueData, userMap) => {
    if (!leagueData || typeof leagueData !== 'object' || !leagueData.values) {
      toast.error('Invalid league data provided')
      console.error('Invalid leagueData:', leagueData)
      return []
    }

    if (!userMap || typeof userMap !== 'object') {
      toast.error('Invalid user map provided')
      console.error('Invalid userMap:', userMap)
      return []
    }

    const { values } = leagueData

    try {
      const teamsWithOverall = Object.keys(values)
        .map((teamId) => {
          const user = userMap[teamId]

          if (!user) {
            console.warn(`User not found for teamId: ${teamId}`)
            toast.warning(`User not found for teamId: ${teamId}`)
            return null
          }

          return {
            teamId,
            teamName: user.teamName || 'Unknown Team',
            teamImage: user.teamImage || 'default_image.png',
            Overall: values[teamId].Overall ?? 0,
            QB_rank: values[teamId].QB?.rank ?? 'N/A',
            RB_rank: values[teamId].RB?.rank ?? 'N/A',
            TE_rank: values[teamId].TE?.rank ?? 'N/A',
            WR_rank: values[teamId].WR?.rank ?? 'N/A',
            PICKS_rank: values[teamId].PICKS?.rank ?? 'N/A',
          }
        })
        .filter((team) => team !== null)

      const sortedTeams = teamsWithOverall.sort((a, b) => b.Overall - a.Overall)

      return sortedTeams.map((team, index) => ({
        team: team.teamName,
        teamId: team.teamId,
        teamImage: team.teamImage,
        QB: values[team.teamId].QB?.value || 0,
        RB: values[team.teamId].RB?.value || 0,
        TE: values[team.teamId].TE?.value || 0,
        WR: values[team.teamId].WR?.value || 0,
        PICKS: values[team.teamId].PICKS?.value || 0,
        Overall: team.Overall,
        Overall_rank: index + 1,
        QB_rank: team.QB_rank,
        RB_rank: team.RB_rank,
        TE_rank: team.TE_rank,
        WR_rank: team.WR_rank,
        PICKS_rank: team.PICKS_rank,
      }))
    } catch (error) {
      toast.error('Error transforming data for bar chart')
      console.error('Error transforming data for bar chart:', error)
      return []
    }
  }

  const addPositionalRanks = (leagueData) => {
    Object.entries(leagueData.values).forEach(([teamId, teamValues]) => {
      ;['QB', 'RB', 'TE', 'WR', 'PICKS'].forEach((position) => {
        if (teamValues[position]) {
          teamValues[position].positional_rank = teamValues[position].rank
        }
      })
    })
    return leagueData
  }

  const createUserMap = (users) => {
    const userMap = {}
    users.forEach((user) => {
      const teamName = user.team_name || user.display_name
      userMap[user.user_id] = {
        teamName: teamName,
        teamImage: user.team_image || '',
        displayName: user.display_name,
        userId: user.user_id,
      }
    })
    return userMap
  }

  const sortedData = useMemo(() => {
    let sortableItems = [...transformedData]
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1
        }
        return 0
      })
    }
    return sortableItems
  }, [transformedData, sortConfig])

  const requestSort = (key) => {
    let direction = 'ascending'
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending'
    } else if (
      sortConfig.key === key &&
      sortConfig.direction === 'descending'
    ) {
      direction = 'ascending'
    }
    setSortConfig({ key, direction })
  }

  const SortableHeader = ({
    title,
    sortKey,
    onSort,
    sortConfig,
    width,
    minWidth,
  }) => {
    const isActive = sortConfig.key === sortKey
    const Icon = isActive
      ? sortConfig.direction === 'ascending'
        ? MdArrowUpward
        : MdArrowDownward
      : null

    return (
      <th
        className="cursor-pointer pb-2"
        onClick={() => onSort(sortKey)}
        style={{ width, minWidth }}
      >
        <div className="flex items-center justify-center space-x-2">
          <span>{title}</span>
          {Icon && <Icon size={16} />}
        </div>
      </th>
    )
  }

  const leagueNotStarted = transformedData.every((team) => team.Overall === 0)

  return (
    <div className="flex flex-col items-center gap-4 bg-light px-2 py-16 font-dmsans dark:bg-dark lg:px-[5%] xl:px-[15%]">
      <div className="w-full items-center justify-between md:flex">
        <div className="items-center gap-10 md:flex">
          <h3 className="text-lg font-bold text-light-text dark:text-dark-text md:text-center lg:text-3xl">
            My Teams <span className="text-xl italic text-accent">Beta</span>
          </h3>
          {!leagueIdFromUrl && (
            <TeamSelect
              onAddNewTeam={addNewTeam}
              onSelect={handleSortChange}
              options={teamNames}
              placeholder={teamSelectPlaceholder}
              refreshLeagues={fetchLeagueAndProfiles}
            />
          )}
        </div>
        {teamName.trim() ? (
          <button
            className="flex rounded-lg border-[1px] border-light2 p-3 text-dark-text dark:border-dark-border"
            onClick={toggleInfoModal}
          >
            Review Details
          </button>
        ) : null}
        <CreatorSelect
          options={creatorList.map((creator) => ({
            ...creator,
            isDisabled: creator.isDisabled,
          }))}
          onChange={setSelectedExpert}
          value={selectedExpert}
        />
      </div>
      <div className="flex min-h-[65vh] w-[100vw] items-center justify-center overflow-x-auto rounded-xl bg-light-bg py-4 dark:bg-dark lg:w-full">
        {isLoading ? (
          <LoadingComponent />
        ) : badLeagueMessage?.length > 0 || leagueNotStarted ? (
          <BadLeagueAlert
            leagueNotStarted={leagueNotStarted}
            message={badLeagueMessage}
            platform={badLeaguePlatform}
          />
        ) : teamNames?.length > 0 && transformedData?.length > 0 ? (
          <BarChart data={transformedData} />
        ) : (
          <SyncLeagueButton setIsModalOpen={setIsModalOpen} />
        )}
      </div>

      <div className="w-full items-center justify-between md:flex">
        <div className="flex w-full items-center gap-6">
          <span className="text-overflow-ellipsis flex justify-center overflow-hidden whitespace-nowrap rounded-full border-0 bg-accent px-4 py-2 text-dark-text">
            {leagueData.settings?.league_size ?? ''} Team
          </span>
          <span className="text-overflow-ellipsis flex justify-center overflow-hidden whitespace-nowrap rounded-full border-0 bg-accent px-4 py-2 text-dark-text">
            {leagueData.settings?.scoring_description ?? 'Scoring'}
          </span>
          <span className="text-overflow-ellipsis flex justify-center overflow-hidden whitespace-nowrap rounded-full border-0 bg-accent px-4 py-2 text-dark-text">
            Start {leagueData.settings?.num_starters ?? ''}
          </span>
          <span className="text-overflow-ellipsis flex justify-center overflow-hidden whitespace-nowrap rounded-full border-0 bg-accent px-4 py-2 text-dark-text">
            {leagueData.settings?.is_superflex ? 'SF' : 'NON-SF'}
          </span>
          <span className="text-overflow-ellipsis flex justify-center overflow-hidden whitespace-nowrap rounded-full border-0 bg-accent px-4 py-2 text-dark-text">
            {leagueData.settings?.is_te_premium ? 'TE +' : 'NON-TE +'}
          </span>
        </div>
        <button
          className="mt-3 flex w-56 items-center justify-center gap-2 whitespace-nowrap rounded-lg bg-accent px-6 py-3 text-dark-text transition-all duration-200 hover:bg-orange-600 md:mt-0"
          onClick={() => navigate('/purchase/review')}
        >
          Submit for review
        </button>
      </div>
      <hr className="h-[1px] w-full border-t border-light2 dark:border-dark-border" />
      <div className="flex w-full flex-col items-center justify-between md:flex-row">
        <div className="flex h-[43px] w-full items-center gap-2 rounded-[10px] bg-light2 px-4 dark:bg-[#FFFFFF1A] md:w-[20rem]">
          <button className="text-zinc-600 hover:text-zinc-900" type="submit">
            <FaSearch className="fill-light-text2 dark:fill-dark-text2" />
          </button>
          <input
            className="mr-3 w-full appearance-none border-none bg-transparent px-2 leading-tight text-light-text focus:outline-none dark:text-dark-text"
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search players..."
            type="text"
            value={searchQuery}
          />
        </div>
        {searchResults?.length > 0 && (
          <ul className="border-light-border absolute top-full z-[12] mt-2 max-h-[300px] w-[20rem] cursor-pointer overflow-auto rounded-md border-[1px] bg-light shadow-lg dark:border-dark-border dark:bg-dark">
            {' '}
            {/* Adjust the width here to match the input */}
            {searchResults.map((player, index) => (
              <li
                className="group flex items-center p-2 px-4 text-light-text transition-all duration-300 hover:bg-zinc-300 dark:text-dark-text hover:dark:bg-zinc-800"
                key={index}
                onClick={() => handleSelectPlayer(player)}
              >
                {player.player_name}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="w-full overflow-x-auto">
        <table className="w-full text-left">
          <thead className="pb-2">
            <tr className="text-xs font-medium text-light-text2 dark:text-dark-text2">
              <th className="w-20% pb-2">Team</th>
              {columnsConfig.map((column) => (
                <SortableHeader
                  key={column.sortKey}
                  onSort={requestSort}
                  sortConfig={sortConfig}
                  sortKey={column.sortKey}
                  title={column.title}
                  width={column.width}
                />
              ))}
            </tr>
          </thead>

          <tbody className="text-light-text dark:text-dark-text">
            {sortedData.map((team, index) => (
              <React.Fragment key={index}>
                <tr
                  className="cursor-pointer border-t-[1px] border-light2 dark:border-dark-border"
                  id={`accordion-${index}`}
                  onClick={() => toggleAccordion(index)}
                >
                  <td className="flex max-w-[100px] flex-col items-center overflow-hidden py-4 text-center text-sm md:max-w-none md:flex-row md:text-lg">
                    {team.teamImage ? (
                      <img
                        alt="Team"
                        className="h-12 w-12 rounded-full md:mr-4"
                        src={team.teamImage}
                      />
                    ) : (
                      <IoPersonSharp className="h-12 w-12 rounded-full md:mr-4" />
                    )}
                    <span className="break-normal">{team.team}</span>
                  </td>
                  <td className="py-4 text-center">{team.QB_rank}</td>
                  <td className="py-4 text-center">{team.RB_rank}</td>
                  <td className="py-4 text-center">{team.WR_rank}</td>
                  <td className="py-4 text-center">{team.TE_rank}</td>
                  <td className="py-4 text-center">{team.PICKS_rank}</td>
                  <td className="py-4 text-center">{team.Overall_rank}</td>
                </tr>
                {accordionOpen[index] && (
                  <tr>
                    <td className="w-full" colSpan="7">
                      <div className="flex flex-col items-center gap-8 py-4 md:flex-row md:items-start md:justify-evenly">
                        {leagueData.rosters &&
                          leagueData.rosters[team.teamId] &&
                          ['QB', 'RB', 'WR', 'TE', 'PICKS'].map((position) => (
                            <div className="mb-4 w-full" key={position}>
                              <div className="flex justify-between text-accent">
                                <h3 className="text-lg font-bold">
                                  {position}
                                </h3>
                                {leagueData.values[team.teamId][position].rank}
                              </div>
                              <div className="mb-3 border-t-[1px] border-light2 dark:border-dark-border" />
                              <ul>
                                {leagueData.rosters[team.teamId][position]
                                  .sort((a, b) => {
                                    const rankA = a.positional_rank || Infinity
                                    const rankB = b.positional_rank || Infinity
                                    return rankA - rankB
                                  })
                                  .map((player, playerIndex) => (
                                    <li
                                      className={`flex justify-between gap-8 ${selectedPlayer && player.player_name === selectedPlayer.player_name ? 'text-accent' : ''}`}
                                      key={playerIndex}
                                    >
                                      <span>{player.player_name}</span>
                                      <span
                                        className={`w-[30px] ${getColorForRank(player.positional_rank)}`}
                                      >
                                        {player.positional_rank || '-'}
                                      </span>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          ))}
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <SyncTeamsModal
        onLeagueSubmitted={() => {
          setLeagueSubmitted((prev) => !prev)
        }}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      {!loggedIn && <SignInModal />}
      {showModal() && (
        <ReviewInfoModal
          comments={comments}
          id={idFromUrl}
          teamName={findTeamNameByTeamId(teamIdFromUrl) || teamName}
        />
      )}
      {showSubscriptionModal && (
        <SubscriptionModal
          isOpen={showSubscriptionModal}
          onClose={() => setShowSubscriptionModal(false)}
        />
      )}
    </div>
  )
}

export default MyTeams
