import React from 'react'
import { FaPlus, FaMinus } from 'react-icons/fa'
import { FaQuestion, FaStar, FaXmark } from 'react-icons/fa6'

const LineupPlayers = ({
  position,
  player,
  onClick,
  onRemoveClick,
  isInactive = false,
  isBench = false,
  isContender = false,
  markContender,
  removeFromLineup,
  disableX = false,
}) => {
  const bgColorClass = (() => {
    switch (position) {
      case 'QB':
        return 'bg-accent-bg-green'
      case 'RB':
        return 'bg-accent-bg-purple'
      case 'WR':
        return 'bg-accent-bg-red'
      case 'TE':
        return 'bg-accent-bg-cyan'
      default:
        return 'bg-accent bg-opacity-50'
    }
  })()

  const borderColorClass = (() => {
    switch (position) {
      case 'QB':
        return 'border-accent-green'
      case 'RB':
        return 'border-accent-purple'
      case 'WR':
        return 'border-accent-red'
      case 'TE':
        return 'border-accent-cyan'
      default:
        return 'border-accent'
    }
  })()

  return (
    <div className="group relative z-0 h-32 w-32" onClick={onClick}>
      <div
        className={`flex h-full w-full justify-center rounded-lg border-[1px] p-2 text-center ${bgColorClass} ${borderColorClass}`}
      >
        {player?.player_id ? (
          <div className="flex flex-col items-center">
            <img
              alt={player.player_name}
              className={`player-picture h-16 w-16 rounded-full border-[1px] bg-dark object-cover ${borderColorClass}`}
              src={`https://flock-player-images-2.s3.us-east-2.amazonaws.com/${player.image || player.picture}`}
            />
            {player.player_name}
          </div>
        ) : (
          <span className="flex flex-col justify-center text-xl font-bold">
            {position}
          </span>
        )}
      </div>
      {!isInactive && (
        <div className="absolute inset-0 flex items-center justify-center rounded-lg bg-black bg-opacity-50 opacity-0 backdrop-blur-md transition-opacity duration-300 group-hover:opacity-100">
          {isBench && !disableX ? (
            <FaXmark className="text-white" size={26} />
          ) : player?.player_id && !isBench ? (
            <FaMinus
              onClick={(e) => {
                e.stopPropagation()
                onRemoveClick()
              }}
              className="text-white"
              size={26}
            />
          ) : (
            <FaPlus className="text-white" size={26} />
          )}
        </div>
      )}
      {!player?.player_id && (
        <button
          onClick={(e) => {
            e.stopPropagation()
            removeFromLineup()
          }}
          className={`${isContender ? 'flex' : 'hidden'} absolute right-0 top-0 h-10 w-10 -translate-y-2 translate-x-2 items-center justify-center rounded-full border-[1px] border-light2 bg-accent transition-all duration-200 ${!isInactive && 'hover:bg-opacity-60 group-hover:flex'} dark:border-dark-border`}
          title="Mark as contender"
        >
          <FaXmark />
        </button>
      )}
      {isBench && (
        <button
          onClick={(e) => {
            e.stopPropagation()
            markContender()
          }}
          className={`${isContender ? 'flex' : 'hidden'} absolute right-0 top-0 h-10 w-10 -translate-y-2 translate-x-2 items-center justify-center rounded-full border-[1px] border-light2 bg-accent transition-all duration-200 ${!isInactive && 'hover:bg-opacity-60 group-hover:flex'} dark:border-dark-border`}
          title="Mark as contender"
        >
          <FaStar />
        </button>
      )}
    </div>
  )
}

export default LineupPlayers
