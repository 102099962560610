import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import { Auth } from 'aws-amplify'
import { useDispatch, useSelector } from 'react-redux'
import { IoMenu, IoClose } from 'react-icons/io5'
import { RiCoinsLine } from 'react-icons/ri'

import { FaDiscord } from 'react-icons/fa6'
import { useNavigate, useLocation } from 'react-router-dom'
import { getVouchers } from '../../store/api'

import ThemeToggle from '../Theme/ThemeToggle'
import { logout as logoutAction } from '../../store/userReducer'
import avatar from '../../images/player_avatar.svg'

// const BEST_BALL = 'BEST_BALL'
const REDRAFT = 'REDRAFT'
const SUPERFLEX = 'SUPERFLEX'
const ONEQB = 'ONEQB'

const TC_SETTINGS = 'Trade Calc Settings'
const ELO_MANAGER = 'ELO Manager'
const ADMIN_SETTINGS = [TC_SETTINGS, ELO_MANAGER]

const Navbar = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const { is_creator, loggedIn, role, username, user_id, is_contributor } =
    useSelector((state) => state.user)

  const [vouchers, setVouchers] = useState([])
  const [unredeemedVoucherCount, setUnredeemedVoucherCount] = useState(0)

  const [selectedItem, setSelectedItem] = useState('Posts')
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [isDesktopDropdownOpen, setIsDesktopDropdownOpen] = useState(false)
  const [isMobileNestedDropdownOpen, setIsMobileNestedDropdownOpen] =
    useState(false)
  const [isCreatorDropdownOpen, setIsCreatorDropdownOpen] = useState(false)
  const [isTradeDropdownOpen, setIsTradeDropdownOpen] = useState(false)
  const [isMobileTradeDropdownOpen, setIsMobileTradeDropdownOpen] =
    useState(false)
  const [isTeamDropdownOpen, setIsTeamDropdownOpen] = useState(false)
  const [isMobileTeamDropdownOpen, setIsMobileTeamDropdownOpen] =
    useState(false)
  const [showAccountDropdown, setShowAccountDropdown] = useState(false)

  const desktopDropdownRef = useRef(null)
  const mobileNestedDropdownRef = useRef(null)
  const accountDropdownRef = useRef(null)
  const mobileMenuRef = useRef(null)
  const creatorDropdownRef = useRef(null)
  const tradeDropdownRef = useRef(null)
  const mobileTradeDropdownRef = useRef(null)
  const teamDropdownRef = useRef(null)
  const mobileTeamDropdownRef = useRef(null)

  useEffect(() => {
    fetchVouchers()
  }, [])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        desktopDropdownRef.current &&
        !desktopDropdownRef.current.contains(event.target)
      ) {
        setIsDesktopDropdownOpen(false)
      }
      if (
        mobileNestedDropdownRef.current &&
        !mobileNestedDropdownRef.current.contains(event.target)
      ) {
        setIsMobileNestedDropdownOpen(false)
      }
      if (
        accountDropdownRef.current &&
        !accountDropdownRef.current.contains(event.target)
      ) {
        setShowAccountDropdown(false)
      }
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target)
      ) {
        setIsMobileMenuOpen(false)
      }
      if (
        creatorDropdownRef.current &&
        !creatorDropdownRef.current.contains(event.target)
      ) {
        setIsCreatorDropdownOpen(false)
      }
      if (
        tradeDropdownRef.current &&
        !tradeDropdownRef.current.contains(event.target)
      ) {
        setIsTradeDropdownOpen(false)
      }
      if (
        mobileTradeDropdownRef.current &&
        !mobileTradeDropdownRef.current.contains(event.target)
      ) {
        setIsMobileTradeDropdownOpen(false)
      }
      if (
        teamDropdownRef.current &&
        !teamDropdownRef.current.contains(event.target)
      ) {
        setIsTeamDropdownOpen(false)
      }
      if (
        mobileTeamDropdownRef.current &&
        !mobileTeamDropdownRef.current.contains(event.target)
      ) {
        setIsMobileTeamDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    const pathToItemMap = {
      '/': 'Posts',
      '/team': 'About Us',
      '/trade': 'trade',
      '/trade-finder': 'trade',
      '/my-teams': 'team',
      '/my-reviews': 'team',
      '/rankings/': 'Rankings',
      '/purchase': 'Purchase',
    }

    let selectedItem = 'Posts'

    const isCreatorRoute = Object.values(creatorRoutes).includes(
      location.pathname
    )

    if (isCreatorRoute) {
      selectedItem = 'Creator'
    } else {
      selectedItem = pathToItemMap[location.pathname] || 'Posts'
    }

    setSelectedItem(selectedItem)
  }, [location.pathname])

  const creatorRoutes = {
    'Create Post': '/post/create',
    'Edit Post': '/post/manage',
    'Create Ranking': '/creator/rankings',
    'Player Profile': '/player-card/create',
    Income: '/creator/income',
    [TC_SETTINGS]: '/admin',
    [ELO_MANAGER]: '/eloManager',
  }

  const closeAllDropdowns = () => {
    setIsMobileMenuOpen(false)
    setIsDesktopDropdownOpen(false)
    setIsMobileNestedDropdownOpen(false)
    setIsCreatorDropdownOpen(false)
    setIsTradeDropdownOpen(false)
    setIsMobileTradeDropdownOpen(false)
    setIsTeamDropdownOpen(false)
    setIsMobileTeamDropdownOpen(false)
    setShowAccountDropdown(false)
  }

  const fetchVouchers = async () => {
    try {
      const response = await getVouchers(user_id)
      if (response?.data?.length > 0) {
        setVouchers(response.data)
        const unredeemedCount = response.data.filter(
          (voucher) => voucher.status !== 'redeemed'
        ).length
        setUnredeemedVoucherCount(unredeemedCount)
      } else {
        console.error('Data fetched is not an array:', response.data)
        setVouchers([])
        setUnredeemedVoucherCount(0)
      }
    } catch (error) {
      console.error('Failed to fetch vouchers:', error)
      setVouchers([])
      setUnredeemedVoucherCount(0)
    }
  }

  const selectItem = (item) => {
    closeAllDropdowns()

    if (creatorRoutes[item]) {
      navigate(creatorRoutes[item])
    } else {
      switch (item) {
        case 'Posts':
          navigate('/')
          break
        case 'About Us':
          navigate('/team')
          break
        case 'trade':
          navigate('/trade')
          break
        case 'TradeFinder':
          navigate('/trade-finder')
          break
        case 'My Teams':
          navigate('/my-teams')
          break
        case 'My Reviews':
          navigate('/my-reviews')
          break
        case 'Rankings':
          break
        case 'Purchase':
          navigate('/purchase')
          break
        default:
          console.log(`No route defined for ${item}`)
      }
    }
    setSelectedItem(item)
  }

  const routeToRankings = (format) => {
    let routeParam = ''
    let queryParam = ''
    switch (format) {
      case SUPERFLEX:
        queryParam = 'format=superflex'
        break
      case ONEQB:
        queryParam = 'format=qb'
        break
      case REDRAFT:
        queryParam = 'format=year'
        break
      default:
        console.log('Invalid ranking type')
        break
    }
    setSelectedItem('Rankings')
    navigate(`/rankings/${routeParam}?${queryParam}`)
    closeAllDropdowns()
  }

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  const toggleAccountDropdown = () => {
    setShowAccountDropdown(!showAccountDropdown)
  }

  const toggleDesktopDropdown = () => {
    setIsDesktopDropdownOpen(!isDesktopDropdownOpen)
  }

  const toggleCreatorDropdown = () => {
    setIsCreatorDropdownOpen(!isCreatorDropdownOpen)
  }

  const toggleTradeDropdown = () => {
    setIsTradeDropdownOpen(!isTradeDropdownOpen)
  }

  const toggleMobileTradeDropdown = () => {
    setIsTradeDropdownOpen(!isMobileTradeDropdownOpen)
  }

  const toggleTeamDropdown = () => {
    setIsTeamDropdownOpen(!isTeamDropdownOpen)
  }

  const toggleMobileTeamDropdown = () => {
    setIsTeamDropdownOpen(!isMobileTradeDropdownOpen)
  }

  const isSelected = (item) => {
    return selectedItem === item
      ? 'bg-[#EAEAEA] py-2 px-6 rounded-full text-light-text dark:bg-dark-bg2 dark:text-dark-text font-bold text-sm'
      : ''
  }

  async function linkDiscord() {
    window.location =
      'https://discord.com/api/oauth2/authorize?client_id=1087443675136663605&redirect_uri=https%3A%2F%2Fflockfantasy.com%2Faccount&response_type=code&scope=identify%20guilds.join%20guilds%20guilds.members.read'
  }

  const handleLogout = async () => {
    await Auth.signOut({ global: true })
    delete axios.defaults.headers.common['Authorization']
    localStorage.removeItem(process.env.REACT_APP_FLOCK_TOKEN)
    dispatch(logoutAction())
    navigate('/')
  }

  const handleLogoClick = () => {
    navigate('/')
  }

  return (
    <div className="flex h-20 items-center justify-between border-b border-gray-300 bg-white px-4 font-epilogue text-sm dark:border-dark-border dark:bg-dark md:px-10">
      <div
        className="rounded-custom border-[1px] border-light2 p-2 dark:border-dark-border lg:hidden"
        onClick={toggleMobileMenu}
      >
        {isMobileMenuOpen ? (
          <IoClose className="fill-dark dark:fill-light" size={22} />
        ) : (
          <IoMenu className="text-dark dark:text-light" size={22} />
        )}
      </div>
      <div
        className="cursor-pointer text-2xl font-bold text-accent"
        onClick={handleLogoClick}
      >
        FLOCK
      </div>

      {/* Desktop Menu - Hidden on mobile */}
      <div className="hidden items-center gap-12 pl-24 font-dmsans text-light-text2 lg:flex">
        <span
          className={`cursor-pointer ${isSelected('Posts')}`}
          onClick={() => selectItem('Posts')}
        >
          Posts
        </span>
        {is_creator && (
          <div className="relative hidden md:block" ref={creatorDropdownRef}>
            <button
              className={`font-bold text-light-text dark:text-dark-text ${isSelected(
                'Creator'
              )}`}
              onClick={toggleCreatorDropdown}
            >
              Creator
            </button>
            {isCreatorDropdownOpen && (
              <div className="absolute left-1/2 top-[45px] z-10 mt-1 w-[10rem] -translate-x-1/2 rounded-b-lg border-[1px] border-dark-text2 bg-light p-2 text-light-text shadow-lg dark:border-dark-border dark:bg-dark dark:text-dark-text">
                {Object.keys(creatorRoutes).map((option) => {
                  if (role !== 2 && ADMIN_SETTINGS.indexOf(option) > -1) {
                    return null
                  }
                  return (
                    <span
                      className="block cursor-pointer rounded-md border-0 px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-dark-bg2"
                      key={option}
                      onClick={() => selectItem(option)}
                    >
                      {option}
                    </span>
                  )
                })}
              </div>
            )}
          </div>
        )}
        <div className="relative inline-block" ref={desktopDropdownRef}>
          <span
            className={`cursor-pointer ${isSelected('Rankings')}`}
            onClick={toggleDesktopDropdown}
          >
            Rankings
          </span>
          {isDesktopDropdownOpen && (
            <div className="absolute left-1/2 top-[45px] z-[100] mt-1 flex w-40 -translate-x-1/2 transform cursor-pointer flex-col gap-4 rounded-b-custom border-[1px] border-t-0 border-light2 bg-light p-2 text-light-text dark:border-dark-border dark:bg-dark dark:text-dark-text">
              <div onClick={() => routeToRankings(REDRAFT)}>Redraft</div>
              <div onClick={() => routeToRankings(SUPERFLEX)}>
                Dynasty SuperFlex
              </div>
              <div onClick={() => routeToRankings(ONEQB)}>Dynasty 1QB</div>
            </div>
          )}
        </div>
        <div className="relative inline-block" ref={tradeDropdownRef}>
          <span
            className={`cursor-pointer ${isSelected('trade')}`}
            onClick={toggleTradeDropdown}
          >
            Trade
          </span>
          {isTradeDropdownOpen && (
            <div className="absolute left-1/2 top-[45px] z-[100] mt-1 flex w-40 -translate-x-1/2 transform cursor-pointer flex-col gap-4 rounded-b-custom border-[1px] border-t-0 border-light2 bg-light p-2 text-light-text dark:border-dark-border dark:bg-dark dark:text-dark-text">
              <span
                className="cursor-pointer"
                onClick={() => selectItem('trade')}
              >
                Trade Calculator
              </span>
              <span
                className="cursor-pointer"
                onClick={() => selectItem('TradeFinder')}
              >
                Trade Finder
              </span>
            </div>
          )}
        </div>
        <div className="relative inline-block" ref={teamDropdownRef}>
          <span
            className={`cursor-pointer ${isSelected('team')}`}
            onClick={toggleTeamDropdown}
          >
            My Teams
          </span>
          {isTeamDropdownOpen && (
            <div className="absolute left-1/2 top-[45px] z-[100] mt-1 flex w-40 -translate-x-1/2 transform cursor-pointer flex-col gap-4 rounded-b-custom border-[1px] border-t-0 border-light2 bg-light p-2 text-light-text dark:border-dark-border dark:bg-dark dark:text-dark-text">
              <span
                className="cursor-pointer"
                onClick={() => selectItem('My Teams')}
              >
                My Teams
              </span>
              <span
                className="cursor-pointer"
                onClick={() => selectItem('My Reviews')}
              >
                My Reviews
              </span>
            </div>
          )}
        </div>
        <span
          className={`cursor-pointer ${isSelected('About Us')}`}
          onClick={() => selectItem('About Us')}
        >
          About Us
        </span>
      </div>
      <div className="flex items-center gap-3">
        <div
          className="flex w-16 cursor-pointer flex-col items-center justify-center rounded-full bg-[#EAEAEA] p-2 transition-all duration-200 hover:shadow-custom-glow2"
          onClick={() => selectItem('Purchase')}
          title="Store"
        >
          <RiCoinsLine
            className="text-[#222222] transition-all duration-200 hover:text-accent"
            size={22}
          />
        </div>
        <div className="h-10 w-[1px] border-[1px] border-light2 dark:border-dark-border"></div>
        <div className="hidden md:block">
          <ThemeToggle />
        </div>
        <div
          className="flex cursor-pointer items-center justify-center rounded-full bg-[#EAEAEA] p-2"
          onClick={linkDiscord}
        >
          <FaDiscord className="fill-[#222222]" size={18} />
        </div>
        {loggedIn ? (
          <div
            className="relative hidden justify-center lg:flex lg:flex-col"
            ref={accountDropdownRef}
          >
            <button
              className="flex min-w-[100px] cursor-pointer justify-center font-epilogue text-lg text-light-text dark:text-dark-text"
              onClick={toggleAccountDropdown}
            >
              {username || (
                <img
                  className="overflow-hidden rounded-full"
                  height={35}
                  src={avatar}
                  width={35}
                />
              )}
            </button>
            {showAccountDropdown && (
              <div className="absolute right-0 top-11 z-[1000] mt-2 rounded-custom border-[1px] border-light2 bg-light py-2 text-light-text dark:border-dark-border dark:bg-dark dark:text-dark-text">
                <span
                  className="block cursor-pointer px-4 py-2 hover:bg-gray-100 dark:hover:bg-dark-bg2"
                  onClick={() => navigate('/account')}
                >
                  Account
                </span>
                <span
                  className="block cursor-pointer px-4 py-2 hover:bg-gray-100 dark:hover:bg-dark-bg2"
                  onClick={handleLogout}
                >
                  Logout
                </span>
                {vouchers.length > 0 && (
                  <span className="px-2 py-2 text-sm text-accent">
                    {unredeemedVoucherCount} Voucher(s)
                  </span>
                )}
              </div>
            )}
          </div>
        ) : (
          <button
            className="rounded-lg border-[1px] border-dark-border bg-accent px-4 py-2 font-bold text-white transition-all duration-200 hover:bg-orange-600"
            onClick={() => navigate('/login')}
          >
            Login
          </button>
        )}
      </div>

      {isMobileMenuOpen && (
        <div
          className="absolute left-0 top-20 z-10 flex flex-col items-center gap-4 rounded-br-custom border-b-[1px] border-r-[1px] border-accent bg-light p-6 text-light-text2 dark:bg-dark"
          ref={mobileMenuRef}
        >
          {is_creator && (
            <div
              className={`relative ${
                isMobileMenuOpen ? 'block' : 'hidden lg:block'
              } `}
            >
              <button
                className={`font-bold text-light-text dark:text-dark-text ${isSelected(
                  'Creator'
                )}`}
                onClick={toggleCreatorDropdown}
              >
                Creator
              </button>
              {isCreatorDropdownOpen && (
                <div className="absolute left-1/2 top-full mt-1 w-[10rem] translate-x-[4rem] rounded-md border-[1px] border-accent bg-light p-2 text-light-text shadow-lg dark:bg-dark dark:text-dark-text">
                  {Object.keys(creatorRoutes).map((option) => {
                    if (role !== 2 && ADMIN_SETTINGS.indexOf(option) > -1) {
                      return null
                    }
                    return (
                      <span
                        className="block cursor-pointer px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-dark-bg2"
                        key={option}
                        onClick={() => navigate(creatorRoutes[option])}
                      >
                        {option}
                      </span>
                    )
                  })}
                </div>
              )}
            </div>
          )}
          <span
            className={`menu-item ${isSelected('Posts')}`}
            onClick={() => selectItem('Posts')}
          >
            Posts
          </span>
          <span
            onClick={() =>
              setIsMobileNestedDropdownOpen(!isMobileNestedDropdownOpen)
            }
            className={`menu-item ${isSelected('Rankings')}`}
          >
            Rankings
            {isMobileNestedDropdownOpen && (
              <div className="absolute left-0 flex w-full translate-y-2 flex-col gap-4 rounded-b-custom border-[1px] border-accent bg-light p-2 text-light-text2 dark:bg-dark dark:text-dark-text">
                <div onClick={() => routeToRankings(REDRAFT)}>Redraft</div>
                <div onClick={() => routeToRankings(SUPERFLEX)}>
                  Dynasty SuperFlex
                </div>
                <div onClick={() => routeToRankings(ONEQB)}>Dynasty 1QB</div>
              </div>
            )}
          </span>
          <span
            onClick={() =>
              setIsMobileTradeDropdownOpen(!isMobileTradeDropdownOpen)
            }
            className={`menu-item ${isSelected('trade')}`}
          >
            Trade
            {isMobileTradeDropdownOpen && (
              <div className="absolute left-0 flex w-full translate-y-2 flex-col gap-4 rounded-b-custom border-[1px] border-accent bg-light p-2 text-light-text2 dark:bg-dark dark:text-dark-text">
                <span
                  className="cursor-pointer"
                  onClick={() => selectItem('trade')}
                >
                  Trade Calculator
                </span>
                <span
                  className="cursor-pointer"
                  onClick={() => selectItem('TradeFinder')}
                >
                  Trade Finder
                </span>
              </div>
            )}
          </span>
          <span
            onClick={() =>
              setIsMobileTeamDropdownOpen(!isMobileTeamDropdownOpen)
            }
            className={`menu-item ${isSelected('My Teams')}`}
          >
            My Teams
            {isMobileTeamDropdownOpen && (
              <div className="absolute left-0 flex w-full translate-y-2 flex-col gap-4 rounded-b-custom border-[1px] border-accent bg-light p-2 text-light-text2 dark:bg-dark dark:text-dark-text">
                <span
                  className="cursor-pointer"
                  onClick={() => selectItem('My Teams')}
                >
                  My Teams
                </span>

                <span
                  className="cursor-pointer"
                  onClick={() => selectItem('My Reviews')}
                >
                  My Reviews
                </span>
              </div>
            )}
          </span>
          <span
            className={`menu-item ${isSelected('About Us')}`}
            onClick={() => selectItem('About Us')}
          >
            About Us
          </span>
          <div className="menu-item">
            <ThemeToggle />
          </div>
          {loggedIn && (
            <div className="flex flex-col gap-4 border-t-[1px] border-light2 p-4 dark:border-dark-text2">
              <span className="menu-item" onClick={() => navigate('/account')}>
                Account
              </span>
              <span className="menu-item" onClick={handleLogout}>
                Logout
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Navbar
