import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Storage } from 'aws-amplify'
import { toast, ToastContainer } from 'react-toastify'
import { MdFileUpload } from 'react-icons/md'
import cryptoJs from 'crypto-js'
import Editor from '../Editor'
import DomPurify from 'dompurify'
import Select1 from '../../_ui/Select'

import { createPost as createPostAction } from '../../../store/api'

const CreatePost = () => {
  const { user_id } = useSelector((state) => state.user)

  const [bodySize, setBodySize] = useState(0)
  const [contentType, setContentType] = useState(1)
  const [draftGuidePdf, setDraftGuidePdf] = useState(null)
  const [draftGuidePdfName, setDraftGuidePdfName] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [imagePreview, setImagePreview] = useState(null)
  const [link, setLink] = useState('')
  const [permissionLevel, setPermissionLevel] = useState(1)
  const [postBody, setPostBody] = useState('')
  const [preview, setPreview] = useState('')
  const [title, setTitle] = useState('')
  const [uploadImage, setUploadImage] = useState(null)
  const [uploadImageName, setUploadImageName] = useState('')

  const checkFields = async () => {
    if (!title) {
      setErrMsg('Title is required.')
      return false
    } else if (!uploadImage) {
      setErrMsg('Thumbnail is required.')
      return false
    } else if (!postBody) {
      setErrMsg('Aritcle body is required.')
      return false
    } else if (contentType > 1 && contentType < 4 && !link) {
      setErrMsg('Bonus content link is required for databases or videos.')
      return false
    } else if (contentType === 4 && !draftGuidePdf) {
      setErrMsg('Draft guide PDF is required.')
      return false
    } else if (bodySize > 100) {
      setErrMsg(
        'Text body must be smaller than 100%. If images were used please try using image upload icon.'
      )
      return false
    }

    setErrMsg('')
    return true
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const valid = await checkFields()

    if (valid) {
      toast.info('Content is being submitted! Please wait...', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })

      try {
        const now = new Date()
        const uploadImageKey =
          user_id + '/' + cryptoJs.SHA1(user_id + now.toISOString())

        Storage.put(uploadImageKey, uploadImage, {
          contentType: 'image/png',
        })

        let draftGuideKey

        if (contentType === 4) {
          draftGuideKey = user_id + '/' + draftGuidePdf.name
          Storage.put(draftGuideKey, draftGuidePdf, {
            bucket: 'fantasy-flock-draft-guides',
          })
        }

        const sanitizedBody = DomPurify.sanitize(postBody)

        const payload = {
          image: uploadImageKey,
          permission: permissionLevel,
          textPreview: preview,
          textBody: sanitizedBody,
          title: title,
          typeId: contentType,
        }

        if (contentType > 1) {
          payload['bonusLink'] = contentType < 4 ? link : draftGuideKey
        }

        await createPostAction(payload)
      } catch (error) {
        console.log(error)
        return
      }
      toast.success(
        'Submission Complete! Your content has been posted successfully. Please allow up to 30 min for the database to refresh to view your post.',
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      )
    }
  }

  const onImageChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      const fileSizeMB = file.size / 1024 / 1024
      if (fileSizeMB > 5) {
        setErrMsg('Thumbnail file size must be 5MB or less.')
        setImagePreview(null)
        setUploadImage(null)
        setUploadImageName('')
        return
      }
      setErrMsg('')
      setUploadImage(file)
      setImagePreview(URL.createObjectURL(file))
      setUploadImageName(file.name)
    }
  }

  const onPdfChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      const fileSizeMB = file.size / 1024 / 1024
      if (fileSizeMB > 18) {
        setErrMsg('PDF file size must be 18MB or less.')
        setDraftGuidePdf(null)
        return
      }
      setErrMsg('')
      setDraftGuidePdf(file)
      setDraftGuidePdfName(file.name)
    }
  }

  const onLinkChange = (event) => {
    setLink(event.target.value)
  }

  const onTitleChange = (event) => {
    setTitle(event.target.value)
  }

  const permissionOptions = ['Free', 'Subscription', 'Premium']

  const mapPermissionLabelToValue = (label) => {
    switch (label) {
      case 'Free':
        return 0
      case 'Subscription':
        return 1
      case 'Premium':
        return 2
      default:
        return 0
    }
  }

  const contentOptions = ['Post', 'Video', 'Database', 'Draft Guide']

  const mapContentLabelToValue = (label) => {
    switch (label) {
      case 'Post':
        return 1
      case 'Video':
        return 2
      case 'Database':
        return 3
      case 'Draft Guide':
        return 4
      default:
        return 1
    }
  }

  return (
    <div>
      <ToastContainer />
      <div className="flex flex-col items-center gap-4 bg-light px-[10%] py-10 font-dmsans dark:bg-dark">
        <div className="w-full">
          <h3 className="pb-10 text-lg font-bold text-light-text dark:text-dark-text lg:text-3xl">
            Create Post
          </h3>
        </div>
        <div className="w-full text-light-text dark:text-dark-text">
          <form onSubmit={handleSubmit}>
            <div className="flex pb-4">
              <div className="flex flex-col gap-1 font-medium">
                <h3 className="text-lg">Title</h3>
                <input
                  className="w-[25rem] rounded-md border-[1px] border-light2 bg-light p-2 focus:border-accent focus:outline-none dark:border-dark-border dark:bg-dark"
                  maxLength="100"
                  name="title"
                  onChange={onTitleChange}
                  placeholder="Name your post..."
                  type="text"
                  value={title}
                />

                <div className="flex flex-col gap-1 font-medium md:w-[25rem]">
                  <h3 className="text-lg">Permissions</h3>
                  <Select1
                    onSelect={(selectedOption) => {
                      const value = mapPermissionLabelToValue(
                        selectedOption.label
                      )
                      setPermissionLevel(value)
                    }}
                    options={permissionOptions}
                    placeholder="Select Permission Level..."
                  />

                  <h3 className="text-lg">Content Type</h3>
                  <Select1
                    onSelect={(selectedOption) => {
                      const value = mapContentLabelToValue(selectedOption.label)
                      setContentType(value)
                    }}
                    options={contentOptions}
                    placeholder="Select content type..."
                  />
                </div>
                {contentType > 1 && contentType !== 4 ? (
                  <>
                    <h3 className="text-lg">
                      {contentType === 2 ? 'Video Link' : 'Database Link'}
                    </h3>
                    <input
                      className="w-[20rem] rounded-md border-[1px] border-light2 bg-light p-2 focus:border-accent focus:outline-none dark:border-dark-border dark:bg-dark"
                      maxLength="100"
                      name="link"
                      onChange={onLinkChange}
                      placeholder="Paste link here..."
                      type="text"
                      value={link}
                    />
                  </>
                ) : contentType === 4 ? (
                  <>
                    <h3 className="text-lg">Draft Guide Upload</h3>
                    <input
                      accept="application/pdf"
                      hidden
                      id="file-input"
                      onChange={onPdfChange}
                      type="file"
                    />
                    <label className="flex items-center" htmlFor="file-input">
                      <MdFileUpload
                        className="cursor-pointer fill-light-text dark:fill-dark-text"
                        size={28}
                      />
                      {draftGuidePdfName && (
                        <span className="ml-2 text-light-text dark:text-dark-text">
                          {draftGuidePdfName}
                        </span>
                      )}
                    </label>
                  </>
                ) : (
                  <></>
                )}
                <h3 className="text-lg">Thumbnail</h3>
                <input
                  hidden
                  id="pdf-input"
                  onChange={onImageChange}
                  type="file"
                />
                <label className="flex" htmlFor="pdf-input">
                  <MdFileUpload
                    className="cursor-pointer fill-light-text dark:fill-dark-text"
                    size={28}
                  />
                  {uploadImageName && (
                    <span className="ml-2 text-light-text dark:text-dark-text">
                      {uploadImageName}
                    </span>
                  )}
                </label>
              </div>

              <div className="flex max-h-[20rem] items-center justify-center overflow-hidden pl-8">
                {imagePreview ? (
                  <img
                    alt="Image preview"
                    className="h-auto max-h-full rounded-md border-0"
                    src={imagePreview}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
            <p className="err-note">{errMsg}</p>
            <Editor
              onChange={setPostBody}
              onPreviewChange={setPreview}
              setBodySize={setBodySize}
              value={postBody}
            />
            <button
              className="mt-4 rounded bg-accent px-4 py-2 font-bold text-dark-text hover:bg-orange-600"
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default CreatePost
