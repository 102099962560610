export const AvailablePicks = [
  // 2024 Picks
  {
    description: '2024 Pick 1.01',
    pick_number: '1.01',
    state: 'SPECIFIC',
    player_id: '1',
  },
  {
    description: '2024 Pick 1.02',
    pick_number: '1.02',
    state: 'SPECIFIC',
    player_id: '2',
  },
  {
    description: '2024 Pick 1.03',
    pick_number: '1.03',
    state: 'SPECIFIC',
    player_id: '3',
  },
  {
    description: '2024 Pick 1.04',
    pick_number: '1.04',
    state: 'SPECIFIC',
    player_id: '4',
  },
  {
    description: '2024 Pick 1.05',
    pick_number: '1.05',
    state: 'SPECIFIC',
    player_id: '5',
  },
  {
    description: '2024 Pick 1.06',
    pick_number: '1.06',
    state: 'SPECIFIC',
    player_id: '6',
  },
  {
    description: '2024 Pick 1.07',
    pick_number: '1.07',
    state: 'SPECIFIC',
    player_id: '7',
  },
  {
    description: '2024 Pick 1.08',
    pick_number: '1.08',
    state: 'SPECIFIC',
    player_id: '8',
  },
  {
    description: '2024 Pick 1.09',
    pick_number: '1.09',
    state: 'SPECIFIC',
    player_id: '9',
  },
  {
    description: '2024 Pick 1.10',
    pick_number: '1.10',
    state: 'SPECIFIC',
    player_id: '10',
  },
  {
    description: '2024 Pick 1.11',
    pick_number: '1.11',
    state: 'SPECIFIC',
    player_id: '11',
  },
  {
    description: '2024 Pick 1.12',
    pick_number: '1.12',
    state: 'SPECIFIC',
    player_id: '12',
  },
  {
    description: '2024 Pick 1.13',
    pick_number: '1.13',
    state: 'SPECIFIC',
    player_id: '13',
  },
  {
    description: '2024 Pick 1.14',
    pick_number: '1.14',
    state: 'SPECIFIC',
    player_id: '14',
  },
  {
    description: '2024 Pick 1.15',
    pick_number: '1.15',
    state: 'SPECIFIC',
    player_id: '15',
  },
  {
    description: '2024 Pick 1.16',
    pick_number: '1.16',
    state: 'SPECIFIC',
    player_id: '16',
  },
  {
    description: '2024 Early Second',
    pick_number: '2.01',
    state: 'SPECIFIC',
    player_id: '17',
  },
  {
    description: '2024 Mid Second',
    pick_number: '2.02',
    state: 'SPECIFIC',
    player_id: '18',
  },
  {
    description: '2024 Late Second',
    pick_number: '2.03',
    state: 'SPECIFIC',
    player_id: '19',
  },
  {
    description: '2024 Early Third',
    pick_number: '3.01',
    state: 'SPECIFIC',
    player_id: '20',
  },
  {
    description: '2024 Mid Third',
    pick_number: '3.02',
    state: 'SPECIFIC',
    player_id: '21',
  },
  {
    description: '2024 Late Third',
    pick_number: '3.03',
    state: 'SPECIFIC',
    player_id: '22',
  },
  {
    description: '2024 Early Fourth',
    pick_number: '4.01',
    state: 'SPECIFIC',
    player_id: '23',
  },
  {
    description: '2024 Mid Fourth',
    pick_number: '4.02',
    state: 'SPECIFIC',
    player_id: '24',
  },
  {
    description: '2024 Late Fourth',
    pick_number: '4.03',
    state: 'SPECIFIC',
    player_id: '25',
  },
  // 2025 Picks
  {
    description: '2025 Early First',
    pick_number: '1.01',
    state: 'SPECIFIC',
    player_id: '26',
  },
  {
    description: '2025 Mid First',
    pick_number: '1.02',
    state: 'SPECIFIC',
    player_id: '27',
  },
  {
    description: '2025 Late First',
    pick_number: '1.03',
    state: 'SPECIFIC',
    player_id: '28',
  },
  {
    description: '2025 Early Second',
    pick_number: '2.01',
    state: 'SPECIFIC',
    player_id: '29',
  },
  {
    description: '2025 Mid Second',
    pick_number: '2.02',
    state: 'SPECIFIC',
    player_id: '30',
  },
  {
    description: '2025 Late Second',
    pick_number: '2.03',
    state: 'SPECIFIC',
    player_id: '31',
  },
  {
    description: '2025 Early Third',
    pick_number: '3.01',
    state: 'SPECIFIC',
    player_id: '32',
  },
  {
    description: '2025 Mid Third',
    pick_number: '3.02',
    state: 'SPECIFIC',
    player_id: '33',
  },
  {
    description: '2025 Late Third',
    pick_number: '3.03',
    state: 'SPECIFIC',
    player_id: '34',
  },
  {
    description: '2025 Early Fourth',
    pick_number: '4.01',
    state: 'SPECIFIC',
    player_id: '35',
  },
  {
    description: '2025 Mid Fourth',
    pick_number: '4.02',
    state: 'SPECIFIC',
    player_id: '36',
  },
  {
    description: '2025 Late Fourth',
    pick_number: '4.03',
    state: 'SPECIFIC',
    player_id: '37',
  },
  // 2026 Picks
  {
    description: '2026 Early First',
    pick_number: '1.01',
    state: 'SPECIFIC',
    player_id: '38',
  },
  {
    description: '2026 Mid First',
    pick_number: '1.02',
    state: 'SPECIFIC',
    player_id: '39',
  },
  {
    description: '2026 Late First',
    pick_number: '1.03',
    state: 'SPECIFIC',
    player_id: '40',
  },
  {
    description: '2026 Early Second',
    pick_number: '2.01',
    state: 'SPECIFIC',
    player_id: '41',
  },
  {
    description: '2026 Mid Second',
    pick_number: '2.02',
    state: 'SPECIFIC',
    player_id: '42',
  },
  {
    description: '2026 Late Second',
    pick_number: '2.03',
    state: 'SPECIFIC',
    player_id: '43',
  },
  {
    description: '2026 Early Third',
    pick_number: '3.01',
    state: 'SPECIFIC',
    player_id: '44',
  },
  {
    description: '2026 Mid Third',
    pick_number: '3.02',
    state: 'SPECIFIC',
    player_id: '45',
  },
  {
    description: '2026 Late Third',
    pick_number: '3.03',
    state: 'SPECIFIC',
    player_id: '46',
  },
  {
    description: '2026 Early Fourth',
    pick_number: '4.01',
    state: 'SPECIFIC',
    player_id: '47',
  },
  {
    description: '2026 Mid Fourth',
    pick_number: '4.02',
    state: 'SPECIFIC',
    player_id: '48',
  },
  {
    description: '2026 Late Fourth',
    pick_number: '4.03',
    state: 'SPECIFIC',
    player_id: '49',
  },
]
