const FLOCK = 'FLOCK'
const UNDERDOG = 'UNDERDOG'
const DOMAIN = 'DOMAIN'
const FSE = 'FSE'
const LAND = 'LAND'

export const MONTHLY = 'MONTHLY'
export const ANNUAL = 'ANNUAL'

export const FLOCKER = 'FLOCKER'
export const MOTHER_FLOCKER = 'MOTHER_FLOCKER'

export const plans = {
  [FLOCK]: {
    [MONTHLY]: {
      [FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock Discord Chat',
      ],
      [MOTHER_FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock Discord Chat',
        'Yearly redraft, rookie & dynasty draft guides',
        'Exclusive Flock Merch',
        'Team breakdown from Mason with either podcast or livestream',
      ],
    },
    [ANNUAL]: {
      [FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock Discord Chat',
        'Exclusive Flock Merch',
      ],
      [MOTHER_FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock Discord Chat',
        'Exclusive Flock Merch',
        'Yearly redraft, rookie & dynasty draft guides',
        'Team breakdown from Mason with either podcast or livestream',
      ],
    },
    annualExtraPerks: 2,
  },

  [UNDERDOG]: {
    [MONTHLY]: {
      [FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock Discord Chat',
      ],
      [MOTHER_FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock Discord Chat',
        'Yearly redraft, rookie & dynasty draft guides',
        'Exclusive Flock Merch',
        'Team breakdown from Mason with either podcast or livestream',
      ],
    },
    [ANNUAL]: {
      [FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock Discord Chat',
        'Exclusive Flock Merch',
      ],
      [MOTHER_FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock Discord Chat',
        'Exclusive Flock Merch',
        'Yearly redraft, rookie & dynasty draft guides',
        'Team breakdown from Mason with either podcast or livestream',
      ],
    },
    annualExtraPerks: 2,
  },

  [FSE]: {
    [MONTHLY]: {
      [FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock Discord Chat',
        'First priority to Dynasty Decisions Episodes',
      ],
      [MOTHER_FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock Discord Chat',
        'First priority to Dynasty Decisions Episodes',
        'Add Bush & Danny on Discord',
        'Yearly redraft, rookie & dynasty draft guides',
        'Free Zoom team Consultation',
      ],
    },
    [ANNUAL]: {
      [FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock Discord Chat',
        'First priority to Dynasty Decisions Episodes',
      ],
      [MOTHER_FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock Discord Chat',
        'First priority to Dynasty Decisions Episodes',
        'Add Bush & Danny on Discord',
        'Yearly redraft, rookie & dynasty draft guides',
        'Free Zoom team Consultation',
      ],
    },
    annualExtraPerks: 1,
  },

  [LAND]: {
    [MONTHLY]: {
      [FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock Discord Chat',
      ],
      [MOTHER_FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock Discord Chat',
        '2024 Rookie Report',
        'Yearly redraft, rookie & dynasty draft guides',
        'Unlock the Fantasy Hotline DM Advice',
        'Personalized Dynasty Team Review from Zach & Badake',
      ],
    },
    [ANNUAL]: {
      [FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock Discord Chat',
      ],
      [MOTHER_FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock Discord Chat',
        '2024 Rookie Report',
        'Yearly redraft, rookie & dynasty draft guides',
        'Unlock the Fantasy Hotline DM Advice',
        'Personalized Dynasty Team Review from Zach & Badake',
      ],
    },
    annualExtraPerks: 1,
  },

  [DOMAIN]: {
    [MONTHLY]: {
      [FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock Discord Chat',
      ],
      [MOTHER_FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock & Domain Discord Chat',
        'Exclusive Subscriber-only Leagues',
        'Yearly redraft, rookie & dynasty draft guides',
        'A Domain Team Blueprint breaking down your team',
      ],
    },
    [ANNUAL]: {
      [FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock Discord Chat',
      ],
      [MOTHER_FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock & Domain Discord Chat',
        'Exclusive Subscriber-only Leagues',
        'Yearly redraft, rookie & dynasty draft guides',
        'A Domain Team Blueprint breaking down your team',
      ],
    },
    annualExtraPerks: 1,
  },

  DEFAULT: {
    [MONTHLY]: {
      [FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock Discord Chat',
      ],
      [MOTHER_FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock Discord Chat',
        'Yearly redraft, rookie & dynasty draft guides',
      ],
    },
    [ANNUAL]: {
      [FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock Discord Chat',
      ],
      [MOTHER_FLOCKER]: [
        'Complete Rankings (Dynasty, Redraft, In-Season)',
        'Unlimited Access To Trade Calculator',
        'Premium Videos, Articles, & Databases',
        'Join the exclusive Flock Discord Chat',
        'Yearly redraft, rookie & dynasty draft guides',
      ],
    },
    annualExtraPerks: 0,
  },
}
