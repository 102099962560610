import React from 'react'
import '../../styles/webkit.css'

const InputBox = ({ placeholder, onChange, value }) => {
  return (
    <div className="flex w-full justify-center gap-3 md:justify-normal">
      <div className="flex w-full items-center gap-4 rounded-custom border-[1px] border-light2 p-3 text-black dark:border-dark-border">
        <input
          className="w-full bg-transparent text-sm text-light-text2 outline-none dark:text-dark-text2"
          onChange={onChange}
          placeholder={placeholder}
          type="text"
          value={value}
        />
      </div>
    </div>
  )
}

export default InputBox
