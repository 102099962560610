import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as TabsPrimitive from '@radix-ui/react-tabs'

import {
  getProducts as getProductionsAction,
  handlePayment as handlePaymentAction,
  handleUpgrade as handleUpgradeAction,
  getTrialEligibility as getTrialEligibilityAction,
} from '../../store/api'

import UpgradeModal from './UpgradeModal'
import Plan from './Plan'
import {
  ANNUAL,
  FLOCKER,
  MONTHLY,
  MOTHER_FLOCKER,
  plans,
} from './subscribePlan'

const Subscribe = () => {
  const navigate = useNavigate()

  const { is_subscribed, loggedIn, promoter_group_code, subscription_plan_id } =
    useSelector((state) => state.user)

  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false)
  const [upgradeConfiguration, setUpgradeConfiguration] = useState({})
  const [interval, setInterval] = useState(ANNUAL)
  const [products, setProducts] = useState(null)
  const [productsFormatted, setProductsFormatted] = useState(undefined)
  const [selectedFreeTrial, setSelectedFreeTrial] = useState(true)
  const [trialEligible, setTrialEligibility] = useState(false)

  const productPricingHeirarchy = [
    productsFormatted?.basic?.monthPriceId,
    productsFormatted?.basic?.yearPriceId,
    productsFormatted?.advanced?.monthPriceId,
    productsFormatted?.advanced?.yearPriceId,
  ]

  const currentSubscriptionHeirarchy =
    productPricingHeirarchy.indexOf(subscription_plan_id)

  useEffect(() => {
    getProductData()
  }, [])

  useEffect(() => {
    if (products) {
      buildProducts()
    }
  }, [products])

  const getProductData = async () => {
    try {
      const [trialEligibilityRes, productsRes] = await Promise.all([
        getTrialEligibilityAction(),
        getProductionsAction(),
      ])
      setTrialEligibility(trialEligibilityRes)
      setProducts(productsRes.data)
    } catch (err) {
      console.log('ERROR getTrialEligibility : ', err)
    }
  }

  const buildProducts = async () => {
    try {
      const basicMonth = setProductData('Flocker', 'month')
      const basicYear = setProductData('Flocker', 'year')
      const advMonth = setProductData('Mother Flocker', 'month')
      const advYear = setProductData('Mother Flocker', 'year')
      const productObject = {
        basic: {
          name: basicMonth['name'],
          description: basicMonth['description'],
          monthPrice: formatPrice(basicMonth['price']['unit_amount']),
          monthPriceId: basicMonth['price']['id'],
          yearPrice: formatPrice(basicYear['price']['unit_amount']),
          yearPriceId: basicYear['price']['id'],
        },
        advanced: {
          name: advMonth['name'],
          description: advMonth['description'],
          monthPrice: formatPrice(advMonth['price']['unit_amount']),
          monthPriceId: advMonth['price']['id'],
          yearPrice: formatPrice(advYear['price']['unit_amount']),
          yearPriceId: advYear['price']['id'],
        },
      }
      setProductsFormatted(productObject)
    } catch (err) {
      console.log('ERROR Subscribe.getProducts : ', err)
    }
  }

  const formatPrice = (str) => {
    str = str.toString()
    let len = str.length
    str = str.slice(0, len - 2) + '.' + str.slice(len - 2)
    return '$' + str
  }

  const setProductData = (subName, subInterval) => {
    for (let prod of products['data']) {
      if (prod['name'] === subName) {
        let price
        for (let p of prod['prices']['data']) {
          if (p['active'] && p['recurring']['interval'] === subInterval) {
            price = p
            break
          }
        }

        const ret = {
          name: prod['name'],
          description: prod['description'],
          price: price,
        }
        return ret
      }
    }
    return null
  }

  const handleUpgrade = async () => {
    const { intervalKey, type } = upgradeConfiguration

    // Determine the correct price ID based on the type and interval
    const priceId =
      intervalKey === 'month'
        ? productsFormatted[type].monthPriceId
        : productsFormatted[type].yearPriceId

    // Make sure we have a valid price ID
    if (!priceId) {
      console.error('Invalid product ID')
      return
    }

    try {
      await handleUpgradeAction({
        productId: priceId,
      })
      navigate('/account', { state: { from: '/subscribe' } })
    } catch (err) {
      console.log('ERROR UPGRADING: ', err)
    }
  }

  const handleSubscribe = async (type, intervalKey) => {
    // Determine the correct price ID based on the type and interval
    const priceId =
      intervalKey === 'month'
        ? productsFormatted[type].monthPriceId
        : productsFormatted[type].yearPriceId

    // Make sure we have a valid price ID
    if (!priceId) {
      console.error('Invalid product ID')
      return
    }

    const requestBody = {
      productId: priceId,
      trial: trialEligible ? selectedFreeTrial : false,
      success: 'http://www.flockfantasy.com/payment/success',
      cancel: 'http://www.flockfantasy.com/payment/cancel',
    }

    try {
      if (!loggedIn) {
        navigate('/login', { state: { from: '/subscribe' } })
        return
      } else {
        const { url } = await handlePaymentAction(requestBody)

        window.location.href = url
      }
    } catch (err) {
      console.log('ERROR Subscribe.handleSubscribe : ', err)

      // if (err?.response?.status === 409) {
      //   setIsDeflectionModalOpen(!isDeflectionModalOpen)
      // }
    }
  }

  const actionText =
    selectedFreeTrial && trialEligible
      ? 'Start your 7 day Free trial'
      : 'Subscribe'

  const canSubscribe = !is_subscribed
  const code =
    promoter_group_code && plans[promoter_group_code]
      ? promoter_group_code
      : 'DEFAULT'
  const perks = plans[code][interval][MOTHER_FLOCKER]
  const premiumLength = plans[code][interval][MOTHER_FLOCKER].length
  const basicLength = plans[code][interval][FLOCKER].length
  const { annualExtraPerks } = plans[code]

  return (
    <>
      {isUpgradeModalOpen && (
        <UpgradeModal
          onConfirm={handleUpgrade}
          onToggle={() => setIsUpgradeModalOpen(!isUpgradeModalOpen)}
        />
      )}
      <div className="flex flex-col items-center gap-8 bg-light py-10 font-dmsans text-light-text dark:bg-dark dark:text-dark-text">
        <div className="text-[26px] font-bold">Subscription Plans</div>
        <div className="px-4 text-sm text-light-text2 dark:text-dark-text2">
          Annual Packages include up to
          <span className="text-accent"> 6 months in savings </span>
          vs Monthly Plans!
        </div>
        <div>
          {productsFormatted ? (
            <>
              {trialEligible && (
                <TabsPrimitive.Root
                  className="w-full"
                  onValueChange={setSelectedFreeTrial}
                  value={selectedFreeTrial}
                >
                  <div className="flex w-full justify-center">
                    <TabsPrimitive.List className="flex w-[305px] justify-center gap-4 rounded-custom border-0 bg-light-bg p-[4px] text-custom-font dark:bg-dark-bg-select">
                      <TabsPrimitive.Trigger
                        className={`flex-1 rounded-lg px-8 py-2 ${
                          selectedFreeTrial
                            ? 'bg-accent-bg font-bold text-accent'
                            : 'text-light-text2 dark:text-dark-text2'
                        }`}
                        value={true}
                      >
                        Free Trial
                      </TabsPrimitive.Trigger>
                      <TabsPrimitive.Trigger
                        className={`rounded-lg px-8 py-2 ${
                          !selectedFreeTrial
                            ? 'bg-accent-bg font-bold text-accent'
                            : 'text-light-text2 dark:text-dark-text2'
                        }`}
                        value={false}
                      >
                        Instant Access
                      </TabsPrimitive.Trigger>
                    </TabsPrimitive.List>
                  </div>
                </TabsPrimitive.Root>
              )}

              <TabsPrimitive.Root
                className="w-full"
                onValueChange={setInterval}
                value={interval}
              >
                <div className="flex w-full justify-center">
                  <TabsPrimitive.List className="mt-4 flex w-[305px] justify-center gap-4 rounded-custom border-0 bg-light-bg p-[4px] text-custom-font dark:bg-dark-bg-select">
                    <TabsPrimitive.Trigger
                      className={`flex-1 rounded-lg py-2 text-center ${
                        interval === MONTHLY
                          ? 'bg-accent-bg font-bold text-accent'
                          : 'text-light-text2 dark:text-dark-text2'
                      }`}
                      value={MONTHLY}
                    >
                      Monthly
                    </TabsPrimitive.Trigger>
                    <TabsPrimitive.Trigger
                      className={`flex-1 rounded-lg py-2 text-center ${
                        interval === ANNUAL
                          ? 'bg-accent-bg font-bold text-accent'
                          : 'text-light-text2 dark:text-dark-text2'
                      }`}
                      value={ANNUAL}
                    >
                      Annual
                    </TabsPrimitive.Trigger>
                  </TabsPrimitive.List>
                </div>
                {selectedFreeTrial && (
                  <div className="flex justify-center px-4 py-4 text-custom-font font-medium text-light-text2 dark:text-accent">
                    * By choosing free trial, you are opting to wait one week
                    from signup to receive your team breakdown.
                  </div>
                )}
                <TabsPrimitive.Content value={MONTHLY}>
                  <div className="flex flex-col justify-center gap-8 pt-4 md:flex-row">
                    <Plan
                      handleUpgrade={() => {
                        setUpgradeConfiguration({
                          type: 'basic',
                          intervalKey: 'month',
                        })
                        setIsUpgradeModalOpen(!isUpgradeModalOpen)
                      }}
                      actionText={actionText}
                      canSubscribe={canSubscribe}
                      discount="13.30"
                      duration="/month"
                      handleSubscribe={() => handleSubscribe('basic', 'month')}
                      perkLength={basicLength}
                      perks={perks}
                      price={productsFormatted.basic.monthPrice}
                    />
                    <Plan
                      canUpgrade={
                        is_subscribed && currentSubscriptionHeirarchy < 1
                      }
                      handleSubscribe={() =>
                        handleSubscribe('advanced', 'month')
                      }
                      handleUpgrade={() => {
                        setUpgradeConfiguration({
                          type: 'advanced',
                          intervalKey: 'month',
                        })
                        setIsUpgradeModalOpen(!isUpgradeModalOpen)
                      }}
                      actionText={actionText}
                      annualExtraPerks={annualExtraPerks}
                      canSubscribe={canSubscribe}
                      discount="16.10"
                      duration="/month"
                      perkLength={premiumLength}
                      perks={perks}
                      price={productsFormatted.advanced.monthPrice}
                    />
                  </div>
                </TabsPrimitive.Content>

                <TabsPrimitive.Content value={ANNUAL}>
                  <div className="flex flex-col justify-center gap-8 pt-4 md:flex-row">
                    <Plan
                      canUpgrade={
                        is_subscribed && currentSubscriptionHeirarchy < 2
                      }
                      handleUpgrade={() => {
                        setUpgradeConfiguration({
                          type: 'basic',
                          intervalKey: 'year',
                        })
                        setIsUpgradeModalOpen(!isUpgradeModalOpen)
                      }}
                      actionText={actionText}
                      canSubscribe={canSubscribe}
                      discount="83.30"
                      duration="/year"
                      handleSubscribe={() => handleSubscribe('basic', 'year')}
                      perkLength={basicLength}
                      perks={perks}
                      price={productsFormatted.basic.yearPrice}
                    />
                    <Plan
                      canUpgrade={
                        is_subscribed && currentSubscriptionHeirarchy < 3
                      }
                      handleSubscribe={() =>
                        handleSubscribe('advanced', 'year')
                      }
                      handleUpgrade={() => {
                        setUpgradeConfiguration({
                          type: 'advanced',
                          intervalKey: 'year',
                        })
                        setIsUpgradeModalOpen(!isUpgradeModalOpen)
                      }}
                      actionText={actionText}
                      canSubscribe={canSubscribe}
                      discount="97.30"
                      duration="/year"
                      perkLength={premiumLength}
                      perks={perks}
                      price={productsFormatted.advanced.yearPrice}
                    />
                  </div>
                </TabsPrimitive.Content>
              </TabsPrimitive.Root>
            </>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </div>
    </>
  )
}
export default Subscribe
