import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getUserDetails } from '../../../store/api'

const UserDetails = () => {
  const [userDetails, setUserDetails] = useState(null)
  const [loading, setLoading] = useState(true)
  const [type, setType] = useState(null)
  const { userId } = useParams()

  useEffect(() => {
    fetchUserDetails(userId, type)
  }, [userId, type])

  const fetchUserDetails = async (userId, type) => {
    setLoading(true)
    try {
      console.log(userId, type)
      const response = await getUserDetails(userId, type)
      console.log(response)
      setUserDetails(response)
    } catch (error) {
      console.error('Error fetching user details:', error)
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <div>Loading...</div>
  }

  if (!userDetails) {
    return <div>No user details found.</div>
  }

  return (
    <div className="grid px-[10%] font-dmsans text-light-text dark:text-dark-text">
      <h2 className="pt-10 text-lg font-bold lg:text-3xl">
        User Payment History
      </h2>
      <div className="flex gap-4 pt-10">
        <button
          className={`rounded-full border-[1px] border-light2 px-[14px] py-2 text-[13px] transition-all duration-200 hover:bg-accent hover:text-light dark:border-dark-border ${
            type === null
              ? 'bg-accent font-bold text-dark-text'
              : 'text-light-text dark:text-dark-text'
          }`}
          onClick={() => setType(null)}
        >
          Overall
        </button>
        <button
          className={`rounded-full border-[1px] border-light2 px-[14px] py-2 text-[13px] transition-all duration-200 hover:bg-accent hover:text-light dark:border-dark-border ${
            type === 'single'
              ? 'bg-accent font-bold text-dark-text'
              : 'text-light-text dark:text-dark-text'
          }`}
          onClick={() => setType('single')}
        >
          Single
        </button>
        <button
          className={`rounded-full border-[1px] border-light2 px-[14px] py-2 text-[13px] transition-all duration-200 hover:bg-accent hover:text-light dark:border-dark-border ${
            type === 'subscription'
              ? 'bg-accent font-bold text-dark-text'
              : 'text-light-text dark:text-dark-text'
          }`}
          onClick={() => setType('subscription')}
        >
          Subscription
        </button>
      </div>
      <body className="grid h-[50vh] items-center justify-center">
        <table className="w-full max-w-[30rem] table-auto border-collapse">
          <thead>
            <tr>
              <th className="border-b border-light2 px-4 py-2 dark:border-dark-border">
                Created On
              </th>
              <th className="border-b border-light2 px-4 py-2 dark:border-dark-border">
                Amount
              </th>
              <th className="border-b border-light2 px-4 py-2 dark:border-dark-border">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {userDetails.map((detail) => (
              <tr key={detail.id}>
                <td className="border-b border-light2 px-4 py-2 dark:border-dark-border">
                  {new Date(detail.created_on).toLocaleString()}
                </td>
                <td className="border-b border-light2 px-4 py-2 dark:border-dark-border">
                  ${(detail.amount / 100).toFixed(2)}
                </td>
                <td className="border-b border-light2 px-4 py-2 dark:border-dark-border">
                  {detail.status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </body>
    </div>
  )
}

export default UserDetails
