import React from 'react'
import PlayerAvatar from '../../images/player_avatar.svg'

const MatchupPlayers = ({ name, position, team, picture }) => {
  const playerPicture = picture
    ? `https://flock-player-images-2.s3.us-east-2.amazonaws.com/${picture}`
    : PlayerAvatar
  return (
    <div className="flex h-[13rem] w-[20rem] justify-center gap-2 rounded-lg border-2 border-zinc-100 bg-zinc-100 p-2 transition-all duration-500 hover:border-custom-orange">
      <div className="flex gap-2">
        <div className="mx-4 flex flex-col gap-4">
          <div className="flex items-center justify-center">
            {/* <div className="text-sm">{position}</div> */}
            <img
              alt={name}
              className="mx-2 h-[8rem] w-[8rem] rounded-full border-[1px] border-light-text2 bg-light-text2 object-cover"
              src={playerPicture}
            />
            {/* <div className="text-sm">{team}</div> */}
          </div>
          <h3 className="text-md font-dark-text font-medium">{name}</h3>
        </div>
      </div>
    </div>
  )
}

export default MatchupPlayers
