import React, { useState } from 'react'
import useDarkMode from '../../hooks/useDarkMode'
import { ResponsiveLine } from '@nivo/line'
import { area } from 'd3-shape'

const LineChart = ({ data, timeFrame, player, isPositionalRank }) => {
  const isDarkMode = useDarkMode()
  const [hoverData, setHoverData] = useState(null)
  const [cursorX, setCursorX] = useState(null)

  if (!data || data.length === 0) {
    return <div>No data available</div>
  }

  const transformData = (data) => {
    if (!data) {
      return []
    }
    return data.map((player) => ({
      ...player,
      data: player.data ? player.data.map((entry) => ({ ...entry })) : [],
    }))
  }

  const CustomCursor = ({ x, isDarkMode }) => {
    if (x === null) {
      return null
    }

    const strokeColor = isDarkMode ? 'rgba(235, 235, 235, 0.10)' : '#EBEBEB'

    return (
      <>
        <line
          stroke={strokeColor}
          strokeWidth={1}
          style={{ pointerEvents: 'none' }}
          x1={x}
          x2={x}
          y1={0}
          y2="85%"
        />
      </>
    )
  }

  const CustomAreaLayer = ({ series, xScale, yScale, onMouseMoveArea }) => {
    if (!series || series.length === 0 || !series[0].data) {
      return null
    }

    const firstSeries = series[0]
    const areaGenerator = area()
      .x((d) => xScale(d.data.x))
      .y0((d) => yScale(d.data.y))
      .y1(yScale(yScale.domain()[0]))

    const areaPath = areaGenerator(firstSeries.data)

    return (
      <path
        d={areaPath}
        fill="rgba(233, 121, 51, 0.3)"
        onMouseMove={(e) => onMouseMoveArea(e, xScale)} // Pass xScale to onMouseMoveArea
      />
    )
  }

  const CustomTooltip = ({ data, player, isPositionalRank }) => {
    const displayPrefix = isPositionalRank ? player.position : '#'
    const displayData = data ? `${displayPrefix} ${data.y}` : ''

    const formatDate = (dateString) => {
      const dateParts = dateString.split('-')
      return `${dateParts[1]}-${dateParts[2]}`
    }
    const displayXValue = data ? formatDate(data.x) : ''

    return (
      <div className="font-dmsans text-xl font-bold text-accent">
        <div className="absolute left-10 top-10">{displayXValue}</div>
        <div className="absolute right-10 top-10">{displayData}</div>
      </div>
    )
  }

  const getMaxYValue = (data) => {
    const allYValues = data.reduce((acc, series) => {
      const seriesYValues = series.data.map((d) => d.y)
      return [...acc, ...seriesYValues]
    }, [])

    return Math.max(...allYValues)
  }

  const getClosestDataPoint = (xPos, data, xScale) => {
    const distances = data
      .map((series) =>
        series.data.map((d) => ({
          distance: Math.abs(xScale(d.x) - xPos),
          data: d,
        }))
      )
      .flat()

    const closestPoint = distances.reduce((prev, curr) =>
      curr.distance < prev.distance ? curr : prev
    )

    return closestPoint.data
  }

  const maxYValue = getMaxYValue(data) + 2

  const onMouseMove = (point) => {
    setHoverData(point.data)
    setCursorX(point.serieColor ? point.x : null)
  }

  const onMouseLeave = () => {
    const lastDataSeries = data[data.length - 1]
    const lastDataPoint = lastDataSeries.data[lastDataSeries.data.length - 1]

    setHoverData(lastDataPoint)
    setCursorX(null)
  }

  const onMouseMoveArea = (e, xScale) => {
    const svgRoot = e.currentTarget.ownerSVGElement
    const point = svgRoot.createSVGPoint()
    point.x = e.clientX
    point.y = e.clientY
    const cursorPoint = point.matrixTransform(svgRoot.getScreenCTM().inverse())

    // Find the closest data point
    const closestData = getClosestDataPoint(cursorPoint.x, data, xScale)

    // Update hoverData and cursorX
    setHoverData(closestData)
    setCursorX(xScale(closestData.x))
  }

  const xAxisLegend =
    timeFrame === 'daily' ? 'Day' : timeFrame === 'weekly' ? 'Week' : 'Month'
  const transformedData = transformData(data)

  const xTickValues =
    data[0] && data[0].data.length > 1
      ? [data[0].data[0].x, data[0].data[data[0].data.length - 1].x]
      : []

  const formatDateForTick = (value) => {
    const dateParts = value.split('-')
    return `${dateParts[1]}-${dateParts[2]}`
  }

  const theme = {
    axis: {
      ticks: {
        text: {
          fill: isDarkMode ? 'white' : 'black',
          fontFamily: 'Arial, sans-serif',
          fontSize: 12,
        },
      },
    },
    grid: {
      line: {
        stroke: isDarkMode ? '#444444' : '#eeeeee',
      },
    },
    points: {
      borderColor: { from: 'color', modifiers: [['darker', 1.6]] },
      borderWidth: 2,
    },
    crosshair: {
      line: {
        stroke: '#E97933',
      },
    },
  }

  return (
    <div className={`h-full w-full ${theme.className}`}>
      <ResponsiveLine
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: xAxisLegend,
          legendOffset: 36,
          legendPosition: 'middle',
          format: formatDateForTick,
          tickValues:
            xTickValues.length > 1
              ? [xTickValues[0], xTickValues[xTickValues.length - 1]]
              : [],
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendOffset: -40,
          legendPosition: 'middle',
          tickValues: [],
        }}
        layers={[
          'grid',
          'markers',
          'axes',
          'areas',
          'lines',
          'points',
          'mesh',
          'legends',
          (props) => (
            <CustomAreaLayer {...props} onMouseMoveArea={onMouseMoveArea} />
          ),
          (props) => (
            <CustomCursor
              {...props}
              hoverData={hoverData}
              isDarkMode={isDarkMode}
              x={cursorX}
            />
          ),
        ]}
        yScale={{
          type: 'linear',
          min: '1',
          max: maxYValue,
          stacked: true,
          reverse: true,
        }}
        axisRight={null}
        axisTop={null}
        colors="#E97933"
        data={transformedData}
        enableArea={false}
        enableGridX={false}
        enableGridY={false}
        margin={{ top: 10, right: 20, bottom: 30, left: 20 }}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        pointBorderColor="#FFFFFF"
        pointBorderWidth={1}
        pointColor="#E97933"
        pointLabelYOffset={-12}
        pointSize={0}
        theme={theme}
        tooltip={CustomTooltip}
        useMesh={true}
        xScale={{ type: 'point' }}
        yFormat=" >-.2f"
      />
      <CustomTooltip
        data={hoverData}
        isPositionalRank={isPositionalRank}
        player={player}
      />
    </div>
  )
}

export default LineChart
