import React from 'react'
import ToggleButton from '../../_ui/ToggleButton'
import { LiaTimesSolid } from 'react-icons/lia'

const scoringOptions = [
  { label: 'None', value: 0 },
  { label: 'Half', value: 0.5 },
  { label: 'Full', value: 1 },
]

const ReviewSettingsModal = ({
  isOpen,
  onClose,
  currentLeagueType,
  selectLeagueType,
  onTeamSelectionChange,
  selectedTeamCounts,
  setSelectedQbs,
  setSelectedPprTypes,
  selectedQbs,
  selectedPprTypes,
  tep,
  toggleTep,
  lineupCount,
  setLineupCount,
  options = ['LeagueType', 'Scoring', 'QBs', 'Teams', 'LineupCount'],
}) => {
  const handleTeamSelect = (teamCount) => {
    onTeamSelectionChange(teamCount.toString())
  }

  const toggleQbSelection = (qbCount) => {
    setSelectedQbs(qbCount.toString())
  }

  const togglePprSelection = (pprType) => {
    setSelectedPprTypes(pprType.toString())
  }

  const isSelectedTeam = (count) => selectedTeamCounts === count.toString()

  if (!isOpen) {
    return null
  }

  return (
    <div
      className={`fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50 ${isOpen ? 'flex' : 'hidden'}`}
    >
      <div className="max-h-[80%] overflow-scroll rounded-lg bg-light px-10 py-8 text-light-text dark:bg-dark dark:text-dark-text">
        <div className="flex items-center justify-between pb-3">
          <h3 className="text-xl font-bold">League Settings</h3>
          <button onClick={onClose}>
            <LiaTimesSolid
              className="fill-light-text2 transition-colors duration-300 hover:fill-accent dark:fill-dark-text2 hover:dark:fill-accent"
              size={22}
            />
          </button>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col gap-4 overflow-scroll">
            {options.includes('LeagueType') && (
              <div className="flex flex-col gap-2">
                <div className="text-md font-medium">League Type</div>
                <div className="flex gap-2">
                  <ToggleButton
                    isSelected={currentLeagueType === 'year'}
                    onClick={() => selectLeagueType('year')}
                    text="2024"
                  />
                  <ToggleButton
                    isSelected={currentLeagueType === 'Dynasty'}
                    onClick={() => selectLeagueType('Dynasty')}
                    text="Dynasty"
                  />
                </div>
              </div>
            )}
            {options.includes('Scoring') && (
              <div className="flex flex-col gap-2">
                <div className="text-md font-medium">Scoring</div>
                <div className="flex gap-2">
                  {scoringOptions.map((pprOption) => (
                    <ToggleButton
                      isSelected={
                        selectedPprTypes === pprOption.value.toString()
                      }
                      key={pprOption.label}
                      onClick={() => togglePprSelection(pprOption.value)}
                      text={pprOption.label}
                    />
                  ))}
                  <div className="border-l-[1px] border-light2 pl-2 dark:border-dark-border">
                    <ToggleButton
                      isSelected={tep}
                      onClick={toggleTep}
                      text={'TEP'}
                    />
                  </div>
                </div>
              </div>
            )}
            {options.includes('QBs') && (
              <div className="flex flex-col gap-2">
                <div className="text-md font-medium">QBs</div>
                <div className="flex gap-2">
                  {[1, 2].map((qbCount) => (
                    <ToggleButton
                      isSelected={selectedQbs === qbCount.toString()}
                      key={qbCount}
                      onClick={() => toggleQbSelection(qbCount)}
                      text={`${qbCount}`}
                    />
                  ))}
                </div>
              </div>
            )}
            {options.includes('Teams') && (
              <div className="flex flex-col gap-2">
                <div className="text-md font-medium">Teams</div>
                <div className="flex gap-2">
                  {[8, 10, 12, 14].map((count) => (
                    <ToggleButton
                      isSelected={isSelectedTeam(count)}
                      key={count}
                      onClick={() => handleTeamSelect(count)}
                      text={`${count}`}
                    />
                  ))}
                </div>
              </div>
            )}
            {options.includes('LineupCount') && (
              <div className="flex flex-col gap-2">
                <div className="text-md font-medium">Starting Lineup</div>
                <div className="flex gap-2">
                  {[8, 9, 10, 11, 12].map((count) => (
                    <ToggleButton
                      isSelected={lineupCount === count}
                      key={count}
                      onClick={() => setLineupCount(count)}
                      text={`${count}`}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReviewSettingsModal
