import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Auth } from 'aws-amplify'
import { loginUser } from '../../store/userReducer.js'
import VerificationCodeComponent from '../Misc/verificationCodeComponent.js'
import axios from 'axios'
import InputBox from '../_ui/InputBox.jsx'
import PassInputBox from '../_ui/PassInputBox.jsx'
import BaseCheckbox from '../_ui/BaseCheckbox.jsx'
import { Triangle } from 'react-loader-spinner'
import PdfModal from '../_ui/PdfModal.jsx'

const Login = () => {
  const [formEmail, setEmail] = useState('')
  const [formPass, setPassword] = useState('')
  const [needsValidation, setNeedsValidation] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false)
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false)

  const openTermsModal = () => setIsTermsModalOpen(true)
  const closeTermsModal = () => setIsTermsModalOpen(false)
  const openPrivacyModal = () => setIsPrivacyModalOpen(true)
  const closePrivacyModal = () => setIsPrivacyModalOpen(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { from } = location.state || { from: { pathname: '/' } }

  const handleLogin = async () => {
    if (!formEmail) {
      setErrMsg('Email cannot be empty')
      return
    } else if (!formPass) {
      setErrMsg('Password cannot be empty')
      return
    }

    setIsLoading(true)
    try {
      // Authenticate user
      const user = await Auth.signIn(formEmail, formPass)

      // Remember user credentials logic here (if needed)
      if (rememberMe) {
        localStorage.setItem('userEmail', formEmail)
        localStorage.setItem('userPass', formPass)
      } else {
        localStorage.removeItem('userEmail')
        localStorage.removeItem('userPass')
      }

      // Get session after successful login
      const token = user.signInUserSession.getAccessToken().getJwtToken()
      axios.defaults.headers.common['Authorization'] = token

      dispatch(loginUser())

      navigate(from)

      // if (location?.state?.from) {
      //   navigate(location.state.from)
      // } else {
      //   navigate('/')
      // }
    } catch (error) {
      console.error('Error signing in:', error)
      setErrMsg(error.message || 'An error occurred')
    } finally {
      setIsLoading(false)
    }
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
    handleLogin()
  }

  const handleForgotPassword = () => {
    navigate('/forgot')
  }

  const handleSignUp = () => {
    navigate('/signup')
  }

  if (needsValidation) {
    return (
      <VerificationCodeComponent
        email={formEmail}
        open={needsValidation}
        pass={formPass}
        route="/subscribe"
        setOpen={setNeedsValidation}
      />
    )
  }

  return (
    <div className="h-[85vh] bg-light font-dmsans dark:bg-dark">
      <div className="h-full p-10">
        <img
          height={50}
          src="https://static-site-images.s3.us-east-2.amazonaws.com/FF-REGULAR_V6.1.png"
          width={50}
        />
        <div className="flex h-full flex-col justify-center gap-[28px] bg-light pb-[10%] dark:bg-dark md:px-[40%]">
          <div className="text-3xl font-bold text-light-text dark:text-dark-text">
            Login
          </div>
          <form className="flex flex-col gap-2" onSubmit={handleFormSubmit}>
            <div className="w-full font-[500] text-light-text dark:text-dark-text">
              Email
              <InputBox
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email..."
                value={formEmail}
              />
            </div>
            <div className="w-full font-[500] text-light-text dark:text-dark-text">
              Password
              <PassInputBox
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password..."
              />
            </div>
            <button
              className="inline-flex h-[51px] w-full justify-center rounded-xl bg-accent py-3 text-sm font-bold text-dark-text"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? (
                <Triangle
                  ariaLabel="loading"
                  color="#FFF"
                  height={30}
                  width={30}
                />
              ) : (
                'Log In'
              )}
            </button>
            {errMsg && (
              <div className="mt-2 text-center text-red-500">{errMsg}</div>
            )}
          </form>

          <div className="flex items-center justify-between">
            <BaseCheckbox
              checked={rememberMe}
              onChange={(isChecked) => setRememberMe(isChecked)}
              text="Remember me"
            />
            <button
              className="text-sm font-medium leading-snug text-orange-400"
              onClick={handleForgotPassword}
            >
              Forgot password?
            </button>
          </div>
          <div className="flex w-full justify-center py-2 text-custom-font text-light-text dark:text-dark-text">
            Don&apos;t have an account yet?
            <button
              className="cursor-pointer pl-2 font-medium leading-snug text-orange-400"
              onClick={handleSignUp}
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>

      {isLoading && (
        <div className="flex items-center justify-center">
          <Triangle color="FFF" height={80} width={80} />
        </div>
      )}
      <div className="py-auto hidden gap-8 bg-light px-10 pb-[2%] dark:bg-dark md:flex">
        <span className="text-custom-font font-normal text-light-text2 dark:text-dark-text2">
          @ Flock 2023
        </span>
        <span
          className="cursor-pointer text-custom-font font-medium text-accent"
          onClick={openPrivacyModal}
        >
          Privacy Policy
        </span>
        <span
          className="cursor-pointer text-custom-font font-medium text-accent"
          onClick={openTermsModal}
        >
          Terms of Service
        </span>
        <PdfModal
          file="/Website_Terms_Of_Use.pdf"
          isOpen={isTermsModalOpen}
          onClose={closeTermsModal}
        />
        <PdfModal
          file="/Online_Privacy_Policy.pdf"
          isOpen={isPrivacyModalOpen}
          onClose={closePrivacyModal}
        />
      </div>
    </div>
  )
}

export default Login
