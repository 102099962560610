import React, { useState, useEffect } from 'react'
import { FaSearch, FaPlus } from 'react-icons/fa'
import { motion, AnimatePresence } from 'framer-motion'

const BaseSearchBar = ({
  onAddPlayer,
  searchTerm,
  setSearchTerm,
  placeholder,
  options = [],
  onSubmit = false,
}) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [filteredOptions, setFilteredOptions] = useState([])
  const [internalSearchTerm, setInternalSearchTerm] = useState(searchTerm)

  useEffect(() => {
    if (internalSearchTerm && !onSubmit) {
      const lowercasedSearchTerm = internalSearchTerm.toLowerCase()
      const filtered = options.filter((player) => {
        const descriptionMatch =
          typeof player.description === 'string' &&
          player.description.toLowerCase().includes(lowercasedSearchTerm)
        const teamNameMatch =
          typeof player.team_name === 'string' &&
          player.team_name.toLowerCase().includes(lowercasedSearchTerm)
        const playerNameMatch =
          typeof player.player_name === 'string' &&
          player.player_name.toLowerCase().includes(lowercasedSearchTerm)
        return descriptionMatch || teamNameMatch || playerNameMatch
      })
      setFilteredOptions(filtered)
    } else {
      setFilteredOptions([])
    }
  }, [internalSearchTerm, options, onSubmit])

  const dropdownVariants = {
    hidden: {
      opacity: 0,
      scaleY: 0,
      transformOrigin: 'top',
    },
    visible: {
      opacity: 1,
      scaleY: 1,
      transformOrigin: 'top',
    },
    exit: {
      opacity: 0,
      scaleY: 0,
      transformOrigin: 'top',
    },
  }

  const positionTransition = { type: 'tween', ease: 'easeInOut', duration: 0.2 }

  const handleSearch = (event) => {
    event.preventDefault()
    if (onSubmit) {
      onSubmit(internalSearchTerm)
    } else {
      setSearchTerm(internalSearchTerm)
      setShowDropdown(false)
    }
  }

  return (
    <div className="relative w-full">
      <form
        className="flex h-[50px] w-full items-center gap-2 rounded-[10px] bg-light2 px-4 dark:bg-[#FFFFFF1A]"
        onSubmit={handleSearch}
      >
        <button className="text-zinc-600 hover:text-zinc-900" type="submit">
          <FaSearch className="fill-light-text2 dark:fill-dark-text2" />
        </button>
        <input
          onChange={(e) => {
            setInternalSearchTerm(e.target.value)
            setShowDropdown(true)
          }}
          className="mr-3 w-full appearance-none border-none bg-transparent px-2 leading-tight text-light-text focus:outline-none dark:text-dark-text"
          onBlur={() => setTimeout(() => setShowDropdown(false), 150)}
          placeholder={placeholder || 'Search...'}
          type="text"
          value={internalSearchTerm}
        />
      </form>
      <AnimatePresence>
        {showDropdown && internalSearchTerm && filteredOptions.length > 0 && (
          <motion.div
            animate="visible"
            className="border-light-border absolute top-full z-[100] mt-2 max-h-[300px] max-w-[437px] cursor-pointer overflow-auto rounded-md border-[1px] bg-light shadow-lg dark:border-dark-border dark:bg-dark"
            exit="exit"
            initial="hidden"
            transition={positionTransition}
            variants={dropdownVariants}
          >
            {filteredOptions.map((player) => (
              <motion.div
                animate={{ opacity: 1 }}
                className="group flex items-center p-2 px-4 text-light-text transition-all duration-300 hover:bg-zinc-300 dark:text-dark-text hover:dark:bg-zinc-800"
                exit={{ opacity: 0, transition: { duration: 0.2 } }}
                initial={{ opacity: 0 }}
                key={player.player_id}
                layout
                onClick={() => onAddPlayer(player)}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
              >
                <span className="flex-grow">
                  {player.description ||
                    player.team_name ||
                    player.user_name ||
                    player.player_name}
                </span>
                <button
                  className="custom-div rounded-full border-0 p-2 duration-300 hover:bg-zinc-100 hover:bg-opacity-40 hover:text-custom-orange"
                  onClick={() => null}
                >
                  <FaPlus className="custom-fa-plus fill-light-text2 dark:fill-dark-text2" />
                </button>
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default BaseSearchBar
