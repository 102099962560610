import React from 'react'
import Perk from './Perk'

const Plan = ({
  actionText,
  annualExtraPerks = 0,
  canSubscribe,
  canUpgrade = false,
  discount,
  duration,
  handleSubscribe,
  handleUpgrade,
  perkLength,
  perks,
  price,
}) => {
  const planTitle = perkLength === perks.length ? 'Mother Flocker' : 'Flocker'

  return (
    <div className="mx-2 flex flex-col items-center gap-4 rounded-[20px] bg-neutral-400 bg-opacity-10 px-10 py-14 dark:bg-neutral-50 dark:bg-opacity-10 md:w-[80%]">
      <div className="text-[22px] font-bold">{planTitle}</div>
      <div className="text-[16px] font-bold">
        <span className="text-accent">$</span>
        <span className="text-[33px] text-accent">{discount}</span>
        <span className="text-sm text-light-text2 dark:text-dark-text2">
          {duration}
        </span>
        <div className="text-center text-sm font-normal text-light-text2 dark:text-dark-text2">
          instead of&nbsp;
          <span className="line-through">{price}</span>
        </div>
      </div>
      <div className="flex flex-col gap-4 md:w-[300px]">
        {perks.map((perkText, i) => {
          const perkIncluded = perkLength - annualExtraPerks > i
          return (
            <Perk isOffered={perkIncluded} key={i + '-basic'} text={perkText} />
          )
        })}
      </div>
      <button
        className="mt-2 rounded-[11px] bg-accent px-8 py-3 text-custom-font font-bold text-dark-text disabled:cursor-not-allowed disabled:bg-gray-500 disabled:text-gray-400"
        // disabled={!canSubscribe}
        disabled={!canSubscribe && !canUpgrade}
        onClick={canSubscribe ? handleSubscribe : handleUpgrade}
      >
        {canUpgrade ? 'Upgrade' : actionText}
      </button>
    </div>
  )
}

export default Plan
