import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import { pdfjs } from 'react-pdf'

import { Amplify, Auth, Storage } from 'aws-amplify'

import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

import awsconfig from './aws-exports'

import { getToken } from './services/authService'

import { logout, setUser } from './store/userReducer'

import { getUser as getUserAction } from './store/api'

import { ThemeProvider } from './components/Theme/ThemeProvider'

import routes from './routes.js'

Amplify.configure(awsconfig)

Auth.configure(awsconfig)

Storage.configure({
  Auth: {
    identityPoolId: awsconfig.aws_cognito_identity_pool_id,

    region: awsconfig.aws_cognito_region,

    userPoolId: awsconfig.aws_user_pools_id,

    userPoolWebClientId: awsconfig.aws_user_pools_web_client_id,
  },

  AWSS3: {
    bucket: 'fantasy-flock-article-images',

    region: 'us-east-2',
  },
})

const App = () => {
  const dispatch = useDispatch()

  const { loggedIn } = useSelector((state) => state.user)

  const [appInit, setAppInit] = useState(false)

  const [role, setRole] = useState(0)

  const isAdmin = role === 2

  const router = createBrowserRouter(routes(isAdmin))

  useEffect(() => {
    if (!appInit) {
      handleToken()
    }
  }, [appInit])

  useEffect(() => {
    if (appInit) {
      getUser()
    }
  }, [appInit, loggedIn])

  const getUser = async () => {
    try {
      const user = await getUserAction()

      setRole(user.role)

      if (user.email) {
        dispatch(setUser(user))
      }
    } catch (err) {
      console.error('getUserRole ERROR: ', err)

      dispatch(logout())

      await Auth.signOut({ global: true })
    }
  }

  const handleToken = async () => {
    try {
      await getToken()
    } catch (err) {
      console.error('handleToken ERROR : ', err)

      dispatch(logout())
    } finally {
      setAppInit(true)
    }
  }

  if (!appInit) {
    return null
  }

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  return (
    <ThemeProvider>
      <RouterProvider router={router} />

      <ToastContainer />
    </ThemeProvider>
  )
}

export default App
