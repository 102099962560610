import React, { useEffect, useState } from 'react'
import * as Slider from '@radix-ui/react-slider'
import { ProgressBar } from 'react-loader-spinner'
import { FaArrowLeft } from 'react-icons/fa6'
import { IoBagCheckOutline } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import useDarkMode from '../../../hooks/useDarkMode'
import { PiArrowBendDownRightFill } from 'react-icons/pi'

const creatorImages = {
  '38f6c2ae-6010-416a-8947-973a4ddc58e6':
    'https://static-site-images.s3.us-east-2.amazonaws.com/mason2.png',
  '222696a0-038d-4689-a3e6-a97046cd2eec':
    'https://static-site-images.s3.us-east-2.amazonaws.com/Compressed_Domain_Image.jpg',
  '5ac59844-e390-46dd-9ecd-b50ecc3c0a08':
    'https://static-site-images.s3.us-east-2.amazonaws.com/LandProfile.png',
  'e401f630-5049-4c83-9b0a-453c1c547ebc':
    'https://static-site-images.s3.us-east-2.amazonaws.com/Corey%26McKinnon.jpg',
  'b23841d4-f381-4569-8857-39b80406e7d5':
    'https://static-site-images.s3.us-east-2.amazonaws.com/FF-REGULAR_V3.1.png',
}

const creatorLogos = {
  '38f6c2ae-6010-416a-8947-973a4ddc58e6': {
    src: 'https://static-site-images.s3.us-east-2.amazonaws.com/FF-REGULAR_V6.1.png',
    height: 'h-16',
  },
  '222696a0-038d-4689-a3e6-a97046cd2eec': {
    src: 'https://static-site-images.s3.us-east-2.amazonaws.com/Domain_Logo.png',
    height: 'h-16',
    bg: 'bg-gray-500 dark:bg-dark',
  },
  '5ac59844-e390-46dd-9ecd-b50ecc3c0a08': {
    src: 'https://static-site-images.s3.us-east-2.amazonaws.com/Land_Logo.png',
    darkSrc:
      'https://static-site-images.s3.us-east-2.amazonaws.com/White-Land-Logo.png',
    height: 'h-16',
  },
  'e401f630-5049-4c83-9b0a-453c1c547ebc': {
    src: '/FantasyStockExchange.png',
    height: 'h-16',
  },
  'b23841d4-f381-4569-8857-39b80406e7d5': {
    src: 'https://static-site-images.s3.us-east-2.amazonaws.com/FF-REGULAR_V6.1.png',
    height: 'h-16',
  },
}

const promoOrder = {
  FLOCK: '38f6c2ae-6010-416a-8947-973a4ddc58e6',
  DOMAIN: '222696a0-038d-4689-a3e6-a97046cd2eec',
  LAND: '5ac59844-e390-46dd-9ecd-b50ecc3c0a08',
  FSE: 'e401f630-5049-4c83-9b0a-453c1c547ebc',
  TEST_DEV: 'b23841d4-f381-4569-8857-39b80406e7d5',
}

export const CreatorOption = ({
  handleReviewCheckout,
  product,
  setSelectedProducts,
  goBack,
  isLoading,
  groupedProducts,
  handleCommentsChange,
  comments,
  maxPrice = 30000,
}) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const code = searchParams.get('code')

  const promoCode = useSelector((state) => state.user.promoter_group_code)
  const [selectedProductIds, setSelectedProductIds] = useState(new Set())
  const isDarkMode = useDarkMode()

  const [sortedGroupedProducts, setSortedGroupedProducts] =
    useState(groupedProducts)
  const [productPrices, setProductPrices] = useState(
    product.reduce((acc, p) => {
      acc[p.id] = p.discount_price !== undefined ? p.discount_price : p.price
      return acc
    }, {})
  )

  useEffect(() => {
    const appliedCode = code || promoCode

    if (appliedCode && promoOrder[appliedCode]) {
      handlePromoOrQuerySelection(appliedCode)
    }

    const sortedProducts = appliedCode
      ? sortGroupedProducts(groupedProducts, appliedCode)
      : groupedProducts
    setSortedGroupedProducts(sortedProducts)
  }, [])

  const sortGroupedProducts = (groupedProducts, promoCode) => {
    const sortedKeys = Object.keys(groupedProducts).sort((a, b) => {
      if (promoCode && promoOrder[promoCode] === a) {
        return -1
      }
      if (promoCode && promoOrder[promoCode] === b) {
        return 1
      }
      return 0
    })

    const sortedGroupedProducts = {}
    sortedKeys.forEach((key) => {
      sortedGroupedProducts[key] = groupedProducts[key]
    })
    return sortedGroupedProducts
  }

  const handlePromoOrQuerySelection = (promoOrQueryCode) => {
    const promoProductId = promoOrder[promoOrQueryCode]
    const promoProduct = product.find(
      (p) => p.configuration.creator.promoter_id === promoProductId
    )
    if (promoProduct) {
      setSelectedProductIds(
        (prevSelectedProductIds) =>
          new Set([...prevSelectedProductIds, promoProduct.id])
      )
      setSelectedProducts((prevSelectedProducts) => [
        ...prevSelectedProducts,
        { ...promoProduct, price: productPrices[promoProduct.id] },
      ])
    }
  }

  const handleSliderChange = (productId, value) => {
    setProductPrices((prevPrices) => ({
      ...prevPrices,
      [productId]: value[0],
    }))

    setSelectedProducts((prevSelectedProducts) => {
      const newSelectedProducts = prevSelectedProducts.map((p) =>
        p.id === productId ? { ...p, price: value[0] } : p
      )
      return newSelectedProducts
    })
  }

  const handleProductChange = (productId) => {
    setSelectedProductIds((prevSelectedProductIds) => {
      const newSelectedProductIds = new Set(prevSelectedProductIds)
      if (newSelectedProductIds.has(productId)) {
        newSelectedProductIds.delete(productId)
      } else {
        newSelectedProductIds.add(productId)
      }

      const updatedSelectedProducts = [...newSelectedProductIds].map((id) => {
        const productToAdd = product.find((p) => p.id === id)
        return { ...productToAdd, price: productPrices[productToAdd.id] }
      })

      setSelectedProducts(updatedSelectedProducts)
      return newSelectedProductIds
    })
  }

  const getGradientClass = (productPrices, selectedProductId, group) => {
    const baseColor = 'from-yellow-500'
    const thresholdColors = [
      'to-accent',
      'to-red-600',
      'to-pink-600',
      'to-purple-600',
      'to-indigo-600',
      'to-blue-600',
      'to-green-600',
      'to-yellow-600',
    ]
    let gradientClass = baseColor

    for (let i = 0; i < group.length; i++) {
      if (
        productPrices[selectedProductId] >=
        (group[i].discount_price || group[i].price)
      ) {
        gradientClass = `from-yellow-500 ${thresholdColors[i % thresholdColors.length]}`
      } else {
        break
      }
    }

    return `absolute h-full rounded-l-full bg-gradient-to-r ${gradientClass}`
  }

  const getProductNameWithTier = (productPrices, selectedProductId, group) => {
    let productName = `${group[0].configuration.tier || ''}`

    for (let i = 1; i < group.length; i++) {
      if (
        productPrices[selectedProductId] >=
        (group[i].discount_price || group[i].price)
      ) {
        productName = `${group[i].configuration.tier || ''}`
      } else {
        break
      }
    }

    return productName
  }

  return (
    <div className="grid gap-4">
      <h3 className="text-xl font-bold md:text-3xl">Select a Creator</h3>
      <div className="flex flex-wrap gap-6">
        {Object.keys(sortedGroupedProducts).map((promoterId) => {
          const group = groupedProducts[promoterId]
          const selectedProduct =
            group.find((p) => selectedProductIds.has(p.id)) || group[0]
          return (
            <div
              className={`grid w-[20rem] cursor-pointer gap-4 rounded-xl border-[1px] bg-zinc-100 transition-all duration-200 hover:shadow-custom-glow2 dark:bg-dark ${selectedProductIds.has(selectedProduct.id) ? 'border-accent shadow-custom-glow2' : 'border-zinc-300 dark:border-dark-border'}`}
              key={selectedProduct.id}
              onClick={() => handleProductChange(selectedProduct.id)}
            >
              <img
                src={
                  creatorImages[
                    selectedProduct.configuration.creator.promoter_id
                  ]
                }
                alt={`${selectedProduct.name} image`}
                className="h-48 w-full rounded-t-xl object-cover"
              />
              <p className="px-4 pb-3">
                <div className="flex items-center justify-between">
                  <span className="text-2xl font-semibold">
                    {selectedProduct.configuration.creator.name}
                  </span>
                  <div
                    className={`flex w-24 items-center justify-end ${creatorLogos[selectedProduct.configuration.creator.promoter_id].bg ? creatorLogos[selectedProduct.configuration.creator.promoter_id].bg + ' rounded-xl p-1' : ''}`}
                  >
                    <img
                      src={
                        isDarkMode &&
                        creatorLogos[
                          selectedProduct.configuration.creator.promoter_id
                        ].darkSrc
                          ? creatorLogos[
                              selectedProduct.configuration.creator.promoter_id
                            ].darkSrc
                          : creatorLogos[
                              selectedProduct.configuration.creator.promoter_id
                            ].src
                      }
                      alt={`${selectedProduct.name} logo`}
                      className={`max-w-full ${creatorLogos[selectedProduct.configuration.creator.promoter_id].height}`}
                    />
                  </div>
                </div>
                <span className="flex w-full justify-between">
                  <p className="text-light-text2 dark:text-dark-text2">
                    {getProductNameWithTier(
                      productPrices,
                      selectedProduct.id,
                      group
                    )}
                  </p>
                  {selectedProduct.discount_price !== undefined && (
                    <p className="font-semibold italic text-accent">
                      {selectedProduct.discount.amount}% Off
                    </p>
                  )}
                </span>
                <div className="w-full">
                  <Slider.Root
                    min={
                      selectedProduct.discount_price || selectedProduct.price
                    }
                    onValueChange={(value) =>
                      handleSliderChange(selectedProduct.id, value)
                    }
                    aria-label="Select value"
                    className="relative flex h-12 w-full touch-none select-none items-center"
                    id={selectedProduct.id}
                    max={maxPrice}
                    onClick={(e) => e.stopPropagation()}
                    step={100}
                    value={[productPrices[selectedProduct.id]]}
                  >
                    <Slider.Track className="relative h-[16px] flex-grow rounded-full bg-zinc-300 transition-all duration-200 hover:bg-zinc-500 dark:bg-dark-border hover:dark:bg-zinc-700">
                      <Slider.Range
                        className={getGradientClass(
                          productPrices,
                          selectedProduct.id,
                          group
                        )}
                      />
                      {group.map((product, index) => {
                        if (index === 0) {
                          return null
                        }
                        return (
                          <div
                            style={{
                              left: `${((product.discount_price - group[0].discount_price || product.price - group[0].price) / (30000 - group[0].discount_price || 30000 - group[0].price)) * 100}%`,
                            }}
                            className="absolute top-0 h-[140%] w-[2px] -translate-y-[15%] rounded-full bg-zinc-500 dark:bg-zinc-300"
                            key={product.id}
                          />
                        )
                      })}
                    </Slider.Track>
                    <Slider.Thumb className="block h-10 w-2 rounded-full bg-light-text shadow-md focus:outline-none dark:bg-light" />
                  </Slider.Root>
                  <span className="flex -translate-y-3 items-end justify-center gap-2 text-sm italic text-light-text2 dark:text-dark-text2 dark:opacity-50">
                    Slide to donate <PiArrowBendDownRightFill />
                  </span>
                </div>
                <div className="mt-2 flex justify-between px-4 text-lg font-semibold text-accent">
                  <span>
                    ${(productPrices[selectedProduct.id] / 100).toFixed(0)}
                  </span>
                  {selectedProductIds.has(selectedProduct.id) && (
                    <span>Selected {[...selectedProductIds].length}</span>
                  )}
                </div>
              </p>
            </div>
          )
        })}
      </div>
      <p>
        <h3 className="pb-4 text-xl font-bold">Comments or Questions</h3>
        <textarea
          className="text-md h-[20vh] w-full max-w-[49%] rounded-lg border-[1px] border-light2 bg-white p-4 dark:border-dark-border dark:bg-dark"
          onChange={handleCommentsChange}
          placeholder="Enter your comments or questions here"
          value={comments}
        ></textarea>
        <div className="pt-1">{comments.length} out of 500 characters</div>
      </p>
      <div className="flex w-full justify-between">
        <button
          className="flex items-center gap-2 rounded-lg bg-zinc-500 px-6 py-3 text-dark-text"
          onClick={goBack}
        >
          <FaArrowLeft />
          Back
        </button>
        {isLoading ? (
          <ProgressBar
            barColor="#E97933"
            borderColor="#BB8360"
            height={75}
            type="Oval"
            width={100}
          />
        ) : (
          <button
            className="flex items-center justify-center gap-2 rounded-lg bg-accent px-12 py-3 text-dark-text"
            onClick={handleReviewCheckout}
          >
            Checkout
            <IoBagCheckOutline size={22} />
          </button>
        )}
      </div>
    </div>
  )
}
