import React from 'react'
import { getDateTimeString } from '../../utils/dateUtils.ts'

export default function PostHeader(props) {
  const creatorLogoConfig = {
    'Avery Huffman': {
      src: 'https://static-site-images.s3.us-east-2.amazonaws.com/Domain_Logo.png',
      width: '55px',
      height: '70px',
    },
    'Nathan Schmidt': {
      src: 'https://static-site-images.s3.us-east-2.amazonaws.com/Domain_Logo.png',
      width: '55px',
      height: '70px',
    },
    'Daniel Badake': {
      src: '/DynastyLand3.png',
      width: '216px',
      height: '35px',
    },
    'Zach Savage': { src: '/DynastyLand3.png', width: '216px', height: '35px' },
    'Corey Buschlen': {
      src: '/FantasyStockExchange.png',
      width: '55px',
      height: '55px',
    },
    'Danny McKinnon': {
      src: '/FantasyStockExchange.png',
      width: '55px',
      height: '55px',
    },
    'Mason Dodd': {
      src: 'https://static-site-images.s3.us-east-2.amazonaws.com/FF-REGULAR_V6.1.png',
      width: '55px',
      height: '55px',
    },
  }

  const postDateTime = getDateTimeString(props.createdDate)
  const logoConfig = creatorLogoConfig[props.author] || {
    src: '',
    width: '45px',
    height: '35px',
  }

  return (
    <div className="pt-4 font-dmsans">
      <div className="flex flex-col gap-4 text-lg text-light-text2 dark:text-light-text2">
        {!props.community ? (
          <img
            alt={`${props.author} logo`}
            src={logoConfig.src}
            style={{ width: logoConfig.width, height: logoConfig.height }}
          />
        ) : (
          <>|</>
        )}
        By {props.author}
        <span>{postDateTime}</span>
      </div>
    </div>
  )
}
