import React from 'react'

const EspnSyncPrivacyPolicy = () => {
  return (
    <div className="flex flex-col gap-4 px-[20%] py-10 font-dmsans text-light-text dark:text-dark-text">
      <div className="flex items-center gap-10">
        <img
          alt="Flock Fantasy Logo"
          className="mr-2 w-24"
          src="https://static-site-images.s3.us-east-2.amazonaws.com/FF-REGULAR_V6.1.png"
        />
        <h2 className="text-3xl font-semibold text-accent">
          Privacy Policy for ESPN Cookie Collector
        </h2>
      </div>

      <h3 className="text-xl font-semibold">Introduction</h3>
      <p className="text-light-text2 dark:text-dark-text2">
        Welcome to the ESPN Cookie Collector extension. This privacy policy
        explains how we collect, use, and protect your information when you use
        our extension.
      </p>

      <h3 className="text-xl font-semibold">Data we collect</h3>
      <p className="text-light-text2 dark:text-dark-text2">
        Cookies: Our extension collects cookies (SWID and espn_s2) from the ESPN
        domain to sync user data with the Flock Fantasy platform.
      </p>

      <h3 className="text-xl font-semibold">How we use the data</h3>
      <ul className="list-disc">
        <li className="text-light-text2 dark:text-dark-text2">
          <strong className="text-light-text dark:text-dark-text">
            Syncing Data
          </strong>
          : The collected cookies are used to authenticate and sync your ESPN
          account data with the Flock Fantasy platform.
        </li>
        <li className="text-light-text2 dark:text-dark-text2">
          <strong className="text-light-text dark:text-dark-text">
            No Data Sharing
          </strong>
          : We do not share, sell, or distribute your information to third
          parties. Data Storage and Security
        </li>
        <li className="text-light-text2 dark:text-dark-text2">
          <strong className="text-light-text dark:text-dark-text">
            Security Measures
          </strong>
          : We implement industry-standard security measures to protect your
          information from unauthorized access, use, or disclosure.
        </li>
      </ul>

      <h3 className="text-xl font-semibold">User Consent</h3>
      <p className="text-light-text2 dark:text-dark-text2">
        By using the ESPN Cookie Collector extension, you consent to the
        collection and use of your information as described in this privacy
        policy.
      </p>

      <h3 className="text-xl font-semibold">Changes to This Privacy Policy</h3>
      <p className="text-light-text2 dark:text-dark-text2">
        We may update this privacy policy from time to time. Any changes will be
        posted on this page, and we will notify you of significant changes
        through the extension.
      </p>

      <h3 className="text-xl font-semibold">Contact Us</h3>
      <p className="text-light-text2 dark:text-dark-text2">
        If you have any questions or concerns about this privacy policy, please
        contact us at support@flockfantasy.com
      </p>
    </div>
  )
}

export default EspnSyncPrivacyPolicy
