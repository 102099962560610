import React, { useState, useEffect, useRef } from 'react'
import { IoChevronDownOutline } from 'react-icons/io5'

const Select = ({
  options,
  onSelect,
  placeholder = 'Select...',
  disabled = false,
  defaultValue,
  lastUpdated = {},
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')
  const [openUpwards, setOpenUpwards] = useState(false)
  const dropdownRef = useRef(null)
  const buttonRef = useRef(null)

  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect()
      const isOverflowing = dropdownRect.bottom > window.innerHeight
      setOpenUpwards(isOverflowing)
    }
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  useEffect(() => {
    if (defaultValue) {
      setSelectedOption(defaultValue.label)
    }
  }, [defaultValue])

  const handleSelect = (option) => {
    setSelectedOption(option.label)
    onSelect(option)
    setIsOpen(false)
  }

  const buttonClass = disabled
    ? 'flex justify-between items-center border-0 rounded-custom bg-gray-200 text-gray-500 px-[14px] py-[13px] w-full min-w-[150px] cursor-not-allowed'
    : 'flex justify-between items-center border-0 rounded-custom bg-light-bg-select dark:bg-dark-select-bg text-light-text2 dark:text-dark-text2 px-[14px] py-[13px] w-full min-w-[150px]'

  // Convert string options to objects
  const objectOptions = options.map((opt) => ({ label: opt }))

  return (
    <div className="relative">
      <button
        className={buttonClass}
        disabled={disabled}
        onClick={() => !disabled && setIsOpen(!isOpen)}
        ref={buttonRef}
      >
        {selectedOption || placeholder}
        <IoChevronDownOutline className="ml-2 fill-dark-text2" size={20} />
      </button>

      {isOpen && (
        <ul
          className={`absolute z-10 max-h-[30vh] w-full overflow-y-scroll rounded-custom rounded-br-custom border-[1px] border-light2 bg-light dark:border-dark-border dark:bg-dark ${openUpwards ? 'bottom-full' : 'mt-1'}`}
          ref={dropdownRef}
        >
          {objectOptions.map((option, idx) => {
            const date = lastUpdated[option.label]
              ? new Date(lastUpdated[option.label]).toLocaleDateString()
              : ''
            return (
              <li
                className="hover:bg-dark-hover flex cursor-pointer justify-between p-2 hover:bg-zinc-700 dark:text-dark-text"
                key={idx}
                onClick={() => handleSelect(option)}
              >
                <span>{option.label}</span>
                <span className="text-sm text-gray-500">{date}</span>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default Select
