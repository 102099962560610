import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getDateTimeString } from '../../utils/dateUtils.ts'
import { deletePostById as deletePostByIdAction } from '../../store/api.js'
import { IoEye } from 'react-icons/io5'
import {
  FaRegNewspaper,
  FaYoutube,
  FaEdit,
  FaTrashAlt,
  FaBookOpen,
} from 'react-icons/fa'
import { MdStorage } from 'react-icons/md'

const PostItem = (props) => {
  const navigate = useNavigate()

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const { post } = props
  const thumbnail =
    'https://fantasy-flock-article-images.s3.us-east-2.amazonaws.com/public/' +
    post.image
  const postDateTime = getDateTimeString(post.created_date)

  const stripHtmlTags = (str) => {
    if (str === null || str === '') {
      return false
    } else {
      str = str.toString()
    }
    return str.replace(/<[^>]*>/g, '')
  }

  const cleanBody = stripHtmlTags(post.body)

  const getIconAndTooltipForType = (typeId) => {
    switch (typeId) {
      case 1:
        return {
          icon: (
            <FaRegNewspaper
              className="fill-light-text dark:fill-dark-text"
              size={22}
            />
          ),
          tooltip: 'Post',
        }
      case 2:
        return {
          icon: (
            <FaYoutube
              className="fill-light-text dark:fill-dark-text"
              size={22}
            />
          ),
          tooltip: 'Video',
        }
      case 3:
        return {
          icon: (
            <MdStorage
              className="fill-light-text dark:fill-dark-text"
              size={22}
            />
          ),
          tooltip: 'Database',
        }
      case 4:
        return {
          icon: (
            <FaBookOpen
              className="fill-light-text dark:fill-dark-text"
              size={22}
            />
          ),
          tooltip: 'Draft Guide',
        }
      default:
        return { icon: null, tooltip: '' }
    }
  }

  const goToPost = () => {
    if (post.community) {
      navigate('/community_article/' + post.post_id + '/', {
        state: { edit: post.edit ? post.edit : false },
      })
    } else {
      navigate('/post/' + post.post_id + '/', {
        state: { edit: post.edit ? post.edit : false },
      })
    }
  }

  const handleDeleteClick = (event) => {
    event.stopPropagation()
    setDeleteDialogOpen(true)
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false)
  }

  const handleDeleteConfirmed = async () => {
    await deletePostByIdAction(post.post_id)
    props.postDeleted(post.post_id)
  }

  const handleEditClick = (event) => {
    event.stopPropagation()
    navigate(`/post/edit/${post.post_id}`)
  }

  return (
    <div className="w-full border-t-[1px] border-light2 dark:border-dark-border">
      <div
        className="flex w-full cursor-pointer flex-col rounded-lg px-4 py-4 transition-all duration-200 hover:shadow-custom-glow md:h-52 md:flex-row"
        onClick={goToPost}
      >
        <div className="mb-4 flex-none md:mb-0 md:w-60 lg:w-80">
          <img
            alt={`${post.title} by ${post.author}`}
            className="w-full rounded-lg object-cover object-center md:h-[175px]"
            src={thumbnail}
          />
        </div>

        <div className="ml-4 flex-grow">
          <div className="flex">
            <div className="text-lg font-medium text-light-text dark:text-dark-text">
              {post.title}
            </div>
            <div className="relative right-0 top-0 ml-auto p-2">
              <div title={getIconAndTooltipForType(post.type_id).tooltip}>
                {getIconAndTooltipForType(post.type_id).icon}
              </div>
            </div>
          </div>
          <div className="text-sm text-light-text2 dark:text-dark-text2">
            {post.author} · {postDateTime}
          </div>
          <div className="flex items-center gap-2 text-sm text-light-text2 dark:text-dark-text2">
            <IoEye className="fill-light-text dark:fill-dark-text" />
            {post.impressions}
          </div>
          <div className="mt-2 max-h-[80px] overflow-hidden text-ellipsis text-sm text-light-text2 dark:text-dark-text2">
            {cleanBody}
          </div>
        </div>
        {props.edit && (
          <div className="ml-4 flex">
            <span
              className={`status-chip ${post.status === 'accepted' ? 'bg-primary' : 'bg-warning'} px-2 pt-2 text-accent`}
            >
              {post.status}
            </span>
            <button className="flex p-2" onClick={handleEditClick}>
              <FaEdit
                className="fill-light-text transition-all duration-200 hover:fill-accent dark:fill-dark-text hover:dark:fill-accent"
                size={22}
              />
            </button>
            <button className="flex p-2" onClick={handleDeleteClick}>
              <FaTrashAlt
                className="fill-light-text transition-all duration-200 hover:fill-accent dark:fill-dark-text hover:dark:fill-accent"
                size={22}
              />
            </button>
          </div>
        )}
      </div>
      {deleteDialogOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 text-light-text dark:text-dark-text">
          <div className="flex flex-col items-center rounded-lg border-[1px] border-light2 bg-light p-5 shadow-lg backdrop-blur-lg dark:border-dark-border dark:bg-dark">
            <div className="dialog-title mb-4 text-lg font-bold">
              Are you sure you want to delete?
            </div>
            <div className="dialog-content mb-4">
              This content will be deleted forever.
            </div>
            <div className="flex gap-4">
              <button
                className="rounded bg-gray-200 px-4 transition duration-150 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600"
                onClick={handleDeleteDialogClose}
              >
                Cancel
              </button>
              <button
                className="rounded bg-red-600 px-4 py-3 text-white transition duration-150 hover:bg-red-700"
                onClick={handleDeleteConfirmed}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PostItem
