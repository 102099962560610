export const promoterImages = {
  '38f6c2ae-6010-416a-8947-973a4ddc58e6':
    'https://static-site-images.s3.us-east-2.amazonaws.com/FF-REGULAR_V6.1.png',
  '222696a0-038d-4689-a3e6-a97046cd2eec':
    'https://static-site-images.s3.us-east-2.amazonaws.com/Domain_Logo.png',
  '83f307b7-2101-4dd0-8716-0a4fc7d50dbb':
    'https://static-site-images.s3.us-east-2.amazonaws.com/Domain_Logo.png',
  'e7c76f08-7131-416f-b08d-49f7b8dec85e': 'DynastyLand3.png',
  '5ac59844-e390-46dd-9ecd-b50ecc3c0a08': 'DynastyLand3.png',
  'e401f630-5049-4c83-9b0a-453c1c547ebc': 'FantasyStockExchange.png',
  '4e433a01-5935-4286-a73d-6df5015bcae2': 'FantasyStockExchange.png',
}
