/* eslint-disable react/no-unescaped-entities */
import React from 'react'

function Team() {
  return (
    <>
      <div className="bg-light font-dmsans text-light-text dark:bg-dark dark:text-dark-text">
        <div className="w-full py-8 text-center text-3xl font-bold">
          Meet the Team
        </div>
        <div className="members-container mx-auto p-4">
          <div className="flex flex-wrap justify-center gap-8">
            <div className="flex max-w-sm flex-col overflow-hidden rounded-lg border-[1px] border-dark-border bg-zinc-800">
              <img
                alt="Mason"
                className=""
                src="https://static-site-images.s3.us-east-2.amazonaws.com/mason2.png"
              />
              <div className="p-4">
                <div className="flex items-center justify-between py-2 pr-8">
                  <h4 className="mb-2 text-lg font-bold text-white">
                    Mason Dodd
                  </h4>
                  <div className="w-[75px]">
                    <img
                      className=""
                      src="https://static-site-images.s3.us-east-2.amazonaws.com/FF-REGULAR_V6.1.png"
                    />
                  </div>
                </div>
                <p className="text-sm text-white">
                  While attending the University of Texas, Mason launched the
                  Fantasy Flock Network in 2018 and has grown it to be one of
                  the largest fantasy football channels on YouTube. He’s been a
                  winning dynasty player since 2014 and this past season he took
                  down multiple high stakes Underdog Fantasy tournaments
                  totaling a profit of ~$150,000.
                </p>
              </div>
            </div>
            <div className="flex max-w-sm flex-col overflow-hidden rounded-lg border-[1px] border-dark-border bg-zinc-800">
              <img
                alt="Fantasy Domain"
                className=""
                src="https://static-site-images.s3.us-east-2.amazonaws.com/Compressed_Domain_Image.jpg"
              />
              <div className="p-4">
                <div className="flex items-center justify-between py-2 pr-8">
                  <h4 className="mb-2 text-lg font-bold text-white">
                    Domain Fantasy Football
                  </h4>
                  <div className="w-[75px]">
                    <img
                      className="logo-small"
                      src="https://static-site-images.s3.us-east-2.amazonaws.com/Domain_Logo.png"
                    />{' '}
                  </div>
                </div>
                <p className="text-sm text-white">
                  &emsp;Avery and Nathan started the Dynasty Domain podcast in
                  February 2022. After some initial success, they launched the
                  “Dynasty Domain” YouTube channel in August 2022 with the goal
                  of helping people focus on the actual goal of playing Dynasty:
                  winning your leagues and winning money. During the summer of
                  2023, they launched the “Domain Fantasy Football” YouTube
                  channel, dedicated to redraft and bestball fantasy football.
                  They both live and record in Indianapolis, Indiana, and have
                  been Colts fans their entire lives.
                </p>
              </div>
            </div>
            <div className="flex max-w-sm flex-col overflow-hidden rounded-lg border-[1px] border-dark-border bg-zinc-800">
              <img
                alt="Land"
                className=""
                src="https://static-site-images.s3.us-east-2.amazonaws.com/LandProfile.png"
              />
              <div className="p-4">
                <div className="flex items-center justify-between gap-2 py-2 pr-8">
                  <h4 className="mb-2 text-lg font-bold text-white">
                    {' '}
                    FantasyLand{' '}
                  </h4>
                  <div className="w-[200px]">
                    <img src="DynastyLand3.png" />
                  </div>
                </div>
                <p className="text-sm text-white">
                  After moving to Australia, Zach and Badake ended up being
                  housemates in 2016 and have been great friends ever since.
                  Born and raised in Brooklyn, Badake is a massive Giants fan
                  and started playing fantasy football in 2009. From Virginia
                  and Missouri, Zach is a fan of both the Chiefs and Commanders
                  and started playing fantasy football in 2005.
                  <br />
                  <br />
                  &emsp; In late 2020, they launched the FantasyLand Football
                  YouTube channel, dedicated to Re-Draft, as a response to
                  losing work from the pandemic. Building on their initial
                  success, in 2021, they established DynastyLand Football, a
                  channel exclusively devoted to Dynasty. Collectively, these
                  channels offer unparalleled guidance for both dynasty and
                  traditional redraft leagues.
                </p>
              </div>
            </div>
            <div className="flex max-w-sm flex-col overflow-hidden rounded-lg border-[1px] border-dark-border bg-zinc-800">
              <img
                alt="Corey and Daniel"
                className=""
                src="https://static-site-images.s3.us-east-2.amazonaws.com/Corey%26McKinnon.jpg"
              />
              <div className="p-4">
                <div className="flex items-center justify-between py-2 pr-8">
                  <h4 className="mb-2 text-lg font-bold text-white">
                    Fantasy Stock Exchange
                  </h4>
                  <div className="w-[75px]">
                    <img
                      className="logo-small"
                      src="FantasyStockExchange.png"
                    />
                  </div>
                </div>
                <p className="text-sm text-white">
                  After meeting at a small college in Canada, Corey and Danny
                  decided to launch the Fantasy Stock Exchange in 2020. After
                  falling in love with creating fantasy football content they
                  decided to go all in and become full time fantasy analysts.
                  Corey quit his job as a financial analyst and Danny decided to
                  drop out of University to pursue their dream.
                  <br />
                  <br />
                  &emsp;Corey is a diehard Bucs fan and is a firm believer in
                  always refining his process and sharing his discoveries along
                  the way. Danny is a massive Cowboys fan and is known as the
                  “Tone Setter” from his playstyle at both fullback and
                  linebacker. Together they provide a unique insight on how to
                  dominate both your dynasty and redraft leagues.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Team
