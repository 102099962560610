import React, { useEffect, useRef, useState } from 'react'
import Post from './PostItem.jsx'
import PostSkeleton from './PostSkeleton.jsx'
import { useSelector } from 'react-redux'
import { getPosts as getPostsAction } from '../../store/api.js'
import Select from '../_ui/Select.jsx'
import { CiSearch } from 'react-icons/ci'
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md'

const PostList = () => {
  const [posts, setPosts] = useState([])
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const { loggedIn } = useSelector((state) => state.user)
  const [searchText, setSearchText] = useState('')

  const [filterOption, setFilterOption] = useState('1,2,3,4')
  const [postOption, setpostOption] = useState('both')
  const [typeOption, setTypeOption] = useState('1,2,3,4')
  const [isLoading, setIsLoading] = useState(false)

  const POSTS_PER_PAGE = 8
  const prevPage = useRef(0)

  const sortOptions = ['All Posts', 'Databases', 'Draft Guides']

  const sortOptionValues = {
    'All Posts': '1,2,3,4',
    Databases: '3',
    'Draft Guides': '4',
  }

  useEffect(() => {
    if (prevPage.current !== page) {
      prevPage.current = page
      getPosts()
    }
  }, [page])

  useEffect(() => {
    getPosts()
  }, [filterOption, postOption, typeOption])

  const getPosts = async () => {
    setIsLoading(true)
    try {
      const options = {
        page,
        initial: 1,
        type: filterOption,
        filter: 'recent' + ',' + 'both',
      }
      if (searchText.length > 0) {
        options.search = searchText
      }

      const { posts, resultsCount } = await getPostsAction(options)
      setPosts(posts)
      let pageCount = Math.ceil(resultsCount / POSTS_PER_PAGE)
      setTotalPages(pageCount)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const searchPosts = async () => {
    try {
      const optionTypes = typeOption === 0 ? '1,2,3,4' : typeOption.toString()

      const options = {
        page,
        initial: 1,
        type: optionTypes,
        filter: filterOption + ',' + postOption,
        search: searchText.trim(),
      }

      const { posts, resultsCount } = await getPostsAction(options)
      setPosts(posts)
      setTotalPages(Math.ceil(resultsCount / POSTS_PER_PAGE))
    } catch (error) {
      console.error(error)
    }
  }

  const handlePageChange = (newPage) => {
    setPage(newPage)
  }

  const handleSortChange = (selectedOption) => {
    const value = sortOptionValues[selectedOption.label]
    console.log('value', value)
    setFilterOption(value)
  }

  const handleSearchChange = (e) => {
    setSearchText(e.target.value)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setPage(0)
      searchPosts()
    }
  }

  return (
    <div className="flex flex-col items-center overflow-x-scroll px-4 md:px-[10%]">
      <div className="flex w-full flex-col justify-center gap-4 py-10 md:flex-row">
        <div className="flex flex-col items-center justify-center">
          <div className="flex">
            <div className="hidden items-center justify-center gap-10 px-6 py-6 md:flex">
              <span className="h-[200px] w-[200px]">
                <img
                  alt="Logo"
                  src="https://static-site-images.s3.us-east-2.amazonaws.com/FF-REGULAR_V6.1.png"
                />
              </span>
            </div>
            <div className="mt-2 flex items-center py-4 text-4xl font-bold text-light-text dark:text-dark-text md:text-6xl">
              <span className="h-[100px] w-[100px] md:hidden">
                <img
                  alt="Logo"
                  src="https://static-site-images.s3.us-east-2.amazonaws.com/FF-REGULAR_V6.1.png"
                />
              </span>
              <div className="flex flex-col justify-center pl-6 md:block">
                {loggedIn ? 'Welcome to ' : 'Join '}{' '}
                <span className="h-[100px] w-[100px]">
                  <img
                    alt="Logo"
                    height={100}
                    src="https://static-site-images.s3.us-east-2.amazonaws.com/FF-REGULAR_V5.3.png"
                    width={400}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="flex w-full max-w-[80%] flex-col items-center justify-center gap-4 md:flex-row">
            <div className="flex w-full justify-center gap-3 md:justify-normal">
              <div className="mt-2 flex w-full items-center gap-4 rounded-custom border-[1px] border-light2 p-3 dark:border-dark-border">
                <CiSearch className="fill-light-bg2" size={22} />
                <input
                  className="bg-transparent text-sm text-light-text2 outline-none dark:text-dark-text2"
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Search..."
                  type="text"
                />
              </div>
            </div>
            <div className="mt-2 flex w-full items-center justify-start gap-4 md:w-36">
              <Select
                onSelect={handleSortChange}
                options={sortOptions}
                placeholder="All Posts"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-col items-center">
        {isLoading
          ? Array.from(new Array(POSTS_PER_PAGE)).map((_, index) => (
              <PostSkeleton key={index} />
            ))
          : posts.map((post, index) => <Post key={index} post={post} />)}
      </div>

      <div className="my-6 flex items-center justify-center gap-6">
        <button
          className="hover:text-black"
          disabled={page === 0}
          onClick={() => handlePageChange(Math.max(page - 1, 0))}
        >
          <MdArrowBackIosNew
            className="cursor-pointer fill-light-text transition-colors duration-200 hover:fill-accent dark:fill-dark-text"
            size={22}
          />
        </button>
        <span className="text-light-text2 dark:text-dark-text2">
          Page {page + 1} of {totalPages}
        </span>
        <button
          disabled={page >= totalPages - 1}
          onClick={() => handlePageChange(Math.min(page + 1, totalPages - 1))}
        >
          <MdArrowForwardIos
            className="cursor-pointer fill-light-text transition-colors duration-200 hover:fill-accent dark:fill-dark-text"
            size={22}
          />
        </button>
      </div>
    </div>
  )
}

export default PostList
