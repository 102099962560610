import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getItemDetails } from '../../../store/api'
import TeamReviewPage from './TeamReviews/TeamReviews'
import StartSitQuestionPage from './StartSit/StartSit'
import TradeQuestionPage from './TradeQuestions/TradeQuestions'
import loadingGif from '../../../images/FlockLoadingIcon.gif'
import SignInModal from '../../_ui/SignInModal'
import { useSelector } from 'react-redux'

const Products = () => {
  const [product, setProduct] = useState(null)
  const [loading, setLoading] = useState(true)
  const loggedIn = useSelector((state) => state.user.loggedIn)
  const location = useLocation()

  useEffect(() => {
    fetchProduct()
  }, [])

  const fetchProduct = async () => {
    try {
      const response = await getItemDetails(
        location.pathname.split('/').slice(-1)[0]
      )
      setProduct(response.data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const renderProductPage = () => {
    switch (product[0].name) {
      case 'Team Review':
        return <TeamReviewPage product={product} />
      case 'Start/Sit Question':
        return <StartSitQuestionPage product={product} />
      case 'Trade Question':
        return <TradeQuestionPage product={product} />
      default:
        return <div>Unknown product</div>
    }
  }

  return (
    <div>
      {!loggedIn && <SignInModal />}
      {loading ? (
        <div className="flex h-[90vh] flex-col items-center justify-center">
          <img
            alt="Loading..."
            src={loadingGif}
            style={{ width: '250px', height: '250px' }}
          />
          <span className="text-light-text dark:text-dark-text">
            Loading...
          </span>
        </div>
      ) : !product ? (
        <div>Product not found.</div>
      ) : (
        renderProductPage()
      )}
    </div>
  )
}

export default Products
