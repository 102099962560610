import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './AdminPanel.css'

import {
  getCommunityRankings as getCommunityRankingsAction,
  getTCW as getTCWAction,
  updateTCWLeague as updateTCWLeagueAction,
  updateTCWRoster as updateTCWRosterAction,
  updateTCWScoring as updateTCWScoringAction,
  getUserRole as getUserRoleAction,
} from '../../../store/api'

import AddPlayers from './AddPlayers'
import LeagueSize from './LeagueSize'
import PlayerScores from './PlayerScores'
import RosterDepth from './RosterDepth'
import Scoring from './Scoring'
import TePremium from './TePremium'
import {
  calcLeagueSize,
  calcScaleDepth,
  calcScaleDepthInverse,
} from './tradeCalc'
import { ColoredText } from './UtilComponents'

const DYNASTY = 'DYNASTY'
const REDRAFT = 'REDRAFT'
const SHALLOW = 'SHALLOW'
const NORMAL = 'NORMAL'
const DEEP = 'DEEP'
const VERY_DEEP = 'VERY_DEEP'
const HALF = 'HALF'
const IS_ACTIVE = '1'
const IS_INACTIVE = '0'

const leagueSizeDefaults = {
  8: { numerator: '8', denominator: '4', base: '2', log: '10' },
  10: { numerator: '10', denominator: '4', base: '2', log: '10' },
  12: { numerator: '10', denominator: '4', base: '2', log: '10' },
  14: { numerator: '10', denominator: '4', base: '2', log: '10' },
  16: { numerator: '10', denominator: '4', base: '2', log: '10' },
}

const scoringFactorsDefault = {
  NON: { rb: '1.1', wr: '0.9', te: '0.9', qb: '1.0', te_premium: '1.0' },
  HALF: { rb: '1.05', wr: '1.05', te: '1.05', qb: '1.0', te_premium: '1.1' },
  PPR: { rb: '1.0', wr: '1.1', te: '1.1', qb: '1.0', te_premium: '1.2' },
}

const defaultDepthMultiplier = {
  [SHALLOW]: { scale: '1', useInverse: false },
  [NORMAL]: { scale: '1', useInverse: false },
  [DEEP]: { scale: '1', useInverse: false },
  [VERY_DEEP]: { scale: '1', useInverse: false },
}

const defaultUserSettings = {
  size: {
    [DYNASTY]: leagueSizeDefaults,
    [REDRAFT]: leagueSizeDefaults,
  },
  scoring: scoringFactorsDefault,
  rosterDepth: {
    [DYNASTY]: defaultDepthMultiplier,
    [REDRAFT]: defaultDepthMultiplier,
  },
}

const LEAGUE_SIZE_DEFAULT = '12'
const SCORING_DEFAULT = HALF
const ROSTER_DEPTH = NORMAL

const AdminPanel = () => {
  const navigate = useNavigate()

  const [allowedAccess, setAllowedAccess] = useState(false)
  const [formatSelect, setFormatSelect] = useState(REDRAFT)
  const [superflexSelect, setSuperflexSelect] = useState(IS_INACTIVE)
  const [leagueSizeSelect, setLeagueSizeSelect] = useState(LEAGUE_SIZE_DEFAULT)
  const [scoringeSelect, setScoringSelect] = useState(SCORING_DEFAULT)
  const [rosterDepthSelect, setRosterDepthSelect] = useState(ROSTER_DEPTH)

  const [adminSettings, setAdminSettings] = useState(defaultUserSettings)
  const [playersById, setPlayersById] = useState({})
  const [playersByRank, setPlayersByRank] = useState({})
  const [selectedPlayersByRank, setSelectedPlayersByRank] = useState([])

  const [rosterDepthLoading, setRosterDepthLoading] = useState('')
  const [scoringLoading, setScoringLoading] = useState('')
  const [sizeLoading, setSizeLoading] = useState('')

  useEffect(() => {
    getTradeCalculatorWeights()
    getUserRole()
  }, [])

  useEffect(() => {
    getCommunityRankings()
  }, [formatSelect, superflexSelect])

  const getCommunityRankings = async () => {
    let type
    if (superflexSelect === IS_ACTIVE) {
      type = 'superflex'
    } else if (formatSelect === DYNASTY) {
      type = 'qb'
    } else {
      type = 'year'
    }

    const { players } = await getCommunityRankingsAction({ type })
    const createPlayersByRank = {}
    Object.keys(players).forEach((playerId) => {
      const playerRecord = players[playerId]
      if (playerRecord.rank <= 250) {
        createPlayersByRank[playerRecord.rank] = playerRecord
      }
    })
    setPlayersById(players)
    setPlayersByRank(createPlayersByRank)
  }

  const getUserRole = async () => {
    try {
      const userRole = await getUserRoleAction()
      if (userRole.role === 2) {
        setAllowedAccess(true)
      } else {
        navigate('/', { replace: true })
      }
    } catch (err) {
      console.log('getUserRole ERROR: ', err)
      navigate('/', { replace: true })
    }
  }

  const getTradeCalculatorWeights = async () => {
    try {
      const weights = await getTCWAction()
      handleWeights(weights)
    } catch (err) {
      console.log('ERROR AdminSegment.getTradeCalculatorWeights : ', err)
    }
  }

  const handleLeagueFormat = (e) => {
    const val = e.target.value
    if (val === REDRAFT && superflexSelect === IS_ACTIVE) {
      setSuperflexSelect(IS_INACTIVE)
    }

    setFormatSelect(val)
  }

  const handleSuperflex = (e) => {
    const val = e.target.value
    if (val === IS_ACTIVE && formatSelect === REDRAFT) {
      setFormatSelect(DYNASTY)
    }

    setSuperflexSelect(val)
  }

  const handleWeights = ({ league, roster, scoring }) => {
    const leagueSizeSettings = JSON.parse(JSON.stringify(adminSettings.size))
    if (league) {
      league.forEach((leagueSettings) => {
        const {
          base_value,
          denominator_value,
          league_type,
          log_value,
          numerator_value,
          team_count,
        } = leagueSettings
        if (!leagueSizeSettings[league_type]) {
          leagueSizeSettings[league_type] = {}
        }
        leagueSizeSettings[league_type][team_count] = {
          base: base_value,
          denominator: denominator_value,
          numerator: numerator_value,
          log: log_value,
        }
      })
    }

    const rosterDepthSettings = { ...adminSettings.rosterDepth }
    if (roster) {
      roster.forEach((rosterSettings) => {
        const { league_type, roster_depth, scale, use_inverse } = rosterSettings
        if (!rosterDepthSettings[league_type]) {
          rosterDepthSettings[league_type] = {}
        }
        rosterDepthSettings[league_type][roster_depth] = {
          scale,
          useInverse: use_inverse === 1 ? true : false,
        }
      })
    }

    const scoringSettings = { ...adminSettings.scoring }
    if (scoring) {
      scoring.forEach((scoreSettings) => {
        const { scoring_id, qb, rb, wr, te, te_premium } = scoreSettings
        if (!scoringSettings[scoring_id]) {
          scoringSettings[scoring_id] = {}
        }
        scoringSettings[scoring_id] = {
          qb,
          rb,
          wr,
          te,
          te_premium,
        }
      })
    }

    const adminSettingsObj = {
      rosterDepth: rosterDepthSettings,
      size: leagueSizeSettings,
      scoring: scoringSettings,
    }

    setAdminSettings(adminSettingsObj)
  }

  const calcSize = (elo, rank) => {
    const { base, denominator, numerator, log } =
      adminSettings.size[formatSelect][leagueSizeSelect]
    const settings = {
      baseValue: base,
      denominator,
      numerator,
      logValue: log,
      playerRank: rank,
    }
    const adjusted = calcLeagueSize(settings)
    return Math.ceil(elo * adjusted)
  }

  const calcSizeScoring = (elo, rank, position) => {
    const currentElo = calcSize(elo, rank)

    return Math.ceil(
      adminSettings.scoring[scoringeSelect][position.toLowerCase()] * currentElo
    )
  }

  const calcSizeScoringTe = (elo, rank, position) => {
    const currentElo = calcSizeScoring(elo, rank, position)
    if (position === 'TE') {
      return Math.ceil(
        Number(currentElo) *
          Number(adminSettings.scoring[scoringeSelect].te_premium)
      )
    }
    return currentElo
  }

  const calcAll = (elo, rank, position) => {
    const currentElo = calcSizeScoringTe(elo, rank, position)

    const { scale, useInverse } =
      adminSettings.rosterDepth[formatSelect][rosterDepthSelect]

    const rosterDepthCalc = useInverse ? calcScaleDepthInverse : calcScaleDepth

    return rosterDepthCalc(scale, currentElo, rank)
  }

  const playerEloType =
    superflexSelect === IS_ACTIVE
      ? 'Dynasty Superflex'
      : formatSelect === DYNASTY
        ? 'Dynasty 1 QB'
        : 'Redraft'

  const saveLeagueWeights = async () => {
    const { base, denominator, numerator, log } =
      adminSettings.size[formatSelect][leagueSizeSelect]
    const reqData = {
      league_type: formatSelect,
      team_count: leagueSizeSelect,
      numerator,
      denominator,
      base,
      log,
    }

    setSizeLoading('Loading..')
    try {
      await updateTCWLeagueAction(reqData)
      setSizeLoading('Success!')
    } catch {
      setSizeLoading('FAILED!!')
    }

    setTimeout(() => {
      setSizeLoading('')
    }, 2000)
  }

  const saveRosterWeights = async () => {
    const { scale, useInverse } =
      adminSettings.rosterDepth[formatSelect][rosterDepthSelect]
    const reqData = {
      roster_depth: rosterDepthSelect,
      league_type: formatSelect,
      use_inverse: useInverse,
      scale: scale,
    }

    setRosterDepthLoading('Loading..')
    try {
      await updateTCWRosterAction(reqData)

      setRosterDepthLoading('Success!')
    } catch {
      setRosterDepthLoading('FAILED!!')
    }

    setTimeout(() => {
      setRosterDepthLoading('')
    }, 2000)
  }

  const saveScoringWeights = async () => {
    const { qb, rb, wr, te, te_premium } = adminSettings.scoring[scoringeSelect]
    const scoring_id = scoringeSelect

    setScoringLoading('Loading..')
    try {
      await updateTCWScoringAction({ scoring_id, qb, rb, wr, te, te_premium })

      setScoringLoading('Success!')
    } catch {
      setScoringLoading('FAILED!!')
    }

    setTimeout(() => {
      setScoringLoading('')
    }, 2000)
  }

  if (!allowedAccess || adminSettings === null) {
    return null
  }

  return (
    <div className="ap-container">
      <h1 className="ap-main-header">Trade Calculator Weights</h1>

      <div className="ap-user-settings-container">
        <div className="ap-header">
          Player ELO Type: <ColoredText text={playerEloType} />
        </div>

        <div className="ap-league-options">
          <div className="ap-user-setting-segment">
            <div>League Format</div>
            <select
              className="ap-user-settings-select"
              onChange={handleLeagueFormat}
              value={formatSelect}
            >
              <option value="REDRAFT">Redraft</option>
              <option value="DYNASTY">Dynasty</option>
            </select>
          </div>

          <div className="ap-user-setting-segment">
            <div>Superflex</div>
            <select
              className="ap-user-settings-select"
              onChange={handleSuperflex}
              value={superflexSelect}
            >
              <option value="1">ON</option>
              <option value="0">OFF</option>
            </select>
          </div>
        </div>
      </div>
      <div>
        {/* Add Playes */}
        <AddPlayers
          allPlayersById={playersById}
          allPlayersByRank={playersByRank}
          selectedPlayersByRank={selectedPlayersByRank}
          setSelectedPlayersByRank={setSelectedPlayersByRank}
        />

        {/* League Size */}
        <div className="ap-calc-area">
          <LeagueSize
            format={formatSelect}
            handleSettings={setAdminSettings}
            loadingState={sizeLoading}
            saveSettings={saveLeagueWeights}
            selectHandler={setLeagueSizeSelect}
            selectValue={leagueSizeSelect}
            userSettings={adminSettings}
          />
          <PlayerScores
            allPlayersByRank={playersByRank}
            multiplier={calcSize}
            prevMultiplier={(v) => v}
            selectedPlayersByRank={selectedPlayersByRank}
          />
        </div>

        {/* Scoring QB/RB/WR/TE */}
        <div className="ap-calc-area">
          <Scoring
            handleSettings={setAdminSettings}
            loadingState={scoringLoading}
            saveSettings={saveScoringWeights}
            selectHandler={setScoringSelect}
            selectValue={scoringeSelect}
            userSettings={adminSettings}
          />
          <PlayerScores
            allPlayersByRank={playersByRank}
            multiplier={calcSizeScoring}
            prevMultiplier={calcSize}
            selectedPlayersByRank={selectedPlayersByRank}
          />
        </div>

        {/* Scoring TE Premium */}
        <div className="ap-calc-area">
          <Fragment>
            <TePremium
              handleSettings={setAdminSettings}
              selectValue={scoringeSelect}
              userSettings={adminSettings}
            />
            <PlayerScores
              allPlayersByRank={playersByRank}
              multiplier={calcSizeScoringTe}
              prevMultiplier={calcSizeScoring}
              selectedPlayersByRank={selectedPlayersByRank}
              teCheck
            />
          </Fragment>
        </div>

        {/* Roster Depth */}
        <div>
          <RosterDepth
            format={formatSelect}
            handleSettings={setAdminSettings}
            loadingState={rosterDepthLoading}
            saveSettings={saveRosterWeights}
            selectHandler={setRosterDepthSelect}
            selectValue={rosterDepthSelect}
            userSettings={adminSettings}
          />
          <PlayerScores
            allPlayersByRank={playersByRank}
            multiplier={calcAll}
            prevMultiplier={calcSizeScoringTe}
            selectedPlayersByRank={selectedPlayersByRank}
          />
        </div>
      </div>
    </div>
  )
}

export default AdminPanel
