import React, { useState, useEffect, useRef } from 'react'
import * as Tabs from '@radix-ui/react-tabs'
import { useSelector } from 'react-redux'
import {
  saveMessages,
  getPresignedUrlForReview,
  saveFilesToS3,
  updateReviewStatus,
} from '../../../store/api'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { AllPromo } from '../../../static/AllPromo'
import { IoPersonSharp } from 'react-icons/io5'
import { FaCircleXmark, FaPaperclip, FaPlay } from 'react-icons/fa6'
import { FiDownload } from 'react-icons/fi'
import { ProgressBar } from 'react-loader-spinner'
import FeedbackModal from './FeedbackModal'
import Checkbox from '../Checkbox'
import ReactSimpleImageViewer from 'react-simple-image-viewer'
import SafariImageViewer from '../../_ui/SafariImageViewer'

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase()
  return ua.indexOf('safari') > -1 && ua.indexOf('chrome') === -1
}

const COMPLETE_REVIEW = 'complete'

const Review = ({ teamName, review, onUpdate, onStatusChange }) => {
  if (!review || !review.message_room) {
    return <div>Loading review details or not available...</div>
  }
  const navigate = useNavigate()

  const { is_creator, user_id, is_contributor } = useSelector(
    (state) => state.user
  )

  const [tab2, setTab2] = useState('chat')
  const [message, setMessage] = useState('')
  const [file, setFile] = useState(null)
  const [isChecked, setIsChecked] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [checkboxKey, setCheckboxKey] = useState(0)
  const [wordCount, setWordCount] = useState(0)
  const [uploadLoading, setUploadLoading] = useState(false)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const [images, setImages] = useState([])
  const [currentImage, setCurrentImage] = useState(0)
  const [messages, setMessages] = useState(review.message_room.messages)
  // const [ creators, setCreators ] = useState([])

  const fileInputRef = useRef(null)

  // useEffect(() => {
  //   fetchCreators()
  // }, [])

  useEffect(() => {
    if (isChecked) {
      if (is_creator || is_contributor) {
        updateToComplete()
      } else {
        updateToUserComplete()
      }
    }
  }, [isChecked])

  useEffect(() => {
    if (review.files.length > 0) {
      const imageUrls = review.files.map(
        (file) =>
          `https://fantasy-flock-review-files.s3.us-east-2.amazonaws.com/${file.file_id}`
      )
      setImages(imageUrls)
    }
  }, [review.files])

  useEffect(() => {
    setMessages(review.message_room.messages)
  }, [review.message_room.messages])

  //---------------------------------------------------------------- Data Fetching ------------------------------------------------------------------------------------

  const updateStatus = async (status) => {
    try {
      const payload = { status: status }
      await updateReviewStatus(review.id, payload)
      onUpdate()
      toast.success(`Updated to ${status}!`)
    } catch (error) {
      console.error('Error updating review status:', error)
      toast.error('Failed to update review status.')
    }
  }

  const updateToComplete = async () => {
    try {
      const payload = { status: 'complete' }
      await updateReviewStatus(review.id, payload)
      toast.success('Review status updated!')
      setCheckboxKey((prevKey) => prevKey + 1)
      setIsChecked(true)
    } catch (error) {
      console.error('Error updating review status:', error)
      toast.error('Failed to update review status.')
      setIsChecked(false)
    }
  }

  const updateToUserComplete = async () => {
    try {
      const payload = { has_requester_accepted: true }
      await updateReviewStatus(review.id, payload)
      toast.success('Review status updated!')
    } catch (error) {
      console.error('Error updating review status:', error)
      toast.error('Failed to update review status.')
      setIsChecked(false)
    }
  }

  const handleUpdateToComplete = async () => {
    onStatusChange(review.id)
    const success = await updateStatus('complete')
    if (success) {
      setIsChecked(true)
    }
  }

  const handleUpdateToRejected = async () => {
    onStatusChange(review.id)
    const success = await updateStatus('rejected')
  }

  const handleUpdateToInProgress = async () => {
    onStatusChange(review.id)
    const success = await updateStatus('in_progress')
  }

  const handleUpdateToUserComplete = async () => {
    onStatusChange(review.id)
    const success = await updateToUserComplete()
    if (success) {
      setIsChecked(true)
      setShowModal(true)
    }
  }

  const formatDate = (dateString) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const date = moment.tz(dateString, 'UTC').tz(userTimeZone)
    const currentDate = moment().tz(userTimeZone)
    if (currentDate.diff(date, 'hours') < 24) {
      return date.format('LT z')
    } else {
      return date.format('LL')
    }
  }

  const formatDateWithoutTime = (dateString) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const date = moment.tz(dateString, 'UTC').tz(userTimeZone)
    return date.format('LL')
  }

  const handleTab2Change = (newValue) => {
    setTab2(newValue)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      sendMessage()
    }
  }

  const handleTextChange = (event) => {
    const newText = event.target.value
    const words = newText.match(/\b\w+\b/g) || []
    setWordCount(words.length)
    if (words.length <= 250) {
      setMessage(newText)
    } else {
      toast.error('Message exceeds 250 word limit.')
    }
  }

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]
    if (selectedFile) {
      if (
        ['audio/mpeg', 'image/jpeg', 'image/png'].includes(selectedFile.type)
      ) {
        const maxFileSize = 100 * 1024 * 1024
        if (selectedFile.size <= maxFileSize) {
          setFile(selectedFile)
          toast.info(`File ${selectedFile.name} ready for upload`)
        } else {
          toast.error('File size exceeds the 30 MB limit.')
        }
      } else {
        toast.error('Please select a valid file type (MP3, JPEG, or PNG).')
      }
    }
  }

  const downloadAllImages = () => {
    review.files.forEach((file, index) => {
      setTimeout(() => {
        const link = document.createElement('a')
        link.href = `https://fantasy-flock-review-files.s3.us-east-2.amazonaws.com/${file.file_id}`
        link.download = file.fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }, index * 500)
    })
  }

  const triggerFileInput = () => {
    fileInputRef.current.click()
  }

  const addOneMonth = (dateString) => {
    const date = new Date(dateString)
    date.setMonth(date.getMonth() + 1)
    return date
  }

  const routeToMyTeams = () => {
    const queryParams = new URLSearchParams({
      league_id: review.league_id,
      id: review.id,
      team_name: encodeURIComponent(review.team_name || review.team_id),
      team_id: encodeURIComponent(review.team_id),
      comments: encodeURIComponent(review.comments),
      rookie_picks: encodeURIComponent(review.rookie_picks),
    })

    navigate(`/my-teams?${queryParams}`)
  }

  const sendMessage = async () => {
    setUploadLoading(true)
    try {
      let newMessage
      if (file) {
        const { presigned_url, file_id } = await getPresignedUrlForReview()
        const response = await saveFilesToS3(presigned_url, file)
        if (!response.success) {
          console.log('Failed to upload file to S3:', response)
          throw new Error(
            `Failed to upload file to S3: HTTP status ${response.status}`
          )
        }

        const fileDetails = [
          {
            fileId: file_id,
            fileName: file.name,
            fileType: file.type,
          },
        ]

        await saveMessages({
          messageRoomId: review.message_room.id,
          message: message,
          files: fileDetails,
        })

        newMessage = {
          user_id,
          created_on: new Date().toISOString(),
          message,
          files: [{ file_id, file_name: file.name, file_type: file.type }],
        }
      } else {
        await saveMessages({
          messageRoomId: review.message_room.id,
          message: message,
        })

        newMessage = {
          user_id,
          created_on: new Date().toISOString(),
          message,
          files: [],
        }
      }

      setMessages((prevMessages) => [...prevMessages, newMessage])
      toast.success('Message sent!')
      setMessage('')
      setFile(null)
    } catch (error) {
      console.error('Error sending message:', error)
      toast.error('Failed to send message')
    } finally {
      setUploadLoading(false)
    }
  }

  const openImageViewer = (index) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  const parseRookiePicks = (rookiePicksString) => {
    if (!rookiePicksString) {
      return []
    }
    return rookiePicksString.split(',').map((pick) => pick.trim())
  }

  const deletionDate = review.completed_on
    ? formatDateWithoutTime(addOneMonth(review.completed_on))
    : 'Not set'

  return (
    <div className="relative flex max-w-[1000px] flex-grow flex-col overflow-auto rounded-xl border-[1px] border-light2 dark:border-dark-border">
      <Tabs.Root
        className="flex h-full flex-col"
        defaultValue="chat"
        onValueChange={handleTab2Change}
      >
        <div className="absolute top-0 z-20 pb-2 md:px-12">
          <Tabs.List className="inline-flex overflow-hidden rounded-b-xl border-x-[1px] border-b-[1px] border-light2 bg-light-bg text-light-text2 dark:border-dark-border dark:bg-dark dark:text-dark-text2">
            <Tabs.Trigger
              className={`border-r-[1px] border-light2 p-4 transition-all duration-200 hover:bg-accent-bg hover:text-accent dark:border-dark-border hover:dark:text-accent ${
                tab2 === 'chat'
                  ? 'bg-accent-bg font-bold text-accent transition-all duration-200 hover:bg-accent-bg hover:text-accent dark:bg-accent-bg hover:dark:text-accent'
                  : ''
              }`}
              value="chat"
            >
              Chat
            </Tabs.Trigger>
            <Tabs.Trigger
              className={`border-r-[1px] border-light2 p-4 transition-all duration-200 hover:bg-accent-bg hover:text-accent dark:border-dark-border hover:dark:text-accent ${
                tab2 === 'details'
                  ? 'bg-accent-bg font-bold text-accent transition-all duration-200 hover:bg-accent-bg hover:text-accent dark:bg-accent-bg hover:dark:text-accent'
                  : ''
              }`}
              value="details"
            >
              Details
            </Tabs.Trigger>
          </Tabs.List>
        </div>
        <div className="z-[15] flex gap-4 rounded-lg backdrop-blur-md md:absolute md:right-6 md:top-6">
          {(is_creator || is_contributor) && (
            <div className="flex flex-col gap-4 md:flex-row">
              {review.status === 'pending' && (
                <div
                  className="flex cursor-pointer items-center rounded-lg border-[1px] border-light2 px-3 py-2 dark:border-dark-border"
                  onClick={handleUpdateToInProgress}
                >
                  <FaPlay className="pr-2 text-green-600" size={22} />
                  <span className="text-sm font-bold text-light-text dark:text-dark-text">
                    Start
                  </span>
                </div>
              )}
              <div
                className="flex cursor-pointer items-center rounded-lg border-[1px] border-light2 px-3 py-2 dark:border-dark-border"
                onClick={handleUpdateToRejected}
              >
                <FaCircleXmark className="mr-2 text-red-600" size={22} />
                <span className="text-sm font-bold text-light-text dark:text-dark-text">
                  Reject
                </span>
              </div>
            </div>
          )}
          {is_creator || is_contributor ? (
            <Checkbox
              text={
                review.status === COMPLETE_REVIEW
                  ? 'Marked as Complete'
                  : 'Mark as Complete'
              }
              defaultChecked={review.status === COMPLETE_REVIEW}
              key={review.id}
              onChange={handleUpdateToComplete}
            />
          ) : review.has_requester_accepted ? (
            <span className="text-sm font-bold text-accent">Complete</span>
          ) : review.status === COMPLETE_REVIEW ? (
            <Checkbox
              defaultChecked={false}
              key={review.id}
              onChange={handleUpdateToUserComplete}
              text="Mark as Complete"
            />
          ) : (
            <span className="text-sm font-bold text-accent">
              Waiting for Creator
            </span>
          )}
        </div>
        <Tabs.Content className="h-full" value="chat">
          <div className="flex h-full flex-grow flex-col">
            <div className="flex h-[75vh] w-full flex-grow flex-col gap-2 overflow-auto px-1 pb-[175px] pt-24 md:h-64 md:px-12 md:pb-[105px]">
              {messages.map((message, index) => {
                const formattedTimestamp = formatDate(message.created_on)
                const isRightAligned =
                  message.user_id === user_id ||
                  (is_creator &&
                    Object.values(AllPromo).includes(message.user_id))
                return (
                  <div
                    className={`text-left ${isRightAligned ? 'flex justify-end' : 'flex justify-start'}`}
                    key={index}
                  >
                    <div
                      className={`max-w-full rounded-lg px-3 py-2 md:min-w-[20rem] md:max-w-[50%] ${isRightAligned ? 'bg-zinc-300 dark:bg-zinc-600' : 'bg-accent-bg'}`}
                    >
                      <div className="flex justify-between gap-2">
                        <span
                          className={`font-bold ${isRightAligned ? 'text-light-text dark:text-dark-text' : 'text-accent'}`}
                        >
                          {is_creator || is_contributor
                            ? isRightAligned
                              ? 'You'
                              : review.user_display_name
                            : isRightAligned
                              ? 'You'
                              : review.promoter_display_name}
                        </span>
                        <div className=""></div>
                        <span className="text-light-text2 dark:text-dark-text2">
                          {formattedTimestamp}
                        </span>
                      </div>
                      <p className="text-light-text dark:text-dark-text">
                        {message.message}
                      </p>
                      {message.files && message.files.length > 0 && (
                        <div>
                          {message.files &&
                            message.files.map((file, index) => (
                              <div
                                className="my-2 flex max-w-full items-center gap-2"
                                key={index}
                              >
                                {file.file_type === 'audio/mpeg' ? (
                                  <audio className="w-full" controls>
                                    <source
                                      src={`https://fantasy-flock-message-files.s3.us-east-2.amazonaws.com/${file.file_id}`}
                                      type="audio/mpeg"
                                    />
                                    Your browser does not support the audio
                                    element.
                                  </audio>
                                ) : file.file_type === 'image/jpeg' ||
                                  file.file_type === 'image/png' ? (
                                  <img
                                    alt={file.file_name}
                                    src={`https://fantasy-flock-message-files.s3.us-east-2.amazonaws.com/${file.file_id}`}
                                    style={{ maxWidth: '100%', height: 'auto' }}
                                  />
                                ) : (
                                  <a
                                    className="w-full overflow-hidden overflow-ellipsis whitespace-nowrap text-accent underline"
                                    href={`https://fantasy-flock-message-files.s3.us-east-2.amazonaws.com/${file.file_id}`}
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    {file.file_name}
                                  </a>
                                )}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
              {review.status === COMPLETE_REVIEW && (
                <p className="text-light-text2 dark:text-dark-text2">
                  You have until
                  <span className="px-1 text-accent">{deletionDate}</span>
                  to download any files.
                </p>
              )}
            </div>
            <div className="absolute bottom-0 w-full border-t-[1px] border-light2 bg-light2 px-2 py-4 dark:border-dark-border dark:bg-dark md:px-12">
              <div className="flex flex-col gap-2 md:flex-row md:items-center md:gap-0">
                <input
                  disabled={
                    review.status === COMPLETE_REVIEW &&
                    !(is_creator || is_contributor)
                  }
                  className={`mr-2 flex-grow rounded-lg border-[1px] border-light2 bg-light px-3 py-2 dark:border-dark-border dark:bg-dark ${review.status === COMPLETE_REVIEW && !(is_creator || is_contributor) ? 'cursor-not-allowed opacity-50' : ''}`}
                  onChange={handleTextChange}
                  onKeyPress={handleKeyPress}
                  placeholder="Type your message..."
                  type="text"
                  value={message}
                />
                {uploadLoading ? (
                  <ProgressBar
                    barColor="#E97933"
                    borderColor="#BB8360"
                    height={50}
                    type="Oval"
                    width={70}
                  />
                ) : (
                  <button
                    disabled={
                      review.status === COMPLETE_REVIEW &&
                      !(is_creator || is_contributor)
                    }
                    className={`rounded-lg bg-accent px-4 py-2 text-dark-text transition-colors duration-200 hover:bg-orange-600 ${(review.status === COMPLETE_REVIEW) === true && !(is_creator || is_contributor) ? 'cursor-not-allowed opacity-50' : ''}`}
                    onClick={sendMessage}
                  >
                    Send
                  </button>
                )}
                <div className="flex items-center">
                  <button
                    disabled={
                      (review.status === COMPLETE_REVIEW) === true &&
                      !(is_creator || is_contributor)
                    }
                    className={`p-3 ${review.status === COMPLETE_REVIEW && !(is_creator || is_contributor) ? 'cursor-not-allowed opacity-50' : ''}`}
                    onClick={triggerFileInput}
                  >
                    <FaPaperclip />
                  </button>
                  <input
                    disabled={
                      (review.status === COMPLETE_REVIEW) === true &&
                      !(is_creator || is_contributor)
                    }
                    accept="audio/mpeg,image/jpeg,image/png"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    type="file"
                  />
                  <div className="flex w-full justify-between md:hidden">
                    <span className="text-sm text-light-text2 dark:text-dark-text2">
                      {wordCount}/250 words
                    </span>
                    {file && (
                      <div className="w-64 overflow-hidden text-ellipsis whitespace-nowrap text-sm text-light-text2 dark:text-dark-text2">
                        {file.name}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="hidden w-full justify-between pt-2 md:flex">
                <span className="text-sm text-light-text2 dark:text-dark-text2">
                  {wordCount}/250 words
                </span>
                {file && (
                  <div className="w-64 overflow-hidden text-ellipsis whitespace-nowrap text-sm text-light-text2 dark:text-dark-text2">
                    {file.name}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Tabs.Content>
        <Tabs.Content className="pt-16" value="details">
          {review.files.length > 0 ? (
            <div className="flex h-full flex-grow flex-col gap-3 px-10 pb-10">
              <div className="flex pt-4 text-xl font-bold text-accent">
                {teamName}
                {is_creator && review.price && (
                  <div className="pl-2 text-light-text2 dark:text-dark-text2">
                    ${(review.price / 100).toFixed(2)}
                  </div>
                )}
              </div>
              <div className="grid grid-cols-3">
                <div className="col-span-1">
                  {' '}
                  {review.type && (
                    <div className="flex w-full flex-col gap-2 md:flex-row md:justify-between md:gap-0">
                      <p>
                        Tier:
                        <div className="text-light-text2 dark:text-dark-text2">
                          {`${review.type}`}
                        </div>
                      </p>
                    </div>
                  )}
                </div>
                <div></div>
              </div>

              <div className="grid auto-rows-auto grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                <div className="col-span-1 grid gap-2">
                  <div className="text-xl font-bold">League Settings</div>
                  <div className="grid grid-cols-2 gap-2">
                    <span className="font-semibold text-accent">
                      League Type:
                    </span>
                    <span>{review.league_settings?.leagueType || 'N/A'}</span>

                    <span className="font-semibold text-accent">PPR:</span>
                    <span>{review.league_settings?.ppr || 'N/A'}</span>

                    <span className="font-semibold text-accent">QBs:</span>
                    <span>{review.league_settings?.qbs || 'N/A'}</span>

                    <span className="font-semibold text-accent">Teams:</span>
                    <span>{review.league_settings?.teamCounts || 'N/A'}</span>

                    <span className="font-semibold text-accent">TEP:</span>
                    <span>
                      {review.league_settings?.tep !== undefined
                        ? String(review.league_settings.tep)
                        : 'N/A'}
                    </span>

                    <span className="font-semibold text-accent">Lineup:</span>
                    <span>
                      {review.league_settings?.lineupCount !== undefined
                        ? String(review.league_settings.lineupCount)
                        : 'N/A'}
                    </span>
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="text-xl font-bold">Picks</div>
                  <div className="pb-3">
                    {review.rookie_picks
                      ? parseRookiePicks(review.rookie_picks).map(
                          (pick, index) => (
                            <div
                              className="flex items-center gap-2"
                              key={index}
                            >
                              <IoPersonSharp className="text-accent" />
                              <span className="text-lg">{pick}</span>
                            </div>
                          )
                        )
                      : 'No Picks'}
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="text-xl font-bold">Comments</div>
                  <div>{review.comments}</div>
                </div>
              </div>

              <div className="flex gap-4 pt-4">
                <div className="text-xl font-bold">Images</div>
                <FiDownload
                  className="cursor-pointer text-accent"
                  onClick={downloadAllImages}
                  size={24}
                />
              </div>
              <div className="flex flex-wrap gap-2">
                {review.files.map((file, index) => (
                  <div
                    className="flex flex-col items-center justify-center overflow-hidden rounded-lg"
                    key={index}
                  >
                    <img
                      alt={file.fileName}
                      className="h-72 max-h-full w-72 max-w-full cursor-pointer object-cover"
                      onClick={() => openImageViewer(index)}
                      src={`https://fantasy-flock-review-files.s3.us-east-2.amazonaws.com/${file.file_id}`}
                    />
                  </div>
                ))}
              </div>
              {isViewerOpen && (
                <div className="">
                  {isSafari() ? (
                    <SafariImageViewer
                      currentImage={currentImage}
                      images={images}
                      isOpen={isViewerOpen}
                      onClose={closeImageViewer}
                    />
                  ) : (
                    <div className="relative z-50">
                      <ReactSimpleImageViewer
                        currentIndex={currentImage}
                        disableScroll={false}
                        onClose={closeImageViewer}
                        src={images}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col gap-6 px-2 pt-4 md:px-12">
              <div className="flex flex-col justify-between gap-2 md:flex-row md:gap-0">
                <div className="flex pt-4 text-xl font-bold text-accent">
                  {teamName}
                  {is_creator && review.price && (
                    <div className="pl-2 text-light-text2 dark:text-dark-text2">
                      ${(review.price / 100).toFixed(2)}
                    </div>
                  )}
                </div>
                <button
                  className="w-48 rounded-lg border-[1px] border-light2 px-4 py-2 text-dark-text transition-all duration-200 hover:bg-dark-bg2 dark:border-dark-border"
                  onClick={routeToMyTeams}
                >
                  View in My Teams
                </button>
              </div>
              <div className="flex max-w-[1000px] flex-col gap-4 px-2 md:px-16">
                {review.type && (
                  <div className="flex w-full flex-col gap-2 md:flex-row md:justify-between md:gap-0">
                    <p>
                      Tier:
                      <div className="text-light-text2 dark:text-dark-text2">
                        {`${review.type}`}
                      </div>
                    </p>
                  </div>
                )}
                <div className="flex w-full flex-col gap-2 md:flex-row md:justify-between md:gap-0">
                  <p>
                    Discord Name:
                    <div className="text-light-text2 dark:text-dark-text2">
                      {review.discord_username}
                    </div>
                  </p>
                  <p className="min-w-[200px]">
                    Sleeper Username:
                    <div className="text-light-text2 dark:text-dark-text2">
                      {review.platform_username || 'No Username'}
                    </div>
                  </p>
                </div>
                <div className="flex w-full flex-col gap-2 md:flex-row md:justify-between md:gap-0">
                  <p>
                    League ID:
                    <div className="text-light-text2 dark:text-dark-text2">
                      {review.league_id}
                    </div>
                  </p>
                  <p className="min-w-[200px]">
                    Request by:
                    <div className="text-light-text2 dark:text-dark-text2">
                      {review.user_display_name || review.user_id}
                    </div>
                  </p>
                </div>
                <div className="flex w-full flex-col gap-2 md:flex-row md:justify-between md:gap-0">
                  <p>
                    Team Name:
                    <div className="text-light-text2 dark:text-dark-text2">
                      {review.team_name || review.team_id}
                    </div>
                  </p>
                  <p className="min-w-[200px]">
                    Creator:
                    <div className="text-light-text2 dark:text-dark-text2">
                      {review.promoter_display_name}
                    </div>
                  </p>
                </div>
                <div className="flex w-full flex-col gap-2 md:flex-row md:justify-between md:gap-0">
                  <p>
                    Date Submitted:
                    <div className="text-light-text2 dark:text-dark-text2">
                      {formatDate(review.created_on)}
                    </div>
                  </p>
                  <p className="min-w-[200px]">
                    Status:
                    <div className="text-light-text2 dark:text-accent2">
                      {review.status}
                    </div>
                  </p>
                </div>
                <div className="h-48 pb-10">
                  Comments:
                  <div className="text-light-text2 dark:text-dark-text2">
                    {review.comments}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Tabs.Content>
      </Tabs.Root>
      <FeedbackModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        reviewId={review.id}
      />
    </div>
  )
}

export default Review
