import React, { useState } from 'react'
import { FaSearch, FaPlus } from 'react-icons/fa'
import { motion, AnimatePresence } from 'framer-motion'

import PlayerAvatar from '../../images/player_avatar.svg'

const SearchBar = ({
  allowDuplicateDraftPicks = true,
  ranks = [],
  onAddPlayer,
  selectedPlayers = [],
  searchTerm,
  setSearchTerm,
  openAbove = false,
}) => {
  const [showDropdown, setShowDropdown] = useState(false)

  const handleSearch = (e) => {
    e.preventDefault()
    setShowDropdown(false)

    if (filteredPlayers.length > 0) {
      onAddPlayer(filteredPlayers[0])
    }
  }

  const filteredPlayers = ranks.filter((player) => {
    const isSelected = selectedPlayers.some(
      (selected) => selected.player_id === player.player_id
    )
    const matchesSearchTerm = player.player_name
      .toLowerCase()
      .includes(searchTerm.toLowerCase())

    // Show draft pick always unless it doesnt match search term
    // If you don't want the user to be able to select picks more than once
    // then set allowDuplicateDraftPicks={false}
    if (player.is_draft_pick && allowDuplicateDraftPicks) {
      return matchesSearchTerm // Only show draft picks if they match the search term
    }

    // Exclude selected players
    if (isSelected) {
      return false
    }

    return matchesSearchTerm // Show players that match the search term
  })

  const dropdownVariants = {
    hidden: {
      opacity: 0,
      scaleY: 0,
      transformOrigin: openAbove ? 'bottom' : 'top',
    },
    visible: {
      opacity: 1,
      scaleY: 1,
      transformOrigin: openAbove ? 'bottom' : 'top',
    },
    exit: {
      opacity: 0,
      scaleY: 0,
      transformOrigin: openAbove ? 'bottom' : 'top',
    },
  }

  const positionTransition = { type: 'tween', ease: 'easeInOut', duration: 0.2 }

  return (
    <div className="relative w-full">
      <form
        className="flex h-[50px] w-full items-center gap-2 rounded-[10px] bg-light2 px-4 dark:bg-[#FFFFFF1A]"
        onSubmit={handleSearch}
      >
        <button className="text-zinc-600 hover:text-zinc-900" type="submit">
          <FaSearch className="fill-light-text2 dark:fill-dark-text2" />
        </button>
        <input
          onChange={(e) => {
            setSearchTerm(e.target.value)
            setShowDropdown(true)
          }}
          className="mr-3 w-full appearance-none border-none bg-transparent px-2 leading-tight text-light-text focus:outline-none dark:text-dark-text"
          onBlur={() => setTimeout(() => setShowDropdown(false), 150)}
          onFocus={() => setShowDropdown(true)}
          placeholder="Search..."
          type="text"
          value={searchTerm}
        />
      </form>
      <AnimatePresence>
        {showDropdown && (
          <motion.div
            className={`border-light-border absolute ${
              openAbove ? 'bottom-full mb-2' : 'top-full mt-2'
            } z-10 max-h-[300px] w-full cursor-pointer overflow-auto rounded-md border-[1px] bg-light shadow-lg dark:border-dark-border dark:bg-dark`}
            animate="visible"
            exit="exit"
            initial="hidden"
            transition={positionTransition}
            variants={dropdownVariants}
          >
            {(searchTerm ? filteredPlayers : ranks).map((player) => {
              const image = player.picture
                ? `https://flock-player-images-2.s3.us-east-2.amazonaws.com/${player.picture}`
                : PlayerAvatar
              return (
                <motion.div
                  animate={{ opacity: 1 }}
                  className="group flex items-center p-2 px-4 text-light-text transition-all duration-300 hover:bg-zinc-300 dark:text-dark-text hover:dark:bg-zinc-800"
                  exit={{ opacity: 0, transition: { duration: 0.2 } }}
                  initial={{ opacity: 0 }}
                  key={player.player_id}
                  layout
                  onClick={() => onAddPlayer(player)}
                  transition={{ duration: 0.3, ease: 'easeInOut' }}
                >
                  <img
                    alt={player.player_name}
                    className="mr-2 h-10 w-12 rounded-full"
                    src={image}
                  />
                  <span className="flex-grow">{player.player_name}</span>
                  <button
                    className="custom-div rounded-full border-0 p-2 duration-300 hover:bg-zinc-100 hover:bg-opacity-40 hover:text-custom-orange"
                    onClick={() => null}
                  >
                    <FaPlus className="custom-fa-plus fill-light-text2 dark:fill-dark-text2" />
                  </button>
                </motion.div>
              )
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default SearchBar
