import { combineReducers, configureStore } from '@reduxjs/toolkit'
import userReducer from './userReducer'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
}

import { logout as logoutAction } from './userReducer'

const rootReducer = (state, action) => {
  if (logoutAction.match(action)) {
    localStorage.removeItem(process.env.REACT_APP_FLOCK_TOKEN)
    return appReducer({}, action)
  }

  return appReducer(state, action)
}

const appReducer = combineReducers({ user: userReducer })

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
})

export const persistor = persistStore(store)
