import React from 'react'
import { useTheme } from './ThemeProvider'
import { FaSun, FaMoon } from 'react-icons/fa'

const ThemeToggle = () => {
  const { theme, setTheme } = useTheme()

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light')
  }

  return (
    <button
      className="flex items-center justify-center rounded-full bg-[#EAEAEA] p-2"
      onClick={toggleTheme}
    >
      {theme === 'light' ? (
        <FaMoon className="fill-[#222222]" size={18} />
      ) : (
        <FaSun className="fill-[#222222]" size={18} />
      )}
    </button>
  )
}

export default ThemeToggle
