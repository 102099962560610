import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { getPostsByCreatorId as getPostsByCreatorIdAction } from '../../../store/api.js'

import Post from '../../Posts/PostItem.jsx'
import Select1 from '../../_ui/Select.jsx'
import BaseCheckbox from '../../_ui/BaseCheckbox.jsx'
import loadingGif from '../../../images/FlockLoadingIcon.gif'

import { CiSearch } from 'react-icons/ci'
import { FaRegNewspaper, FaYoutube, FaBookOpen } from 'react-icons/fa'
import {
  MdStorage,
  MdFirstPage,
  MdArrowBackIosNew,
  MdArrowForwardIos,
  MdLastPage,
} from 'react-icons/md'

function ManageContentList() {
  const [posts, setPosts] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [postOption, setpostOption] = useState('both')
  const [searchValue, setSearchValue] = useState('')
  const [totalPages, setTotalPages] = useState(0)
  const { subscription_tier } = useSelector((state) => state.user)
  // filter options (recent, impressions)
  const [filterOption, setFilterOption] = useState('recent')
  // type options (1-posts, 2-videos, 3-database, 4-draft guides)
  const [typeOptions, setTypeOptions] = useState([1, 2, 3, 4])
  // radio options (community, creator, both) - set both if regular posts and expert only for everything else

  const prevPage = useRef(0)

  //----------------------------------------------------------------Use Effects-------------------------------------------------------------------------------------

  useEffect(() => {
    getPosts()
  }, [page, rowsPerPage])

  useEffect(() => {
    getPosts()
  }, [filterOption, typeOptions, postOption])

  //----------------------------------------------------------------Data fetching-------------------------------------------------------------------------------------

  const getPosts = async () => {
    try {
      const optionTypes = typeOptions.join(',')
      const options = {
        page,
        initial: 1,
        type: optionTypes,
        filter: filterOption + ',' + postOption,
      }
      const { posts, count } = await getPostsByCreatorIdAction(options)
      setPosts(posts)

      const pageCount = Math.ceil(count / rowsPerPage)
      setTotalPages(pageCount)
    } catch (err) {
      console.error('ERROR ManageContentList.getPosts : ', err)
    }
  }

  const searchPosts = async () => {
    try {
      const optionTypes = typeOptions.join(',')

      const options = {
        page,
        initial: 1,
        type: optionTypes,
        filter: filterOption + ',' + postOption,
        search: searchValue.trim(),
      }

      const { posts, count } = await getPostsByCreatorIdAction(options)
      setPosts(posts)
      const pageCount = Math.ceil(count / rowsPerPage)
      setTotalPages(pageCount)
    } catch (error) {
      console.error(error)
    }
  }

  //----------------------------------------------------------------Handlers-------------------------------------------------------------------------------------

  const handleTypeOptionChange = (value) => (checked) => {
    if (checked) {
      setTypeOptions([...typeOptions, value])
    } else {
      setTypeOptions(typeOptions.filter((option) => option !== value))
    }
  }

  //----------------------------------------------------------------Utility Functions-------------------------------------------------------------------------------------

  const postDeleted = () => {
    getPosts()
  }

  const filterOptions = [
    { label: 'Most Recent', value: 'recent' },
    { label: 'Most Popular', value: 'impressions' },
  ]

  const mapFilterLabelToValue = (label) => {
    const option = filterOptions.find((option) => option.label === label)
    return option ? option.value : 'recent'
  }

  return (
    <>
      <div>
        <div className="flex flex-col flex-wrap">
          <div className="">
            <div className="flex justify-between">
              <Select1
                onSelect={(selectedOption) => {
                  const value = mapFilterLabelToValue(selectedOption.label)
                  setFilterOption(value)
                }}
                options={filterOptions.map((option) => option.label)}
                placeholder="Select Filter Option..."
              />
              <div className="flex h-[50px] items-center gap-4 rounded-custom border-[1px] border-light2 p-3 dark:border-dark-border md:w-[300px]">
                <CiSearch className="fill-light-bg2" size={22} />
                <input
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      searchPosts() // Call the search function when Enter is pressed
                    }
                  }}
                  className="bg-transparent text-sm text-light-text2 outline-none dark:text-dark-text2"
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder="Search..."
                  type="text"
                  value={searchValue}
                />
              </div>
            </div>
            <div className="flex gap-8 py-10">
              <div className="flex gap-2">
                <BaseCheckbox
                  checked={typeOptions.includes(1)}
                  onChange={handleTypeOptionChange(1)}
                  text="Posts"
                />
                <FaRegNewspaper
                  className="fill-light-text dark:fill-dark-text"
                  size={22}
                />
              </div>
              <div className="flex gap-2">
                <BaseCheckbox
                  checked={typeOptions.includes(3)}
                  onChange={handleTypeOptionChange(3)}
                  text="Databases"
                />
                <MdStorage
                  className="fill-light-text dark:fill-dark-text"
                  size={22}
                />
              </div>

              <div className="flex gap-2">
                <BaseCheckbox
                  checked={typeOptions.includes(2)}
                  onChange={handleTypeOptionChange(2)}
                  text="Videos"
                />
                <FaYoutube
                  className="fill-light-text dark:fill-dark-text"
                  size={22}
                />
              </div>
              <div className="flex gap-2">
                <BaseCheckbox
                  checked={typeOptions.includes(4)}
                  onChange={handleTypeOptionChange(4)}
                  text="Draft Guides"
                />
                <FaBookOpen
                  className="fill-light-text dark:fill-dark-text"
                  size={22}
                />
              </div>
            </div>
          </div>
        </div>
        {posts.length > 0 ? (
          <>
            {posts.map((post) => (
              <Post
                edit={true}
                key={post.post_id}
                post={post}
                postDeleted={postDeleted}
                userPermission={subscription_tier}
              />
            ))}
          </>
        ) : (
          <img
            alt="Loading..."
            src={loadingGif}
            style={{ width: '250px', height: '250px' }}
          />
        )}
        <div className="flex items-center gap-6 py-4">
          <button
            className="hover:text-black"
            disabled={page === 0}
            onClick={() => setPage(0)}
          >
            <MdFirstPage
              className="cursor-pointer fill-light-text transition-colors duration-200 hover:fill-accent dark:fill-dark-text"
              size={22}
            />
          </button>

          <button
            className="hover:text-black"
            disabled={page === 0}
            onClick={() => setPage((prev) => Math.max(prev - 1, 0))}
          >
            <MdArrowBackIosNew
              className="cursor-pointer fill-light-text transition-colors duration-200 hover:fill-accent dark:fill-dark-text"
              size={22}
            />
          </button>
          <div>
            <p className="text-light-text dark:text-dark-text">
              {page + 1} of {totalPages}
            </p>
          </div>
          <button
            onClick={() =>
              setPage((prev) => Math.min(prev + 1, totalPages - 1))
            }
            disabled={page >= totalPages - 1}
          >
            <MdArrowForwardIos
              className="cursor-pointer fill-light-text transition-colors duration-200 hover:fill-accent dark:fill-dark-text"
              size={22}
            />
          </button>

          <button
            disabled={page >= totalPages - 1}
            onClick={() => setPage(totalPages - 1)}
          >
            <MdLastPage
              className="cursor-pointer fill-light-text transition-colors duration-200 hover:fill-accent dark:fill-dark-text"
              size={22}
            />
          </button>
        </div>
      </div>
    </>
  )
}

export default ManageContentList
