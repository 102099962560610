import React, { useEffect, useState, useRef } from 'react'
import TeamSelect from '../../../MyTeams/TeamSelect'
import SyncTeamsModal from '../../../MyTeams/SyncTeamsModal'
import {
  getUserLeagueProfiles,
  calculateLeague,
  handleCheckout,
  getRankings,
  getPositions,
} from '../../../../store/api'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import ReviewSettingsModal from '../../../MyTeams/SubmitReview/ReviewSettingsModal'
import HorzontalDivider from '../../../_ui/HorozontalDivider'
import { ProgressBar } from 'react-loader-spinner'

import { MdOutlineSettings } from 'react-icons/md'
import { CreatorOption } from '../CreatorOption'
import MyLineup from './MyLineup'
import MyBench from './MyBench'

const promoOrder = {
  FLOCK: '38f6c2ae-6010-416a-8947-973a4ddc58e6',
  DOMAIN: '222696a0-038d-4689-a3e6-a97046cd2eec',
  LAND: '5ac59844-e390-46dd-9ecd-b50ecc3c0a08',
  FSE: 'e401f630-5049-4c83-9b0a-453c1c547ebc',
  TEST_DEV: 'b23841d4-f381-4569-8857-39b80406e7d5',
}

const StartSit = (product) => {
  const location = useLocation()
  const navigate = useNavigate()

  const [teamNames, setTeamNames] = useState([])
  const [teamNameToId, setTeamNameToId] = useState({})
  const [comments, setComments] = useState('')
  const [selectedLeagueId, setSelectedLeagueId] = useState('')
  const [selectedTeamId, setSelectedTeamId] = useState('')
  const [placeholderLeagueName, setPlaceholderLeagueName] =
    useState('Flock League')
  const [profiles, setProfiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLinked, setIsLinked] = useState(false)
  const [teamOptions, setTeamOptions] = useState([])
  const [tep, setTep] = useState(false)

  const [step, setStep] = useState(1)

  const [isOpen, setIsOpen] = useState(false)
  const [currentLeagueType, setCurrentLeagueType] = useState('')
  const [selectedPprTypes, setSelectedPprTypes] = useState('')
  const [selectedQbs, setSelectedQbs] = useState('')
  const [selectedTeamCounts, setSelectedTeamCounts] = useState('')
  const [lineupCount, setLineupCount] = useState('')
  const [selectedProducts, setSelectedProducts] = useState([])
  const [league, setLeague] = useState({})
  const [lineupPlayers, setLineupPlayers] = useState([])
  const [bench, setBench] = useState([])
  const [allPlayers, setAllPlayers] = useState([])
  const [isSyncOpen, setIsSyncOpen] = useState(false)
  const [selectedTeamName, setSelectedTeamName] = useState('')
  const [selectedPosition, setSelectedPosition] = useState(null)
  const [positions, setPositions] = useState('')
  const [filteredBench, setFilteredBench] = useState([])

  const [groupedProducts, setGroupedProducts] = useState({})

  useEffect(() => {
    fetchLeagueAndProfiles()
    fetchAllPlayers()
    const searchParams = new URLSearchParams(location.search)
    const leagueId = searchParams.get('leagueId')
    if (leagueId) {
      setSelectedLeagueId(leagueId)
    }
    setGroupedProducts(groupProductsByPromoterId(product.product, promoOrder))
  }, [location.search, product.product])

  useEffect(() => {
    if (selectedLeagueId && profiles.length > 0) {
      const league = profiles.find(
        (profile) => profile.league_id === selectedLeagueId
      )
      if (league) {
        setPlaceholderLeagueName(league.league_name)
      }
      getCalculatedLeague(selectedLeagueId)
    }
  }, [selectedLeagueId, profiles])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])

  useEffect(() => {
    setSelectedLeagueId('')
    setIsLinked(false)
  }, [
    currentLeagueType,
    selectedPprTypes,
    selectedQbs,
    selectedTeamCounts,
    lineupCount,
    tep,
  ])

  useEffect(() => {
    fetchPositions()
  }, [])

  const fetchLeagueAndProfiles = async () => {
    try {
      const profilesData = await getUserLeagueProfiles()
      setProfiles(profilesData)
      const names = profilesData.map((profile) => profile.league_name)
      const nameToId = profilesData.reduce((acc, profile) => {
        acc[profile.league_name] = profile.league_id
        return acc
      }, {})

      setTeamNames(names)
      setTeamNameToId(nameToId)
    } catch (error) {
      console.error('Failed to fetch creators and profiles:', error)
    }
  }

  const fetchAllPlayers = async () => {
    try {
      const response = await getRankings('ONEQB')

      const uniquePlayersArray = Array.from(
        new Map(
          response.data.map((player) => [player.player_id, player])
        ).values()
      )

      const filteredPlayers = uniquePlayersArray.filter(
        (player) => !player.is_draft_pick
      )

      filteredPlayers.sort(
        (a, b) => parseFloat(a.average_rank) - parseFloat(b.average_rank)
      )

      const top300Players = filteredPlayers.slice(0, 300)

      setAllPlayers(top300Players)
    } catch (error) {
      console.error('Error fetching players:', error)
    }
  }

  const fetchPositions = async () => {
    const response = await getPositions()
    setPositions(response.data)
  }

  const getCalculatedLeague = async (leagueId) => {
    try {
      const league = await calculateLeague({ creatorId: 'EXPERT', leagueId })
      setLeague(league)
      const userMap = league.settings.users.reduce((map, user) => {
        const teamName = user.team_name || user.display_name
        map[user.user_id] = {
          teamName,
          teamImage: user.team_image || '',
          displayName: user.display_name,
          userId: user.user_id,
        }
        return map
      }, {})

      const mergedData = Object.keys(league.values)
        .map((teamId) => {
          const user = userMap[teamId]
          return {
            teamId,
            teamName: user.teamName,
            teamImage: user.teamImage,
          }
        })
        .sort((a, b) => b.overall - a.overall)
      const teamOptions = createTeamOptions(mergedData)
      setTeamOptions(teamOptions)
    } catch (error) {
      console.error('Failed to fetch league:', error)
      return null
    }
  }

  //---------------------------------------------------------------- Utility Functions ------------------------------------------------------------------------------------

  const groupProductsByPromoterId = (products, promoOrder) => {
    // Group products by promoter ID
    const groupedProducts = products.reduce((acc, item) => {
      const promoterId = item.configuration.creator.promoter_id
      if (!acc[promoterId]) {
        acc[promoterId] = []
      }
      acc[promoterId].push(item)
      return acc
    }, {})

    // Sort each group's products by discount price or price
    for (const promoterId in groupedProducts) {
      groupedProducts[promoterId].sort((a, b) => {
        const priceA =
          a.discount_price !== undefined ? a.discount_price : a.price
        const priceB =
          b.discount_price !== undefined ? b.discount_price : b.price
        return priceA - priceB
      })
    }

    // Reorder the grouped products based on the promoOrder map
    const sortedGroupedProducts = Object.keys(promoOrder).reduce((acc, key) => {
      const promoterId = promoOrder[key]
      if (groupedProducts[promoterId]) {
        acc[promoterId] = groupedProducts[promoterId]
      }
      return acc
    }, {})
    return sortedGroupedProducts
  }

  const handleSortChange = (selectedOption) => {
    const value = teamNameToId[selectedOption.label]
    setIsLinked(true)
    setSelectedLeagueId(value)
  }

  const handleAutofillLineup = (selectedOption) => {
    const roster = league.rosters[selectedOption]

    if (roster) {
      const tradeSettings = league.settings.trade_settings

      const benchPlayers = [
        ...roster.QB,
        ...roster.RB,
        ...roster.WR,
        ...roster.TE,
      ]

      const sortedBenchPlayers = benchPlayers
        .sort((a, b) => a.overall_rank - b.overall_rank)
        .sort((a, b) => {
          const positionOrder = { QB: 1, RB: 2, WR: 3, TE: 4 }
          return positionOrder[a.position] - positionOrder[b.position]
        })

      const addPlayersToLineup = (position, count) => {
        for (let i = 0; i < count; i++) {
          lineupPlayers.push({
            position,
            player: null,
          })
        }
      }

      const lineupPlayers = []

      addPlayersToLineup('QB', tradeSettings.qb)
      addPlayersToLineup('RB', tradeSettings.rb)
      addPlayersToLineup('WR', tradeSettings.wr)
      addPlayersToLineup('TE', tradeSettings.te)

      addPlayersToLineup('FLEX', tradeSettings.flex, benchPlayers)

      const usedPlayerIds = new Set(
        lineupPlayers.filter((lp) => lp.player).map((lp) => lp.player.player_id)
      )
      const remainingBenchPlayers = sortedBenchPlayers.filter(
        (player) => !usedPlayerIds.has(player.player_id)
      )

      setLineupPlayers(lineupPlayers)
      setBench(remainingBenchPlayers)
    } else {
      console.log('No roster found for the selected option')
    }
  }

  const handleCommentsChange = (event) => {
    const inputComments = event.target.value
    if (inputComments.length <= 500) {
      setComments(inputComments)
    }
  }

  const getLeagueTypeId = (leagueType) => {
    let type = 1
    if (leagueType === 'year') {
      type = 1
    } else if (leagueType === 'Dynasty' && selectedQbs === 2) {
      type = 3
    } else if (leagueType === 'Dynasty') {
      type = 2
    }
    return type
  }

  const handleReviewCheckout = async () => {
    setIsLoading(true)

    if (!validateInputs()) {
      setIsLoading(false)
      return
    }

    let productDetails
    console.log('lineupPlayers', lineupPlayers)
    const rosterPositions = lineupPlayers
      .map((lineupPlayer) => {
        const position = positions.find(
          (pos) =>
            pos.short_name === lineupPlayer.position ||
            pos.name.toUpperCase() === lineupPlayer.position
        )
        return {
          rosterPositionId: position ? position.id : null,
          playerId:
            lineupPlayer.player?.player_id === 777
              ? null
              : lineupPlayer.player?.player_id || null,
        }
      })
      .filter((rp) => rp.rosterPositionId !== null)

    const players = bench.map((benchPlayer) => ({
      playerId: benchPlayer.player_id === 777 ? null : benchPlayer.player_id,
      isContender:
        benchPlayer.isContender === undefined ? false : benchPlayer.isContender,
    }))

    if (isLinked) {
      productDetails = {
        comments: String(comments),
        leagueId: String(selectedLeagueId),
        leagueSettings: {
          leagueTypeId: getLeagueTypeId('Dynasty'),
          leagueType: 'Dynasty',
          ppr: league.settings.scoring,
          qbs: league.settings.is_superflex ? 2 : 1,
          teamCounts: league.settings.league_size,
          tep: league.settings.tep ? true : false,
          lineupCount: league.settings.num_starters,
        },
        rosterPositions: rosterPositions,
        players: players,
        type: 'startsit',
      }
    } else {
      productDetails = {
        comments: String(comments),
        leagueSettings: {
          leagueTypeId: getLeagueTypeId(currentLeagueType),
          leagueType: currentLeagueType,
          ppr: selectedPprTypes,
          qbs: selectedQbs,
          teamCounts: selectedTeamCounts,
          tep: tep,
          lineupCount: lineupCount,
        },
        rosterPositions: rosterPositions,
        players: players,
        type: 'startsit',
      }
    }

    const payload = {
      products: selectedProducts.map((product) => {
        const group = groupedProducts[product.configuration.creator.promoter_id]
        const productsWithinBudget = group.filter(
          (p) => p.discount_price || p.price <= product.price
        )
        const bestProduct = productsWithinBudget.reduce(
          (best, current) =>
            current.discount_price > best.discount_price ||
            current.price > best.price
              ? current
              : best,
          productsWithinBudget[0] || product
        )

        return {
          id: bestProduct.id,
          price: product.price,
          discountId: bestProduct?.discount?.id || null,
          name: bestProduct.name,
          promoterId: bestProduct.configuration.creator.promoter_id,
        }
      }),
      productDetails: productDetails,
    }

    try {
      const res = await handleCheckout(payload)
      window.location.href = res.url
    } catch (error) {
      console.error('Error submitting review:', error)
      if (error.response && error.response.data) {
        console.error('Server response:', error.response.data)
      }
      toast.error('Error submitting review.')
    }

    setIsLoading(false)
  }

  const validateInputs = () => {
    if (!isLinked) {
      if (!selectedPprTypes) {
        toast.error('Please select all league settings.')
        return false
      }
    }
    return true
  }

  const handleContinueClick = () => {
    if (validateInputs()) {
      setStep(2)
    }
  }

  const createTeamOptions = (transformedData) => {
    if (!transformedData) {
      return []
    }
    return transformedData.map((team) => ({
      label: team.teamName || team.displayName,
      id: team.teamId,
      teamImage: team.teamImage,
    }))
  }

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const handleLeagueTypeChange = (type) => {
    setCurrentLeagueType(type)
  }

  const handleSetSelectedPosition = (position) => {
    setSelectedPosition(position)

    let filteredPlayers

    if (position !== 'FLEX') {
      filteredPlayers = bench.filter(
        (player) =>
          player.position === position || player.short_name === position
      )
    } else {
      filteredPlayers = bench.filter((player) => player.position !== 'QB')
    }
    const placeholderPlayer = {
      player_id: 777,
      player_name: 'Undecided',
      position: position,
      picture: 'Blank_Player.jpg',
    }

    setFilteredBench([placeholderPlayer, ...filteredPlayers])
  }

  const handleTeamSelectionChange = (teams) => {
    setSelectedTeamCounts(teams)
  }

  const handleTeamChange = (selectedOption) => {
    setSelectedTeamId(selectedOption.id)
    handleAutofillLineup(selectedOption.id)
    setSelectedTeamName(selectedOption.label)
  }

  const attachToPosition = (player) => {
    const newLineupPlayers = [...lineupPlayers]
    let playerAssigned = false

    const updatedLineupPlayers = newLineupPlayers.map((lineupPlayer) => {
      const isEmptySlot =
        lineupPlayer.player === null || !lineupPlayer.player?.player_id

      if (
        !playerAssigned &&
        isEmptySlot &&
        ((selectedPosition === 'FLEX' && lineupPlayer.position === 'FLEX') ||
          lineupPlayer.position === selectedPosition)
      ) {
        console.log('lineupPlayer before adding player', lineupPlayer)
        playerAssigned = true
        return { ...lineupPlayer, player: { ...player } }
      }

      return lineupPlayer
    })

    const newBenchPlayers = bench.filter(
      (benchPlayer) => benchPlayer.player_id !== player.player_id
    )

    setSelectedPosition(null)
    setLineupPlayers(updatedLineupPlayers)
    setBench(newBenchPlayers)
  }

  const removeFromPosition = (player) => {
    const newLineupPlayers = lineupPlayers.map((lineupPlayer) => {
      if (lineupPlayer.player?.player_id === player.player.player_id) {
        return { ...lineupPlayer, player: null }
      }
      return lineupPlayer
    })
    const newBenchPlayers = [...bench, player.player]
    setLineupPlayers(newLineupPlayers)
    setBench(newBenchPlayers)
  }

  const removeFromLineup = (player) => {
    const newLineupPlayers = [...lineupPlayers]
    let playerRemoved = false

    const updatedLineupPlayers = newLineupPlayers.filter((lineupPlayer) => {
      if (!playerRemoved && lineupPlayer.position === player.position) {
        playerRemoved = true
        return false
      }
      return true
    })

    setLineupPlayers(updatedLineupPlayers)
  }

  const toggleTep = () => {
    setTep((prev) => !prev)
  }

  return (
    <div className="flex flex-col items-center bg-light px-4 py-16 font-dmsans text-light-text dark:bg-dark dark:text-dark-text">
      {step === 1 && (
        <body className="flex flex-col gap-10 md:pb-[20vw] lg:px-[29vw]">
          <div className="grid gap-2">
            <h3 className="text-xl font-bold md:text-3xl">
              Tell us about your team!
            </h3>
            <span className="max-w-64 text-light-text2 dark:text-dark-text2">
              {product.product[0].description}
            </span>
            <HorzontalDivider />
          </div>
          <p className="flex flex-col gap-2">
            <div className="flex w-full flex-col items-center gap-4 md:flex-row md:gap-10">
              <p className="flex flex-col items-start gap-2">
                <h3 className="text-xl font-bold">My League Settings</h3>
                <div className="flex flex-col gap-4 md:flex-row md:gap-10">
                  <div className="border-light-border flex h-[50px] w-[200px] max-w-full items-center gap-2 rounded-lg border-[1px] bg-zinc-300 transition-all duration-200 dark:border-dark-border dark:bg-dark hover:dark:bg-accent-bg">
                    <button
                      className="flex w-full cursor-pointer items-center justify-center gap-2 rounded-custom p-2 text-white"
                      onClick={openModal}
                    >
                      <MdOutlineSettings
                        className="text-light-text dark:text-dark-text"
                        size={24}
                      />
                      <span className="font-bold text-light-text dark:text-dark-text">
                        Choose Settings
                      </span>
                    </button>
                  </div>
                </div>
              </p>
              <span className="pt-6 font-bold text-accent">OR</span>
              <div className="relative z-20 flex flex-col gap-2">
                <h3 className="text-xl font-bold">Link My League</h3>
                <div className="flex gap-4">
                  <div>
                    <TeamSelect
                      defaultValue={selectedLeagueId}
                      footer={true}
                      onAddNewTeam={() => setIsSyncOpen(true)}
                      onSelect={handleSortChange}
                      options={teamNames}
                      placeholder={placeholderLeagueName}
                    />

                    {teamNames.length === 0 && (
                      <span className="text-light-text2 dark:text-dark-text2">
                        Please add a league to your profile
                        <a
                          className="cursor-pointer font-bold text-accent"
                          onClick={() => navigate('/my-teams')}
                        >
                          {' '}
                          Here
                        </a>
                      </span>
                    )}
                  </div>
                  <TeamSelect
                    defaultValue={selectedTeamName}
                    footer={false}
                    onSelect={handleTeamChange}
                    options={teamOptions}
                    placeholder={'FlockTeam'}
                  />
                </div>
              </div>
            </div>
          </p>
          <MyLineup
            lineup={lineupPlayers}
            onRemoveClick={removeFromPosition}
            players={bench}
            removeFromLineup={removeFromLineup}
            setLineup={setLineupPlayers}
            setSelectedPosition={handleSetSelectedPosition}
          />
          <MyBench
            attachToPosition={attachToPosition}
            bench={selectedPosition ? filteredBench : bench}
            players={allPlayers}
            selectedPosition={selectedPosition}
            setBench={setBench}
            setSelectedPosition={setSelectedPosition}
          />
          <div className="flex items-center justify-end pt-10">
            {isLoading ? (
              <ProgressBar
                barColor="#E97933"
                borderColor="#BB8360"
                height={75}
                type="Oval"
                width={100}
              />
            ) : (
              <button
                className="flex items-center gap-2 rounded-lg bg-accent px-24 py-3 text-dark-text"
                onClick={handleContinueClick}
              >
                Continue
              </button>
            )}
          </div>
        </body>
      )}
      {step === 2 && (
        <CreatorOption
          comments={comments}
          goBack={() => setStep(1)}
          groupedProducts={groupedProducts}
          handleCommentsChange={handleCommentsChange}
          handleReviewCheckout={handleReviewCheckout}
          isLoading={isLoading}
          maxPrice={10000}
          product={product.product}
          setSelectedProducts={setSelectedProducts}
        />
      )}
      <ReviewSettingsModal
        currentLeagueType={currentLeagueType}
        isOpen={isOpen}
        lineupCount={lineupCount}
        onClose={closeModal}
        onTeamSelectionChange={handleTeamSelectionChange}
        options={['Scoring']} // Only show these sections
        selectLeagueType={handleLeagueTypeChange}
        selectedPprTypes={selectedPprTypes}
        selectedQbs={selectedQbs}
        selectedTeamCounts={selectedTeamCounts}
        setLineupCount={setLineupCount}
        setSelectedPprTypes={setSelectedPprTypes}
        setSelectedQbs={setSelectedQbs}
        tep={tep}
        toggleTep={toggleTep}
      />
      <SyncTeamsModal
        onLeagueSubmitted={() => {
          fetchLeagueAndProfiles()
        }}
        isOpen={isSyncOpen}
        onClose={() => setIsSyncOpen(false)}
      />
    </div>
  )
}

export default StartSit
