import React, { useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { motion, AnimatePresence } from 'framer-motion'

function UserSearchBar({
  subscriptionSummaries = [],
  searchTerm,
  setSearchTerm,
}) {
  const [showDropdown, setShowDropdown] = useState(false)

  const filteredUsers = subscriptionSummaries
    .filter((user) => user && user.username)
    .filter((user) =>
      user.username.toLowerCase().includes(searchTerm.toLowerCase())
    )

  const handleSearch = (e) => {
    e.preventDefault()
    setShowDropdown(false)
  }

  const handleClickUser = (username) => {
    setSearchTerm(username) // Update the search term to the selected username.
    setShowDropdown(false) // Optionally hide the dropdown after selection.
  }

  const dropdownVariants = {
    hidden: { opacity: 0, scaleY: 0, transformOrigin: 'top' },
    visible: { opacity: 1, scaleY: 1, transformOrigin: 'top' },
    exit: { opacity: 0, scaleY: 0, transformOrigin: 'top' },
  }

  const positionTransition = { type: 'tween', ease: 'easeInOut', duration: 0.2 }

  return (
    <div className="relative w-full">
      <form
        className="flex h-[43px] w-full items-center gap-2 rounded-[10px] bg-light2 px-4 dark:bg-[#FFFFFF1A]"
        onSubmit={handleSearch}
      >
        <button className="text-zinc-600 hover:text-zinc-900" type="submit">
          <FaSearch className="fill-light-text2 dark:fill-dark-text2" />
        </button>
        <input
          onChange={(e) => {
            setSearchTerm(e.target.value)
            setShowDropdown(true)
          }}
          className="mr-3 w-full appearance-none border-none bg-transparent px-2 leading-tight text-light-text focus:outline-none dark:text-dark-text"
          onBlur={() => setTimeout(() => setShowDropdown(false), 150)}
          placeholder="Search..."
          type="text"
          value={searchTerm}
        />
      </form>
      <AnimatePresence>
        {showDropdown && searchTerm && (
          <motion.div
            animate="visible"
            className="border-light-border absolute top-full z-[12] mt-2 max-h-[300px] w-[437px] cursor-pointer overflow-auto rounded-md border-[1px] bg-light shadow-lg dark:border-dark-border dark:bg-dark"
            exit="exit"
            initial="hidden"
            transition={positionTransition}
            variants={dropdownVariants}
          >
            {filteredUsers.map((user, index) => (
              <motion.div
                animate={{ opacity: 1 }}
                className="group flex items-center p-2 px-4 text-light-text transition-all duration-300 hover:bg-zinc-300 dark:text-dark-text hover:dark:bg-zinc-800"
                exit={{ opacity: 0, transition: { duration: 0.2 } }}
                initial={{ opacity: 0 }}
                key={index}
                layout
                onClick={() => handleClickUser(user.username)}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
              >
                <span className="flex-grow">{user.username}</span>
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default UserSearchBar
