import React from 'react'
import { HiOutlineCalculator } from 'react-icons/hi'
import { FaSearch } from 'react-icons/fa'
import { AiOutlineTeam } from 'react-icons/ai'
import { FaRankingStar } from 'react-icons/fa6'
import { IoCreateOutline, IoArrowRedoOutline } from 'react-icons/io5'
import RankingsBanner from '../../../images/RankingsBanner.png'

const StorefrontToolsBanner = () => {
  return (
    <div className="col-span-3 flex flex-col justify-between gap-8 rounded-lg border-[1px] border-zinc-300 bg-gradient-to-r from-accent/10 to-accent/70 p-6 dark:border-dark-border dark:bg-dark dark:from-accent/0 dark:to-accent/10 md:flex-row">
      <div className="flex flex-col gap-4">
        <h3 className="text-2xl font-bold text-accent">
          Join the flock & unlock premium tools!
        </h3>
        <ul className="flex flex-col gap-2">
          <li className="flex gap-2">
            <FaSearch className="mr-2 text-accent" size={20} />
            Free Yearly Team Review
            <IoArrowRedoOutline className="text-accent" size={22} />
          </li>
          <li className="flex items-end gap-2">
            <HiOutlineCalculator className="text-accent" size={28} />
            Trade Calculator & Trade Finder
            <IoArrowRedoOutline className="text-accent" size={22} />
          </li>
          <li className="flex gap-2">
            <AiOutlineTeam className="mr-1 text-accent" size={26} />
            My Teams
            <IoArrowRedoOutline className="text-accent" size={22} />
          </li>
          <li className="flex gap-2">
            <FaRankingStar className="mr-1 text-accent" size={26} />
            Expert/Community Rankings
            <IoArrowRedoOutline className="text-accent" size={22} />
          </li>
          <li className="flex gap-2">
            <IoCreateOutline className="mr-1 text-accent" size={26} />
            Articles, Databases, Draft Guides and more!
            <IoArrowRedoOutline className="text-accent" size={22} />
          </li>
        </ul>
      </div>
      <div className="">
        <img
          alt="Rankings Screenshot"
          className="h-72 w-full rounded-lg"
          src={RankingsBanner}
        />
      </div>
    </div>
  )
}

export default StorefrontToolsBanner
