import React from 'react'
import { FaPlay, FaQuestion, FaChessRook } from 'react-icons/fa6'
import { GoCodeReview } from 'react-icons/go'
import { promoterImages } from '../../../static/PromoterImages'

const ProductTag = ({
  onClick,
  product,
  index,
  formatDate,
  is_contributor,
  is_creator,
  selectedReview,
}) => {
  const getCreatorImage = (promoter_id) => {
    const imageUrl = promoterImages[promoter_id]
    if (imageUrl) {
      return (
        <img
          alt={product.user_display_name}
          className="max-h-12 w-10 object-cover"
          src={imageUrl}
        />
      )
    }

    return null
  }

  const getLastName = (fullName) => {
    const parts = fullName.split(' ')
    return parts[parts.length - 1]
  }

  const getTopPlayers = (tradePlayers, receivePlayers) => {
    const tradePlayerLastName = tradePlayers[0]
      ? getLastName(tradePlayers[0].player_name)
      : ''
    const receivePlayerLastName = receivePlayers[0]
      ? getLastName(receivePlayers[0].player_name)
      : ''

    const tradePlayersCount = tradePlayers.length - 1
    const receivePlayersCount = receivePlayers.length - 1

    return `${tradePlayerLastName}${tradePlayersCount > 0 ? ` (+${tradePlayersCount})` : ''} vs ${receivePlayerLastName}${receivePlayersCount > 0 ? ` (+${receivePlayersCount})` : ''}`
  }

  const renderIcon = () => {
    switch (product.table_name) {
      case 'trade_questions':
        return <FaQuestion size={28} />
      case 'reviews':
        return <GoCodeReview size={28} />
      case 'start_sit_questions':
        return <FaChessRook size={28} />
      default:
        return null
    }
  }

  const renderName = () => {
    switch (product.table_name) {
      case 'trade_questions':
        return product.user_display_name
      case 'reviews':
        return product.discord_username || product.team_name
      case 'start_sit_questions':
        return product.user_display_name
      default:
        return null
    }
  }

  return (
    <div
      className={`flex items-center justify-between gap-4 rounded-xl border-[1px] border-light2 p-4 dark:border-dark-border md:w-[310px] ${product.id === selectedReview?.id ? 'bg-accent-bg text-accent' : 'hover:bg-light2 hover:dark:bg-dark-border'} transition-all duration-200`}
      key={index}
      onClick={onClick}
    >
      <div className="flex items-center gap-8">
        {renderIcon()}
        <div className="flex flex-col gap-1">
          <span className="text-xl font-bold">{renderName()}</span>
          <span className="text-light-text2 dark:text-dark-text2">
            {product.table_name === 'trade_questions'
              ? getTopPlayers(
                  product.trade_configurations[0]?.trade_players,
                  product.trade_configurations[0]?.receive_players
                )
              : formatDate(product.created_on)}
          </span>
        </div>
      </div>

      {product.status === 'in_progress' && (is_creator || is_contributor) && (
        <FaPlay className="text-green-600" size={22} />
      )}
      {getCreatorImage(product.promoter_id)}
    </div>
  )
}

export default ProductTag
