import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IoClose } from 'react-icons/io5'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import PlayerAvatar from '../../images/player_avatar.svg'
import { Triangle } from 'react-loader-spinner'
import ReactSimpleImageViewer from 'react-simple-image-viewer'
import LineChart from '../_ui/LineChart.jsx'

import { getPlayerCardByPlayerId } from '../../store/api'
import { getPlayerRecords } from '../../store/api'

const PlayerInfoModal = ({ player, onClose, tab, ranks }) => {
  if (!player) {
    return null
  }
  const navigate = useNavigate()

  const [images, setImages] = useState([])
  const [selectedTab, setSelectedTab] = useState('info-tab')
  const [isLoading, setIsLoading] = useState(false)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)
  const [isPortrait, setIsPortrait] = useState(false)
  const [displayPromoCode, setDisplayPromoCode] = useState('')
  const [timeFrame, setTimeFrame] = useState('All Time')
  const [combinedData, setCombinedData] = useState([])
  const [displayOverallRank, setDisplayOverallRank] = useState([])
  const [displayPositionalRank, setDisplayPositionalRank] = useState([])
  const [expertPositionRank, setExpertPositionRank] = useState('')
  const [communityPositionRank, setCommunityPositionRank] = useState('')

  //---------------------------------------------------------------- Use Effects ------------------------------------------------------------------------------------

  useEffect(() => {
    const checkOrientation = () => {
      const isPortrait = window.matchMedia('(orientation: portrait)').matches
      setIsPortrait(isPortrait)
    }

    checkOrientation()

    window.addEventListener('resize', checkOrientation)

    return () => {
      window.removeEventListener('resize', checkOrientation)
    }
  }, [])

  useEffect(() => {
    if (player) {
      fetchImages()
      fetchPlayerRecords()
    }
  }, [player])

  useEffect(() => {
    if (ranks && player) {
      // Assuming the ranks array is already processed by assignPositionalRanks
      // Find the current player's rank object in the ranks array
      const playerRank = ranks.find(
        (rank) => rank.player_id === player.player_id
      )

      // Update state with the found ranks
      if (playerRank) {
        setExpertPositionRank(
          playerRank[`expert_${player.position}_rank`] || '-'
        )
        setCommunityPositionRank(
          playerRank[`comm_${player.position}_rank`] || '-'
        )
      }
    }
  }, [ranks, player])

  //---------------------------------------------------------------- Data Fetching ------------------------------------------------------------------------------------

  const fetchPlayerRecords = async () => {
    if (player && player.player_id) {
      try {
        const data = await getPlayerRecords(player.player_id, tab)
        const typeId = tabMapping[tab] || null
        const overallRankData = data
          .filter(
            (record) =>
              record.date_created !== null &&
              record.type_id === typeId &&
              record.overall_rank !== null
          )
          .map((record) => ({
            x: record.date_created.split('T')[0],
            y: record.overall_rank,
          }))
          .sort((a, b) => new Date(a.x) - new Date(b.x))
        const positionalRankData = data
          .filter(
            (record) =>
              record.date_created !== null &&
              record.type_id === typeId &&
              record.positional_rank !== null
          )
          .map((record) => ({
            x: record.date_created.split('T')[0],
            y: record.positional_rank,
          }))
          .sort((a, b) => new Date(a.x) - new Date(b.x))

        const combinedData = {
          overallRank: overallRankData,
          positionalRank: positionalRankData,
        }
        setCombinedData(combinedData)
        transformDataForTimeFrame('All Time', combinedData)
      } catch (error) {
        console.error('Error fetching player records:', error)
      }
    }
  }

  const fetchImages = async () => {
    setIsLoading(true)
    try {
      const playerCardData = await getPlayerCardByPlayerId(player.player_id)
      setIsLoading(false)
      setImages(playerCardData)
      if (playerCardData[0].promo_code) {
        setDisplayPromoCode(getDisplayPromoCode(playerCardData[0].promo_code))
      }
    } catch (error) {
      console.error('Error fetching images:', error)
    }
  }

  //----------------------------------------------------------------Utility Functions------------------------------------------------------------------------------------

  const tabMapping = {
    SUPERFLEX: 2,
    ONEQB: 3,
    BEST_BALL: 4,
  }

  const playerPicture = player.picture
    ? `https://flock-player-images-2.s3.us-east-2.amazonaws.com/${player.picture}`
    : PlayerAvatar

  const activeTabClass = 'dark:bg-zinc-700 bg-accent text-dark-text'

  const expertOverallRank = Math.round(player.average_rank)

  const assignPositionalRanks = (players, rankType) => {
    const filteredPlayers = players.filter((player) => {
      const rankValue =
        rankType === 'average_rank'
          ? parseFloat(player[rankType])
          : player[rankType]
      return rankValue !== null && !isNaN(rankValue) && !player.is_rookie
    })

    const sortedByRank = filteredPlayers.sort((a, b) => {
      const rankA =
        rankType === 'average_rank' ? parseFloat(a[rankType]) : a[rankType]
      const rankB =
        rankType === 'average_rank' ? parseFloat(b[rankType]) : b[rankType]
      return rankA - rankB
    })

    const positionCounts = {}

    sortedByRank.forEach((player) => {
      const positionKey =
        rankType === 'average_rank'
          ? `expert_${player.position}_rank`
          : `comm_${player.position}_rank`

      if (!positionCounts[positionKey]) {
        positionCounts[positionKey] = 1
      } else {
        positionCounts[positionKey] += 1
      }

      player[positionKey] = positionCounts[positionKey]
    })

    return sortedByRank
  }

  const updatedRanksWithCommunity = assignPositionalRanks(
    ranks,
    'communityRank'
  )

  const updatedRanksWithExpert = assignPositionalRanks(ranks, 'average_rank')

  const convertHeight = (inches) => {
    const feet = Math.floor(inches / 12)
    const remainingInches = inches % 12
    return `${feet}' ${remainingInches}"`
  }

  const getDisplayPromoCode = (promoCode) => {
    if (promoCode === 'DYNASTYLAND') {
      return 'LAND'
    }
    return promoCode
  }

  const getLogoForPromoCode = (promoCode) => {
    if (promoCode === 'DYNASTYLAND') {
      promoCode = 'LAND'
    }

    switch (promoCode) {
      case 'TEST':
        return (
          <div className="aspect-w-1 aspect-h-1 w-6">
            <img
              alt="Logo"
              className="object-contain"
              src="https://static-site-images.s3.us-east-2.amazonaws.com/FF-REGULAR_V6.1.png"
            />
          </div>
        )
      case 'FLOCK':
        return (
          <div className="aspect-w-1 aspect-h-1 w-6">
            <img
              alt="Logo"
              className="object-contain"
              src="https://static-site-images.s3.us-east-2.amazonaws.com/FF-REGULAR_V6.1.png"
            />
          </div>
        )
      case 'FSE':
        return (
          <div className="aspect-w-1 aspect-h-1 w-6">
            <img
              alt="Logo"
              className="object-contain"
              src="/FantasyStockExchange.png"
            />
          </div>
        )
      case 'LAND' || 'DYNASTYLAND':
        return (
          <div className="aspect-w-1 aspect-h-1 w-10">
            <img
              alt="Logo"
              className="object-contain"
              src="/DynastyLand1.png"
            />
          </div>
        )
      case 'ENDGAME':
        return (
          <div className="aspect-w-1 aspect-h-1 w-10">
            <img
              alt="Logo"
              className="object-contain"
              src="/FantasyEndgame.png"
            />
          </div>
        )
      case 'DOMAIN':
        return (
          <div className="aspect-w-1 aspect-h-1 w-10">
            <img
              alt="Logo"
              className="object-contain"
              src="https://static-site-images.s3.us-east-2.amazonaws.com/Domain_Logo.png"
            />
          </div>
        )
      default:
        return null
    }
  }

  const transformDataForTimeFrame = (timeFrame, transformedData) => {
    const now = new Date()
    let dateLimit
    switch (timeFrame) {
      case '1 month':
        dateLimit = new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          now.getDate()
        )
        break
      case 'All Time':
      default:
        dateLimit = new Date('1900-01-01')
        break
    }

    const filterData = (data) => {
      return data.filter(({ x }) => new Date(x) >= dateLimit)
    }

    const overallRankTransformed = filterData(transformedData.overallRank)
    const positionalRankTransformed = filterData(transformedData.positionalRank)

    setDisplayOverallRank([{ id: 'overallRank', data: overallRankTransformed }])
    setDisplayPositionalRank([
      { id: 'positionalRank', data: positionalRankTransformed },
    ])
  }

  //---------------------------------------------------------------- Handlers ------------------------------------------------------------------------------------

  const handleNavigate = () => {
    navigate('/trade-finder', { state: { playerId: player.player_id } })
  }

  const openImageViewer = (index) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  const handleModalClick = (event) => {
    event.stopPropagation()
  }

  const handleSetTimeFrameToOneMonth = () => {
    const newTimeFrame = '1 month'
    setTimeFrame(newTimeFrame)
    transformDataForTimeFrame(newTimeFrame, combinedData)
  }

  const handleSetTimeFrameToAllTime = () => {
    const newTimeFrame = 'All Time'
    setTimeFrame(newTimeFrame)
    transformDataForTimeFrame(newTimeFrame, combinedData)
  }

  return (
    <div className="fixed inset-0 z-[100] flex items-center justify-center bg-black bg-opacity-50 pt-10 backdrop-blur-sm">
      <div
        className="flex max-w-full flex-col items-center overflow-auto rounded-custom border-[1px] border-light-text2 bg-light p-2 pb-2 text-center text-light-text transition-opacity duration-500 ease-in-out dark:border-dark-border dark:bg-dark dark:text-dark-text md:my-10 md:overflow-visible"
        onClick={handleModalClick}
      >
        {' '}
        <div
          aria-label="Timeout"
          className="relative flex w-full justify-end fill-light-bg2 text-2xl transition-all duration-200 dark:text-white"
          onClick={onClose}
        >
          <IoClose
            className="cursor-pointer fill-light-text transition-all duration-200 hover:fill-accent dark:fill-light"
            height={50}
            width={50}
          />
        </div>
        <TabsPrimitive.Root
          onValueChange={(newValue) => {
            setSelectedTab(newValue)
          }}
          className="relative z-[100] w-full"
          value={selectedTab}
        >
          <TabsPrimitive.List className="absolute top-[-35px] z-[100] flex max-w-[250px] space-x-1 overflow-auto rounded-xl px-4 py-2 text-xs font-medium md:top-[-75px] md:max-w-full md:text-base">
            <TabsPrimitive.Trigger
              className={`tab-button rounded-lg px-4 py-2 text-dark-text ${selectedTab === 'info-tab' ? activeTabClass : 'bg-accent2 dark:bg-dark'}`}
              onClick={(event) => event.stopPropagation()}
              value="info-tab"
            >
              {player.player_name}
            </TabsPrimitive.Trigger>
            {isLoading ? (
              <TabsPrimitive.Trigger
                className={'} rounded-lg bg-light px-4 py-2 dark:bg-dark'}
                onClick={(event) => event.stopPropagation()}
              >
                <Triangle
                  ariaLabel="loading"
                  color="#E97933"
                  height={25}
                  width={25}
                />
              </TabsPrimitive.Trigger>
            ) : (
              images.map((image, index) => (
                <TabsPrimitive.Trigger
                  className={`flex items-center gap-2 rounded-lg px-4 py-2 ${selectedTab === `tab-${index}` ? activeTabClass : 'bg-accent2 dark:bg-dark'}`}
                  key={index}
                  onClick={(event) => event.stopPropagation()}
                  value={`tab-${index}`}
                >
                  {getLogoForPromoCode(image.promo_code)}
                  <span className="whitespace-nowrap text-xs text-dark-text md:text-base">
                    {getDisplayPromoCode(image.promo_code)}
                  </span>
                </TabsPrimitive.Trigger>
              ))
            )}
          </TabsPrimitive.List>
          <TabsPrimitive.Content value="info-tab">
            <div className="mt-2 flex max-h-[90vh] flex-col justify-between gap-10 overflow-auto rounded-lg pt-6 md:flex-row md:px-10">
              <div>
                <div className="items-center pb-4 lg:flex">
                  <div className="flex flex-col items-center">
                    <img
                      alt={player.player_name}
                      className="mx-auto mb-4 h-[200px] rounded-full"
                      src={playerPicture}
                    />
                    <div className="flex gap-10 text-lg font-medium">
                      <span>{player.position}</span>
                      <span className="pt-4">
                        {player.jersey_number
                          ? `# ${player.jersey_number}`
                          : ''}
                      </span>
                      <span>{player.team}</span>
                    </div>
                  </div>
                  <div className="flex flex-col items-center gap-10 md:items-start">
                    <h2 className="flex pl-4 text-2xl font-medium">
                      {player.player_name}
                    </h2>
                    <div className="flex flex-wrap justify-center gap-y-2 md:justify-start">
                      <div className="border-r-[1px] border-light-text px-4 font-medium dark:border-light2">
                        Age
                        <h2 className="text-light-text2 dark:text-dark-text2">
                          {player.dob}
                        </h2>
                      </div>
                      <div className="border-r-[1px] border-light-text px-4 dark:border-light2">
                        Height
                        <h2 className="text-light-text2 dark:text-dark-text2">
                          {convertHeight(player.height)}
                        </h2>
                      </div>
                      <div className="border-r-[1px] border-light-text px-4 dark:border-light2">
                        Weight
                        <h2 className="text-light-text2 dark:text-dark-text2">
                          {player.weight}
                        </h2>
                      </div>
                      <div className="border-r-[1px] border-light-text px-4 dark:border-light2">
                        Exp
                        <h2 className="text-light-text2 dark:text-dark-text2">
                          {player.experience}
                        </h2>
                      </div>
                      <div className="border-light-text px-4 dark:border-light2">
                        School
                        <h2 className="text-light-text2 dark:text-dark-text2">
                          {player.college}
                        </h2>
                      </div>
                    </div>
                    {!player.is_rookie && (
                      <div className="flex w-full flex-col items-center justify-center gap-4 sm:flex-row">
                        <div className="flex flex-col items-center gap-1 border-light-text dark:border-dark-border dark:border-light2 sm:border-r-[1px] md:pr-4">
                          <h2 className="text-xl font-medium">Expert Rank</h2>
                          <div className="flex w-full justify-center gap-4">
                            <div className="text-light-text2 dark:text-dark-text2">{`${player.position}: ${expertPositionRank || '-'}`}</div>
                            <div className="text-light-text2 dark:text-dark-text2">{`#${expertOverallRank} Overall`}</div>
                          </div>
                        </div>
                        <div className="flex flex-col items-center gap-1">
                          <h2 className="text-xl font-medium">
                            Community Rank
                          </h2>
                          <div className="flex w-full justify-center gap-4">
                            <div className="text-light-text2 dark:text-dark-text2">{`${player.position}: ${communityPositionRank || '-'}`}</div>
                            <div className="text-light-text2 dark:text-dark-text2">
                              {`#${player.communityRank || player.commRank} Overall`}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col pt-2 text-sm">
                    <div className="">
                      <button
                        className="w-full rounded-lg bg-accent px-4 py-2 text-dark-text"
                        onClick={handleNavigate}
                      >
                        Find Trades
                      </button>
                    </div>
                    <div>
                      <div className="my-2 border-b-[1px] border-light2 dark:border-dark-border" />
                      <div className="flex flex-col gap-2 text-sm">
                        <button
                          className="w-full rounded-md border-[1px] border-light2 px-2 py-1 transition-all duration-200 hover:bg-light-bg2 dark:border-dark-border hover:dark:bg-dark-bg2"
                          onClick={handleSetTimeFrameToOneMonth}
                        >
                          1 Month
                        </button>
                        <button
                          className="w-full rounded-md border-[1px] border-light2 px-2 py-1 transition-all duration-200 hover:bg-light-bg2 dark:border-dark-border hover:dark:bg-dark-bg2"
                          onClick={handleSetTimeFrameToAllTime}
                        >
                          All Time
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="relative">
                    <h2 className="pb-2 text-center text-xl font-medium">
                      Overall Rank
                    </h2>
                    <div className="h-[200px] w-full min-w-[300px] bg-light-bg dark:bg-dark">
                      <LineChart
                        data={displayOverallRank}
                        isPositionalRank={false}
                        player={player}
                        timeFrame={timeFrame}
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <h2 className="pb-2 text-center text-xl font-medium">
                      Positonal Rank
                    </h2>
                    <div className="h-[200px] w-full min-w-[300px] bg-light-bg dark:bg-dark">
                      <LineChart
                        data={displayPositionalRank}
                        isPositionalRank={true}
                        player={player}
                        timeFrame={timeFrame}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabsPrimitive.Content>

          {images.map((image, index) => (
            <TabsPrimitive.Content
              className="mt-2 max-h-[80vh] overflow-auto rounded-lg md:max-w-[65vw]"
              key={index}
              value={`tab-${index}`}
            >
              <img
                alt="Player"
                className="aspect-auto h-auto w-full cursor-pointer overflow-hidden rounded-md"
                onClick={() => openImageViewer(index)}
                src={image.image_link}
              />
            </TabsPrimitive.Content>
          ))}
        </TabsPrimitive.Root>
      </div>
      {isViewerOpen && (
        <ReactSimpleImageViewer
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
            zIndex: 101,
          }}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          src={images.map((img) => img.image_link)}
        />
      )}
      {isViewerOpen && isPortrait && (
        <div className="fixed left-1/2 top-[10%] z-[102] -translate-x-1/2 -translate-y-1/2 transform rounded-lg p-5 text-center text-dark-text">
          For a better viewing experience, please turn your device sideways.
        </div>
      )}
    </div>
  )
}

export default PlayerInfoModal
