import React, { useState, useEffect } from 'react'
import * as Select from '@radix-ui/react-select'
import { CheckIcon, ChevronDownIcon } from '@radix-ui/react-icons'
import classnames from 'classnames'

const PositionSelect = ({
  label,
  onPositionChange,
  defaultValue,
  maxOptions,
  disabled,
  league,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue)
  const [options, setOptions] = useState([])

  useEffect(() => {
    setSelectedValue(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    let newOptions = []
    if (label === 'BENCH' && league.toLowerCase() === 'dynasty') {
      newOptions = ['< 10', '10-15', '16-20', '21-25', '26-30', '40+']
    } else {
      newOptions = Array.from(
        { length: maxOptions },
        (_, index) => `${index + 1}`
      )
    }
    setOptions(newOptions)
  }, [league, label, maxOptions])

  const handleValueChange = (value) => {
    let newValue = value

    if (label === 'BENCH') {
      switch (value) {
        case '>10':
        case '10+':
          newValue = '10'
          break
        case '10-15':
          newValue = '15'
          break
        case '16-20':
          newValue = '20'
          break
        case '21-25':
          newValue = '25'
          break
        case '26-30':
          newValue = '30'
          break
        case '40+':
          newValue = '40'
          break
        default:
          break
      }
    }

    setSelectedValue(newValue)
    onPositionChange(newValue)
  }

  return (
    <Select.Root onValueChange={handleValueChange} value={selectedValue}>
      {disabled ? (
        <div className="inline-flex h-[43px] w-[85px] items-center justify-between gap-[5px] rounded-[10px] bg-zinc-300 px-4 text-[13px] leading-none text-light-text2 dark:bg-zinc-400 dark:text-dark-text2 md:w-[108px]">
          <div className="mr-[1.25rem]">{defaultValue}</div>
        </div>
      ) : (
        <>
          <Select.Trigger
            aria-label={label}
            className="inline-flex h-[43px] w-[85px] items-center justify-between gap-10 overflow-auto rounded-[10px] border-[1px] bg-light px-4 text-[13px] leading-none text-light-text2 shadow-sm hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2 dark:border-[#F8F8F81A] dark:bg-[#F8F8F81A] dark:text-dark-text2 md:w-[108px]"
          >
            <Select.Value>{selectedValue}</Select.Value>
            <Select.Icon>
              <ChevronDownIcon />
            </Select.Icon>
          </Select.Trigger>
          <Select.Portal>
            <Select.Content className="z-[10001] overflow-hidden rounded-md bg-white shadow-lg">
              <Select.Viewport>
                <Select.Group>
                  {options.map((option, index) => (
                    <SelectItem key={index} value={option}>
                      {option}
                    </SelectItem>
                  ))}
                </Select.Group>
              </Select.Viewport>
            </Select.Content>
          </Select.Portal>
        </>
      )}
    </Select.Root>
  )
}

// eslint-disable-next-line react/display-name
const SelectItem = React.forwardRef(({ value, children, ...props }, ref) => (
  <Select.Item
    {...props}
    className={classnames(
      'relative cursor-default select-none py-2 pl-10 pr-4',
      'text-gray-900 hover:bg-custom-orange-bg hover:text-white',
      'focus:bg-custom-orange focus:text-white focus:outline-none'
    )}
    ref={ref}
    value={value}
  >
    <Select.ItemIndicator className="absolute inset-y-0 left-0 flex items-center pl-3">
      <CheckIcon />
    </Select.ItemIndicator>
    <Select.ItemText>{children}</Select.ItemText>
  </Select.Item>
))

export default PositionSelect
