import React, { useState, useRef, useEffect } from 'react'
import { IoChevronDownOutline } from 'react-icons/io5'
import { FaPlus, FaXmark } from 'react-icons/fa6'
import { toast } from 'react-toastify'
import { deleteUserLeagueProfile } from '../../store/api'
import { Triangle } from 'react-loader-spinner'
import ConfirmationModal from './ConfirmationModal'

const TeamSelect = ({
  options,
  onSelect,
  placeholder = 'Select...',
  disabled = false,
  defaultValue,
  onAddNewTeam,
  refreshLeagues,
  footer = true,
}) => {
  const buttonRef = useRef(null)
  const dropdownRef = useRef(null)

  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState({})
  const [openUpwards, setOpenUpwards] = useState(false)
  const [leagueIdToDelete, setLeagueIdToDelete] = useState(null)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect()
      const isOverflowing = dropdownRect.bottom > window.innerHeight
      setOpenUpwards(isOverflowing)
    }
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  useEffect(() => {
    if (defaultValue) {
      setSelectedOption({
        label: defaultValue.label,
        id: defaultValue.id,
      })
    }
  }, [defaultValue])

  const handleSelect = (option) => {
    setSelectedOption(option)
    onSelect(option)
    setIsOpen(false)
  }

  const handleAddNewTeam = () => {
    onAddNewTeam()
    setIsOpen(false)
  }

  const initiateDeleteTeam = (leagueId) => {
    setLeagueIdToDelete(leagueId)
    setIsConfirmModalOpen(true)
  }

  const handleDeleteTeam = async () => {
    if (!leagueIdToDelete) {
      toast.error('League ID is required')
      return
    }

    const trimmedLeagueId = leagueIdToDelete.trim()

    const selectedOptionToDelete = options.find(
      (opt) => opt.id === trimmedLeagueId
    )

    const selectedOptionPlatform = selectedOptionToDelete.platform || 'SLEEPER'

    const data = { leagueId: trimmedLeagueId, platform: selectedOptionPlatform }

    try {
      await deleteUserLeagueProfile(data)
      toast.success('Team deleted successfully!')
      refreshLeagues()
      setIsConfirmModalOpen(false)
      setLeagueIdToDelete(null)
    } catch (error) {
      console.error('Failed to delete team:', error)
      toast.error('Failed to delete team')
    }
  }

  const buttonClass = disabled
    ? 'flex justify-between items-center border-0 rounded-custom bg-gray-200 text-gray-500 px-[14px] py-[13px] w-full min-w-[200px] cursor-not-allowed'
    : 'flex justify-between items-center border-0 rounded-custom bg-light-bg-select dark:bg-dark-select-bg text-light-text2 dark:text-dark-text2 px-[14px] py-[13px] w-full min-w-[200px]'

  const objectOptions = options.map((opt) => ({
    label: opt.label || opt.name || opt,
    id: opt.id,
  }))

  useEffect(() => {
    if (options.length === 0) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [options])

  return (
    <div className="relative">
      <button
        className={buttonClass}
        disabled={disabled}
        onClick={() => !disabled && setIsOpen(!isOpen)}
        ref={buttonRef}
      >
        {selectedOption.label || placeholder}
        <IoChevronDownOutline className="ml-2 fill-dark-text2" size={20} />
      </button>

      {isOpen && (
        <ul
          className={`absolute z-10 max-h-[30vh] w-full overflow-y-scroll rounded-custom rounded-br-custom border-[1px] border-light2 bg-light dark:border-dark-border dark:bg-dark ${openUpwards ? 'bottom-full' : 'mt-1'}`}
          ref={dropdownRef}
        >
          {isLoading ? (
            <div className="flex w-full justify-center py-2">
              <Triangle color="#E97933" height={30} width={30} />
            </div>
          ) : (
            objectOptions.map((option, idx) => (
              <li
                className="flex cursor-pointer justify-between p-2 hover:bg-light2 dark:text-dark-text dark:hover:bg-zinc-700"
                key={idx}
                onClick={() => handleSelect(option)}
              >
                <span className="max-w-[80%] overflow-hidden text-ellipsis whitespace-nowrap">
                  {option.label}
                </span>
                <div
                  onClick={(e) => {
                    e.stopPropagation()
                    initiateDeleteTeam(option.id)
                  }}
                  className="flex items-center"
                >
                  <FaXmark
                    className="text-light-text transition-all duration-200 hover:text-accent dark:text-dark-text dark:hover:text-accent"
                    size={18}
                  />
                </div>
              </li>
            ))
          )}
          {footer && (
            <>
              <div className="border-b-[1px] border-light2 dark:border-dark-border" />
              <li
                className="hover:bg-dark-hover flex cursor-pointer items-center justify-center p-2 hover:bg-light2 dark:text-dark-text dark:hover:bg-zinc-700"
                onClick={handleAddNewTeam}
              >
                <FaPlus className="mr-2 fill-accent" />
                <span>Sync new team</span>
              </li>
            </>
          )}
        </ul>
      )}
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={handleDeleteTeam}
      />
    </div>
  )
}

export default TeamSelect
