import React, { useState } from 'react'
import { FaArrowRight, FaXmark, FaArrowLeft, FaCheck } from 'react-icons/fa6'
import TradeTeam from '../../Purchase/Products/TradeQuestions/TradeTeam'
import PoolSection from './PoolSection'
import { TbArrowsLeftRight } from 'react-icons/tb'
import { FaCrown } from 'react-icons/fa6'
import BaseSwitch from '../../_ui/BaseSwitch'
import { toast } from 'react-toastify'

import { saveTradeQuestionOptions } from '../../../store/api'

const TradeModal = ({ isOpen, onClose, trade, onUpdate }) => {
  if (!isOpen) {
    return null
  }
  if (
    !trade ||
    !trade.trade_configurations ||
    trade.trade_configurations.length < 1
  ) {
    console.error('Invalid trade object:', trade)
    return null
  }

  const [step, setStep] = useState(1)
  const [evenTrade, setEvenTrade] = useState(false)
  const [winningTeam, setWinningTeam] = useState('')

  const [tradingPlayers, setTradingPlayers] = useState(
    trade.trade_configurations[0].trade_players || []
  )
  const [receivingPlayers, setReceivingPlayers] = useState(
    trade.trade_configurations[0].receive_players || []
  )
  const [tradingPool, setTradingPool] = useState(
    trade.trade_configurations[0].trade_pool || []
  )
  const [receivingPool, setReceivingPool] = useState(
    trade.trade_configurations[0].receive_pool || []
  )

  const validation = () => {
    if (winningTeam === '') {
      toast('Please choose a winning team')
      return false
    }
    return true
  }

  const goNext = () => {
    if (validation()) {
      setStep(step + 1)
    }
  }

  const selectWinner = async () => {
    try {
      const payload = {
        tradeConfigurationId: trade.trade_configurations[0].id,
        tradeOutcome: winningTeam,
      }
      await saveTradeQuestionOptions(trade.id, payload)
      onUpdate()
      toast.success('Winner selected as ' + winningTeam)
    } catch (error) {
      console.error('Error updating question status:', error)
      toast.error('Failed to update question status.')
    }
  }

  const submitTradeConfig = async () => {
    try {
      const payload = {
        tradePlayers: tradingPlayers,
        tradePool: trade.trade_configurations[0].trade_players,
        receivePlayers: receivingPlayers,
        receivePool: trade.trade_configurations[0].receive_pool,
        tradeConfigurationId: trade.trade_configurations[0].id,
        tradeOutcome: winningTeam,
      }

      Object.keys(payload).forEach((key) => {
        if (Array.isArray(payload[key])) {
          payload[key] = payload[key].map((player) => player.player_id)
        }
      })

      await saveTradeQuestionOptions(trade.id, payload)
      onUpdate()
      toast.success('Trade configuration submitted successfully.')
    } catch (error) {
      console.error('Error updating question status:', error)
      toast.error('Failed to update question status.')
    }
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative flex h-full max-h-[90dvh] w-full max-w-[90%] flex-col gap-4 overflow-auto rounded-lg bg-light bg-opacity-100 p-10 text-light-text shadow-lg backdrop-blur-md dark:bg-dark dark:bg-opacity-50 dark:text-dark-text">
        <div className="absolute right-2 top-2" onClick={onClose}>
          <FaXmark size={26} />
        </div>
        <h2 className="text-2xl font-semibold text-accent">
          {trade.user_display_name}
        </h2>
        {step === 1 ? (
          <>
            <section className="flex flex-col items-center justify-center gap-2">
              <span className="flex items-center gap-3">
                <h2 className="text-2xl font-semibold">Choose the winner</h2>
                <FaCrown className="text-2xl text-accent" />
              </span>
              <p className="flex w-full flex-col gap-10 px-[10%] md:flex-row">
                <div
                  className="flex w-full flex-col"
                  onClick={() => setWinningTeam('trader')}
                >
                  <p className="h-[350px] w-full">
                    <TradeTeam
                      className=""
                      flex={true}
                      hover={true}
                      isInteractive={false}
                      isWinning={winningTeam === 'trader'}
                      players={tradingPlayers}
                      search={false}
                      setPlayers={setTradingPlayers}
                      text={'Add your offered players...'}
                    />
                  </p>
                </div>
                <div className="flex items-center md:h-[400px]">
                  <TbArrowsLeftRight size={50} />
                </div>
                <div
                  className="flex w-full flex-col"
                  onClick={() => setWinningTeam('receiver')}
                >
                  <p className="h-[350px] w-full">
                    <TradeTeam
                      flex={true}
                      hover={true}
                      isInteractive={false}
                      isWinning={winningTeam === 'receiver'}
                      players={receivingPlayers}
                      search={false}
                      setPlayers={setReceivingPlayers}
                      text={'Add your offered players...'}
                    />
                  </p>
                </div>
              </p>
            </section>
            <section className="grid grid-cols-2 gap-4 px-[10%]">
              <div className="col-span-1">
                <h2 className="pb-2 text-2xl font-semibold md:w-[50%]">
                  Settings
                </h2>
                <div className="grid md:w-[75%] md:grid-cols-2">
                  <p className="col-span-1 flex flex-col gap-2">
                    <div className="flex gap-4">
                      <h3 className="font-bold">League Type</h3>
                      <div className="text-light-text2 dark:text-dark-text2">
                        {trade.league_settings.leagueType}
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <h3 className="font-bold">Lineup Count</h3>
                      <div className="text-light-text2 dark:text-dark-text2">
                        {trade.league_settings.lineupCount}
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <h3 className="font-bold">Team Count</h3>
                      <div className="text-light-text2 dark:text-dark-text2">
                        {trade.league_settings.teamCounts}
                      </div>
                    </div>
                  </p>
                  <p className="col-span-1 flex flex-col gap-2">
                    <div className="flex gap-4">
                      <h3 className="font-bold">PPR</h3>
                      <div className="text-light-text2 dark:text-dark-text2">
                        {trade.league_settings.ppr}
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <h3 className="font-bold">QBs</h3>
                      <div className="text-light-text2 dark:text-dark-text2">
                        {trade.league_settings.qbs}
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <h3 className="font-bold">TE Premium</h3>
                      <div className="text-light-text2 dark:text-dark-text2">
                        {trade.league_settings.tep ? 'True' : 'False'}
                      </div>
                    </div>
                  </p>
                </div>
              </div>
              <div className="col-span-1 h-32 md:pl-14">
                <h2 className="pb-2 text-2xl font-semibold">Comments</h2>
                <div className="rounded-lg">{trade.comments}</div>
              </div>
              <div className="col-span-2 flex w-full justify-end">
                <p className="flex gap-4">
                  <BaseSwitch
                    isChecked={evenTrade}
                    justifyStart={true}
                    label="Even trade?"
                    setIsChecked={setEvenTrade}
                  />
                  {evenTrade ? (
                    <button
                      className="flex items-center rounded-lg bg-accent px-10 py-3 text-dark-text"
                      onClick={goNext}
                    >
                      Continue
                      <FaArrowRight className="ml-2" />
                    </button>
                  ) : (
                    <button
                      className="flex items-center rounded-lg bg-accent px-10 py-3 text-dark-text"
                      onClick={selectWinner}
                    >
                      Submit
                      <FaArrowRight className="ml-2" />
                    </button>
                  )}
                </p>
              </div>
            </section>
          </>
        ) : (
          <>
            <section className="flex flex-col items-center justify-center gap-2">
              <h2 className="text-2xl font-semibold">Even the trade</h2>
              <p className="flex w-full flex-col gap-10 px-[10%] md:flex-row">
                <div className="flex w-full flex-col">
                  <p className="h-[300px] w-full">
                    <TradeTeam
                      className=""
                      flex={true}
                      isWinning={winningTeam === 'trading'}
                      players={tradingPlayers}
                      pool={tradingPool}
                      search={false}
                      setPlayers={setTradingPlayers}
                      setPool={setTradingPool}
                      text={'Add your offered players...'}
                    />
                  </p>
                  <PoolSection players={tradingPool} />
                </div>
                <div className="flex items-center md:h-[400px]">
                  <TbArrowsLeftRight size={50} />
                </div>
                <div className="flex w-full flex-col">
                  <p className="h-[300px] w-full">
                    <TradeTeam
                      flex={true}
                      isWinning={winningTeam === 'receiving'}
                      players={receivingPlayers}
                      pool={receivingPool}
                      search={false}
                      setPlayers={setReceivingPlayers}
                      setPool={setReceivingPool}
                      text={'Add your offered players...'}
                    />
                  </p>
                  <PoolSection players={receivingPool} />
                </div>
              </p>
            </section>
            <section className="grid grid-cols-2 gap-4 px-[10%]">
              <div className="col-span-1">
                <h2 className="pb-2 text-2xl font-semibold md:w-[50%]">
                  Settings
                </h2>
                <div className="grid md:w-[75%] md:grid-cols-2">
                  <p className="col-span-1 flex flex-col gap-2">
                    <div className="flex gap-4">
                      <h3 className="font-bold">League Type</h3>
                      <div className="text-light-text2 dark:text-dark-text2">
                        {trade.league_settings.leagueType}
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <h3 className="font-bold">Lineup Count</h3>
                      <div className="text-light-text2 dark:text-dark-text2">
                        {trade.league_settings.lineupCount}
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <h3 className="font-bold">Team Count</h3>
                      <div className="text-light-text2 dark:text-dark-text2">
                        {trade.league_settings.teamCounts}
                      </div>
                    </div>
                  </p>
                  <p className="col-span-1 flex flex-col gap-2">
                    <div className="flex gap-4">
                      <h3 className="font-bold">PPR</h3>
                      <div className="text-light-text2 dark:text-dark-text2">
                        {trade.league_settings.ppr}
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <h3 className="font-bold">QBs</h3>
                      <div className="text-light-text2 dark:text-dark-text2">
                        {trade.league_settings.qbs}
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <h3 className="font-bold">TE Premium</h3>
                      <div className="text-light-text2 dark:text-dark-text2">
                        {trade.league_settings.tep ? 'True' : 'False'}
                      </div>
                    </div>
                  </p>
                </div>
              </div>
              <div className="col-span-1 h-32 md:pl-14">
                <h2 className="pb-2 text-2xl font-semibold">Comments</h2>
                <div className="rounded-lg">{trade.comments}</div>
              </div>
              <div className="col-span-2 flex w-full justify-between">
                <button
                  className="flex items-center rounded-lg bg-accent px-10 py-3 text-dark-text"
                  onClick={() => setStep(1)}
                >
                  Back
                  <FaArrowLeft className="ml-2" />
                </button>
                <button
                  className="flex items-center rounded-lg bg-accent px-10 py-3 text-dark-text"
                  onClick={submitTradeConfig}
                >
                  Submit
                  <FaCheck className="ml-2" />
                </button>
              </div>
            </section>
          </>
        )}
      </div>
    </div>
  )
}

export default TradeModal
