import React from 'react'

const AddPositionModal = ({ selectPlayer }) => {
  const players = [
    { position: 'QB', player: {} },
    { position: 'RB', player: {} },
    { position: 'WR', player: {} },
    { position: 'TE', player: {} },
    { position: 'FLEX', player: {} },
  ]

  return (
    <div className="absolute right-0 top-2 z-10 grid w-max grid-cols-2 gap-1 rounded-lg border-[1px] border-light2 bg-dark bg-opacity-60 p-1 backdrop-blur-sm dark:border-dark-border dark:bg-opacity-70">
      {players.map((player, index) => (
        <div
          className="flex cursor-pointer items-center justify-center rounded border-[1px] border-light2 bg-dark-bg2 p-4 text-dark-text transition-all duration-200 hover:bg-accent dark:border-dark-border"
          key={index}
          onClick={() => selectPlayer(player)}
        >
          {player.position}
        </div>
      ))}
    </div>
  )
}

export default AddPositionModal
