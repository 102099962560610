/* eslint-disable eqeqeq */
import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  setAppliedPromoCode,
  updatePromoCode,
} from '../../store/userReducer.js'
import { toast, ToastContainer } from 'react-toastify'
import {
  getCreatorDetails,
  // applyUnderDogCredit,
  linkDiscordAndUpdateRoles,
} from '../../requests/requests.js'
import {
  applyPromoCode as applyPromoCodeAction,
  createCode as createCodeAction,
  getPromoCode as getPromoCodeAction,
} from '../../store/api.js'

import { getToken } from '../../services/authService.js'
import { editPaymentInfo } from '../../store/api.js'

// import { loginUser, appliedUDCredit } from '../store/userReducer'
// import { loginUser } from '../store/userReducer'

import ChangeDisplayName from '../Misc/ChangeDisplayName.js'
import * as Tabs from '@radix-ui/react-tabs'
import { FaDiscord, FaLink } from 'react-icons/fa6'

const Account = () => {
  const {
    applied_promo_code,
    // address,
    canceling,
    discord_id,
    email,
    is_creator,
    is_trialing,
    is_subscribed,
    loggedIn,
    payments,
    // phone,
    promo_code,
    product_name,
    subscription_end,
    // underdog_username,
    // underdog_deposit,
    username,
    ...rest
  } = useSelector((state) => state.user)

  const [formCode, setCode] = useState('')
  // const [ formUnderdogUsername, setUnderdogUsername ] = useState('')
  const [paymentsDisplay, setPaymentsDisplay] = useState('')
  const [creatorData, setCreatorData] = useState('')
  const [promoErrMsg, setPromoErrMsg] = useState('')
  const [displayedName, setDisplayedName] = useState(username)
  const [setWidth] = useState(window.innerWidth)
  const [editDisplayName, setEditDisplayName] = useState('')
  const [tab, setTab] = useState('general')

  const initialRef = useRef(true)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!loggedIn) {
      navigate('/login')
    }

    if (payments) {
      if (!paymentsDisplay) {
        let formatted = formatPayments(
          JSON.parse(JSON.stringify(payments.data))
        )
        setPaymentsDisplay(formatted)
      }
    }

    if (!initialRef.current) {
      return
    }
    initialRef.current = false
    attemptLink()

    if (!creatorData && is_creator) {
      fetchCreatorDetails()
    }

    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  useEffect(() => {
    attemptLink()
  }, [])

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)

    if (Array.from(searchParams.keys()).length > 0) {
      toast('Please Visit our discord to channel to confirm your link', {
        type: 'info',
      })
    }
  }, [])

  const fetchCreatorDetails = async () => {
    const data = await getCreatorDetails()
    setCreatorData(data)
  }

  const formatTimestamp = (timestamp) => {
    if (!timestamp) {
      return ''
    }

    const date = new Date(timestamp * 1000)
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().slice(-2)}`

    return formattedDate
  }

  const attemptLink = async () => {
    const params = new URLSearchParams(window.location.search)
    let code = params.get('code')

    if (!code) {
      return
    }
    let response = await linkDiscordAndUpdateRoles(code)

    if (response) {
      console.log('RES JOOOE : ', response)
    }
  }

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  const handleUpdateCode = (code) => {
    dispatch(updatePromoCode(code))
  }

  const formatPayments = (res) => {
    res = res.map((payment) => {
      payment.created = new Date(payment.created * 1000).toLocaleDateString()
      if (payment.currency == 'usd') {
        let str = '$' + payment.amount
        let len = str.length
        str = str.slice(0, len - 2) + '.' + str.slice(len - 2)
        payment.amount = str
      }
      return payment
    })
    return res
  }

  const createCode = async () => {
    const requestBody = {
      code: formCode,
      accessToken: await getToken(),
    }

    try {
      await createCodeAction(requestBody)
      handleUpdateCode(formCode)
      // showToast('Promo code created successfully!', 'success')
    } catch (err) {
      console.log('ERROR createCode : ', err)
      // showToast('Error creating promo code.', 'error')
    }
  }

  const handleSubscribe = () => {
    navigate('/subscribe')
  }

  const handleStripe = () => {
    try {
      editPaymentInfo()
    } catch (err) {
      // Most likely no customer in Stripe
      console.log('ERROR Account.handleStripe : ', err)
    }
  }

  // async function applyUnderdogCredit() {
  //   if (formUnderdogUsername == '') {
  //     return ''
  //   }

  //   let response = await applyUnderDogCredit(formUnderdogUsername)

  //   console.log(response)
  //   if (response) {
  //     response.username = formUnderdogUsername
  //     dispatch(appliedUDCredit(response))
  //   }
  // }

  const linkDiscord = () => {
    window.location =
      'https://discord.com/api/oauth2/authorize?client_id=1087443675136663605&redirect_uri=https%3A%2F%2Fflockfantasy.com%2Faccount&response_type=code&scope=identify%20guilds.join%20guilds%20guilds.members.read'
  }

  const codeIsValid = async () => {
    const alphanumeric = /[A-Za-z0-9]+/
    if (formCode == '') {
      // showToast('Please enter a promo code.', 'warning')
      return ''
    }

    const isValid = alphanumeric.test(formCode)
    const upperCode = formCode.toUpperCase()
    console.log('upperCode : ', upperCode)
    if (!isValid) {
      // showToast('Invalid format for promo code.', 'warning')
      return undefined
    }

    try {
      const { promoter_id } = await getPromoCodeAction(upperCode)
      try {
        const { applied_promo_code } = await applyPromoCodeAction({
          promoCode: upperCode,
          promoterId: promoter_id,
        })
        dispatch(setAppliedPromoCode(applied_promo_code))
        toast.success('Promo code applied successfully!')
      } catch (error) {
        // showToast('Error applying promo code.', 'error')
        console.log('error with code:', error)
        toast.error('Failed to apply promo code.')
      }
    } catch (err) {
      console.log('INVALID CODE')
      setPromoErrMsg('Invalid Promo Code')
      toast.error('Invalid promo code.')
      return undefined
    }
  }

  const saveDisplayName = (newDisplayName) => {
    setDisplayedName(newDisplayName)
    setEditDisplayName('')
    toast.success('Display name updated successfully!')
  }

  const handleTabChange = (newValue) => {
    setTab(newValue)
  }

  // const showToast = (message, type) => {
  //   toast(message, { type })
  // }

  return (
    <div className="flex flex-col items-center gap-4 bg-light px-4 font-dmsans dark:bg-dark lg:h-[92vh]">
      <div className="flex w-full flex-col px-[10vw] pt-10 lg:w-[65%]">
        <Tabs.Root defaultValue="general" onValueChange={handleTabChange}>
          <div className="w-full pt-10">
            <h3 className="pb-10 text-center text-lg font-bold text-light-text dark:text-dark-text lg:text-3xl">
              Account
            </h3>
            <div className="flex justify-center">
              <Tabs.List className="inline-flex flex-wrap justify-center gap-1 rounded-xl bg-light-bg p-1 dark:bg-dark-bg-select">
                <Tabs.Trigger
                  className={`rounded-lg px-8 py-2 transition-all duration-200 hover:bg-accent-bg hover:text-accent hover:dark:text-accent ${
                    tab === 'general'
                      ? 'bg-accent-bg font-bold text-accent'
                      : 'text-light-text2 dark:text-dark-text2'
                  }`}
                  value="general"
                >
                  General
                </Tabs.Trigger>
                <Tabs.Trigger
                  className={`rounded-lg px-8 py-2 transition-all duration-200 hover:bg-accent-bg hover:text-accent hover:dark:text-accent ${
                    tab === 'subscription'
                      ? 'bg-accent-bg font-bold text-accent'
                      : 'text-light-text2 dark:text-dark-text2'
                  }`}
                  value="subscription"
                >
                  Subscription
                </Tabs.Trigger>
                {/* {is_creator && (
                  <Tabs.Trigger
                    className={`px-8 py-2 rounded-lg hover:bg-accent-bg hover:text-accent hover:dark:text-accent transition-all duration-200 ${
                      tab === 'income' ? 'bg-accent-bg text-accent font-bold' : 'dark:text-dark-text2 text-light-text2'
                    }`}
                    value="income"
                  >
                  Income
                  </Tabs.Trigger>
                )} */}
              </Tabs.List>
            </div>
            <Tabs.Content value="general">
              <div className="flex flex-col items-center pt-10 text-lg text-light-text dark:text-dark-text md:mx-[20%]">
                <div className="flex w-full flex-col gap-4">
                  <div className="border-t-[1px] border-light2 pt-4 dark:border-dark-border">
                    <div className="font-bold">Email:</div>
                    <div className="text-light-text2">{email}</div>
                  </div>
                  <div className="border-t-[1px] border-light2 pt-4 dark:border-dark-border">
                    <div className="pb-2 font-bold">Display Name:</div>
                    <ChangeDisplayName
                      currentDisplayName={editDisplayName}
                      placeholderDisplayName={displayedName}
                      saveDisplayName={saveDisplayName}
                      setEditDisplayName={setEditDisplayName}
                    />
                  </div>
                  <div className="border-t-[1px] border-light2 pt-4 dark:border-dark-border">
                    {!applied_promo_code && !is_creator ? (
                      <>
                        <div className="flex flex-col gap-2">
                          <div className="font-bold">Promo Code:</div>
                          <div className="flex flex-col">
                            <div className="flex gap-6">
                              <input
                                className="flex-grow rounded-custom border-[1px] border-light2 p-2 text-sm dark:border-dark-border dark:bg-dark"
                                onChange={(e) => setCode(e.target.value)}
                                placeholder="Promo Code"
                                type="text"
                                value={formCode}
                              />
                              <button
                                className="w-[130px] rounded-custom bg-accent p-2 text-sm text-white transition-colors hover:bg-orange-600"
                                onClick={codeIsValid}
                              >
                                Apply Code
                              </button>
                            </div>
                            <div className="pt-2 text-sm text-light-text2 dark:text-dark-text2">
                              * Apply a creator&apos;s promo code for a 30%
                              discount on all future subscription purchases!
                            </div>
                          </div>
                        </div>
                        <p className="mt-2 text-sm text-red-500">
                          {promoErrMsg}
                        </p>
                      </>
                    ) : !is_creator ? (
                      <>
                        <div>Promo Code: {applied_promo_code}</div>
                      </>
                    ) : (
                      is_creator && (
                        <>
                          {!promo_code ? (
                            <div className="flex flex-col justify-center gap-2">
                              <input
                                className="flex-grow rounded-custom border-[1px] border-light2 p-2 text-sm dark:border-dark-border dark:bg-dark"
                                onChange={(e) => setCode(e.target.value)}
                                placeholder="Promo Code"
                                type="text"
                                value={formCode}
                              />
                              <button
                                className="w-[130px] rounded-custom bg-accent p-2 text-sm text-white transition-colors hover:bg-orange-600"
                                onClick={createCode}
                              >
                                Create
                              </button>
                            </div>
                          ) : (
                            <div className="mt-2">
                              Sharable Promo Code: {promo_code}
                            </div>
                          )}
                        </>
                      )
                    )}
                  </div>
                  <div className="border-y-[1px] border-light2 py-4 dark:border-dark-border">
                    <div className="flex-col items-center gap-4">
                      <button
                        className="flex rounded-custom bg-discord-purple px-8 py-2 text-sm text-white transition-colors hover:bg-indigo-700"
                        onClick={linkDiscord}
                      >
                        <FaLink className="mr-2" size={20} />
                        {discord_id ? 'Link Discord' : 'Sync Discord Roles'}
                      </button>
                      <div className="pt-2 text-sm text-light-text2 dark:text-dark-text2">
                        {discord_id
                          ? '* Join the discord server and get your subscription roles!'
                          : '* If youve recently made a new purchase, sync your roles to get the new role!'}
                      </div>
                    </div>
                  </div>
                  <div className="border-b-[1px] border-light2 pb-4 dark:border-dark-border">
                    <div className="flex-col items-center gap-4">
                      <button
                        onClick={() =>
                          window.open('https://discord.com/login', '_blank')
                        }
                        className="flex rounded-custom bg-discord-purple px-8 py-2 text-sm text-white transition-colors hover:bg-indigo-700"
                      >
                        <FaDiscord className="mr-2" size={20} />
                        Visit Discord
                      </button>
                      <div className="pt-2 text-sm text-light-text2 dark:text-dark-text2">
                        * Visit our discord server to chat with other flockers
                        and get the latest news and updates!
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tabs.Content>
            <Tabs.Content value="utilities">
              <div className="flex flex-col items-center pt-10 text-lg text-light-text dark:text-dark-text md:mx-[20%]">
                <div className="flex w-full flex-col gap-4">
                  {/*<div className="border-b-[1px] border-light2 dark:border-dark-border pb-4">
                    {!underdog_username || underdog_deposit ? (
                      <div className="flex justify-center gap-2 flex-col">
                        <input
                          placeholder={
                            underdog_username.length > 0
                              ? underdog_username
                              : 'Underdog Username...'
                          }
                          className="border-[1px] border-light2 dark:border-dark-border rounded-custom p-2 flex-grow text-sm dark:bg-dark"
                          disabled={canSubscribe() || underdog_deposit}
                          onChange={(e) => setUnderdogUsername(e.target.value)}
                          type="text"
                          value={formUnderdogUsername}
                        />
                        <div className="flex flex-col gap-4 w-full">
                          <button
                            className="bg-custom-orange text-white rounded-custom p-2 text-sm hover:bg-orange-600 disabled:bg-light-text2 disabled:text-gray-200 w-full"
                            disabled={canSubscribe() || underdog_deposit}
                            onClick={applyUnderdogCredit}
                          >
                        Apply Credit
                          </button>
                          <div className="text-sm text-light-text2 dark:text-dark-text2">
                            {underdog_deposit
                              ? '* Your credit will be applied on initial deposit with UnderDog'
                              : canSubscribe()
                                ? '* $10 Underdog credit available for anyone with an active subscription'
                                : '* Enter Underdog username for credit on Underdogs site'}
                          </div>                    
                        </div>
                      </div>
                    ) : (
                      <div>
                    Underdog Credit applied: {underdog_username} <br />
                      </div>
                    )}
                  </div>
                    */}
                </div>
              </div>
            </Tabs.Content>
            <Tabs.Content value="subscription">
              <div className="flex flex-col items-center pt-10 text-lg text-light-text dark:text-dark-text md:mx-[20%]">
                <div className="flex w-full flex-col gap-4">
                  <div className="border-y-[1px] border-light2 py-4 dark:border-dark-border">
                    <div className="flex flex-col gap-2">
                      <div className="status">
                        Status:{' '}
                        <span className="italic text-zinc-500">
                          {is_subscribed ? 'Active' : 'Inactive'}
                          {is_trialing ? ' (Trial)' : ''}
                        </span>
                      </div>
                      <div>Active Promo: {applied_promo_code}</div>
                      {is_subscribed && (
                        <React.Fragment>
                          <div>Active Sub: {product_name}</div>
                          {canceling && (
                            <div>
                              Ends on: {formatTimestamp(subscription_end)}
                            </div>
                          )}
                          {!canceling && (
                            <div>
                              Renews on: {formatTimestamp(subscription_end)}
                            </div>
                          )}
                          <button
                            className="w-[175px] rounded-custom bg-accent p-2 text-sm text-dark-text transition-colors hover:bg-orange-600"
                            onClick={handleStripe}
                          >
                            Manage Payments
                          </button>
                          {is_subscribed && (
                            <button
                              className="w-[175px] rounded-custom bg-accent p-2 text-sm text-dark-text transition-colors hover:bg-orange-600"
                              onClick={handleSubscribe}
                            >
                              Upgrade Subscription
                            </button>
                          )}
                        </React.Fragment>
                      )}
                      {!is_subscribed && (
                        <button
                          className="w-[175px] rounded-custom bg-accent p-2 text-sm text-dark-text transition-colors hover:bg-orange-600"
                          onClick={handleSubscribe}
                        >
                          Subscribe
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Tabs.Content>
          </div>
        </Tabs.Root>
      </div>
      <ToastContainer
        autoClose={5000}
        closeOnClick
        draggable
        hideProgressBar={false}
        newestOnTop={false}
        pauseOnFocusLoss
        pauseOnHover
        position="top-right"
        rtl={false}
      />
    </div>
  )
}

export default Account
