import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import banner from '../../images/flockbanner.jpg'
import '../../styles/landing.css'

function PaymentCancel() {
  const navigate = useNavigate()

  useEffect(() => {
    // Redirect to / after 4 seconds
    const timer = setTimeout(() => {
      navigate('/')
    }, 4000)

    return () => clearTimeout(timer)
  }, [navigate])

  return (
    <div className="landing-container">
      <img alt="Flock Banner" className="landing-banner" src={banner} />
      <div className="landing-content-container">
        <div>
          <h1>Purchase Canceled</h1>
          <p style={{ color: 'white', textAlign: 'center' }}>
            You will be redirect to the homepage in 4 seconds..
          </p>
        </div>
      </div>
    </div>
  )
}
export default PaymentCancel
