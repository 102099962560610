import React from 'react'
import PositionSelect from './PositionSelect'

function RosterSelect({
  onRbChange,
  onWrChange,
  onTeChange,
  onFlexChange,
  onBenchChange,
  settings,
  superflex,
  league,
}) {
  const maxOptionsForPosition = 6
  const maxOptionsForBench = 10

  const defaultSettings = {
    qb: '1',
    rb: '2',
    wr: '2',
    te: '1',
    flex: '1',
    bench: '6',
    league: 'REDRAFT',
  }

  const currentSettings = settings || defaultSettings

  const qbValue = league === 'redraft' ? '1' : superflex ? '2' : '1'

  return (
    <div className="flex flex-col gap-2">
      <div className="text-md font-medium">Roster</div>
      <div className="flex flex-wrap gap-2 rounded-xl p-4 text-left">
        <Position
          defaultValue={qbValue}
          disabled
          maxOptions={maxOptionsForPosition}
          onPositionChange={() => null}
          title="QB"
        />
        <Position
          defaultValue={currentSettings.rb}
          maxOptions={maxOptionsForPosition}
          onPositionChange={onRbChange}
          title="RB"
        />
        <Position
          defaultValue={currentSettings.wr}
          maxOptions={maxOptionsForPosition}
          onPositionChange={onWrChange}
          title="WR"
        />
        <Position
          defaultValue={currentSettings.te}
          maxOptions={maxOptionsForPosition}
          onPositionChange={onTeChange}
          title="TE"
        />
        <Position
          defaultValue={currentSettings.flex}
          maxOptions={maxOptionsForPosition}
          onPositionChange={onFlexChange}
          title="FLEX"
        />
        <Position
          defaultValue={currentSettings.bench}
          league={league}
          maxOptions={maxOptionsForBench}
          onPositionChange={onBenchChange}
          title="BENCH"
        />
      </div>
    </div>
  )
}

function Position({
  title,
  onPositionChange,
  defaultValue,
  maxOptions,
  disabled,
  league,
}) {
  return (
    <div className="relative z-50 flex flex-col gap-2">
      <div className="text-sm">{title}</div>

      <PositionSelect
        defaultValue={defaultValue}
        disabled={disabled}
        label={title}
        league={league}
        maxOptions={maxOptions}
        onPositionChange={onPositionChange}
      />
    </div>
  )
}

export default RosterSelect
