const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
]

export const getDateTimeString = (dateString: string) => {
  const createdDate = new Date(dateString.replace(' ', 'T') + 'Z')

  const twoDigitMonth = createdDate.getMonth()
  let twoDigitDate = createdDate.getDate() + ''

  if (twoDigitDate.length === 1) {
    twoDigitDate = '0' + twoDigitDate
  }

  const currentDate =
    months[twoDigitMonth] +
    ' ' +
    twoDigitDate +
    ', ' +
    createdDate.getFullYear()
  return currentDate
}
