import React, { useEffect, useState, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { FaUpload } from 'react-icons/fa'
import { ToastContainer, toast } from 'react-toastify'
import { Triangle } from 'react-loader-spinner'

import {
  createPlayerCardData,
  deletePlayerCard,
  getAllPlayers,
  getPlayerCardsByCreator,
  getPresignedUrlForPlayerCard,
  getRankings,
  savePlayCardToS3,
} from '../../../store/api'

import SearchBar from '../../_ui/SearchBar'
import Select from '../../_ui/Select'
import { FaPlus, FaXmark } from 'react-icons/fa6'
import 'react-toastify/dist/ReactToastify.css'
import '../../../styles/PlayerPdf.css'

const subscriptionOptions = ['Free', 'Subscription', 'Premium']

const CreatePlayerCardImages = () => {
  const fileInputRef = useRef(null)

  const [creatorPlayers, setCreatorPlayers] = useState([])
  const [currentPlayer, setCurrentPlayer] = useState(null)
  const [isAddingPlayer, setIsAddingPlayer] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [imageFile, setImageFile] = useState(null)
  const [rankedPlayers, setRankedPlayers] = useState([])
  const [playerToOverwrite, setPlayerToOverwrite] = useState(null)
  const [removingPlayerId, setRemovingPlayerId] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [subLevel, setSubLevel] = useState(0)

  useEffect(() => {
    fetchCreatorPlayers()
    fetchPlayerData()
  }, [])

  const fetchPlayerData = async () => {
    try {
      const format = 'SUPERFLEX'

      const [rankingsRes, getAllPlayersRes] = await Promise.all([
        getRankings(format),
        getAllPlayers(),
      ])

      // Delete this after player refactor
      const prospectKeys = Object.keys(getAllPlayersRes).filter(
        (key) => key >= 99950
      )
      const prospects = prospectKeys.map((key) => getAllPlayersRes[key])

      const formattedProspect = prospects.map((player) => {
        return {
          ...player,
          Age: player.age,
          Positions: [player.position],
          player_name: player.player_name,
        }
      })
      // ^^^^

      setRankedPlayers([...rankingsRes.data, ...formattedProspect])
    } catch (error) {
      console.error('Error fetching rankings:', error)
    }
  }

  const fetchCreatorPlayers = async () => {
    try {
      const creatorPlayerCards = await getPlayerCardsByCreator()
      setCreatorPlayers(creatorPlayerCards)
    } catch (error) {
      console.error('Error fetching creator players:', error)
    }
  }

  const handleAddPlayer = async () => {
    if (!currentPlayer) {
      toast.error('No player selected!')
      return
    }
    if (!imageFile) {
      toast.error('No image file attached!')
      return
    }
    const existingPlayer = creatorPlayers.find(
      (p) => p.player_id === currentPlayer.player_id
    )
    if (existingPlayer) {
      setPlayerToOverwrite(currentPlayer)
      setIsModalOpen(true)
    } else {
      await addPlayer()
    }
  }

  const addPlayer = async () => {
    const imageId = uuidv4()
    const fileExtension = imageFile.type.split('/')[1]
    setIsAddingPlayer(true)
    try {
      const { presigned_url } = await getPresignedUrlForPlayerCard(
        imageId,
        imageFile.type
      )

      await savePlayCardToS3(presigned_url, imageFile)
      setIsAddingPlayer(false)
      await saveImageMetadataToDatabase(
        currentPlayer.player_id,
        imageId,
        fileExtension,
        subLevel
      )

      const removeDupes = creatorPlayers.filter(
        ({ player_id }) => currentPlayer.player_id !== player_id
      )

      const playerWithImage = { ...currentPlayer, image_id: `${imageId}` }
      setCreatorPlayers([...removeDupes, playerWithImage])

      setCurrentPlayer(null)
      setImageFile(null)
    } catch (error) {
      console.error('Error handling image:', error)
      toast.error('Failed to handle image')
    }
  }

  const handleRemovePlayer = async (playerId) => {
    setRemovingPlayerId(playerId)
    try {
      const { image_id } = creatorPlayers.find(
        (playerCard) => playerCard.player_id === playerId
      )
      await deletePlayerCard(image_id)
      setCreatorPlayers(
        creatorPlayers.filter((records) => records.image_id !== image_id)
      )
    } catch (err) {
      console.log('ERROR handleRemovePlayer : ', err)
    }

    setRemovingPlayerId(null)
  }

  const handleImageDrop = (event) => {
    event.preventDefault()
    const file = event.nativeEvent.dataTransfer.files[0]
    if (file && file.type.startsWith('image/')) {
      setImageFile(file)
    }
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0]
    if (file && file.type.startsWith('image/')) {
      setImageFile(file)
    }
  }

  const saveImageMetadataToDatabase = async (
    playerId,
    imageId,
    imageExtension,
    subLevel
  ) => {
    const payload = {
      imageExtension,
      imageId,
      playerId,
      subLevel,
    }
    try {
      await createPlayerCardData(payload)
    } catch (error) {
      console.error('Error saving metadata:', error)
      toast.error('Failed to save metadata')
    }
  }

  const handleImageIconClick = () => {
    fileInputRef.current.click()
  }

  const mapSubscriptionLabelToValue = (label) => {
    switch (label) {
      case 'Free':
        return 0
      case 'Subscription':
        return 1
      case 'Premium':
        return 2
      default:
        return 0
    }
  }

  const handleModalConfirm = async () => {
    setIsModalOpen(false)
    await addPlayer()
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    setPlayerToOverwrite(null)
  }

  const handlePlayerSelect = (player) => {
    setCurrentPlayer(player)
    setSearchTerm(player.player_name)
  }

  return (
    <div className="flex flex-col gap-4 bg-light px-10 py-16 font-dmsans text-light-text dark:bg-dark dark:text-dark-text md:px-[20%] lg:h-[92vh]">
      <ToastContainer />
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onConfirm={handleModalConfirm}
        player_name={playerToOverwrite ? playerToOverwrite.player_name : ''}
      />
      {/*testing*/}
      <h2 className="text-lg font-bold lg:text-3xl">Player Image</h2>
      <div className="flex flex-col justify-between gap-10 lg:flex-row">
        <div className="flex flex-col gap-4 lg:h-full lg:w-6/12">
          <div className="pt-9"></div>
          <Select
            onSelect={(selectedOption) => {
              const value = mapSubscriptionLabelToValue(selectedOption.label)
              setSubLevel(value)
            }}
            options={subscriptionOptions}
            placeholder="Select Subscription Level"
          />
          <SearchBar
            onAddPlayer={handlePlayerSelect}
            ranks={rankedPlayers}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
          <div
            className="//bg-dot-pattern-light //dark:bg-dot-pattern-dark flex h-[250px] cursor-pointer items-center justify-center rounded-xl border-[2px] border-light2 text-light-text2 dark:border-dark-border dark:text-dark-text2 md:w-[100%]"
            onClick={handleImageIconClick}
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleImageDrop}
          >
            {imageFile ? (
              <div>File Attached: {imageFile.name}</div>
            ) : (
              <div>
                <div className="flex cursor-pointer items-center gap-2">
                  Drop image here or{' '}
                  <FaUpload className="fill-light-text dark:fill-light" />
                </div>
                <input
                  accept="image/"
                  onChange={handleImageUpload}
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  type="file"
                />
              </div>
            )}
          </div>
          <div className="w-40">
            <button
              className="inline-flex w-full items-center justify-between rounded-lg bg-accent px-4 py-3 font-medium text-dark-text"
              disabled={isAddingPlayer}
              onClick={handleAddPlayer}
            >
              {isAddingPlayer ? (
                <Triangle
                  ariaLabel="loading"
                  color="#FFF"
                  height={25}
                  width={25}
                />
              ) : (
                <FaPlus />
              )}
              Add Player
            </button>
          </div>
        </div>
        <div className="lg:w-6/12">
          <h2 className="pb-6 text-xl font-medium">Current Players</h2>
          <div className="h-[88%] overflow-scroll rounded-lg border-[1px] border-light2 px-4 py-2 dark:border-dark-border lg:w-72 xl:w-96">
            {creatorPlayers.map((creatorPlayer) => {
              const player = rankedPlayers.find(
                (p) => p.player_id === creatorPlayer.player_id
              )
              if (player) {
                return (
                  <div
                    className="flex justify-between px-2 pt-2"
                    key={creatorPlayer.image_id}
                  >
                    <span className="text-lg font-medium">
                      {player.player_name}
                    </span>
                    {removingPlayerId === player.player_id ? (
                      <Triangle
                        ariaLabel="loading"
                        color="#000"
                        height={20}
                        width={20}
                      />
                    ) : (
                      <FaXmark
                        className="cursor-pointer fill-dark transition-all duration-200 hover:fill-accent dark:fill-light hover:dark:fill-accent"
                        onClick={() => handleRemovePlayer(player.player_id)}
                      />
                    )}
                  </div>
                )
              }
              return null
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const ConfirmationModal = ({ isOpen, onClose, onConfirm, player_name }) => {
  if (!isOpen) {
    return null
  }

  return (
    <div className="fixed inset-0 z-[100] flex items-center justify-center backdrop-blur-sm">
      <div className="flex flex-col items-center justify-center gap-4 rounded-lg border-[1px] border-light2 bg-light p-10 text-light-text dark:border-dark-border dark:bg-dark dark:text-dark-text md:max-w-[35%]">
        <div className="flex justify-center">
          You are about to overwrite {player_name}, are you sure you want to
          continue?
        </div>
        <div className="flex gap-4">
          <button
            className="rounded-lg bg-zinc-300 px-4 py-2 dark:bg-zinc-500"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="rounded-lg bg-accent px-4 py-2 text-dark-text"
            onClick={onConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  )
}

export default CreatePlayerCardImages
