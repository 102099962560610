import React from 'react'
import { IoLockClosed } from 'react-icons/io5'
import { RiArrowRightDoubleFill } from 'react-icons/ri'

const StoreItem = ({ name, description, image, disabled, onClick }) => {
  return (
    <div className="col-span-1 flex flex-col gap-8 rounded-lg border-[1px] border-zinc-300 bg-zinc-200 p-6 dark:border-dark-border dark:bg-dark">
      <h3 className="text-2xl font-semibold">{name}</h3>
      {disabled ? (
        <div className="relative mb-4 flex h-36 w-full items-center justify-center rounded-lg">
          <div className="absolute inset-0 bg-dark bg-grid-pattern bg-20"></div>
          <span className="relative z-20 italic text-accent">Coming Soon</span>
          <div className="absolute inset-0 bg-fade-out"></div>
        </div>
      ) : (
        <img
          alt={name}
          className="h-40 w-full rounded-lg object-cover"
          src={image}
        />
      )}
      <div>{description}</div>
      <button
        className={`mt-auto flex items-center justify-center rounded-lg px-4 py-2 ${disabled ? 'cursor-not-allowed bg-zinc-400 text-dark-text dark:bg-zinc-500' : 'bg-accent text-dark-text'}`}
        disabled={disabled}
        onClick={onClick}
      >
        {disabled ? (
          <>
            Coming Soon
            <IoLockClosed className="ml-2" />
          </>
        ) : (
          <>
            Continue
            <RiArrowRightDoubleFill className="text-dark-text" size={22} />
          </>
        )}
      </button>
    </div>
  )
}

export default StoreItem
