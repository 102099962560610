import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import TradeHistory from './TradeHistory'
import SearchBar from '../_ui/SearchBar'
import { getRankings, getAllPlayers } from '../../store/api'
import { motion, AnimatePresence } from 'framer-motion'

import PlayerAvatar from '../../images/player_avatar.svg'
import SettingsModal from './SettingsModal'

import { LiaCogSolid } from 'react-icons/lia'
import { FaRegTrashCan } from 'react-icons/fa6'

const numStarters = 8

const FindTrades = () => {
  const location = useLocation()

  const [searchTerm1, setSearchTerm1] = useState('')
  const [searchTerm2, setSearchTerm2] = useState('')
  const [ranks, setRanks] = useState([])
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [tradeHistoryPayload, setTradeHistoryPayload] = useState({})
  const [isDynasty, setIsDynasty] = useState(true)
  const [selectedTeamCounts, setSelectedTeamCounts] = useState([8, 10, 12, 14])
  const [selectedQbs, setSelectedQbs] = useState([1, 2])
  const [selectedPprTypes, setSelectedPprTypes] = useState([0, 0.5, 1])
  const [team1SelectedPlayer, setTeam1SelectedPlayer] = useState(null)
  const [team2SelectedPlayer, setTeam2SelectedPlayer] = useState(null)

  const playerFromModalId = location.state?.playerId
  useEffect(() => {
    fetchPlayerData()
  }, [])

  useEffect(() => {
    if (ranks.length > 0 && playerFromModalId) {
      const playerFromModal = ranks.find(
        (player) => player.player_id === playerFromModalId
      )
      if (playerFromModal) {
        setTeam1SelectedPlayer(playerFromModal)
      }
    }
  }, [ranks, playerFromModalId])

  useEffect(() => {
    const newPayload = {
      isDynasty,
      numQbs: selectedQbs,
      ppr: selectedPprTypes,
      numStarters,
      side1: team1SelectedPlayer ? team1SelectedPlayer.fantasy_calc_id : null,
      side2: team2SelectedPlayer ? team2SelectedPlayer.fantasy_calc_id : null,
      numTeams: selectedTeamCounts,
    }

    // Only update the payload if it's different from the current one
    if (JSON.stringify(newPayload) !== JSON.stringify(tradeHistoryPayload)) {
      setTradeHistoryPayload(newPayload)
    }
  }, [
    selectedQbs,
    selectedPprTypes,
    numStarters,
    isDynasty,
    selectedTeamCounts,
  ])

  const fetchPlayerData = async () => {
    try {
      const format = 'SUPERFLEX'
      const [rankedPlayersResponse, allPlayersResponse] = await Promise.all([
        getRankings(format),
        getAllPlayers(),
      ])

      const newCombinedRanks = rankedPlayersResponse.data.map((player) => {
        const allPlayerDetails = allPlayersResponse[player.player_id] || {}
        return {
          ...player,
          ...allPlayerDetails,
        }
      })
      setRanks(newCombinedRanks)
    } catch (error) {
      console.error('Error fetching rankings:', error)
    }
  }

  const addPlayerToTeam = (player, teamNumber) => {
    const updateTeam =
      teamNumber === 1 ? setTeam1SelectedPlayer : setTeam2SelectedPlayer
    updateTeam(player)
  }

  const removePlayerFromTeam = (teamNumber) => {
    const updateTeam =
      teamNumber === 1 ? setTeam1SelectedPlayer : setTeam2SelectedPlayer
    updateTeam(null)
  }

  const toggleSettingsModal = () => {
    setIsSettingsModalOpen(!isSettingsModalOpen)
  }

  const selectLeagueType = (leagueType) => {
    setIsDynasty(leagueType === 'Redraft')
  }

  const handleTeamSelectionChange = (newSelectedTeams) => {
    setSelectedTeamCounts(newSelectedTeams)
  }

  const PlayerCard = ({ player, onRemove }) => {
    if (!player || !player.position) {
      return null
    }
    const positionColors = {
      RB: 'text-accent-purple bg-accent-bg-purple',
      QB: 'text-accent-green bg-accent-bg-green',
      WR: 'text-accent-red bg-accent-bg-red',
      TE: 'text-accent-cyan bg-accent-bg-cyan',
    }
    const image = player.picture
      ? `https://flock-player-images-2.s3.us-east-2.amazonaws.com/${player.picture}`
      : PlayerAvatar
    const playerPosition = player.position
    const positionStyle = positionColors[playerPosition] || ''

    return (
      <AnimatePresence>
        <motion.div
          animate="visible"
          className="my-2 flex w-full items-center justify-between gap-4 rounded-[14px] bg-light2 px-2 py-2 dark:bg-zinc-100 dark:bg-opacity-10"
          exit="exit"
          initial="hidden"
          transition={{ duration: 0.5 }}
        >
          <div className="flex items-center gap-2">
            <img
              alt={player.player_name}
              className="w-[64px] rounded-xl"
              src={image}
            />
            <div className="text-md flex min-w-0 flex-col px-2 text-light-text dark:text-dark-text">
              <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                {player.player_name}
              </div>
              <div className="flex gap-2">
                <span
                  className={`flex w-10 rounded-full px-1 text-sm ${positionStyle} justify-center gap-1`}
                >
                  {player.position}
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <button
              className="border-light-border ml-auto rounded-md border-[1px] p-1 dark:border-dark-border"
              onClick={onRemove}
            >
              <FaRegTrashCan
                className="fill-light-text2 transition-colors duration-300 hover:fill-custom-orange dark:fill-dark-text2"
                size={18}
              />
            </button>
          </div>
        </motion.div>
      </AnimatePresence>
    )
  }

  return (
    <div className="flex min-h-[91vh] flex-col items-center justify-between bg-light px-4 font-dmsans dark:bg-dark">
      <div className="flex w-full flex-col pt-10 md:w-[65%]">
        <div className="flex justify-between">
          <h3 className="text-lg font-bold text-light-text dark:text-dark-text lg:text-3xl">
            Trade Finder
          </h3>
          <button
            className="flex items-center gap-2 rounded-custom border-[1px] border-light2 p-3 px-5 text-sm font-bold text-light-text dark:border-dark-border dark:text-dark-text"
            onClick={toggleSettingsModal}
          >
            <LiaCogSolid
              className="fill-light-text dark:fill-dark-text"
              size={22}
            />
            Settings
          </button>
        </div>
        <div className="flex flex-col justify-evenly p-10 md:flex-row">
          <div className="flex flex-col items-center gap-4 md:w-[30%]">
            <h2 className="text-xl font-medium text-light-text dark:text-dark-text">
              Your Team
            </h2>
            {team1SelectedPlayer ? (
              <PlayerCard
                onRemove={() => removePlayerFromTeam(1)}
                player={team1SelectedPlayer}
              />
            ) : (
              <SearchBar
                onAddPlayer={(player) => addPlayerToTeam(player, 1)}
                ranks={ranks}
                searchTerm={searchTerm1}
                selectedPlayers={team1SelectedPlayer || []}
                setSearchTerm={setSearchTerm1}
              />
            )}
          </div>
          <div className="flex flex-col items-center gap-4 md:w-[30%]">
            <h2 className="text-xl font-medium text-light-text dark:text-dark-text">
              Their Team
            </h2>
            {team2SelectedPlayer ? (
              <PlayerCard
                onRemove={() => removePlayerFromTeam(2)}
                player={team2SelectedPlayer}
              />
            ) : (
              <SearchBar
                onAddPlayer={(player) => addPlayerToTeam(player, 2)}
                ranks={ranks}
                searchTerm={searchTerm2}
                selectedPlayers={team2SelectedPlayer || []}
                setSearchTerm={setSearchTerm2}
              />
            )}
          </div>
        </div>
        <div className="">
          <TradeHistory
            playersGetting={
              team1SelectedPlayer ? [team1SelectedPlayer.fantasy_calc_id] : []
            }
            playersGiving={
              team2SelectedPlayer ? [team2SelectedPlayer.fantasy_calc_id] : []
            }
            isDynasty={false}
            payload={tradeHistoryPayload}
            team1SelectedPlayer={team1SelectedPlayer}
            team2SelectedPlayer={team2SelectedPlayer}
          />
        </div>
      </div>
      <SettingsModal
        currentLeagueType={isDynasty ? 'Dynasty' : 'Redraft'}
        isOpen={isSettingsModalOpen}
        onClose={toggleSettingsModal}
        onTeamSelectionChange={handleTeamSelectionChange}
        selectLeagueType={selectLeagueType}
        selectedPprTypes={selectedPprTypes}
        selectedQbs={selectedQbs}
        selectedTeamCounts={selectedTeamCounts}
        setSelectedPprTypes={setSelectedPprTypes}
        setSelectedQbs={setSelectedQbs}
      />
      <h2 className="py-2 text-accent">
        <a
          href="https://www.fantasycalc.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          Powered by FantasyCalc.com
        </a>
      </h2>
    </div>
  )
}

export default FindTrades
