import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  getCreatorSubscriptionSummaries,
  getMonthlyIncomes,
  getTotalSubs,
  createVoucher,
} from '../../../store/api'
import UserSearchBar from '../../_ui/UserSearchBar'
import ConfirmationModal from '../../_ui/ConfimationModal'
import * as Tabs from '@radix-ui/react-tabs'
import loadingGif from '../../../images/FlockLoadingIcon.gif'
import { PromoToId } from '../../../static/PromoToId'
import { PromoGroupMap } from '../../../static/PromoGroupMap'
import { toast } from 'react-toastify'

const CreatorIncome = () => {
  const navigate = useNavigate()
  const { user_id } = useSelector((state) => state.user)

  const [subscriptionSummaries, setSubscriptionSummaries] = useState([])
  const [monthlyIncomes, setMonthlyIncomes] = useState([])
  const [singleIncomes, setSingleIncomes] = useState([])
  const [totalSubs, setTotalSubs] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [tab, setTab] = useState('general')
  const [isLoading, setIsLoading] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [userIdToConfirm, setUserIdToConfirm] = useState({
    id: null,
    promoCode: null,
    username: null,
  })
  const [incomeSettings, setIncomeSettings] = useState('subscription')
  const [sortColumn, setSortColumn] = useState(null)
  const [sortOrder, setSortOrder] = useState('asc')
  const [sortedSummaries, setSortedSummaries] = useState([])

  const filteredSummaries = searchTerm
    ? subscriptionSummaries.filter(
        (summary) =>
          summary.username &&
          summary.username.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : subscriptionSummaries

  const headerMap = {
    'Member Since': 'member_since',
    Username: 'username',
    'Current Status': 'status',
    'Current Sub Type': 'type',
    'Date Last Charged': 'latest_charge_date',
    'Amount Last Charged': 'latest_charge_amount',
    'Total Charged': 'total_charge_amount',
    'Promo Code': 'promo_code',
  }

  useEffect(() => {
    if (tab === 'general') {
      fetchGeneralData()
    } else if (tab === 'monthly') {
      fetchMonthlyData()
    }
  }, [incomeSettings, tab])

  useEffect(() => {
    setSortedSummaries(sortData())
  }, [filteredSummaries, sortColumn, sortOrder])

  const formatDate = (dateString) => {
    if (!dateString) {
      return '-'
    }
    const date = new Date(dateString)
    return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`
  }

  const formatAmount = (amount) => {
    let amountString = String(amount)
    if (amountString.length >= 3) {
      const len = amountString.length
      const formattedAmount = `${amountString.substring(0, len - 2)}.${amountString.substring(len - 2)}`
      return parseFloat(formattedAmount).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    } else if (amountString.length > 0) {
      return `0.${amountString.padStart(2, '0')}`
    }
    return amountString
  }

  const fetchGeneralData = async () => {
    setIsLoading(true)
    try {
      const [summaries] = await Promise.all([
        getCreatorSubscriptionSummaries(incomeSettings),
      ])

      setSubscriptionSummaries(summaries)
    } catch (error) {
      console.error('Failed to fetch data:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchMonthlyData = async () => {
    setIsLoading(true)
    try {
      const [monthlyIncomes, singleIncomes, totalSubs] = await Promise.all([
        getMonthlyIncomes('subscription'),
        getMonthlyIncomes('single'),
        getTotalSubs(),
      ])

      setMonthlyIncomes(monthlyIncomes.data)
      setSingleIncomes(singleIncomes.data)

      setTotalSubs(totalSubs)
    } catch (error) {
      console.error('Failed to fetch data:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const displayValue = (value) => {
    if (typeof value === 'number' && !isNaN(value)) {
      return `$${(value / 100).toFixed(2)}`
    }
    return value ? value : '-'
  }

  const handleSort = (label) => {
    const column = headerMap[label]
    const newSortOrder =
      sortColumn === column && sortOrder === 'asc' ? 'desc' : 'asc'
    setSortColumn(column)
    setSortOrder(newSortOrder)
  }

  const sortData = () => {
    if (!sortColumn) {
      return filteredSummaries
    }
    return [...filteredSummaries].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) {
        return sortOrder === 'asc' ? -1 : 1
      }
      if (a[sortColumn] > b[sortColumn]) {
        return sortOrder === 'asc' ? 1 : -1
      }
      return 0
    })
  }

  const headers = [
    { id: 'joinDate', label: 'Member Since' },
    { id: 'username', label: 'Username', widthClass: 'w-[50px]' },
    { id: 'status', label: 'Current Status' },
    { id: 'subType', label: 'Current Sub Type' },
    { id: 'dateOfLastCharge', label: 'Date Last Charged' },
    { id: 'amountOfLastCharge', label: 'Amount Last Charged' },
    { id: 'totalAmountCharged', label: 'Total Charged' },
    { id: 'promoCodeUsed', label: 'Promo Code' },
  ]

  const singleHeaders = [
    { id: 'username', label: 'Username' },
    { id: 'dateOfLastCharge', label: 'Date Last Charged' },
    { id: 'amountOfLastCharge', label: 'Amount Last Charged' },
    { id: 'totalAmountCharged', label: 'Total Charged' },
    { id: 'grantVoucher', label: 'Grant Voucher' },
  ]

  const renderHeaderCell = (header) => (
    <th
      className={`z-8 sticky top-0 bg-light py-3 text-left text-xs font-medium text-light-text2 dark:bg-dark dark:text-dark-text2 w-1/${headers.length} cursor-pointer`}
      key={header.id}
      onClick={() => handleSort(header.label)}
    >
      {header.label}{' '}
      {sortColumn === headerMap[header.label]
        ? sortOrder === 'asc'
          ? '▲'
          : '▼'
        : ''}
    </th>
  )

  const handleTabChange = (newValue) => {
    setTab(newValue)
  }

  const handleOpenModal = (userId, promoCode, username) => {
    setUserIdToConfirm({ id: userId, promoCode, username })
    setIsModalOpen(true)
  }

  const handleCreateVoucher = async () => {
    const { id } = userIdToConfirm
    const promoter_id = PromoGroupMap[user_id]
    if (!id) {
      console.error('No user ID provided for voucher creation')
      return
    }

    const payload = {
      userId: id,
      promoterId: promoter_id,
    }

    try {
      console.log('Creating voucher with payload:', payload)
      await createVoucher(payload)
      toast.success('Voucher created successfully')
    } catch (error) {
      toast.error('Error creating voucher')
      console.error('Error creating voucher:', error)
    }
    setIsModalOpen(false)
  }

  return (
    <div className="px-[5%] pt-10 font-dmsans text-light-text dark:text-dark-text md:px-[10%]">
      <Tabs.Root defaultValue="general" onValueChange={handleTabChange}>
        <h2 className="pb-10 text-lg font-bold lg:text-3xl">Creator Incomes</h2>
        <div className="flex w-full justify-center pb-4">
          <Tabs.List className="inline-flex flex-wrap justify-center gap-1 rounded-xl bg-light-bg p-1 dark:bg-dark-bg-select">
            <Tabs.Trigger
              className={`rounded-lg px-8 py-2 transition-all duration-200 hover:bg-accent-bg hover:text-accent hover:dark:text-accent ${tab === 'general' ? 'bg-accent-bg font-bold text-accent' : 'text-light-text2 dark:text-dark-text2'}`}
              value="general"
            >
              General
            </Tabs.Trigger>
            <Tabs.Trigger
              className={`rounded-lg px-8 py-2 transition-all duration-200 hover:bg-accent-bg hover:text-accent hover:dark:text-accent ${tab === 'monthly' ? 'bg-accent-bg font-bold text-accent' : 'text-light-text2 dark:text-dark-text2'}`}
              value="monthly"
            >
              Monthly
            </Tabs.Trigger>
          </Tabs.List>
        </div>
        <Tabs.Content value="general">
          <div className="flex justify-between px-16">
            <div>
              <UserSearchBar
                className=""
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                subscriptionSummaries={subscriptionSummaries}
              />
            </div>
            <div className="flex gap-4">
              <button
                className={`rounded-full border-[1px] border-light2 px-[14px] py-2 text-[13px] transition-all duration-200 hover:bg-accent hover:text-light dark:border-dark-border ${incomeSettings === 'single' ? 'bg-accent font-bold text-dark-text' : 'text-light-text dark:text-dark-text'}`}
                onClick={() => setIncomeSettings('single')}
              >
                Single
              </button>
              <button
                className={`rounded-full border-[1px] border-light2 px-[14px] py-2 text-[13px] transition-all duration-200 hover:bg-accent hover:text-light dark:border-dark-border ${incomeSettings === 'subscription' ? 'bg-accent font-bold text-dark-text' : 'text-light-text dark:text-dark-text'}`}
                onClick={() => setIncomeSettings('subscription')}
              >
                Subscription
              </button>
            </div>
          </div>
          {isLoading ? (
            <div className="flex h-[60vh] items-center justify-center">
              <img
                alt="Loading..."
                src={loadingGif}
                style={{ width: '250px', height: '250px' }}
              />
            </div>
          ) : (
            <div className="relative h-[75vh] overflow-auto md:mx-16">
              <table className="w-full table-auto">
                <thead>
                  <tr>
                    {incomeSettings === 'single'
                      ? singleHeaders.map(renderHeaderCell)
                      : headers.map(renderHeaderCell)}
                  </tr>
                </thead>
                <tbody>
                  {sortedSummaries.map((summary, index) => (
                    <tr
                      onClick={() =>
                        navigate(`/creator/income/user/${summary.user_id}`)
                      }
                      className={`${index % 2 === 0 ? 'bg-zinc-800' : ''} font-medium transition-all duration-150 hover:bg-accent-bg`}
                      key={summary.user_id}
                    >
                      {incomeSettings === 'single' ? (
                        <>
                          <td>{displayValue(summary.username)}</td>
                          <td>{formatDate(summary.latest_charge_date)}</td>
                          <td>{displayValue(summary.latest_charge_amount)}</td>
                          <td>{displayValue(summary.total_charge_amount)}</td>
                          <td>
                            <button
                              onClick={(event) => {
                                event.stopPropagation()
                                handleOpenModal(
                                  summary.user_id,
                                  summary.promo_code,
                                  summary.username
                                )
                              }}
                              className="rounded-lg border-[1px] border-light2 px-3 py-1 transition-all duration-200 hover:bg-accent dark:border-dark-border"
                            >
                              Grant Voucher
                            </button>
                          </td>
                        </>
                      ) : (
                        <>
                          <td>{formatDate(summary.member_since)}</td>
                          <td>{displayValue(summary.username)}</td>
                          <td className="italic">
                            {displayValue(summary.status)}
                          </td>
                          <td>{displayValue(summary.type)}</td>
                          <td>{formatDate(summary.latest_charge_date)}</td>
                          <td>{displayValue(summary.latest_charge_amount)}</td>
                          <td>{displayValue(summary.total_charge_amount)}</td>
                          <td>{displayValue(summary.promo_code)}</td>
                          <td>
                            <button
                              onClick={(event) => {
                                event.stopPropagation()
                                handleOpenModal(
                                  summary.user_id,
                                  summary.promo_code,
                                  summary.username
                                )
                              }}
                              className="rounded-lg border-[1px] border-light2 px-3 py-1 transition-all duration-200 hover:bg-accent dark:border-dark-border"
                            >
                              Grant Voucher
                            </button>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Tabs.Content>
        <Tabs.Content value="monthly">
          {isLoading ? (
            <div className="flex h-[60vh] items-center justify-center">
              <img
                alt="Loading..."
                src={loadingGif}
                style={{ width: '250px', height: '250px' }}
              />
            </div>
          ) : (
            <div className="flex w-full justify-center gap-[5%]">
              <div className="mt-4">
                <h3 className="mb-3 text-xl font-semibold">Monthly Incomes</h3>
                <div className="max-h-[60vh] overflow-auto">
                  <table className="text-light-text dark:text-dark-text">
                    <thead className="border-b-[1px] border-light2 dark:border-dark-border">
                      <tr>
                        <th
                          className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                          scope="col"
                        >
                          Payment Date
                        </th>
                        <th
                          className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                          scope="col"
                        >
                          Total Amount
                        </th>
                        <th
                          className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                          scope="col"
                        >
                          Total Processor Fees
                        </th>
                        <th
                          className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                          scope="col"
                        >
                          Total Platform Fees
                        </th>
                        <th
                          className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                          scope="col"
                        >
                          Net
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {monthlyIncomes.map((income, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap px-6 py-2">
                            {income.payment_month_year}
                          </td>
                          <td className="whitespace-nowrap px-6 py-2">
                            $
                            {formatAmount(income.total_amount).toLocaleString()}
                          </td>
                          <td className="whitespace-nowrap px-6 py-2">
                            $
                            {formatAmount(
                              income.total_processor_fee
                            ).toLocaleString()}
                          </td>
                          <td className="whitespace-nowrap px-6 py-2">
                            $
                            {formatAmount(
                              income.total_platform_fee
                            ).toLocaleString()}
                          </td>
                          <td className="whitespace-nowrap px-6 py-2">
                            ${formatAmount(income.total_net).toLocaleString()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="mt-4 h-[60vh] border-l-[1px] border-light2 dark:border-dark-border"></div>
              <div className="mt-4">
                <h3 className="mb-3 text-xl font-semibold">
                  Single Item Incomes
                </h3>
                <div className="max-h-[60vh] overflow-auto">
                  <table className="text-light-text dark:text-dark-text">
                    <thead className="border-b-[1px] border-light2 dark:border-dark-border">
                      <tr>
                        <th
                          className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                          scope="col"
                        >
                          Payment Date
                        </th>
                        <th
                          className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                          scope="col"
                        >
                          Total Amount
                        </th>
                        <th
                          className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                          scope="col"
                        >
                          Total Processor Fees
                        </th>
                        <th
                          className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                          scope="col"
                        >
                          Total Platform Fees
                        </th>
                        <th
                          className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                          scope="col"
                        >
                          Net
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {singleIncomes.map((income, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap px-6 py-2">
                            {income.payment_month_year}
                          </td>
                          <td className="whitespace-nowrap px-6 py-2">
                            $
                            {formatAmount(income.total_amount).toLocaleString()}
                          </td>
                          <td className="whitespace-nowrap px-6 py-2">
                            $
                            {formatAmount(
                              income.total_processor_fee
                            ).toLocaleString()}
                          </td>
                          <td className="whitespace-nowrap px-6 py-2">
                            $
                            {formatAmount(
                              income.total_platform_fee
                            ).toLocaleString()}
                          </td>
                          <td className="whitespace-nowrap px-6 py-2">
                            ${formatAmount(income.total_net).toLocaleString()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="mt-4 h-[60vh] border-l-[1px] border-light2 dark:border-dark-border"></div>
              <div className="mt-4">
                <h3 className="mb-3 text-xl font-semibold">Total Subs</h3>
                <div className="mt-16 text-lg">
                  {totalSubs.map((totalSub, index) => (
                    <div className="flex justify-between gap-4" key={index}>
                      <p className="text-light-text dark:text-dark-text">
                        {totalSub.type}:
                      </p>
                      <p className="text-accent">
                        {totalSub.total_subscriptions}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </Tabs.Content>
      </Tabs.Root>
      <ConfirmationModal
        onConfirm={() =>
          handleCreateVoucher(userIdToConfirm.Id, userIdToConfirm.promoCode)
        }
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        text={`Are you sure you want to create a voucher for user ${userIdToConfirm.username}?`}
      />
    </div>
  )
}

export default CreatorIncome
