import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Triangle } from 'react-loader-spinner'
import { LiaCogSolid } from 'react-icons/lia'
import {
  FaArrowRotateLeft,
  FaArrowsRotate,
  FaCrown,
  FaRegHandshake,
} from 'react-icons/fa6'
// import { FaSync } from 'react-icons/fa'
import { IoSync } from 'react-icons/io5'
import 'react-toastify/dist/ReactToastify.css'

import {
  calculateTrade,
  getCommunityRankings,
  getCreators as getCreatorsAction,
  getRankings,
  getTradeSettings,
  getAllPlayers,
} from '../../store/api'

import TradeHistory from '../TradeFinder/TradeHistory'
import CalcModal from './CalcModal'
import TeamTradeCard from './TeamTradeCard'
import ProgressBar from './ProgressBar'
import SignInModal from '../_ui/SignInModal'
import LimitModal from './LimitModal'
import CreatorSelect from './CreatorSelect'
import SyncPopover from '../_ui/SyncPopover'
import PlayerInfoModal from '../Rankings/PlayerInfoModal'
// import Select from '../components/_ui/Select'

const defaultCreatorList = [
  {
    user_id: 'COMMUNITY',
    last_updated: null,
    username: 'Community',
  },
]

const unSubbedCreatorList = [
  {
    user_id: 'Avery',
    last_updated: null,
    username: 'Avery Huffman',
  },
  {
    user_id: 'Mason',
    last_updated: null,
    username: 'Mason Dodd',
  },
  {
    user_id: 'Danny',
    last_updated: null,
    username: 'Danny Mckinnon',
  },
  {
    user_id: 'Zach',
    last_updated: null,
    username: 'Zach Savage',
  },
  {
    user_id: 'Nathan',
    last_updated: null,
    username: 'Nathan Schmidt',
  },
  {
    user_id: 'Corey',
    last_updated: null,
    username: 'Corey Buschlen',
  },
  {
    user_id: 'Badake',
    last_updated: null,
    username: 'Daniel Badake',
  },
]

const defaultTradeResult = { team1Value: 0, team2Value: 0 }

const REDRAFT = 'REDRAFT'
const SUPERFLEX = 'SUPERFLEX'
const ONEQB = 'ONEQB'
const EXPERT = 'EXPERT'
const COMMUNITY = 'COMMUNITY'

const TradeCalc = () => {
  const { is_creator, loggedIn, is_subscribed, is_trialing } = useSelector(
    (state) => state.user
  )

  let interval = undefined

  const [combinedRanks, setCombinedRanks] = useState([])
  const [creatorList, setCreatorList] = useState([])
  // const [ usersLeagueRoster, setUsersLeagueRoster ] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(true)
  // const [ leagueList, setLeagueList ] = useState([])
  const [resetTrigger, setResetTrigger] = useState(false)

  const [settings, setSettings] = useState(null)
  const [showLimitModal, setShowLimitModal] = useState(false)
  const [showSyncPopover, setShowSyncPopover] = useState(false)
  const [tradeResult, setTradeResult] = useState(defaultTradeResult)
  const [team1Players, setTeam1Players] = useState([])
  const [team2Players, setTeam2Players] = useState([])
  const [selectedFullPlayer, setSelectedFullPlayer] = useState(null)
  const [tradeHistoryPayload, setTradeHistoryPayload] = useState({
    isDynasty: false,
    numQbs: 1,
    ppr: 1,
    numStarters: [],
    side1: null,
    side2: null,
    numTeams: 12,
  })
  const [outgoingPlayersGetting, setOutgoingPlayersGetting] = useState([])
  const [outgoingPlayersGiving, setOutgoingPlayersGiving] = useState([])
  const [outgoingPayload, setOutgoingPayload] = useState({
    isDynasty: false,
    numQbs: 1,
    ppr: 1,
    numStarters: [],
    side1: null,
    side2: null,
    numTeams: 12,
  })

  const defaultUserId =
    is_creator || is_trialing || is_subscribed ? EXPERT : COMMUNITY

  const [selectedExpert, setSelectedExpert] = useState(defaultUserId)

  // const [ teamDetails, setTeamDetails ] = useState([])
  // const [ userLeagueProfiles, setUserLeagueProfiles ] = useState([])

  //----------------------------------------------------------------Use Effects-------------------------------------------------------------------------------------

  useEffect(() => {
    getSettings()
    // getLeagueProfile()
  }, [])

  useEffect(() => {
    if (settings) {
      fetchPlayerData()
      getCreators()
    }
  }, [settings?.format, settings?.enable_superflex])

  useEffect(() => {
    if (combinedRanks.length > 0) {
      setTeam1Players((prevPlayers) => updateTeamPlayers(prevPlayers))
      setTeam2Players((prevPlayers) => updateTeamPlayers(prevPlayers))
    }
  }, [combinedRanks])

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }

    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isModalOpen])

  useEffect(() => {
    const openModal = !settings ? true : !loggedIn ? true : false
    setIsModalOpen(openModal)
  }, [loggedIn])

  useEffect(() => {
    const newPayload = {
      isDynasty: settings?.format === 'DYNASTY',
      numQbs: settings?.qb || 1,
      ppr: [0, 0.5, 1],
      numStarters: [8, 10, 12, 14],
      side1: team1Players[0]?.fantasy_calc_id,
      side2: team2Players[0]?.fantasy_calc_id,
      numTeams: [8, 10, 12, 14, 16],
    }

    if (JSON.stringify(newPayload) !== JSON.stringify(tradeHistoryPayload)) {
      setTradeHistoryPayload(newPayload)
    }
  }, [settings?.format, team1Players, team2Players])

  //----------------------------------------------------------------Data Fetching-------------------------------------------------------------------------------------

  // Get all the creators for the creator dropdown
  const getCreators = async () => {
    const { creator_list } = await getCreatorsAction()
    const masonIndex = creator_list.findIndex(
      (c) => c.username === 'Mason Dodd'
    )

    if (masonIndex > -1) {
      const [masonData] = creator_list.splice(masonIndex, 1)
      creator_list.unshift(masonData)
    }

    const expertIndex = creator_list.findIndex((c) => c.username === 'Expert')
    if (expertIndex > -1) {
      const [expertData] = creator_list.splice(expertIndex, 1)
      creator_list.unshift(expertData)
    }

    let mergedCreatorList = [
      ...defaultCreatorList,
      ...creator_list.filter(
        (c) => !defaultCreatorList.some((dc) => dc.user_id === c.user_id)
      ),
    ]

    if (!is_creator && !is_subscribed) {
      const unsubbedCreatorsToAdd = unSubbedCreatorList
        .filter(
          (uc) => !mergedCreatorList.some((mc) => mc.user_id === uc.user_id)
        )
        .map((uc) => ({ ...uc, disabled: true }))

      mergedCreatorList = [...mergedCreatorList, ...unsubbedCreatorsToAdd]
    }
    setCreatorList(mergedCreatorList)
  }

  // Gets the league settings for the user on initial load
  const getSettings = async (retry = false, retryCount = 0) => {
    try {
      const settingsData = await getTradeSettings()

      if (retry && retryCount < 4 && !settingsData) {
        // This is for new users - first time using trade calc
        // Default settings will save once they close modal
        // Need to wait to save then retry to get those settings
        interval = setTimeout(() => {
          getSettings(true, retryCount + 1)
        }, 2000)
      } else {
        clearInterval(interval)
      }

      if (settingsData) {
        setSettings(settingsData)
        setIsModalOpen(false)

        if (settingsData.last_expert_used) {
          setSelectedExpert(settingsData.last_expert_used)
        }
      } else if (!retry) {
        setIsModalOpen(true)
      }
    } catch (err) {
      console.error('ERROR TradeCalc.getSettings : ', err)
      setIsModalOpen(true)
    }
  }

  // Gets all the player data
  const fetchPlayerData = async () => {
    try {
      let rankingType
      let format
      if (settings.enable_superflex) {
        rankingType = 'superflex'
        format = SUPERFLEX
      } else if (settings.format === 'REDRAFT') {
        rankingType = 'year'
        format = REDRAFT
      } else if (settings.format === 'DYNASTY') {
        rankingType = 'qb'
        format = ONEQB
      } else {
        // DEFAULT
        rankingType = 'superflex'
        format = SUPERFLEX
      }
      const [getRankingsRes, getCommRanksRes, getAllPlayersRes] =
        await Promise.all([
          getRankings(format),
          getCommunityRankings({ type: rankingType }),
          getAllPlayers(),
        ])

      const newCombinedRanks = getRankingsRes.data.map((player) => {
        const commPlayerRank = getCommRanksRes.players[player.player_id]
        const allPlayerDetails = getAllPlayersRes[player.player_id] || {}
        return {
          ...player,
          ...(commPlayerRank
            ? { elo: commPlayerRank.elo_rating, commRank: commPlayerRank.rank }
            : {}),
          ...allPlayerDetails,
        }
      })

      // Compute Positional Ranks without changing original values
      const computeAndMergeRanks = (data, rankType) => {
        const sorted = [...data].sort((a, b) =>
          rankType === 'expert'
            ? parseFloat(a.average_rank) - parseFloat(b.average_rank)
            : a.commRank - b.commRank
        )
        // Assign whole number ranks based on sorted order
        const withWholeNumberRanks = assignWholeNumberRanks(sorted, rankType)

        // Then compute and merge position ranks
        return computePositionRanks(withWholeNumberRanks, rankType)
      }

      const expertRanks = computeAndMergeRanks(newCombinedRanks, 'expert')
      const communityRanks = computeAndMergeRanks(newCombinedRanks, 'community')

      // Merge computed ranks into original data
      newCombinedRanks.forEach((player) => {
        const expertRank = expertRanks.find(
          (e) => e.player_id === player.player_id
        )
        const communityRank = communityRanks.find(
          (c) => c.player_id === player.player_id
        )
        if (expertRank) {
          Object.keys(expertRank).forEach((key) => {
            if (key.includes('expert_')) {
              player[key] = expertRank[key]
            }
          })
        }
        if (communityRank) {
          Object.keys(communityRank).forEach((key) => {
            if (key.includes('community_')) {
              player[key] = communityRank[key]
            }
          })
        }
      })

      newCombinedRanks.sort((a, b) => {
        const aRank = parseFloat(a.average_rank)
        const bRank = parseFloat(b.average_rank)
        return aRank - bRank
      })
      setCombinedRanks(newCombinedRanks)
    } catch (error) {
      console.error('Error fetching rankings:', error)
    }
  }

  // Gets the user_league_profile for the user. For each league, gets the league name
  // const getLeagueProfile = async () => {
  //   try {
  //     const leagueRes = await axios.get('/leagueProfile')
  //     const profiles = leagueRes.data.league_profiles

  //     const getLeagues = profiles.map((league) => {
  //       return axios(`https://api.sleeper.app/v1/league/${league.league_id}`)
  //     })

  //     const listOfLeaguesRes = await Promise.all(getLeagues)
  //     const listOfLeagues = listOfLeaguesRes.map((leagueRes) => leagueRes.data)

  //     setUserLeagueProfiles(profiles)
  //     setLeagueList(listOfLeagues)
  //   } catch (err) {
  //     console.error('Error fetching league profile:', err)
  //   }
  // }

  // Get the leagues full details. Team names and rosters. Filter Ranks
  // const getLeagueInfo = async (selectedLeagueId) => {

  //   try {
  //     const [ teamsRes, rosterRes ] = await Promise.all([
  //       axios(`https://api.sleeper.app/v1/league/${selectedLeagueId}/users`),
  //       axios(`https://api.sleeper.app/v1/league/${selectedLeagueId}/rosters`)
  //     ])

  //     const teamDetails = teamsRes.data.map((team) => {
  //       const roster = rosterRes.data.find((r) => r.owner_id === team.user_id)
  //       return {
  //         leagueId: selectedLeagueId,
  //         sleeperId: team.user_id,
  //         teamName: team.display_name || team.username,
  //         roster: roster ? roster.players : [],
  //       }
  //     })

  //     const selectedTeam = teamDetails.find((team) => team.leagueId === selectedLeagueId)
  //     if (selectedTeam && selectedTeam.roster) {
  //       const usersRoster = getUserRosterFromAllPlayers(combinedRanks, selectedTeam.roster)
  //       setUsersLeagueRoster(usersRoster)
  //     }

  //     setTeamDetails(teamDetails)

  //   } catch (err) {
  //     console.log('ERROR FETCHING:', err)
  //   }
  // }

  //----------------------------------------------------------------Utility Functions-------------------------------------------------------------------------------------
  // const getUserRosterFromAllPlayers = (ranks, rosterIds) => {
  //   return ranks.filter((player) => rosterIds.includes(player.player_id.toString()))
  // }

  const computePositionRanks = (rankings, selectedExpertName) => {
    if (selectedExpertName.toLowerCase() === 'community') {
      selectedExpertName = 'community'
    }

    const positionRanks = { QB: 0, RB: 0, WR: 0, TE: 0 }
    let sortedRankings
    if (selectedExpertName === 'community') {
      sortedRankings = rankings.slice().sort((a, b) => a.commRank - b.commRank)
    } else {
      sortedRankings = rankings.slice().sort((a, b) => {
        const rankA = a.ranks[selectedExpertName]
        const rankB = b.ranks[selectedExpertName]
        return rankA - rankB
      })
    }
    return sortedRankings.map((player) => {
      const position = player.position
      if (position && Object.hasOwn(positionRanks, position)) {
        positionRanks[position]++

        const rankKey = `${selectedExpertName.replace(/\s+/g, '')}_${position}_Rank`
        player[rankKey] = positionRanks[position]
      }

      return player
    })
  }

  const assignWholeNumberRanks = (sortedData, rankType) => {
    if (rankType === 'expert') {
      sortedData.forEach((item, index) => {
        item.expert_rank = index + 1
      })
    }
    return sortedData
  }

  const updateTeamPlayers = (teamPlayers) => {
    return teamPlayers.map((player) => {
      const updatedPlayer = combinedRanks.find(
        (p) => p.player_id === player.player_id
      )
      return updatedPlayer ? { ...player, ...updatedPlayer } : player
    })
  }

  const clearPlayerSelections = () => {
    setTeam1Players([])
    setTeam2Players([])
    setTradeResult(defaultTradeResult)
    setResetTrigger((prev) => !prev)
  }

  const resetTradeResult = () => {
    if (tradeResult.team1Value !== 0 && tradeResult.team2Value !== 0) {
      setTradeResult(defaultTradeResult)
    }
  }

  // const extractLeagueSettings = (leagueDetails) => {
  //   const format = leagueDetails.settings.type === 'DYNASTY' || leagueDetails.settings.type === 'REDRAFT'
  //     ? leagueDetails.settings.type
  //     : 'DYNASTY'

  //   const scoring = leagueDetails.scoring_settings.pass_td === 4
  //     ? 'FULL'
  //     : (leagueDetails.scoring_settings.pass_td === 6
  //       ? 'NON'
  //       : 'HALF')

  //   return {
  //     format: format,
  //     scoring: scoring,
  //     enable_te_premium: false, // Assuming default value, adjust based on actual data structure
  //     enable_superflex: false, // Assuming default value, adjust based on actual data structure
  //     league_size: leagueDetails.total_rosters,
  //     qb: leagueDetails.roster_positions.filter((pos) => pos === 'QB').length,
  //     rb: leagueDetails.roster_positions.filter((pos) => pos === 'RB').length,
  //     wr: leagueDetails.roster_positions.filter((pos) => pos === 'WR').length,
  //     te: leagueDetails.roster_positions.filter((pos) => pos === 'TE').length,
  //     flex: leagueDetails.roster_positions.filter((pos) => pos === 'FLEX').length,
  //     bench: leagueDetails.roster_positions.filter((pos) => pos === 'BN').length,
  //   }
  // }

  //----------------------------------------------------------------Handlers-------------------------------------------------------------------------------------
  const closeModal = () => {
    if (!settings) {
      getSettings(true)
    }
    setIsModalOpen(false)
  }
  const openModal = () => setIsModalOpen(true)

  const toggleSyncPopover = () => {
    setShowSyncPopover(!showSyncPopover)
  }

  const handleSubmit = async () => {
    setIsLoading(true)

    const payload = {
      selectedExpert: selectedExpert || null,
      team1: team1Players.map((player) => ({
        elo_rating: player.elo,
        player_id: player.player_id,
        position: player.position,
        rank: player.commRank,
      })),
      team2: team2Players.map((player) => ({
        elo_rating: player.elo,
        player_id: player.player_id,
        position: player.position,
        rank: player.commRank,
      })),
    }

    try {
      setOutgoingPlayersGetting(
        team1Players.map((player) => player.fantasy_calc_id)
      )
      setOutgoingPlayersGiving(
        team2Players.map((player) => player.fantasy_calc_id)
      )
      setOutgoingPayload({
        ...tradeHistoryPayload,
        side1: team1Players[0]?.fantasy_calc_id,
        side2: team2Players[0]?.fantasy_calc_id,
      })

      const { team1, team2 } = await calculateTrade(payload)

      setTradeResult({
        team1Value: team1,
        team2Value: team2,
        message: getTradeResultMessage(team1, team2),
      })
    } catch (err) {
      if (err.response && err.response.status === 403) {
        setShowLimitModal(true)
      } else {
        console.error('Error:', err)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handlePlayerSelect = (player) => {
    setSelectedFullPlayer(player)
  }

  // const handleLeagueSelect = async (selectedLeague) => {
  //   try {
  //     const selectedLeagueDetails = leagueList.find((league) => league.league_id === selectedLeague.value)
  //     const newSettings = extractLeagueSettings(selectedLeagueDetails)
  //     setSettings(newSettings)
  //     getLeagueInfo(selectedLeague.value)
  //   } catch (error) {
  //     console.error('Error fetching new league settings:', error)
  //   }
  // }

  //----------------------------------------------------------------Conditional Styles-------------------------------------------------------------------------------------

  const getTradeResultMessage = (team1Value, team2Value) => {
    const difference = Math.abs(team1Value - team2Value)
    const averageValue = (team1Value + team2Value) / 2
    const percentageDifference = (difference / averageValue) * 100

    if (percentageDifference > 30) {
      return team1Value > team2Value
        ? 'Youre robbing them'
        : 'Theyre robbing you'
    } else if (percentageDifference > 20) {
      return team1Value > team2Value ? 'You win' : 'You lose'
    } else if (percentageDifference > 10) {
      return team1Value > team2Value ? 'You slightly win' : 'You slightly lose'
    } else {
      return 'Fair Trade'
    }
  }

  const getProgressBarStyles = () => {
    if (tradeResult.team1Value > tradeResult.team2Value) {
      // Winning trade styles
      return 'bg-lime-500 bg-opacity-10 border-[#7FC43AE5] border-[1px] shadow-green-glow'
    } else if (tradeResult.team1Value < tradeResult.team2Value) {
      // Losing trade styles
      return 'bg-[#DC54541A] border-[#DC5454E5] border-[1px] shadow-red-glow'
    } else {
      // Default or neutral trade styles
      return 'bg-zinc-100 bg-opacity-10 border-neutral'
    }
  }

  const getProgressBarColor = () => {
    if (tradeResult.team1Value > tradeResult.team2Value) {
      // Winning trade color
      return { fillBg: 'bg-green-500', backgroundBg: 'bg-[#7FC43A1A]' }
    } else if (tradeResult.team1Value < tradeResult.team2Value) {
      // Losing trade color
      return { fillBg: 'bg-red-500', backgroundBg: 'bg-[#DC54541A]' }
    } else {
      // Neutral trade color
      return { fillBg: 'bg-gray-500', backgroundBg: 'bg-neutral-500' }
    }
  }

  const isTeam1Winning = tradeResult.team1Value > tradeResult.team2Value
  const isTeam2Winning = tradeResult.team2Value > tradeResult.team1Value

  // Right before the return statement in the TradeCalc component
  const selectedExpertName =
    creatorList.find((creator) => creator.user_id === selectedExpert)
      ?.username || ''

  return (
    <div className="flex flex-col items-center gap-4 bg-light px-4 font-dmsans dark:bg-dark lg:h-[92vh]">
      {showLimitModal && <LimitModal />}

      <div className="flex w-full flex-col pt-10 lg:w-[65%]">
        <div className="flex justify-between">
          <h3 className="text-center text-lg font-bold text-light-text dark:text-dark-text lg:text-3xl">
            Trade Calculator
          </h3>
          <div className="flex items-center gap-4">
            <button
              className="hidden items-center gap-2 rounded-custom border-[1px] border-light2 p-3 px-5 text-sm font-bold text-light-text dark:border-dark-border dark:text-dark-text lg:flex"
              onClick={clearPlayerSelections}
            >
              <FaArrowRotateLeft
                className="fill-light-text dark:fill-dark-text"
                size={18}
              />
              Clear
            </button>
            <div className="flex lg:hidden" onClick={clearPlayerSelections}>
              <FaArrowRotateLeft
                className="fill-light-text dark:fill-dark-text2"
                size={18}
              />
            </div>
            {/* {leagueList.length > 0 ? (
              <div className="relative flex items-center gap-2">
                <div className="p-3 border-[1px] dark:border-dark-border border-light2 rounded-lg relative cursor-pointer">
                  <div onClick={toggleSyncPopover}>
                    <FaSync
                      className="text-4xl fill-dark dark:fill-light"  
                      size={18}
                    />
                  </div>
                  {showSyncPopover && (
                    <div className="absolute centered-popover">
                      <SyncPopover />
                    </div>
                  )}
                </div>
                <Select 
                  onSelect={handleLeagueSelect} 
                  options={leagueList.map((league) => ({ label: league.name, value: league.league_id }))}
                  placeholder="Select League"
                />

              </div>
            ) : (
              <div className="relative inline-block">
                <button
                  className="lg:flex hidden dark:text-dark-text gap-2 p-3 px-5 text-sm font-bold items-center border-[1px] dark:border-dark-border rounded-custom border-light2 text-light-text"
                  onClick={toggleSyncPopover}
                >
                  <FaArrowsRotate
                    className="fill-light-text dark:fill-dark-text"
                    size={18}
                  />
                  Sync my League
                </button>
                {showSyncPopover && <SyncPopover />}
              </div>
            )} */}
            <div
              className="relative inline-block lg:hidden"
              onClick={toggleSyncPopover}
            >
              <FaArrowsRotate
                className="fill-light-text dark:fill-dark-text2"
                size={18}
              />
              {showSyncPopover && <SyncPopover />}
            </div>

            <button
              className="hidden items-center gap-2 rounded-custom border-[1px] border-light2 p-3 px-5 text-sm font-bold text-light-text dark:border-dark-border dark:text-dark-text lg:flex"
              onClick={openModal}
            >
              <LiaCogSolid
                className="fill-light-text dark:fill-dark-text"
                size={22}
              />
              My League Settings
            </button>
            <div className="flex lg:hidden" onClick={openModal}>
              <LiaCogSolid
                className="fill-light-text dark:fill-dark-text2"
                size={22}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <CreatorSelect
            options={creatorList.map((creator) => ({
              ...creator,
              isDisabled: creator.disabled,
            }))}
            onChange={setSelectedExpert}
            value={selectedExpert}
          />
          <div className="flex w-full items-center justify-end pt-3">
            <button
              className="font-['DM Sans'] mb-4 flex w-[24%] items-center justify-center rounded-[11px] bg-accent px-12 py-3 text-sm font-bold text-white sm:w-[19%] lg:w-[18.5%]"
              onClick={handleSubmit}
            >
              {isLoading ? (
                <Triangle
                  ariaLabel="loading"
                  color="white"
                  height="30"
                  width="30"
                />
              ) : (
                'Submit'
              )}
            </button>
          </div>
        </div>
        <div
          className={`text-md flex w-full flex-col items-center rounded-xl px-4 py-6 text-light-text shadow dark:text-dark-text lg:px-[20%] ${getProgressBarStyles()}`}
        >
          <span>
            <span className="text-light-text dark:text-dark-text">
              {tradeResult.message}
            </span>
          </span>
          <div className="flex w-full items-center gap-4">
            {isTeam1Winning && <FaCrown className="text-2xl text-accent" />}
            <ProgressBar
              barColor={getProgressBarColor()}
              isTradeFavorable={isTeam1Winning}
              key={tradeResult.team1Value + tradeResult.team2Value}
              max={tradeResult.team1Value + tradeResult.team2Value}
              value={tradeResult.team1Value}
            />
            {isTeam2Winning && <FaCrown className="text-2xl text-accent" />}
          </div>
        </div>
      </div>
      {loggedIn ? (
        <CalcModal
          fetchSettings={getSettings}
          modalVisible={isModalOpen}
          onClose={closeModal}
          settings={settings}
          updateSettings={setSettings}
        />
      ) : (
        <SignInModal />
      )}

      <div className="w-full items-center justify-center text-zinc-100 lg:flex lg:px-[18%]">
        <TeamTradeCard
          isWinning={isTeam1Winning}
          onPlayersChange={setTeam1Players}
          onSelectPlayer={handlePlayerSelect}
          otherTeamPlayerCount={team2Players.length}
          ranks={combinedRanks}
          resetTradeResult={resetTradeResult}
          resetTrigger={resetTrigger}
          selectedExpertName={selectedExpertName}
          teamNumber="I"
        />
        <div className="z-20 -my-6 flex justify-center lg:-mx-6 lg:my-0">
          <span
            className="relative flex h-16 w-16 cursor-pointer items-center justify-center rounded-full border-2 border-light bg-accent dark:border-dark"
            onClick={handleSubmit}
          >
            <IoSync className="absolute h-full w-full text-center text-4xl leading-[80px]" />
            <FaRegHandshake className="relative z-10 text-2xl" />
          </span>
        </div>
        <TeamTradeCard
          isWinning={isTeam2Winning}
          onPlayersChange={setTeam2Players}
          onSelectPlayer={handlePlayerSelect}
          otherTeamPlayerCount={team1Players.length}
          ranks={combinedRanks}
          resetTradeResult={resetTradeResult}
          resetTrigger={resetTrigger}
          selectedExpertName={selectedExpertName}
          teamNumber="They"
        />
      </div>

      <div className="w-full bg-light pb-10 dark:bg-dark md:mx-[15%] md:px-[15%]">
        <TradeHistory
          isDynasty={true}
          payload={outgoingPayload}
          playersGetting={outgoingPlayersGetting}
          playersGiving={outgoingPlayersGiving}
          team1SelectedPlayer={team1Players[0]}
          team2SelectedPlayer={team2Players[0]}
        />
      </div>
      <PlayerInfoModal
        onClose={() => setSelectedFullPlayer(null)}
        player={selectedFullPlayer}
        ranks={combinedRanks}
        tab={'dynasty'}
      />
    </div>
  )
}

export default TradeCalc
