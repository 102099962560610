import React, { useEffect, useState } from 'react'
import { Triangle } from 'react-loader-spinner'
import { FaCircleCheck } from 'react-icons/fa6'
import { FaX } from 'react-icons/fa6'

import {
  validateYahooCode as validateYahooCodeAction,
  saveEspnCredentials as saveEspnCredentialsAction,
} from '../../store/api'

const YAHOO = 'YAHOO'
const ESPN = 'ESPN'

const EspnAccessProfile = () => {
  const [error, setError] = useState(false)
  const [saving, setSaving] = useState(true)

  const params = new URLSearchParams(window.location.search)
  const platform = params.get('platform')
  const textPlatform = platform.toUpperCase()

  useEffect(() => {
    if (textPlatform === ESPN) {
      const espnS2Value = params.get('espnS2')
      const SWIDValue = params.get('SWID')

      saveEspnCredentials(espnS2Value, SWIDValue)
    } else if (textPlatform === YAHOO) {
      const yahooCode = params.get('code')
      validateYahooCode(yahooCode)
    }
  }, [textPlatform])

  const saveEspnCredentials = async (espnS2, swid) => {
    try {
      await saveEspnCredentialsAction({ espnS2, swid })
      setSaving(false)

      setTimeout(() => {
        window.location.href = '/my-teams'
      }, 3000)
    } catch {
      setError(true)
    }
  }

  const validateYahooCode = async (code) => {
    try {
      await validateYahooCodeAction(code)
      setSaving(false)

      setTimeout(() => {
        window.location.href = '/my-teams'
      }, 3000)
    } catch (err) {
      setError(true)
    }
  }

  return (
    <div className="flex h-[90vh] items-center justify-center text-xl font-semibold text-light-text dark:text-dark-text">
      {!error && saving && (
        <div className="flex flex-col items-center gap-6">
          <img
            className="w-[250px]"
            src="https://static-site-images.s3.us-east-2.amazonaws.com/FF-REGULAR_V6.1.png"
          />
          <p className="flex items-center gap-6">
            <Triangle
              ariaLabel="loading"
              color="#E97933"
              height={50}
              width={50}
            />
            Saving your {textPlatform} credentials..
          </p>
        </div>
      )}
      {!saving && !error && (
        <div className="flex flex-col items-center gap-6">
          <img
            className="w-[250px]"
            src="https://static-site-images.s3.us-east-2.amazonaws.com/FF-REGULAR_V6.1.png"
          />
          <p className="flex items-center gap-6">
            <FaCircleCheck className="text-green-500" size={50} />
            Success! Redirecting you...
          </p>
        </div>
      )}
      {error && (
        <div className="flex flex-col items-center gap-6">
          <img
            className="w-[250px]"
            src="https://static-site-images.s3.us-east-2.amazonaws.com/FF-REGULAR_V6.1.png"
          />
          <p className="flex items-center gap-6">
            <FaX className="text-red-500" size={50} />
            There was an error trying to sync your account. Please notify us on
            Discord.
          </p>
        </div>
      )}
    </div>
  )
}

export default EspnAccessProfile
