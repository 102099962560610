import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Auth } from 'aws-amplify'

import { loginUser } from '../../store/userReducer'

import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function VerificationCodeComponent(props) {
  const [formCode, setCode] = useState('')
  const [errMsg, setErrMsg] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (props.open) {
      document.body.style.overflow = 'hidden' // Prevent scrolling
    } else {
      document.body.style.overflow = '' // Enable scrolling
    }

    // Cleanup function to reset overflow when component unmounts
    return () => {
      document.body.style.overflow = ''
    }
  }, [props.open])

  const handleCode = async () => {
    if (!formCode) {
      setErrMsg('Verification Code cannot be empty')
      return
    }
    try {
      await Auth.confirmSignUp(props.email, formCode)
      setErrMsg('')
      toast.success('Verification successful!', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      props.setOpen(false)
      await handleLogin()
    } catch (error) {
      console.log(error)
      if (error.code === 'CodeMismatchException') {
        setErrMsg('Invalid Verification Code Entered: Please try again')
      } else if (error.code === 'ExpiredCodeException') {
        setErrMsg('Verification Code Expired: Please try again')
      } else if (
        error.code === 'LimitExceededException' ||
        error.code === 'TooManyRequestsException'
      ) {
        setErrMsg(
          'Too Many Attempts: Please try to login with these credentials later'
        )
      } else {
        setErrMsg(
          'Unexpected Error: Please notify support of error ',
          error.code
        )
      }
      console.log('error confirming sign up', error)
    }
  }

  const handleLogin = async () => {
    const formEmail = props.email
    const formPass = props.pass
    if (!formEmail) {
      setErrMsg('Email cannot be empty')
      return
    } else if (!formPass) {
      setErrMsg('Password cannot be empty')
      return
    }

    try {
      await Auth.signIn(formEmail, formPass)
      navigate(props.route)
      dispatch(loginUser())
    } catch (error) {
      console.log('error signing up:', error)
    }
  }

  const resendCode = async () => {
    try {
      await Auth.resendSignUp(props.email)
      setErrMsg('')
    } catch (error) {
      console.log(error)
      if (error.code === 'LimitExceededException') {
        setErrMsg('Resend Limit Reached: Please try again after some time')
      } else {
        setErrMsg(
          'Unexpected Error: Please notify support of error ',
          error.code
        )
      }
    }
  }

  return (
    <div className="h-[95vh] bg-light dark:bg-dark">
      <ToastContainer />
      <div
        className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 ${props.open ? '' : 'hidden'} font-dmsans`}
      >
        <div className="mx-auto max-w-sm rounded-lg bg-white p-5 shadow-lg">
          <h2 className="mb-4 text-center text-lg font-semibold">
            Enter Verification Code
          </h2>
          <p>
            A verification code has been sent to your email. Please enter the
            code below to activate your account.
          </p>
          <div className="my-3 flex flex-col items-center">
            <input
              className="rounded-md border border-gray-300 px-3 py-2"
              onChange={(e) => setCode(e.target.value)}
              placeholder="Code"
              value={formCode}
            />
            {errMsg && <p className="mt-2 text-red-500">{errMsg}</p>}
          </div>
          <div className="mt-4 flex justify-around">
            <button
              className="rounded bg-accent px-4 py-2 text-white hover:bg-orange-600"
              onClick={handleCode}
            >
              Submit
            </button>
            <button
              className="rounded bg-gray-300 px-4 py-2 text-black hover:bg-gray-400"
              onClick={resendCode}
            >
              Resend
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default VerificationCodeComponent
