import React from 'react'

const BaseSelect = ({ onClick, highlight, label }) => {
  return (
    <button
      className={`rounded-full border-[1px] border-light2 px-[14px] py-2 text-[13px] transition-all duration-200 hover:bg-accent hover:text-light dark:border-dark-border ${
        highlight
          ? 'bg-accent font-bold text-dark-text'
          : 'text-light-text dark:text-dark-text'
      }`}
      onClick={onClick}
    >
      {label}
    </button>
  )
}

export default BaseSelect
