import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
// import { useSelector } from 'react-redux'
import MatchupPlayers from './MatchupPlayers'
// import { DndContext, closestCenter } from '@dnd-kit/core'
import {
  // SortableContext,
  // arrayMove,
  useSortable,
  // verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Triangle } from 'react-loader-spinner'
// import { IoMdArrowUp, IoMdArrowDown } from 'react-icons/io'
// import { IoClose, IoChevronDown } from 'react-icons/io5'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { getMatchup, submitMatchup } from '../../store/api'

const SortableUser = ({ player, submit }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: player.player_id })

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    touchAction: 'none',
  }
  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className="players"
      onClick={() => submit(player.player_id)}
      style={style}
    >
      <MatchupPlayers
        name={player.name}
        picture={player.picture}
        position={''}
        team={''}
      />
    </div>
  )
}

const formatMap = {
  // 'year': 'Rest of Season',
  superflex: 'Dynasty Superflex',
  qb: 'Dynasty 1QB',
  BEST_BALL: '2024 Redraft',
}

const MatchupModal = ({ onClose, modalVisible }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const query = new URLSearchParams(location.search)
  // const { is_creator, is_subscribed } = useSelector((state) => state.user)
  const formatFromUrl = query.get('format') || 'year'
  const displayFormatInitial = formatMap[formatFromUrl] || 'Rest of Season'

  const [displayFormat, setDisplayFormat] = useState(displayFormatInitial)
  // const [ isDropdownVisible, setDropdownVisible ] = useState(false)
  const [loading, setLoading] = useState(false)
  const [matchupID, setMatchupID] = useState('')
  const [players, setPlayers] = useState([])
  // const [ requiresSubmission, setRequiresSubmission ] = useState(!is_creator && !is_subscribed)

  // const formats = [ 'Rest of Season', 'Dynasty Superflex', 'Dynasty 1QB' ]
  const formats = ['Dynasty Superflex', 'Dynasty 1QB']

  useEffect(() => {
    fetchMatchupData()
  }, [formatFromUrl])

  useEffect(() => {
    if (modalVisible) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }

    return () => {
      document.body.style.overflow = ''
    }
  }, [modalVisible])

  const fetchMatchupData = async () => {
    try {
      setLoading(true)
      // Lowercase the format value to match your API's expected format
      const apiFormatValue = formatFromUrl.toLowerCase()

      // Fetch the matchup data using the API format value
      const res = await getMatchup(formatFromUrl)
      setMatchupID(res.matchup_id)

      // Process the data if it's in the expected format
      const data = res.players
      if (data && Array.isArray(data)) {
        const modifiedPlayers = data.map((player) => {
          let position = player.position?.slice(2, -2)
          return {
            ...player,
            id: player.player_id,
            Positions: position,
          }
        })
        setPlayers(modifiedPlayers)
      }

      // Set the display format based on the API format value
      switch (apiFormatValue) {
        // case 'year':
        //   setDisplayFormat('Rest of Season')
        //   break
        case 'superflex':
          setDisplayFormat('Dynasty Superflex')
          break
        case 'qb':
          setDisplayFormat('Dynasty 1QB')
          break
        default:
          break
      }
    } catch (error) {
      console.error('There was an error fetching the matchup data:', error)
      // Handle any errors that occur during fetch
    } finally {
      setLoading(false) // Ensure loading is set to false when operation is complete
    }
  }

  const postPlayerRanks = async (rankedPlayers) => {
    setLoading(true)
    const lowercaseFormat = formatFromUrl.toLowerCase()
    try {
      await submitMatchup({
        format: lowercaseFormat,
        matchupId: matchupID,
        rankings: rankedPlayers,
      })
    } catch (error) {
      console.error('There was a problem with the axios request:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = async (player_id) => {
    const rankedPlayers = players.map((player) => {
      let rank
      if (player_id === player.player_id) {
        rank = 1
      } else {
        rank = 2
      }

      return {
        player_id: player.player_id,
        rank,
      }
    })

    await postPlayerRanks(rankedPlayers)

    toast('👍 Thank You!', {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    })
    onClose()
    // setRequiresSubmission(false)
  }

  // function routeToRankings(event) {
  //   const rankingType = event.target.textContent
  //   let routeParam = ''

  //   setDisplayFormat(rankingType)

  //   switch (rankingType) {
  //   case 'Rest of Season':
  //     routeParam = 'year'
  //     break
  //   case 'Dynasty Superflex':
  //     routeParam = 'superflex'
  //     break
  //   case 'Dynasty 1QB':
  //     routeParam = 'qb'
  //     break
  //   default:
  //     break
  //   }

  //   navigate(`/rankings?format=${routeParam}`)
  // }

  // const onDragEnd = (event) => {
  //   const { active, over } = event
  //   if (active.id === over.id) {
  //     return
  //   }
  //   setPlayers((players) => {
  //     const oldIndex = players.findIndex((player) => player.id === active.id)
  //     const newIndex = players.findIndex((player) => player.id === over.id)
  //     return arrayMove(players, oldIndex, newIndex)
  //   })
  // }

  // const handleModalClose = () => {
  //   if (!requiresSubmission) {
  //     onClose()
  //   } else {
  //     // Optionally, display a message to the user explaining why they can't close the modal
  //     toast.error('Please submit at least one matchup to exit.', {
  //       position: 'top-center',
  //       autoClose: 3000,
  //     })
  //   }
  // }

  // const movePlayerUp = (index) => {
  //   if (index === 0) {return}
  //   setPlayers((prevPlayers) => {
  //     const newPlayers = [ ...prevPlayers ]
  //     const temp = newPlayers[index - 1]
  //     newPlayers[index - 1] = newPlayers[index]
  //     newPlayers[index] = temp
  //     return newPlayers
  //   })
  // }

  // const movePlayerDown = (index) => {
  //   if (index === players.length - 1) {return}
  //   setPlayers((prevPlayers) => {
  //     const newPlayers = [ ...prevPlayers ]
  //     const temp = newPlayers[index + 1]
  //     newPlayers[index + 1] = newPlayers[index]
  //     newPlayers[index] = temp
  //     return newPlayers
  //   })
  // }

  // const handleNewPlayers = async () => {
  //   await fetchMatchupData()
  // }

  return (
    <div
      className={
        'absolute inset-0 z-[1400] flex h-full w-full items-center justify-center overflow-y-auto bg-black bg-opacity-50 font-dmsans backdrop-blur-sm'
      }
    >
      <ToastContainer
        autoClose={5000}
        closeOnClick
        draggable
        hideProgressBar={false}
        newestOnTop={false}
        pauseOnFocusLoss
        pauseOnHover
        position="bottom-center"
        rtl={false}
        theme="dark"
      />
      <div
        className={`flex max-w-full flex-col items-center rounded-custom border-[1px] border-dark-border bg-dark bg-opacity-50 p-2 px-10 py-10 pb-10 text-center backdrop-blur-lg transition-opacity duration-500 ease-in-out ${
          modalVisible ? 'opacity-100' : 'invisible opacity-0'
        } max-h-[95vh] overflow-y-auto md:max-w-[80%]`}
      >
        <div className="flex flex-col items-center px-4 md:flex-row md:gap-4">
          <div className="relative">
            <span className="flex items-center text-4xl text-dark-text md:text-3xl">
              {displayFormat}
            </span>
            {/* {isDropdownVisible && (
              <div className="absolute top-full left-0 mt-2 w-full bg-custom-dark border-2 border-custom-orange rounded-lg shadow z-50 text-custom-orange bg-opacity-90 backdrop-blur-lg">
                {formats.map((f) => (
                  <div key={f}>
                    <div
                      className="p-2 hover:bg-custom-dark cursor-pointer text-center transition-all duration-300 border-0 rounded-lg"
                      onClick={routeToRankings}
                    >
                      {f}
                    </div>
                  </div>
                ))}
              </div>
            )} */}
          </div>
        </div>
        <p className="mt-2 text-center text-sm font-medium text-dark-text2 md:px-10">
          Who would you rather have? (This contributes to our free community
          rankings)
        </p>
        <div className="mt-4 flex">
          <div className="flex flex-col gap-2 md:flex-row" id="player-div">
            {players.map((player) => (
              <div className="flex" key={player.player_id}>
                <div className="flex items-center gap-4" key={player.player_id}>
                  <SortableUser player={player} submit={handleSubmit} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="absolute bottom-2 right-1/2 translate-x-[13px] transform">
          {loading && (
            <Triangle
              color="rgba(251, 113, 15, 1)"
              height={26}
              type="Triangle"
              visible={loading}
              width={26}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default MatchupModal
