import React, { createContext, useContext, useEffect, useState } from 'react'

const ThemeContext = createContext()

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(() => {
    const localTheme = window.localStorage.getItem('theme')
    return localTheme || 'dark'
  })

  useEffect(() => {
    window.localStorage.setItem('theme', theme)
    const root = document.documentElement
    const body = document.body

    if (theme === 'dark') {
      root.classList.add('dark')
      body.classList.add('bg-dark')
      body.classList.remove('bg-light')
    } else {
      root.classList.remove('dark')
      body.classList.add('bg-light')
      body.classList.remove('bg-dark')
    }
  }, [theme])

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export const useTheme = () => useContext(ThemeContext)
