import React, { useState } from 'react'
import { Document, Page } from 'react-pdf'

const PdfModal = ({ isOpen, onClose, file }) => {
  const [numPages, setNumPages] = useState(null)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  if (!isOpen) {
    return null
  }

  const pages = []
  for (let i = 1; i <= numPages; i++) {
    pages.push(
      <div className="mb-2" key={i}>
        <Page pageNumber={i} renderAnnotationLayer={false} />
      </div>
    )
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div className="flex h-[75%] w-[40%] flex-col items-center overflow-scroll rounded-lg bg-white shadow-xl">
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          {pages}
        </Document>
        <div className="flex justify-end p-2">
          <button
            className="rounded bg-accent px-4 py-2 font-bold text-white hover:bg-orange-700"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  )
}

export default PdfModal
