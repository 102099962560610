import React, { useState } from 'react'
import { Triangle } from 'react-loader-spinner'

const UpgradeModal = (props) => {
  const { onConfirm, onToggle: handleToggle } = props

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleConfirm = async () => {
    setIsSubmitting(true)
    await onConfirm()
    setIsSubmitting(false)
  }

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
      onClick={handleToggle}
    >
      <div className="flex flex-col items-center rounded-[25px] bg-light p-6 py-10 text-center text-light-text shadow-lg dark:bg-dark dark:text-dark-text md:w-[40%] xl:max-w-[20%]">
        <h2 className="mb-4 text-xl font-bold">Confirm Upgrade</h2>
        <p className="mb-4 px-8 text-sm text-light-text text-opacity-60 dark:text-dark-text">
          It looks like you are trying to upgrade your subscription. By
          confirming, your subscription will be upgraded and your card
          immediately charged.
        </p>
        <div
          style={{
            flexDirection: 'row',
            width: '80%',
            gap: '20px',
            display: 'flex',
          }}
        >
          <button
            className="rounded-[14px] bg-accent px-4 py-2 text-sm font-bold text-dark-text transition-all duration-300 hover:bg-orange-600 lg:w-[75%]"
            onClick={handleToggle}
            style={{ flex: 1 }}
          >
            Cancel
          </button>
          <button
            className="rounded-[14px] bg-accent px-4 py-2 text-sm font-bold text-dark-text transition-all duration-300 hover:bg-orange-600 lg:w-[75%]"
            onClick={handleConfirm}
            style={{ flex: 1 }}
          >
            {isSubmitting ? (
              <Triangle
                ariaLabel="loading"
                color="#FFF"
                height={30}
                width={30}
              />
            ) : (
              'Confirm'
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default UpgradeModal
