import { createAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
  // email: '',
  applied_promo_code: '',
  loggedIn: false,
  // verified: false,
  // is_creator: false,
  // nickname: '',
  // phone: '',
  // address: '',
  // subscription_end: '',
  // promoter_id: '',
  // promo_code: '',
  // subscriptions: undefined,
  // subscription_tier: 0,
  // userSub: '',
  // payments: undefined,
  // underdog_username: '',
  // underdog_deposit: false,
  // discord_id: 0,
  // sortFilter: '',
  // typeFilter: [],
  // postFilter: '',
  // role: 0
}

export const logout = createAction('user/logout')

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginUser: (state) => {
      state.loggedIn = true
    },
    setAppliedPromoCode: (state, { payload }) => {
      state.applied_promo_code = payload
    },
    setUsername: (state, { payload }) => {
      state.username = payload
    },
    setUser: (state, { payload }) => {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key]
      })
      state.loggedIn = true
    },
    updatePromoCode: (state, code) => {
      if (code.payload) {
        state.promo_code = code.payload
      }
    },
    // loginUser: (state, loginData) => {
    //   state.email = loginData.payload.email
    //   state.verified = loginData.payload.email_verified
    //   state.loggedIn = true
    //   state.nickname = loginData.payload.nickname

    //   //Optional attributes
    //   if(loginData.payload.is_creator === '1') {
    //     state.is_creator = true
    //   }

    //   if(loginData.payload.subscription_end) {
    //     state.subscription_end = loginData.payload.subscription_end
    //   }
    //   if(loginData.payload.promoter_id) {
    //     state.promoter_id = loginData.payload.promoter_id
    //   }
    //   if(loginData.payload.promo_code) {
    //     state.promo_code = loginData.payload.promo_code
    //   }
    //   if(loginData.payload.subscriptions) {
    //     state.subscriptions = loginData.payload.subscriptions

    //     const activeProduct = getProduct(state.subscriptions)
    //     if(activeProduct) {
    //       state.subscription_tier = activeProduct === stripeConstants.MOTHERFLOCKER_PRODUCT ? 2 : activeProduct === stripeConstants.FLOCKER_PRODUCT ? 1 : 0
    //     }
    //   }
    //   if(loginData.payload.payments) {
    //     state.payments = loginData.payload.payments
    //   }
    //   if(loginData.payload.applied_code) {
    //     state.applied_code = loginData.payload.applied_code
    //   }
    //   if(loginData.payload.underdog_username){
    //     state.underdog_username = loginData.payload.underdog_username
    //   }
    //   if(loginData.payload.Underdog_deposit){
    //     state.underdog_deposit = loginData.payload.Underdog_deposit === 1
    //   }
    //   if(loginData.payload.discord_id){
    //     state.discord_id = loginData.payload.discord_id
    //   }
    // },
    appliedUDCredit: (state, data) => {
      if (
        data.payload.statusCode === 422 &&
        data.payload.detail === 'Promotion will be awarded on deposit'
      ) {
        state.underdog_deposit = true
        state.underdog_username = data.payload.username
      } else if (data.payload.statusCode === 200) {
        state.underdog_username = data.payload.username
      }
    },
  },
})

export const {
  appliedUDCredit,
  setAppliedPromoCode,
  loginUser,
  setUser,
  setUsername,
  updatePromoCode,
} = userSlice.actions
export default userSlice.reducer
