import React, { useState, useEffect } from 'react'
import { FaCheck } from 'react-icons/fa6'

const Checkbox = ({ text = '', onChange, defaultChecked = false }) => {
  const [checked, setChecked] = useState(defaultChecked)

  useEffect(() => {
    setChecked(defaultChecked)
  }, [defaultChecked])

  const handleCheckboxChange = () => {
    const newChecked = !checked
    setChecked(newChecked)
    if (onChange) {
      onChange(newChecked)
    }
  }

  return (
    <div className="flex items-center">
      <div
        className={`flex h-[22px] w-[22px] cursor-pointer items-center justify-center rounded-[4px] shadow-[0_0px_1px] outline-none transition-all duration-200 md:h-[17px] md:w-[17px] ${checked ? 'bg-accent' : 'bg-white'}`}
        onClick={handleCheckboxChange}
      >
        {checked && <FaCheck className="fill-light" size={12} />}
      </div>
      <label
        className="my-auto cursor-pointer pl-[10px] text-sm leading-none text-light-text dark:text-dark-text"
        onClick={handleCheckboxChange}
      >
        {text}
      </label>
    </div>
  )
}

export default Checkbox
