import React, { useState } from 'react'
import { saveReviewFeedback } from '../../../store/api'
import { toast } from 'react-toastify'
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa6'

const FeedbackModal = ({ isOpen, onClose, reviewId }) => {
  const [comment, setComment] = useState('')
  const [feedback, setFeedback] = useState(null)

  if (!isOpen) {
    return null
  }

  const handleConfirm = async () => {
    try {
      const score = feedback === 'up' ? 1 : 0
      await saveReviewFeedback(reviewId, { comments: comment, score })
      toast.success('Feedback submitted successfully!')
      setComment('')
      setFeedback(null)
      onClose()
    } catch (error) {
      console.error('Failed to submit feedback:', error)
      toast.error('Error submitting feedback.')
    }
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-md rounded-lg bg-white p-8 text-light-text shadow-lg dark:bg-dark dark:text-dark-text">
        <h4 className="font-bold">Were you satisfied with the product?</h4>
        <h4 className="mt-2">We would love to hear why</h4>
        <textarea
          className="mt-4 w-full rounded-lg border border-gray-300 p-2 text-light-text transition focus:ring-2 focus:ring-accent"
          onChange={(e) => setComment(e.target.value)}
          placeholder="Your feedback here..."
          value={comment}
        />
        <div className="mt-4 flex justify-center gap-4">
          <button
            className={`flex items-center gap-4 rounded-lg px-4 py-2 ${feedback === 'up' ? 'bg-green-500 text-white' : 'bg-gray-400 hover:bg-gray-400'}`}
            onClick={() => setFeedback('up')}
          >
            <FaThumbsUp size={26} /> Thumbs Up
          </button>
          <button
            className={`flex items-center gap-4 rounded-lg px-4 py-2 ${feedback === 'down' ? 'bg-red-500 text-white' : 'bg-gray-400 hover:bg-gray-400'}`}
            onClick={() => setFeedback('down')}
          >
            <FaThumbsDown size={26} /> Thumbs Down
          </button>
        </div>
        <div className="mt-4 flex justify-end gap-4">
          <button
            className="rounded bg-accent px-4 py-2 text-dark-text hover:bg-orange-600"
            onClick={handleConfirm}
          >
            Submit
          </button>
          <button
            className="rounded bg-gray-300 px-4 py-2 hover:bg-gray-400 dark:bg-dark-bg2"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}

export default FeedbackModal
