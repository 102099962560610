import axios from 'axios'
import qs from 'qs'

const stack2BaseUrl = process.env.REACT_APP_API_2_BASE_URL

// Auth
export const exchangePromoCode = async (code) => {
  const { data } = await axios.get('/promoCode', {
    params: {
      code,
    },
  })
  return data
}

export const getUser = async () => {
  const { data } = await axios.get('/user')
  return data
}

export const saveUser = async (payload) => {
  await axios.post('/user', payload)
  return true
}

// Checkout
export const getStoreItems = async () => {
  const { data } = await axios.get('/store/products')
  return data
}

export const getItemDetails = async (itemId) => {
  const { data } = await axios.get(`/store/products/${itemId}`)
  return data
}

export const handleCheckout = async (payload) => {
  const { data } = await axios.post('/store/checkout', payload)
  return data
}

// Creators

export const createCode = async (codeData) => {
  const { data } = await axios.post('/promoCode', codeData)
  return data
}

export const getCreatorLastRankedByFormat = async (format) => {
  const { data } = await axios.get(`/creators?format=${format}`)
  return data
}

export const getCreatorRankings = async (format) => {
  const { data } = await axios.get('/creator/rankings', {
    params: {
      format,
    },
  })
  return data
}

export const getCreatorSubscriptionSummaries = async (type) => {
  const { data } = await axios.get(
    `/creator/subscriptions/summaries?type=${type}`
  )
  return data
}

export const getMonthlyIncomes = async (type) => {
  const { data } = await axios.get(`/getIncomes?type=${type}`)
  return data
}

export const getUserDetails = async (userId, type) => {
  const { data } = await axios.get(
    `/users/${userId}/payments/history?type=${type}`
  )
  return data
}

export const getTotalSubs = async () => {
  const { data } = await axios.get('/creator/subscriptions/totals')
  return data
}

export const getPostsByCreatorId = async (options) => {
  const { data } = await axios.get('/creator/posts', {
    params: options,
  })
  return data
}

export const submitRanking = async (payload) => {
  try {
    await axios.post('/creator/rankings', payload)
    console.log('Rankings submitted successfully')
  } catch (error) {
    console.error('Failed to submit rankings:', error)
  }
}

export const createVoucher = async (voucherData) => {
  const { data } = await axios.post('/vouchers', voucherData)
  return data
}

// Fantasy Calc

export const getTradeHistory = async (options) => {
  const url = 'https://api.fantasycalc.com/trades?maxPlayers=12&_source=flock'
  const paramsSerializer = (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  }

  // Call the paramsSerializer with the options and log the output
  const serializedParams = paramsSerializer(options)

  try {
    const response = await axios.get(url, {
      params: options,
      paramsSerializer: paramsSerializer,
    })

    return response.data
  } catch (error) {
    console.error('Error fetching trade history:', error)
    throw error
  }
}

// Matchups

export const getMatchup = async (format) => {
  const { data } = await axios.get(`/matchup?type=${format}`)
  return data
}

export const submitMatchup = async ({ format, matchupId, rankings }) => {
  await axios.post(`/matchup?type=${format}`, {
    rankings,
    matchupId,
  })
}

// Player Cards

export const createPlayerCardData = async (payload) => {
  const { data } = await axios.post('/playerCards/image', payload)
  return data
}

export const deletePlayerCard = async (imageId) => {
  await axios.delete(`playerCards/image/${imageId}`)
  return true
}

export const getPlayerCardsByCreator = async () => {
  const { data } = await axios.get('/playerCards/creator')
  return data
}

export const getPlayerCardByPlayerId = async (playerId) => {
  const { data } = await axios.get(`/playerCards/${playerId}`)
  return data
}

export const getPlayerRecords = async (player_id) => {
  const { data } = await axios.get(`/records/player?playerId=${player_id}`)
  return data
}

export const getPresignedUrlForPlayerCard = async (imageId, imageType) => {
  const { data } = await axios.post('/playerCards/url', {
    imageId: imageId,
    imageType: imageType,
  })
  return data
}

export const savePlayCardToS3 = async (presignedUrl, imageData) => {
  const awsAxios = axios.create({
    transformRequest: [
      (data, headers) => {
        delete headers['Authorization']
        return data
      },
    ],
  })

  await awsAxios.put(presignedUrl, imageData)
  return true
}

// Posts

export const createPost = async (postData) => {
  const { data } = await axios.post('/posts', postData)
  return data
}

export const deletePostById = async (postId) => {
  const { data } = await axios.delete(`/posts/${postId}`)
  return data
}

export const getPostById = async (options) => {
  const { data } = await axios.get('/posts', {
    params: options,
  })
  return data
}

export const getPosts = async (options) => {
  const { data } = await axios.get('/posts/all', {
    params: options,
  })

  return data
}

export const updatePost = async (postId, postData) => {
  const { data } = await axios.put(`/posts/${postId}`, postData)
  return data
}

// Products

export async function applyPromoCode(promoCodeDetails) {
  const { data } = await axios.post('/promoCode/apply', promoCodeDetails)
  return data
}

export const editPaymentInfo = async () => {
  const redirectData = {
    redirect_url: 'https://flockfantasy.com/account',
  }
  const { data } = await axios.post('/manageStripe', redirectData)
  window.location.href = data.url
}

export const handlePayment = async (payload) => {
  const { data } = await axios.post('/handlePayment', payload)
  return data
}

export const handleUpgrade = async (payload) => {
  const { data } = await axios.post('/subscriptions/upgrade', payload)
  return data
}

export const getProducts = async () => {
  const { data } = await axios.get('/getProducts')
  return data
}

export const getPromoCode = async (code) => {
  const { data } = await axios.get('/promoCode', {
    params: {
      code,
    },
  })
  return data
}

export const getTrialEligibility = async () => {
  const { data } = await axios.get('/trial')
  return data.eligible
}

// Rankings

export const getCommunityRankings = async (options) => {
  const { data } = await axios.get('/rankings/community', {
    params: {
      ...options,
    },
  })
  return data
}

export const getProspectRankings = async (format) => {
  const { data } = await axios.get('/ranks/prospects', {
    params: {
      format,
    },
  })
  return data
}

export const getRankings = async (format) => {
  const { data } = await axios.get('/rankings', {
    params: {
      format,
    },
  })
  return data
}

export const updatePlayerElo = async (payload) => {
  const { data } = await axios.put('/rankings/community', payload)
  return data
}

// Players

export const getAllPlayers = async () => {
  const { data } = await axios.get('/players')
  return data
}

export const getPositions = async () => {
  const { data } = await axios.get('/positions')
  return data
}

// Trade Calculator

export const calculateTrade = async (payload) => {
  const { data } = await axios.post('/calculateTrade', payload)
  return data
}

export const getCreators = async () => {
  const { data } = await axios.get('/trade/creators')
  return data
}

export const getTradeSettings = async () => {
  const { data } = await axios.get('/trade/settings')
  return data
}

export const saveTradeSettings = async (payload) => {
  const { data } = await axios.post('/trade/settings', payload)
  return data
}

export const updateTradeSettings = async (payload) => {
  const { data } = await axios.put('/trade/settings', payload)
  return data
}

// Trade Calculator Weights

export const getTCW = async () => {
  const { data } = await axios.get('/trade/weights')
  return data
}

export const updateTCWLeague = async (weights) => {
  const { data } = await axios.put('/trade/weights/league', weights)
  return data
}

export const updateTCWRoster = async (weights) => {
  const { data } = await axios.put('/trade/weights/roster', weights)
  return data
}

export const updateTCWScoring = async (weights) => {
  const { data } = await axios.put('/trade/weights/scoring', weights)
  return data
}

// User

export const getUserRole = async () => {
  const { data } = await axios.get('/user/role')
  return data
}

export const updateUsername = async ({ username }) => {
  const { data } = await axios.put('/user', {
    username,
  })
  return data
}

// User League

export const calculateLeague = async ({
  creatorId,
  leagueId,
  platform = 'SLEEPER',
}) => {
  const { data } = await axios.get('/user/league/calculate', {
    params: {
      creatorId,
      leagueId,
      platform,
    },
  })
  return data
}

export const createUserLeagueProfile = async (userLeagueInfo) => {
  const { data } = await axios.post(
    `${stack2BaseUrl}/user/league`,
    userLeagueInfo
  )
  return data
}

export const deleteUserLeagueProfile = async ({ leagueId, platform }) => {
  const { data } = await axios.delete(
    `${stack2BaseUrl}/user/league/${leagueId}/platform/${platform}`
  )
  return data
}

export const getUserLeagueProfiles = async () => {
  const { data } = await axios.get('/user/league')
  return data
}

export const saveEspnCredentials = async ({ espnS2, swid }) => {
  const { data } = await axios.post('/user/league/access-profile', {
    SWID: swid,
    espn_S2: espnS2,
  })
  return data
}

// Reviews

export const createReview = async (reviewData) => {
  const { data } = await axios.post('/reviews', reviewData)
  return data
}

export const getUserReviews = async (
  creatorId,
  order,
  productTypes,
  status,
  limit,
  page,
  search
) => {
  const { data } = await axios.get(
    `/users/${creatorId}/reviews?status=${status}&limit=${limit}&order=${order}&type=${productTypes}&page=${page}&search=${search}`
  )
  return data
}

export const getCreatorReviews = async (
  creatorId,
  order,
  productTypes,
  status,
  limit,
  page,
  search
) => {
  const { data } = await axios.get(
    `/creators/${creatorId}/reviews?status=${status}&limit=${limit}&order=${order}&type=${productTypes}&page=${page}&search=${search}`
  )
  return data
}

export const saveMessages = async (messageData) => {
  const { data } = await axios.post('/messages', messageData)
  return data
}

export const getPresignedUrlForReview = async () => {
  const { data } = await axios.get('/files/url')
  return data
}

export const getPresignedUrlForReviewImage = async () => {
  const { data } = await axios.get('/reviews/files/url')
  return data
}

export const saveFilesToS3 = async (presignedUrl, fileData) => {
  const awsAxios = axios.create({
    transformRequest: [
      (data, headers) => {
        delete headers['Authorization']
        return data
      },
    ],
  })

  const response = await awsAxios.put(presignedUrl, fileData)
  if (response.status === 200) {
    return { success: true }
  } else {
    return { success: false, status: response.status }
  }
}

export const updateReviewStatus = async (reviewId, payload) => {
  const { data } = await axios.put(`/reviews/${reviewId}`, payload)
  return data
}

export const saveTradeQuestionOptions = async (tradeQuestionId, payload) => {
  const { data } = await axios.put(`/trades/${tradeQuestionId}`, payload)
  return data
}

export const saveStartSitQuestionOptions = async (questionId, payload) => {
  const { data } = await axios.put(
    `/start-sit-questions/${questionId}`,
    payload
  )
  return data
}

export const saveReviewFeedback = async (reviewId, feedback) => {
  const { data } = await axios.post(`/reviews/${reviewId}/feedback`, feedback)
  return data
}

export const getVouchers = async (userId) => {
  const { data } = await axios.get(`/users/${userId}/vouchers`)
  return data
}

// Sleeper

export const getAllLeaguesByUserSleeperId = async (userId) => {
  const season = 2024
  return await axios.get(
    `https://api.sleeper.app/v1/user/${userId}/leagues/nfl/${season}`
  )
}

export const validateLeague = async (leagueId) => {
  await axios.get(`https://api.sleeper.app/v1/league/${leagueId}`)
}

export const validateUser = async (userId) => {
  return await axios.get(`https://api.sleeper.app/v1/user/${userId}`)
}

// Sync from platform

export const validateYahooCode = async (yahooCode) => {
  await axios.post(`${stack2BaseUrl}/user/league/validate/yahoo`, {
    code: yahooCode,
  })
}
