import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import TeamSelect from '../../../MyTeams/TeamSelect'
import SyncTeamsModal from '../../../MyTeams/SyncTeamsModal'
import {
  getUserLeagueProfiles,
  calculateLeague,
  handleCheckout,
  getRankings,
} from '../../../../store/api'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import ReviewSettingsModal from '../../../MyTeams/SubmitReview/ReviewSettingsModal'
import HorzontalDivider from '../../../_ui/HorozontalDivider'
import { ProgressBar } from 'react-loader-spinner'
import { TbArrowsLeftRight } from 'react-icons/tb'

import { PromoToId } from '../../../../static/PromoToId'
import { MdOutlineSettings } from 'react-icons/md'
import { CreatorOption } from '../CreatorOption'
import TradeTeam from './TradeTeam'

const promoOrder = {
  FLOCK: '38f6c2ae-6010-416a-8947-973a4ddc58e6',
  DOMAIN: '222696a0-038d-4689-a3e6-a97046cd2eec',
  LAND: '5ac59844-e390-46dd-9ecd-b50ecc3c0a08',
  FSE: 'e401f630-5049-4c83-9b0a-453c1c547ebc',
  TEST_DEV: 'b23841d4-f381-4569-8857-39b80406e7d5',
}

const MAX_SELECTED_PLAYERS = 6

const TeamReviews = (product) => {
  const location = useLocation()
  const navigate = useNavigate()

  const [teamNames, setTeamNames] = useState([])
  const [teamNameToId, setTeamNameToId] = useState({})
  const [comments, setComments] = useState('')
  const [selectedLeagueId, setSelectedLeagueId] = useState('')
  const [selectedTeamId, setSelectedTeamId] = useState('')
  const [placeholderLeagueName, setPlaceholderLeagueName] =
    useState('Flock League')
  const [profiles, setProfiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLinked, setIsLinked] = useState(false)
  const [teamOptions, setTeamOptions] = useState([])
  const [teamPicks, setTeamPicks] = useState([])
  const [tep, setTep] = useState(false)

  const [step, setStep] = useState(1)

  const [isOpen, setIsOpen] = useState(false)
  const [currentLeagueType, setCurrentLeagueType] = useState('')
  const [selectedPprTypes, setSelectedPprTypes] = useState('')
  const [selectedQbs, setSelectedQbs] = useState('')
  const [selectedTeamCounts, setSelectedTeamCounts] = useState('')
  const [lineupCount, setLineupCount] = useState('')
  const [selectedProducts, setSelectedProducts] = useState([])
  const [league, setLeague] = useState({})
  const [tradingTeamId, setTradingTeamId] = useState('')
  const [receivingTeamId, setReceivingTeamId] = useState('')
  const [team1SelectedPlayers, setTeam1SelectedPlayers] = useState([])
  const [team2SelectedPlayers, setTeam2SelectedPlayers] = useState([])
  const [playerPool1, setPlayerPool1] = useState([])
  const [playerPool2, setPlayerPool2] = useState([])
  const [allPlayers, setAllPlayers] = useState([])
  const [isSyncOpen, setIsSyncOpen] = useState(false)

  const [groupedProducts, setGroupedProducts] = useState({})

  useEffect(() => {
    fetchLeagueAndProfiles()
    fetchAllPlayers()
    const searchParams = new URLSearchParams(location.search)
    const leagueId = searchParams.get('leagueId')
    if (leagueId) {
      setSelectedLeagueId(leagueId)
    }
    setGroupedProducts(groupProductsByPromoterId(product.product, promoOrder))
  }, [location.search, product.product])

  useEffect(() => {
    if (selectedLeagueId && profiles.length > 0) {
      const league = profiles.find(
        (profile) => profile.league_id === selectedLeagueId
      )
      if (league) {
        setPlaceholderLeagueName(league.league_name)
      }
      getCalculatedLeague(selectedLeagueId)
    }
  }, [selectedLeagueId, profiles])

  useEffect(() => {
    if (tradingTeamId || receivingTeamId) {
      handleRemovePlayers()
    }
  }, [tradingTeamId, receivingTeamId])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])

  useEffect(() => {
    setSelectedLeagueId('')
    setIsLinked(false)
  }, [
    currentLeagueType,
    selectedPprTypes,
    selectedQbs,
    selectedTeamCounts,
    lineupCount,
    tep,
  ])

  const fetchLeagueAndProfiles = async () => {
    try {
      const profilesData = await getUserLeagueProfiles()
      setProfiles(profilesData)
      const names = profilesData.map((profile) => profile.league_name)
      const nameToId = profilesData.reduce((acc, profile) => {
        acc[profile.league_name] = profile.league_id
        return acc
      }, {})

      setTeamNames(names)
      setTeamNameToId(nameToId)
    } catch (error) {
      console.error('Failed to fetch creators and profiles:', error)
    }
  }

  const fetchAllPlayers = async () => {
    try {
      const response = await getRankings('ONEQB')

      // Remove duplicate players by player_id
      const uniquePlayersArray = Array.from(
        new Map(
          response.data.map((player) => [player.player_id, player])
        ).values()
      )

      // Sort players by average_rank
      uniquePlayersArray.sort(
        (a, b) => parseFloat(a.average_rank) - parseFloat(b.average_rank)
      )

      // Keep only the top 300 players
      const top300Players = uniquePlayersArray.slice(0, 300)

      setAllPlayers(top300Players)
    } catch (error) {
      console.error('Error fetching players:', error)
    }
  }

  const getCalculatedLeague = async (leagueId) => {
    try {
      const league = await calculateLeague({ creatorId: 'EXPERT', leagueId })
      setLeague(league)
      const userMap = league.settings.users.reduce((map, user) => {
        const teamName = user.team_name || user.display_name
        map[user.user_id] = {
          teamName,
          teamImage: user.team_image || '',
          displayName: user.display_name,
          userId: user.user_id,
        }
        return map
      }, {})

      const mergedData = Object.keys(league.values)
        .map((teamId) => {
          const user = userMap[teamId]
          return {
            teamId,
            teamName: user.teamName,
            teamImage: user.teamImage,
          }
        })
        .sort((a, b) => b.overall - a.overall)

      const teamOptions = createTeamOptions(mergedData)
      setTeamOptions(teamOptions)
    } catch (error) {
      console.error('Failed to fetch league:', error)
      return null
    }
  }

  //---------------------------------------------------------------- Utility Functions ------------------------------------------------------------------------------------

  const groupProductsByPromoterId = (products, promoOrder) => {
    // Group products by promoter ID
    const groupedProducts = products.reduce((acc, item) => {
      const promoterId = item.configuration.creator.promoter_id
      if (!acc[promoterId]) {
        acc[promoterId] = []
      }
      acc[promoterId].push(item)
      return acc
    }, {})

    // Sort each group's products by discount price or price
    for (const promoterId in groupedProducts) {
      groupedProducts[promoterId].sort((a, b) => {
        const priceA =
          a.discount_price !== undefined ? a.discount_price : a.price
        const priceB =
          b.discount_price !== undefined ? b.discount_price : b.price
        return priceA - priceB
      })
    }

    // Reorder the grouped products based on the promoOrder map
    const sortedGroupedProducts = Object.keys(promoOrder).reduce((acc, key) => {
      const promoterId = promoOrder[key]
      if (groupedProducts[promoterId]) {
        acc[promoterId] = groupedProducts[promoterId]
      }
      return acc
    }, {})
    return sortedGroupedProducts
  }

  const handleSortChange = (selectedOption) => {
    const value = teamNameToId[selectedOption.label]
    setIsLinked(true)
    setSelectedLeagueId(value)
  }

  const handleCommentsChange = (event) => {
    const inputComments = event.target.value
    if (inputComments.length <= 500) {
      setComments(inputComments)
    }
  }

  const handleRemovePlayers = () => {
    const receivingTeam = league.rosters[receivingTeamId]
    const tradingTeam = league.rosters[tradingTeamId]

    if (receivingTeam || tradingTeam) {
      // Combine players from both teams
      const selectedTeamPlayers = receivingTeam
        ? Object.values(receivingTeam).flatMap((pos) => pos)
        : []
      const tradingTeamPlayers = tradingTeam
        ? Object.values(tradingTeam).flatMap((pos) => pos)
        : []

      const allSelectedPlayers = [...selectedTeamPlayers, ...tradingTeamPlayers]

      // Filter out players from the combined teams
      const filteredAllPlayers = allPlayers.filter(
        (player) =>
          !allSelectedPlayers.some(
            (teamPlayer) => teamPlayer.player_id === player.player_id
          )
      )
      setAllPlayers(filteredAllPlayers)
    } else {
      console.error(
        'No teams found with the given IDs:',
        receivingTeamId,
        tradingTeamId
      )
      return null
    }
  }

  const getLeagueTypeId = (leagueType) => {
    let type = 1
    if (leagueType === 'year') {
      type = 1
    } else if (leagueType === 'Dynasty' && selectedQbs === 2) {
      type = 3
    } else if (leagueType === 'Dynasty') {
      type = 2
    }
    return type
  }

  const handleReviewCheckout = async () => {
    setIsLoading(true)

    const team1 = team1SelectedPlayers.map((player) => {
      return player.player_id
    })

    const team2 = team2SelectedPlayers.map((player) => {
      return player.player_id
    })

    const pool1 = playerPool1.map((player) => {
      return player.player_id
    })

    const pool2 = playerPool2.map((player) => {
      return player.player_id
    })

    if (!validateInputs()) {
      setIsLoading(false)
      return
    }

    let productDetails
    if (isLinked) {
      productDetails = {
        comments: String(comments),
        leagueId: String(selectedLeagueId),
        tradePlayers: team1,
        receivePlayers: team2,
        tradePool: pool1,
        receivePool: pool2,
        leagueSettings: {
          leagueTypeId: getLeagueTypeId('Dynasty'),
          leagueType: 'Dynasty',
          ppr: league.settings.scoring,
          qbs: league.settings.is_superflex ? 2 : 1,
          teamCounts: league.settings.league_size,
          tep: league.settings.tep ? true : false,
          lineupCount: league.settings.num_starters,
        },
        type: 'trade',
      }
    } else {
      productDetails = {
        comments: String(comments),
        leagueSettings: {
          leagueTypeId: getLeagueTypeId(currentLeagueType),
          leagueType: currentLeagueType,
          ppr: selectedPprTypes,
          qbs: selectedQbs,
          teamCounts: selectedTeamCounts,
          tep: tep,
          lineupCount: lineupCount,
        },
        tradePlayers: team1,
        receivePlayers: team2,
        tradePool: pool1,
        receivePool: pool2,
        type: 'trade',
      }
    }

    const payload = {
      products: selectedProducts.map((product) => {
        const group = groupedProducts[product.configuration.creator.promoter_id]
        const productsWithinBudget = group.filter(
          (p) => p.discount_price || p.price <= product.price
        )
        const bestProduct = productsWithinBudget.reduce(
          (best, current) =>
            current.discount_price > best.discount_price ||
            current.price > best.price
              ? current
              : best,
          productsWithinBudget[0] || product
        )

        return {
          id: bestProduct.id,
          price: product.price,
          discountId: bestProduct?.discount?.id || null,
          name: bestProduct.name,
          promoterId: bestProduct.configuration.creator.promoter_id,
        }
      }),
      productDetails: productDetails,
    }

    try {
      const res = await handleCheckout(payload)
      window.location.href = res.url
    } catch (error) {
      console.error('Error submitting review:', error)
      if (error.response && error.response.data) {
        console.error('Server response:', error.response.data)
      }
      toast.error('Error submitting review.')
    }

    setIsLoading(false)
  }

  const validateInputs = () => {
    if (!isLinked) {
      if (
        !currentLeagueType ||
        !selectedPprTypes ||
        !selectedQbs ||
        !selectedTeamCounts
      ) {
        toast.error('Please select all league settings.')
        return false
      }
    }
    if (!team1SelectedPlayers.length || !team2SelectedPlayers.length) {
      toast.error('Please add players to both teams.')
      return false
    }
    return true
  }

  const handleContinueClick = () => {
    if (validateInputs()) {
      setStep(2)
    }
  }

  const createTeamOptions = (transformedData) => {
    if (!transformedData) {
      return []
    }
    return transformedData.map((team) => ({
      label: team.teamName || team.displayName,
      id: team.teamId,
      teamImage: team.teamImage,
    }))
  }

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const handleLeagueTypeChange = (type) => {
    setCurrentLeagueType(type)
  }

  const handleTeamSelectionChange = (teams) => {
    setSelectedTeamCounts(teams)
  }

  const toggleTep = () => {
    setTep((prev) => !prev)
  }

  return (
    <div className="flex flex-col items-center bg-light px-4 py-16 font-dmsans text-light-text dark:bg-dark dark:text-dark-text">
      {step === 1 && (
        <body className="flex flex-col gap-10 md:px-[10vw]">
          <div className="grid gap-2">
            <h3 className="text-xl font-bold md:text-3xl">
              Tell us about your team!
            </h3>
            <span className="max-w-64 text-light-text2 dark:text-dark-text2">
              {product.product[0].description}
            </span>
            <HorzontalDivider />
          </div>
          <p className="flex flex-col gap-2">
            <div className="flex w-full flex-col items-center gap-4 md:flex-row md:gap-10">
              {!isLinked && (
                <p className="flex flex-col items-start gap-2">
                  <h3 className="text-xl font-bold">My League Settings</h3>
                  <div className="flex flex-col gap-4 md:flex-row md:gap-10">
                    <div className="border-light-border flex h-[50px] w-[200px] max-w-full items-center gap-2 rounded-lg border-[1px] bg-zinc-300 transition-all duration-200 dark:border-dark-border dark:bg-dark hover:dark:bg-accent-bg">
                      <button
                        className="flex w-full cursor-pointer items-center justify-center gap-2 rounded-custom p-2 text-white"
                        onClick={openModal}
                      >
                        <MdOutlineSettings
                          className="text-light-text dark:text-dark-text"
                          size={24}
                        />
                        <span className="font-bold text-light-text dark:text-dark-text">
                          Choose Settings
                        </span>
                      </button>
                    </div>
                  </div>
                </p>
              )}
              {!isLinked && (
                <span className="pt-6 font-bold text-accent">OR</span>
              )}
              <div className="relative z-20 flex flex-col gap-2">
                <h3 className="text-xl font-bold">Link My League</h3>
                <TeamSelect
                  defaultValue={selectedLeagueId}
                  footer={true}
                  onAddNewTeam={() => setIsSyncOpen(true)}
                  onSelect={handleSortChange}
                  options={teamNames}
                  placeholder={placeholderLeagueName}
                />

                {teamNames.length === 0 && (
                  <span className="text-light-text2 dark:text-dark-text2">
                    Please add a league to your profile
                    <a
                      className="cursor-pointer font-bold text-accent"
                      onClick={() => navigate('/my-teams')}
                    >
                      {' '}
                      Here
                    </a>
                  </span>
                )}
              </div>
            </div>
          </p>
          <p className="">
            <h2 className="pb-2 text-xl font-semibold">Propose a Trade</h2>
            <span className="flex flex-col items-center gap-4 md:flex-row md:items-end">
              {!isLinked && (
                <div className="h-[500px]">
                  <h2 className="text-lg font-semibold text-accent">
                    Your Assets
                  </h2>
                  <TradeTeam
                    allPlayers={allPlayers}
                    isLinked={isLinked}
                    league={league}
                    players={playerPool1}
                    setPlayers={setPlayerPool1}
                    teams={teamOptions}
                    text={'Any other players you may consider trading...'}
                  />
                </div>
              )}

              <div className="h-[450px]">
                <h2 className="pt-4 text-lg font-semibold text-accent md:pt-0">
                  Your Team
                </h2>
                <TradeTeam
                  allPlayers={allPlayers}
                  allowDuplicates={true}
                  isLinked={isLinked}
                  league={league}
                  maxPlayers={MAX_SELECTED_PLAYERS}
                  players={team1SelectedPlayers}
                  pool={playerPool1}
                  setPlayers={setTeam1SelectedPlayers}
                  setPool={setPlayerPool1}
                  setSelectedTeamId={setTradingTeamId}
                  teams={teamOptions}
                  text={'Add your offered players...'}
                />
              </div>
              <div className="flex items-center pt-10 md:h-[400px] md:pt-0">
                <TbArrowsLeftRight size={50} />
              </div>
              <div className="h-[450px]">
                <h2 className="text-lg font-semibold text-accent">
                  Their Team
                </h2>
                <TradeTeam
                  allPlayers={allPlayers}
                  allowDuplicates={true}
                  isLinked={isLinked}
                  league={league}
                  players={team2SelectedPlayers}
                  pool={playerPool2}
                  setPlayers={setTeam2SelectedPlayers}
                  setPool={setPlayerPool2}
                  setSelectedTeamId={setReceivingTeamId}
                  teams={teamOptions}
                  text={'Add what you want in return...'}
                />
              </div>
              {!isLinked && (
                <div className="h-[500px]">
                  <h2 className="pt-4 text-lg font-semibold text-accent">
                    Their Assets
                  </h2>
                  <TradeTeam
                    allPlayers={allPlayers}
                    isLinked={isLinked}
                    league={league}
                    players={playerPool2}
                    pool={true}
                    setPlayers={setPlayerPool2}
                    teams={teamOptions}
                    text={'Any other players you may consider receiving...'}
                  />
                </div>
              )}
            </span>
          </p>
          <div className="flex items-center justify-end pt-10">
            {isLoading ? (
              <ProgressBar
                barColor="#E97933"
                borderColor="#BB8360"
                height={75}
                type="Oval"
                width={100}
              />
            ) : (
              <button
                className="flex items-center gap-2 rounded-lg bg-accent px-24 py-3 text-dark-text"
                onClick={handleContinueClick}
              >
                Continue
              </button>
            )}
          </div>
        </body>
      )}
      {step === 2 && (
        <CreatorOption
          comments={comments}
          goBack={() => setStep(1)}
          groupedProducts={groupedProducts}
          handleCommentsChange={handleCommentsChange}
          handleReviewCheckout={handleReviewCheckout}
          isLoading={isLoading}
          product={product.product}
          setSelectedProducts={setSelectedProducts}
        />
      )}
      <ReviewSettingsModal
        currentLeagueType={currentLeagueType}
        isOpen={isOpen}
        lineupCount={lineupCount}
        onClose={closeModal}
        onTeamSelectionChange={handleTeamSelectionChange}
        selectLeagueType={handleLeagueTypeChange}
        selectedPprTypes={selectedPprTypes}
        selectedQbs={selectedQbs}
        selectedTeamCounts={selectedTeamCounts}
        setLineupCount={setLineupCount}
        setSelectedPprTypes={setSelectedPprTypes}
        setSelectedQbs={setSelectedQbs}
        tep={tep}
        toggleTep={toggleTep}
      />
      <SyncTeamsModal
        onLeagueSubmitted={() => {
          fetchLeagueAndProfiles()
        }}
        isOpen={isSyncOpen}
        onClose={() => setIsSyncOpen(false)}
      />
    </div>
  )
}

export default TeamReviews
