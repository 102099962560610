import React from 'react'

function TeamSelect({ selectedTeamCount, onTeamSelect }) {
  const handleTeamSelect = (count) => {
    if (onTeamSelect) {
      onTeamSelect(count)
    }
  }

  const buttonClass = 'rounded-full p-3 h-[40px] w-[40px] text-custom-font'
  const selectedButtonClass =
    buttonClass + ' bg-accent text-dark-text font-medium'
  const notSelectedButtonClass = buttonClass + ' bg-[#E9793330] text-accent'

  const teamCounts = [8, 10, 12, 14, 16]

  return (
    <div className="flex flex-col gap-2">
      <div className="text-md font-medium">Teams</div>
      <div className="flex gap-1 rounded-full p-2">
        {teamCounts.map((count) => (
          <button
            className={
              selectedTeamCount === count.toString()
                ? selectedButtonClass
                : notSelectedButtonClass
            }
            key={count}
            onClick={() => handleTeamSelect(count.toString())}
          >
            {count}
          </button>
        ))}
      </div>
    </div>
  )
}

export default TeamSelect
